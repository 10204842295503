import React from "react";
import { Container } from "react-bootstrap";
import { MetaTags } from "react-meta-tags";

const styles = {
    p: {
        marginRight: 25
    },
    pp: {
        marginRight: 40
    }
};

const Privacy = () => {

    return (
        <Container>
            <MetaTags>
                <title>תנאי השימוש באתר קידוש החודש לרמב"ם</title>
                <meta name="description" content='תנאי השימוש והפרטיות באתר קידוש החודש לרמב"ם.' />
            </MetaTags>

            <h1 dir="rtl" className="text-center">תנאי שימוש ופרטיות</h1>
            <h2 dir="rtl">1. ברוכים הבאים:</h2>
            <p dir="rtl" style={styles.p}>
                הלל סקולניק בעל אתרי קידוש החודש לרמב"ם מברך אותך על שבחרת להשתמש באתר זה ומאחלת לך עיון נעים ומהנה בתכני האתר.
            </p>
            <p dir="rtl" style={styles.p}>
                בטרם גלישה באתר, לרבות עיון וקריאת תכניו, ביצוע כל פעולה בו כגון הזמנת שירותים ו/או מוצרים כלשהם, רכישה, תגובה לכתבה, השתתפות בפורום, או השתתפות בפעילות אינטראקטיבית כלשהי, הינך מתבקש לקרוא את תנאי השימוש המפורטים להלן וכן תנאי שימוש יעודיים במידה וקיימים.
            </p>
            <p dir="rtl" style={styles.p}>
                אם יש לך שאלה או אי בהירות כלשהי, תוכל להפנות את שאלתך בדואר-אלקטרוני: site@kiddushhachodesh.net
            </p>
            <p dir="rtl" style={styles.p}>
                תנאי השימוש מנוסחים מטעמי נוחות בלשון זכר, והם מתייחסים לגברים ולנשים כאחד.
            </p>
            <h2 dir="rtl">2. מונחים והגדרות:</h2>
            <p dir="rtl" style={styles.p}>
                למונחים המפורטים להלן, תהא בתנאי שימוש אלו המשמעות המופיעה בצידן, אלא אם כן נאמר מפורשות אחרת:
            </p>
            <p dir="rtl" style={styles.p}>1. "אתר" קידוש החודש לרמב"ם נכללים האתרים הבאים http://kiddushhachodesh.net https://hokshamayim.com https://kiddushhodesh.com, שנרשמו ע"י הלל סקולניק.</p>
            <p dir="rtl" style={styles.p}>2. "תכנים" או "מידע" - התכנים המופעים באתר לרבות טקסט, תמונות, קטעי ודיאו, קטעי אודיו, תוכנות, קבצי גרפים, ישומים, עיצובים וכו'.</p>
            <p dir="rtl" style={styles.p}>3. "המשתמש" או "הגולש" – מי שקרוא ו/או מעיין בתכני האתר או באפליקציה, בין במישרין ובין בעקיפין, באמצעות מחשב ו/או אמצעי אחר לרבות תקשורת סלולרית.</p>
            <p dir="rtl" style={styles.p}>4. "רשימה" / "רשימות" / "מאגר מידע" – רשימה/ות של אנשים ו/או מוסדות מוסדות ו/או גופים ו/או קבוצות המתפרסמות באתר באופן גלוי ו/או בבסיס הנתונים של האתר, בין שכוללת פרטים כלשהם ליצירת קשר והתכתבות בדרך כלשהי, ובין שאינה כוללת פרטים כאמור.</p>

            <h2 dir="rtl">3. מידע ותכנים באתר:</h2>
            <p dir="rtl" style={styles.p}>אתר קידוש החודש לרמב"ם משתדל שהמידע יהיה אמין ומדויק, אם כי יתכנו שיבושים ואי דיוקים, בין היתר, בשל תקלות טכניות, טעויות אנוש ו/או מעשים/מחדלים של צדדים שלישיים. מובהר בזאת כי אתר קידוש החודש לרמב"ם לא יהיה אחראי לכל נזק עקיף ו/או ישיר שיגרם למשתמש כתוצאה מהסתמכות ו/או שימוש בתכנים ובמידע המתפרסם באתר.</p>

            <h2 dir="rtl">4. אחריות על תכנים ומידע:</h2>
            <p dir="rtl" style={styles.p}>אף על פי שאתר קידוש החודש עושה כל השתדלות שהמידע יהיה מדויק, ופעולת האתר וכן כל תוכנה יישום, קוד מחשב שסופק על ידי האתר יפעלו פעולה תקינה. אין האתר לוקח אחריות על שום נזק שנגרם, מתקלה או מפעולה לא תקינה של אחד ממוצריו. וכן אינו מתחייב בשום אופן לספק גרסה חלופית, לתוכנה שנמצאה לא תקינה או גורמת נזק כל שהוא.</p>

            <h2 dir="rtl">5. זכויות יוצרים וקניין רוחני:</h2>
            <p dir="rtl" style={styles.p}>1. כל זכויות הקניין הרוחני לרבות זכויות היוצרים בתכנים לרבות בעיצוב האתר, ובכל תוכנה, יישום, קובץ גרפי, קוד מחשב, טקסט, תמונה, משחקים, קטעי וידיאו קטעי אודיו - יהיו קניינו של אתר קידוש החודש לרמב"ם (הלל סקולניק) בלבד.</p>
            <p dir="rtl" style={styles.p}>2. אין להעתיק, להפיץ להציג בפומבי או לעשות שימוש בתכנים הנזכרים הנ"ל ללא קבלת הסכמת אתר קידוש החודש לרמב"ם (הלל סקולניק) מראש ובכתב, לרבות העתקה מלאה ו/או חלקית; שימוש בסימני מסחר ו/או לוגו; איחסון בארכיון; תירגום; עריכה מחדש וכיוצא בזה, וזאת למעט שימוש עצמי. </p>

            <h2 dir="rtl">6. פירסום:</h2>
            <p dir="rtl" style={styles.p}>1. המשתמש מסכים שאתר קידוש החודש לרמב"ם תכלול פרסום באתר, לרבות באמצעות פירסום "באנרים" ו/או כתבות פירסומיות ו/או כל שימוש אחר שאתר קידוש החודש לרמב"ם ימצא לנכון כגון פרסומות קופצות, חלונות נוספים הנפתחים בלא שהמשתמש עשה לשם כך דבר, פירסום קולי; וידאו, פלש וכו'. </p>
            <p dir="rtl" style={styles.p}>2. המשתמש מסכים שאתר קידוש החודש לרמב"ם תכלול פרסום באתר, לרבות באמצעות פירסום "באנרים" ו/או כתבות פירסומיות ו/או כל שימוש אחר שאתר קידוש החודש לרמב"ם ימצא לנכון כגון פרסומות קופצות, חלונות נוספים הנפתחים בלא שהמשתמש עשה לשם כך דבר, פירסום קולי; וידאו, פלש וכו'. </p>

            <h2 dir="rtl">7. מידע שמתקבל מגולשים:</h2>
            <p dir="rtl" style={styles.p}>1. שאתר קידוש החודש לרמב"ם מאפשרת לגולשים, בחלקים שונים באתר, למסור ולפרסם מידע ותכנים (כגון: תגובות לכתבות / מאמרים, מודעות, תמונות, הודעות, פורומים, צ'טים וכיו"ב) (להלן - "התכנים של הגולשים"). </p>
            <p dir="rtl" style={styles.p}>2. הגולש מתחייב בזאת, כי התכנים של הגולשים שיוזנו על ידו הינם בהתאם לכל דין, לרבות העדר לשון הרע ו/או הוצאת דיבה ו/או הפרת זכויות יוצרים ו/או פגיעה בפרטיות ו/או צו איסור פירסום. </p>
            <p dir="rtl" style={styles.p}>3. הגולש מתחייב בזאת לשאת באחריות ביחס לתכנים של הגולשים שנמסרו על ידו וזאת לשלילת אחריות שאתר קידוש החודש לרמב"ם. בנוסף מתחייב הגולש לשפות את שאתר קידוש החודש לרמב"ם בגין כל תביעה ו/או דרישה ו/או נזק ו/או הפסד שיגרמו כתוצאה מאי קיום הוראות תנאי השימוש ו/או מהפרת הוראות החוק ו/או מדרישת צד ג' כלשהו ו/או מפרסום מוטעה, וזאת לרבות הוצאות משפטיות. השיפוי ישולם מיד עם דרישתה הראשונה של שאתר קידוש החודש לרמב"ם. </p>
            <p dir="rtl" style={styles.p}>4. הגולש בביצוע פעולת מסירת התכנים של הגולשים לאתר מצהיר בזאת במפורש כי הינו בעל מלוא זכויות הקניין הרוחני בתכנים, והוא מרשה בעצם מסירת התכנים לאתר שאתר קידוש החודש לרמב"ם - לעשות שימוש מכל סוג בתכנים, לרבות פרסומם ו/או הסרתם מהאתר ו/או פרסומם בכל מדיה אחרת שאתר קידוש החודש לרמב"ם תמצא לנכון, לרבות לצדדים שלישים ולכל גורם שהוא. </p>

            <h2 dir="rtl">8. תקלות ואבטחת מידע:</h2>
            <p dir="rtl" style={styles.p}>1. הגולש מצהיר כי ידוע לו שיתכנו נסיבות, בין בשליטת אתר קידוש החודש לרמב"ם ובין שלא בשליטתה, לרבות נפילת שרתים, הפסקות חשמל, פריצת גורמים שאינם מורשים לאתר וטעויות אנוש, בהן יאבד מידע ו/או יוצג מידע חלקי ו/או מוטעה ו/או לא תתאפשר הגלישה באתר ו/או ילקחו פרטים שנמסרו על ידו לאתר, וכל תקלה אפשרית אחרת, זאת מתוך הכרה במגבלות הקיימות באינטרנט.</p>
            <p dir="rtl" style={styles.p}>2. בהמשך לאמור ומתחייב הגולש שלא לתבוע ו/או לטעון כנגד אתר קידוש החודש לרמב"ם ו/או צד שלישי כלשהו בגין אירועים הנזכרים לעיל. </p>

            <h2 dir="rtl">9. שינויים בתנאי השימוש:</h2>
            <p dir="rtl" style={styles.p}>אתר קידוש החודש לרמב"ם רשאית לשנות לפי שיקול דעתו הבלעדי, מעת לעת, את תנאי השימוש באתר. </p>

            <h2 dir="rtl">10. הגבלת התיישנות:</h2>
            <p dir="rtl" style={styles.p}>בהתחשב באופי השימוש ברשת האינטרנט, מסכים בזאת המשתמש, כי כל תביעה /ואו דרישה נגד אתר קידוש החודש לרמב"ם תוגבל לתקופה של 6 חודשים ממועד פרסומה ו/או הופעתה באתר, והצדדים רואים בכך הסכם לתקופת התיישנות כמשמעו לפי כל דין וחוק.</p>

            <h2 dir="rtl">11. הגבלת אחריות:</h2>
            <p dir="rtl" style={styles.p}>מובהר בזאת, כי המשתמש פוטר בזאת מאחריות את ההנהלה, ו/או מנהלי ו/או עובדי ו/או נושאי משרה ו/או כל  חבר או נושא תפקיד של אתר קידוש החודש לרמב"ם, וכי כל הגבלה על האחריות של אתר קידוש החודש לרמב"ם, הכוונה גם להגבלת של כל הנזכרים לעיל. </p>

            <h2 dir="rtl">12. בוררות מוסכמת:</h2>
            <p dir="rtl" style={styles.p}>1. המשתמש מצהיר בזאת כי הינו מוכן למסור לבוררות, כמשמעו לפי חוק הבוררות, ולפי כל חוק ודין, כל עניין ומחלוקת הנובעת מהשימוש באתר.</p>
            <p dir="rtl" style={styles.p}>2. המשתמש מצהיר בזאת, בהמשך לאמור, כי הינו מוכן ומסכים לכך כי הבוררות תיעשה בפני בית דין רבני חב"ד (ע"ר) בישראל, בהרכב של שלושה רבנים לפחות, כהחלטת מזכירות בית הדין, וכי כל פסיקה של בית הדין תהיה מקובלת עליו, אף בלא חובת נימוק. </p>
            <p dir="rtl" style={styles.p}>3. השימוש באתר זה מהווה הסכמה, וקנין מועיל כמשמעו ההכלתי, כנעשה בבית דין חשוב, לכל האמור בדף זה. </p>

            <h2 dir="rtl">13. מדיניות פרטיות, רישום לאתר וקשר עם הגולשים:</h2>
            <p dir="rtl" style={styles.p}>1. אתר קידוש החודש לרמב"ם תהיה רשאית להתנות את הגלישה לאתר כולו ו/או לחלקים ממנו, ברישום אשר יכלול פרטים מזהים של הגולשים ופרטים נוספים בהתאם לשיקול דעתה. הפרטים אשר חובה למלאם יצויינו בסימן * ו/או בצבע שונה ו/או באופן ברור כלשהו. במידה והמשתמש לא ימסור את הנתונים בשדות החובה לא יתאפשר לו לגלוש באתר ו/או בחלק ממנו, כפי שיקבע על ידי אתר קידוש החודש לרמב"ם מעת לעת. </p>
            <p dir="rtl" style={styles.p}>2. אתר קידוש החודש לרמב"ם תהיה רשאית לדרוש את חידוש הרישום ו/או פרטים ביחס אליהם לפי שיקול דעתה הבלעדי.</p>
            <p dir="rtl" style={styles.p}>3. אתר קידוש החודש לרמב"ם רשאית לפי שיקול דעתה הבלעדי, למנוע גישה לאתר במקרה של שימוש שלא כדין או בכל מקרה אחר של שימוש לרעה ו/או פגיעה שלא כדין ו/או רישום כוזב ו/או רשלני לאחד או יותר מן האתרים, וזאת מבלי לגרוע מכל סעד אחר.</p>
            <p dir="rtl" style={styles.p}>4. הנתונים שימסרו הגולש בעת הרישום ו/או בעת ביצוע פעולות אחרות באתר ישמרו במאגר המידע של אתר קידוש החודש לרמב"ם הרשום כדין.</p>
            <p dir="rtl" style={styles.p}>5. הגולש זכאי לעיין בפרטיו הרשומים במאגר המידע, לבקש לתקן את הרישום או לבקש את מחיקתו ממאגר המידע. לשם כך עליו לפנות בדואר-אלקטרוני: site@kiddushhachodesh.net </p>
            <p dir="rtl" style={styles.p}>6. אתר קידוש החודש לרמב"ם משתמשת באתר ובאפליקציה בטכנולוגיות ונוהלי הפעלה מקובלים של אבטחת מידע. יחד עם זאת, אין חסימה מוחלטת מכניסה לא מורשית למאגרי המידע, והגולש מוותר בזאת על כל תביעה ו/או טענה כנגד אתר קידוש החודש לרמב"ם בעניין זה, ובלבד שאתר קידוש החודש לרמב"ם נקטה באמצעים סבירים למנוע את הכניסה הלא מורשית. </p>

            <p dir="rtl" style={{ ...styles.p, fontWeight: "bold" }}>7. אתר קידוש החודש לרמב"ם תהא ראשית: </p>
            <p dir="rtl" style={styles.pp}>1) לעשות שימוש במאגר המידע בהתאם לאמור בתנאי שימוש אלה, לסעיפיהם. אתר קידוש החודש לרמב"ם על פי שיקול דעתה הבלעדי תהיה רשאית לכלול בהם גם חומר פירסומי.</p>
            <p dir="rtl" style={styles.pp}>2) לבצע מחקרים סטטיסטיים של הרגלי הגלישה ומאפייניה על ידי אתר קידוש החודש לרמב"ם ו/או מי מטעמה.</p>
            <p dir="rtl" style={styles.pp}>3) הגדרה אישית של התכנים ו/או הפירסום אליו יחשף הגולש בעת הגלישה באתר.</p>
            <p dir="rtl" style={styles.pp}>4) כל שימוש אחר אשר הינו בהתאם לחוק, כפי שתמצא אתר קידוש החודש לרמב"ם לנכון.</p>

            <p dir="rtl" style={styles.p}>8. הגולש מצהיר כי ידוע לו שבעת שימוש באתר, אתר קידוש החודש לרמב"ם תהיה רשאית "לשתול" במחשבו קבצי טסקט ותוכנה (המכונים "קוקיס"), אשר מאפשרים לזהות את הגולש בעת הגלישה באתרים (להלן - "עוגיות"). במידה והגולש רוצה למחוק את העוגיות עליו לפעול על פי הוראות ההפעלה של כלי/תוכנת הגלישה המשמש אותו לגלישה באתר.</p>
            <p dir="rtl" style={styles.p}>9. אתר קידוש החודש לרמב"ם מפעילה מעת לעת שירותים מתן פירסומות עם צדדים שלישים (חברות פירסום אינטראקטיביות). הגולש מתיר בזאת לאתר קידוש החודש לרמב"ם להעביר את הנתונים המצטברים מהעוגיות לחברות אלו. </p>

        </Container>
    );
};


export default Privacy;