import React, { useEffect, useRef} from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";


const Slider2D = ({ width, onChange, text, init = 0, min = 0, max = 360, trackColor = "red", bodyColor = "#8080ff", backgroundColor = "#ffffff" }) => {

    const canvasRef = useRef(null);
    const ctxRef = useRef(null);
    const mouseState = useRef(false);
    const lastVal = useRef(init);
    const lineLength = 0.8;
    const start = (width - width * lineLength) / 2;
    const height = 40;
    const oneUnitSize = (width * lineLength) / (max - min);

    const { dataWidth, paneHeight } = useWindowDimensions();
    useEffect(() => {
        if (!ctxRef.current) {
            return;
        }

        drawBody();
        drawTrack(lastVal.current);

    }, [dataWidth, paneHeight, width]);


    const drawBody = () => {

        const ctx = ctxRef.current;
        ctx.beginPath();
        ctx.clearRect(0, 0, width, height);

        ctx.fillStyle = backgroundColor;
        ctx.fillRect(0, 0, width, height);
        ctx.lineWidth = 10;
        ctx.strokeStyle = bodyColor;

        const y = height * 3 / 4;
        ctx.moveTo(start, y);
        ctx.lineTo(width - start, y);
        ctx.stroke();

        ctx.fillStyle = bodyColor;
        ctx.arc(start, y, ctx.lineWidth / 2, 0, Math.PI * 2);
        ctx.arc(width - start, y, ctx.lineWidth / 2, 0, Math.PI * 2);
        ctx.fill();

    };

    const drawTrack = (v) => {
        const ctx = ctxRef.current;
        const x = start + v * oneUnitSize;
        const y = height * 3 / 4;
        const r = height / 4 - 1;

        ctx.beginPath();
        ctx.lineWidth = 1;
        var grd = ctx.createRadialGradient(x, y, 0, x, y, r);
        grd.addColorStop(0, "#ffff00");
        grd.addColorStop(1, trackColor);

        ctx.strokeStyle = trackColor;

        ctx.fillStyle = grd;
        ctx.arc(x, y, r, 0, Math.PI * 2);
        ctx.fill();
        ctx.stroke();

        ctx.font = "900 14px Arial";
        ctx.fillStyle = bodyColor;
        ctx.textAlign = "center";
        ctx.fillText(`${text} ${Math.round(v)}`, width / 2, height / 4 + 3);
        lastVal.current = v;

    };

    const onMouseUp = () => {
        mouseState.current = false;
    };

    useEffect(() => {

        document.getElementById("root").addEventListener("mouseup", () => {
            onMouseUp();
        });
        ctxRef.current = canvasRef.current.getContext("2d");
        drawBody();
        drawTrack(init)


    }, []);


    const draw = (x) => {
        if (mouseState.current === false) {
            return;
        }
        drawBody();
        let v;
        if (x <= start) {
            v = min;
        } else if (x >= width - start) {
            v = max;
        } else {
            v = (x - start) / oneUnitSize;

        }

        drawTrack(v);

        if (onChange) {
            onChange(v);
        }
    };

    return (
        <>
            <canvas ref={canvasRef} width={width} height={height}
                onMouseDown={e => {
                    mouseState.current = true;
                    draw(e.clientX - e.target.offsetLeft);
                }}

                onMouseMove={e => {
                    draw(e.clientX - e.target.offsetLeft);

                }}

                onTouchMove={e => {
                    mouseState.current = true;
                    draw(e.touches[0].pageX - canvasRef.current.offsetLeft);
                }}
            />
        </>
    );
};

export default Slider2D;
