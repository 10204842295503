import firebase from "firebase";
import "firebase/database"


export const getUserSnapshotByEmail = async (email, err) => {
    try {
        const base64 = btoa(email);
        const dbRef = firebase.database().ref();
        const snapshot = await dbRef.child("users").child(base64).get();
        return snapshot;
    } catch (error) {
        if (err) {
           err(error) ;
        }
    }

    return null;
};

export const isUerVerified = async (email) => {
    const res =  await getUserSnapshotByEmail(email);
    if (!res) {
        return false;
    }

    return res.exists()? res.val().verified : false;
};