import { useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import * as THREE from "three";
import { GRAPHIC_PADDING } from "../../apis/commonConsts";
import { createLine, loadBall, loadEarth, loadGradientRing, loadMoon, loadRing, loadRingTexture, planeText, ringWithTexture, removeMesh, transperantSphere, worldLineSphere } from "../../apis/loaders";
import { emzaRoshSpeed, emzaYareachSpeed, menatMaslulRochav, menatMaslulRochavString } from "../../apis/orbits/orbitsGeneral";
import { textTexture } from "../../apis/textures";
import { Matrix, Point3D } from "../../utiles/hebrew-calculator";
import { getGraphicWidthHeight } from "../../utiles/helperFunctions";
import { setGroupPosition, Vector3D } from "../../utiles/threeDHelpers";

export const AUTO_RUN_ROCHAV_YAREACH = 1;
export const TWO_POINTS_ROCHAV_YAREACH = 2;
export const ROSH_TEXT_ROCHAV_YAREACH = 4;
export const ZANAV_TEXT_ROCHAV_YAREACH = 8;
export const YAREACH_ROCHAV_YAREACH = 16
export const AUTO_OPEN_ROCHAV_YAREACH = 31;
export const MAHALACH_ROSH_ROCHAV_YAREACH = 32;
export const MEKOM_EMZA_ROSH_ROCHAV_YAREACH = 64;
export const MSLUL_ROCHAV_YAREAC = 128;
export const ROCHAV_YAREAC_TEXT = 256;

export const C16_P10 = TWO_POINTS_ROCHAV_YAREACH
    | ROSH_TEXT_ROCHAV_YAREACH
    | ZANAV_TEXT_ROCHAV_YAREACH
    | YAREACH_ROCHAV_YAREACH
    | MSLUL_ROCHAV_YAREAC;

export const C16_p11 = C16_P10 | ROCHAV_YAREAC_TEXT;

const MAHALACH_ROSH_NAME = "mahalach_rosh_name";
const MEKOM_EMZA_ROSH_NAME = "mekom_emza_rosh_name";
const MSLUL_ROCHAV_YAREAC_NAME = "mslul_rochav_yareac_name";

const RochavHaYareach = forwardRef((props, ref) => {
    const mountRef = useRef(null);

    const [cameraRef, setCameraRef] = useState(null);
    const [rendererRef, setRendererRef] = useState(null);
    const [width, setWidth] = useState(500);
    const [height, setHeight] = useState(500);

    const vars = useRef({
        stateToRun: AUTO_OPEN_ROCHAV_YAREACH,
        updateAllObject: null, // calback function
        mahalchHaRosh: 0,
        yareachAmiti: 0,
        multiplyMekomHaRosh: 0.5,
        multiplyYareachAmiti: 0.05
    });

    const isStopRun = useRef(false);

    useEffect(() => {
        if (!cameraRef || !rendererRef) {
            return;
        }

        const { w, h } = getGraphicWidthHeight();

        cameraRef.aspect = w / h;
        cameraRef.updateProjectionMatrix();
        rendererRef.setSize(w, h);
    }, [height, width]);


    useImperativeHandle(ref, () => ({

        setRunState(runState) {
            vars.current.stateToRun = runState;
            vars.current.mahalchHaRosh = 0;
            vars.current.yareachAmiti = 0;
            vars.current.updateAllObject();
        },
        setSise(h, w) {
            setWidth(w);
            setHeight(h);
            vars.current.updateAllObject();
        },
        setMoonRosh(m, r = 0) {
            vars.current.mahalchHaRosh = r;
            vars.current.yareachAmiti = m;
            vars.current.updateAllObject();
        }
    }));

    const isVisible = (state) => {
        return (state & vars.current.stateToRun) === state;
    };

    useEffect(() => {
        const { w, h } = getGraphicWidthHeight();


        var scene = new THREE.Scene();
        var camera = new THREE.PerspectiveCamera(60, w / h, 0.1, 20000);
        var renderer = new THREE.WebGLRenderer();


        renderer.setSize(w, h);


        mountRef.current.appendChild(renderer.domElement);

        const earthPoint = new Point3D(0, 0, -1000);

        const mazalotRadius = 400;
        const cuttingReingsRadius = 280;
        const cuttingReingsWidth = 10;

        const visualNetiya = 12.5;

        const yareachPoint = new Point3D(-cuttingReingsRadius + cuttingReingsWidth / 2);
        const yareach = loadMoon(scene);
        yareach.rotation.y = Math.PI;


        const { mesh: background } = loadGradientRing(scene, 2000, 2000);
        background.position.z = -3000;

        const top_1_x = new THREE.Group();
        scene.add(top_1_x);

        const earth = loadEarth(top_1_x, 15, mesh => {
            mesh.material.transparent = true;
            mesh.material.opacity = 0.7;
        });


        // const worldLines = worldLineSphere(top_1_x);
        // worldLines.renderOrder = 3;

        const igulatYareach_1_y = new THREE.Group();
        top_1_x.add(igulatYareach_1_y);
        const { mesh: igulatYareachMesk } = loadRing(igulatYareach_1_y, cuttingReingsRadius, cuttingReingsWidth, 0xffff00);
        igulatYareachMesk.rotation.x = Math.PI / 180 * visualNetiya + Math.PI / 2;

        const { mesh: igulatshemeshMesk } = loadRing(top_1_x, cuttingReingsRadius, cuttingReingsWidth, 0xff0000);
        igulatshemeshMesk.rotation.x = Math.PI / 2;
        igulatshemeshMesk.position.y = 1;
        igulatshemeshMesk.material.opacity = 0.8;
        igulatshemeshMesk.material.transparent = true;



        const mazalotB = transperantSphere(top_1_x, true, mazalotRadius);
        const mazalotF = transperantSphere(top_1_x, false, mazalotRadius);
        // mazalotB.renderOrder = 0;


        const roshRadius = 5;
        const rosh = loadBall(scene, roshRadius, 0xff0000);
        const zanav = loadBall(scene, roshRadius, 0xff0000);
        const roshZanavPoint = new Point3D(-mazalotRadius + roshRadius + 5);
        const zafonDaromPoint = loadBall(top_1_x, roshRadius, 0xffff00);

        const textWidth = 60;
        const textHight = 30;
        const roshText = "ראש";
        const roshTextMesh = planeText(textWidth, textHight, roshText, 176, 80);
        scene.add(roshTextMesh);
        const zanavText = "זנב";
        const zanavTextMesh = planeText(textWidth, textHight, zanavText, 176, 80);
        scene.add(zanavTextMesh);
        const roshZanavTextPoint = new Point3D(-cuttingReingsRadius + textWidth * 1, -textHight * 3 / 4);

        const zafonText = "צפון";
        const zafonTextMesh = planeText(textWidth, textHight, zafonText, 196, 80);
        top_1_x.add(zafonTextMesh);
        zafonTextMesh.position.y = mazalotRadius;

        const daromOffset = 1.5;
        const daromText = "דרום";
        const daromTextMesh = planeText(textWidth * daromOffset, textHight * daromOffset, daromText, 196, 80);
        top_1_x.add(daromTextMesh);
        daromTextMesh.position.y = -mazalotRadius * daromOffset;

        const zafonPoint = new Point3D(0, mazalotRadius - textHight / 2);
        const daromPoint = new Point3D(0, -mazalotRadius * daromOffset + textHight / 2)
        const daromZafonVector = new Vector3D(zafonPoint, daromPoint);
        createLine(top_1_x, "zafonDaromLine", daromZafonVector.lineGeometry, 1, 0xffcccc);

        const menatMaslulRochavText = planeText();
        menatMaslulRochavText.position.y = -450;
        scene.add(menatMaslulRochavText);


        const netiyaClalit = 30;
        camera.position.z = 5;
        setGroupPosition(scene, earthPoint);
        top_1_x.rotation.x = Math.PI / 180 * netiyaClalit;

        setCameraRef(camera);
        setRendererRef(renderer);

        const mat = new Matrix();
        vars.current.updateAllObject = () => {
            igulatYareach_1_y.rotation.y = vars.current.mahalchHaRosh / 180 * Math.PI

            const mekomRosh = (360 - vars.current.mahalchHaRosh % 360);
            let yareachFromRosh = (mekomRosh - (vars.current.yareachAmiti % 360)) % 360;
            if (yareachFromRosh < 0) {
                yareachFromRosh += 360;
            }

            mat.init();
            mat.rotMulY(-yareachFromRosh);
            mat.rotMulX(-visualNetiya);
            mat.rotMulY(mekomRosh);
            mat.rotMulX(-netiyaClalit);
            const ptYreach = mat.transform(yareachPoint);
            setGroupPosition(yareach, ptYreach);
            yareach.visible = isVisible(YAREACH_ROCHAV_YAREACH);

            const rlWidth = 30;
            const zafonDaromY = Math.sin(menatMaslulRochav(-yareachFromRosh, visualNetiya).rad) * cuttingReingsRadius;
            const ptZafonDarom = new Point3D(0, zafonDaromY);
            setGroupPosition(zafonDaromPoint, ptZafonDarom);
            zafonDaromPoint.visible = isVisible(YAREACH_ROCHAV_YAREACH);
            const vecRL = new Vector3D(new Point3D(-rlWidth, zafonDaromY), new Point3D(rlWidth, zafonDaromY));
            createLine(top_1_x, "zafonDaromPosition", vecRL.lineGeometry, 1, 0xffff00)
                .visible = isVisible(YAREACH_ROCHAV_YAREACH);

            mat.init();
            mat.rotMulY(mekomRosh);
            mat.rotMulX(-netiyaClalit);
            const ptRosh = mat.transform(roshZanavPoint);
            setGroupPosition(rosh, ptRosh);
            rosh.visible = isVisible(TWO_POINTS_ROCHAV_YAREACH);

            const ptRoshText = mat.transform(roshZanavTextPoint);
            setGroupPosition(roshTextMesh, ptRoshText);
            roshTextMesh.visible = isVisible(ROSH_TEXT_ROCHAV_YAREACH);

            mat.init();
            mat.rotMulY(mekomRosh + 180);
            mat.rotMulX(-netiyaClalit);
            const ptZanav = mat.transform(roshZanavPoint);
            setGroupPosition(zanav, ptZanav);
            zanav.visible = isVisible(TWO_POINTS_ROCHAV_YAREACH);

            const ptZanavText = mat.transform(roshZanavTextPoint);
            setGroupPosition(zanavTextMesh, ptZanavText);
            zanavTextMesh.visible = isVisible(ZANAV_TEXT_ROCHAV_YAREACH);

            const roshZanavVector = new Vector3D(ptRosh, ptZanav);
            createLine(scene, "roshZanav", roshZanavVector.lineGeometry, 1, 0xffff00).visible = isVisible(TWO_POINTS_ROCHAV_YAREACH);

            removeMesh(top_1_x, MAHALACH_ROSH_NAME);
            const { mesh: mahalachRosh } = loadRing(top_1_x,
                cuttingReingsRadius + cuttingReingsWidth * 2,
                cuttingReingsWidth, 0xff00ff, Math.PI,
                (vars.current.mahalchHaRosh % 360) / 180 * Math.PI);


            mahalachRosh.name = MAHALACH_ROSH_NAME;
            mahalachRosh.rotation.x = -Math.PI / 2;
            mahalachRosh.visible = isVisible(MAHALACH_ROSH_ROCHAV_YAREACH);

            removeMesh(top_1_x, MEKOM_EMZA_ROSH_NAME);
            const { mesh: mekomEmzaRoshMesh } = loadRing(top_1_x,
                cuttingReingsRadius + cuttingReingsWidth * 2,
                cuttingReingsWidth, 0xccffcc, Math.PI,
                -mekomRosh / 180 * Math.PI);

            mekomEmzaRoshMesh.name = MEKOM_EMZA_ROSH_NAME;
            mekomEmzaRoshMesh.rotation.x = -Math.PI / 2;
            mekomEmzaRoshMesh.visible = isVisible(MEKOM_EMZA_ROSH_ROCHAV_YAREACH);

            removeMesh(igulatYareach_1_y, MSLUL_ROCHAV_YAREAC_NAME);
            const { mesh: maslulRochavYareachMesh } =
                loadRing(igulatYareach_1_y,
                    cuttingReingsRadius + cuttingReingsWidth * 3,
                    cuttingReingsWidth * 1.5, 0xbdc3c7, Math.PI,
                    (360 - yareachFromRosh) / 180 * Math.PI);

            maslulRochavYareachMesh.name = MSLUL_ROCHAV_YAREAC_NAME;
            maslulRochavYareachMesh.rotation.x = Math.PI / 180 * visualNetiya + Math.PI / 2;
            maslulRochavYareachMesh.visible = isVisible(MSLUL_ROCHAV_YAREAC);

            menatMaslulRochavText.visible = isVisible(ROCHAV_YAREAC_TEXT);

            if (menatMaslulRochavText.visible) {
                menatMaslulRochavText.material.map.dispose();
                menatMaslulRochavText.material.map =
                    textTexture(menatMaslulRochavString(-yareachFromRosh));
            }

        };

        const autoRun = () => {

            if (isVisible(AUTO_RUN_ROCHAV_YAREACH)) {
                vars.current.mahalchHaRosh += emzaRoshSpeed.degreesCount *
                    vars.current.multiplyMekomHaRosh;

                vars.current.yareachAmiti +=
                    emzaYareachSpeed.degreesCount * vars.current.multiplyYareachAmiti;
                vars.current.updateAllObject();
            }
        };

        var animate = function () {
            if (isStopRun.current) {
                return;
            }
            requestAnimationFrame(animate);

            autoRun();

            renderer.render(scene, camera);
        };

        const copymountRef = mountRef;
        animate();

        return () => {
            mountRef.current?.removeChild(renderer.domElement);
            copymountRef.current?.removeChild(renderer.domElement);
            isStopRun.current = true;
        };
    }, []);

    return (
        <div ref={mountRef} style={{ padding: GRAPHIC_PADDING }} />

    );
});

export default RochavHaYareach;