import React from "react";
import { Container, Row } from "react-bootstrap";
import { VIEW_C14_STATE_1, VIEW_C14_STATE_10, VIEW_C14_STATE_11, VIEW_C14_STATE_12, VIEW_C14_STATE_13, VIEW_C14_STATE_14, VIEW_C14_STATE_15, VIEW_C14_STATE_16, VIEW_C14_STATE_17, VIEW_C14_STATE_18, VIEW_C14_STATE_2, VIEW_C14_STATE_3, VIEW_C14_STATE_4, VIEW_C14_STATE_40, VIEW_C14_STATE_41, VIEW_C14_STATE_42, VIEW_C14_STATE_43, VIEW_C14_STATE_44, VIEW_C14_STATE_45, VIEW_C14_STATE_46, VIEW_C14_STATE_47, VIEW_C14_STATE_5, VIEW_C14_STATE_6, VIEW_C14_STATE_7, VIEW_C14_STATE_8, VIEW_C14_STATE_9, VIEW_C14_STATE_OPEN } from "../../screens/chapters/Chapter14";
// import { Context as Authcontext } from "../../context/Authcontext";


const Chapter14content = ({ setViewState }) => {

    return (
        <Container className="chapter-container">
            <Row>
                <h4 className="chpter-header">פרק יד</h4>
                <h6 className="paragrph">מהלכי אמצע המסלול הירח, אמצע הירח, ואמצע הירח לשעת ראיה</h6>
                {/* <p style={{ textAlign: 'center', color: 'red' }}>פרק זה בשלבי בניה</p> */}

                <div className="chapter-p" dir="rtl">
                    <strong className="span-6" onClick={() => setViewState(VIEW_C14_STATE_OPEN)}>א.</strong><strong> </strong>
                    הירח שני מהלכים אמצעיים יש לו, הירח עצמו מסבב בגלגל קטן שאינו מקיף את העולם (כולו), ומהלכו האמצעי באותו הגלגל הקטן נקרא <span className="span-1" onClick={() => setViewState(VIEW_C14_STATE_1)}>אמצע המסלול</span>,
                    והגלגל הקטן עצמו מסבב בגלגל גדול המקיף את העולם, ומהלך אמצעי זה שלגלגל הקטן באותו הגלגל הגדול המקיף את העולם הוא הנקרא <span className="span-2" onClick={() => setViewState(VIEW_C14_STATE_2)}>אמצע הירח</span>.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>ב. </strong>
                    מהלך אמצע הירח <span className="span-3" onClick={() => setViewState(VIEW_C14_STATE_3)}>ביום אחד</span> שלש עשרה מעלות ועשרה חלקים וחמש ושלשים שניות, סימנם י"ג יל"ה
                    נמצא מהלכו <span  className="span-4" onClick={() => setViewState(VIEW_C14_STATE_4)}>בעשרה ימים</span> מאה ואחת ושלשים מעלות וחמשה וארבעים חלקים וחמשים שניות,
                    סימנם קל"א מה"נ,
                    ונמצא שארית מהלכו <span className="span-5" onClick={() => setViewState(VIEW_C14_STATE_5)}>במאה יום</span> מאתים ושבע ושלשים מעלות ושמונה ושלשים חלקים ושלש ועשרים שניות, סימנם רל"ז ל"ח כ"ג,
                    ונמצא שארית מהלכו <span className="span-1" onClick={() => setViewState(VIEW_C14_STATE_6)}>באלף יום</span> מאתים ושש עשרה מעלות ושלשה ועשרים חלקים וחמשים שניות, סימנם רי"ו כג"נ,
                    ונמצא שארית מהלכו <span className="span-2" onClick={() => setViewState(VIEW_C14_STATE_7)}>בעשרת אלפים</span> יום שלש מעלות ושמונה וחמשים חלקים ועשרים שניות, סימנם ג' נ"ח כ',
                    ונמצא שארית מהלכו <span className="span-3" onClick={() => setViewState(VIEW_C14_STATE_8)}>בתשעה ועשרים יום</span> שתים ועשרים מעלות וששה חלקים ושש וחמשים שניות, סימנם כב"ו נ"ו,
                    ונמצא שארית מהלכו <span className="span-4" onClick={() => setViewState(VIEW_C14_STATE_9)}>בשנה סדורה</span> שלש מאות וארבע וארבעים מעלות וששה ועשרים חלקים ושלש וארבעים שניות, סימן להם שמ"ד כ"ו מ"ג,
                    ועל דרך זו תכפול לכל מנין ימים או שנים שתרצה.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>ג. </strong>
                    ומהלך אמצע המסלול <span className="span-5" onClick={() => setViewState(VIEW_C14_STATE_10)}>ביום אחד</span> שלש עשרה מעלות ושלשה חלקים וארבע וחמשים שניות, סימנם י"ג גנ"ד,
                    נמצא מהלכו <span className="span-1" onClick={() => setViewState(VIEW_C14_STATE_11)}>בעשרה ימים</span> מאה ושלשים מעלות תשעה ושלשים חלקים בלא שניות, סימנם ק"ל ל"ט,
                    ונמצא שארית מהלכו <span className="span-2" onClick={() => setViewState(VIEW_C14_STATE_12)}>במאה יום</span> מאתים ושש ועשרים מעלות ותשעה ועשרים חלקים ושלש וחמשים שניות, סימנם רכ"ו כ"ט נ"ג,
                    ונמצא שארית מהלכו <span className="span-3" onClick={() => setViewState(VIEW_C14_STATE_13)}>באלף יום</span> מאה וארבע מעלות ושמונה וחמשים חלקים וחמשים שניות, סימנם ק"ד נח"נ,
                    ונמצא שארית מהלכו <span className="span-4" onClick={() => setViewState(VIEW_C14_STATE_14)}>בעשרת אלפים יום</span> שלש מאות ותשע ועשרים מעלות ושמונה וארבעים חלקים ועשרים שניות, סימנם שכ"ט מח"כ,
                    ונמצא שארית מהלכו <span className="span-5" onClick={() => setViewState(VIEW_C14_STATE_15)}>בתשעה ועשרים יום</span> שמונה עשרה מעלות ושלשה וחמשים חלקים וארבע שניות, סימנם י"ח נג"ד.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>ד. </strong>
                    ונמצא שארית מהלכו <span className="span-1" onClick={() => setViewState(VIEW_C14_STATE_16)}>בשנה סדורה</span> שלש מאות וחמש מעלות ושלש עשרה שניות בלא חלק, סימנם ש"ה י"ג.
                    מקום אמצע הירח היה בתחלת ליל חמישי שהוא העיקר לחשבונות אלו <span className="span-2" onClick={() => setViewState(VIEW_C14_STATE_17)}>במזל שור מעלה אחת וארבעה עשר חלקים ושלש וארבעים שניות</span>, סימנם (א) [ל"א] י"ד מ"ג,
                    ואמצע המסלול היה בעיקר זה <span className="span-3" onClick={() => setViewState(VIEW_C14_STATE_18)}>ארבע ושמונים מעלות ושמונה ועשרים חלקים ושתים וארבעים שניות</span>, סימנם פ"ד כ"ח מ"ב.
                    מאחר שתדע מהלך אמצע הירח והאמצע שהוא העיקר שעליו תוסיף, תדע מקום אמצע הירח בכל יום שתרצה על דרך שעשית באמצע השמש, ואחר שתוציא אמצע הירח לתחלת הלילה שתרצה התבונן בשמש ודע באיזה מזל הוא.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>ה. </strong>
                    אם היתה השמש <span className="span-1" onClick={() => setViewState(VIEW_C14_STATE_40)}>מחצי מזל דגים עד חצי מזל טלה</span>, תניח אמצע הירח כמות שהוא,
                    ואם תהיה השמש <span className="span-2" onClick={() => setViewState(VIEW_C14_STATE_41)}>מחצי מזל טלה עד תחלת מזל תאומים</span>, תוסיף על אמצע הירח חמשה עשר חלקים,
                    ואם תהיה השמש <span className="span-3" onClick={() => setViewState(VIEW_C14_STATE_42)}>מתחלת מזל תאומים עד תחלת מזל אריה</span>, תוסיף על אמצע הירח שלשים חלקים,
                    ואם תהיה השמש <span className="span-4" onClick={() => setViewState(VIEW_C14_STATE_43)}>מתחלת מזל אריה עד חצי מזל בתולה</span> תוסיף על אמצע הירח חמשה עשר חלקים,
                    ואם תהיה השמש <span className="span-5" onClick={() => setViewState(VIEW_C14_STATE_44)}>מחצי מזל בתולה עד חצי מאזנים</span>, הנח אמצע הירח כמות שהוא,
                    ואם תהיה השמש <span className="span-1" onClick={() => setViewState(VIEW_C14_STATE_45)}>מחצי מאזנים עד תחלת מזל קשת</span>, תגרע מאמצע הירח חמשה עשר חלקים,
                    ואם תהיה השמש מתחלת <span className="span-2" onClick={() => setViewState(VIEW_C14_STATE_46)}>מזל קשת עד תחלת מזל דלי</span>, תגרע מאמצע הירח שלשים חלקים,
                    ואם תהיה השמש <span className="span-3" onClick={() => setViewState(VIEW_C14_STATE_47)}>מתחלת מזל דלי עד חצי דגים</span>, תגרע מאמצע הירח חמשה עשר חלקים.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>ו. </strong>
                    ומה שיהיה האמצע אחר שתוסיף עליו או תגרע ממנו או תניח אותו כמות שהוא, הוא אמצע הירח לאחר שקיעת החמה בכמו שליש שעה באותו הזמן שתוציא האמצע לו, וזה הוא הנקרא אמצע הירח לשעת הראייה.
                </div>
                <p></p>
            </Row>
        </Container>
    );
};

export default Chapter14content;