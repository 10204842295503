import React from "react";
import firebase from "firebase/app";
import 'firebase/analytics';

const createAnalytics = () => {
    const AnalyticsContext = React.createContext();

    const AnalyticsProvider = ({ children }) => {
        var firebaseConfig = {
            apiKey: "AIzaSyDfTblf_RFZDig7jRc2cvZ9mtrrWDxaHDI",
            authDomain: "kiddush-hodesh.firebaseapp.com",
            projectId: "kiddush-hodesh",
            storageBucket: "kiddush-hodesh.appspot.com",
            messagingSenderId: "69654190580",
            appId: "1:69654190580:web:1f89e498c76ae46183c1a1",
            databaseURL: "https://kiddush-hodesh-default-rtdb.europe-west1.firebasedatabase.app/",
            measurementId: "G-LKEG4NTDLF"
        };
        // Initialize Firebase
        const app = firebase.initializeApp(firebaseConfig);
        const analytics = () => {
            return firebase.analytics(app);
        };

        return (
            <AnalyticsContext.Provider value={{ analytics }}>
                {children}
            </AnalyticsContext.Provider>
        );
    };

    return { AnalyticsContext, AnalyticsProvider };
};

export const {AnalyticsContext, AnalyticsProvider} = createAnalytics();