import React, { useEffect, useRef, useContext, useState } from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";

import { AnalyticsContext } from "../../context/AnalyticsContext";
import DualPaneManager from "../DualPaneManager";
import { MetaTags } from "react-meta-tags";
import CreateOrVerifyEmail from "../../components/CreateOrVerifyEmail";
import ContactAndOrder from "../../components/ContactAndOrder";
import { Context as Authcontext } from "../../context/Authcontext";
import { ORDER_MESSAGE } from "../../apis/commonConsts";
import Chapter11content from "../../components/chaptersContents/Chapter11content";
import Chapter11OpenContent from "../../components/chaptersContents/Chapter11OpenContent";

export const VIEW_C11_STATE_OPEN = 0;

const Chapter11 = ({ history }) => {
    const [viewState, setViewState] = useState(VIEW_C11_STATE_OPEN);
    const { state } = useContext(Authcontext);
    const { height, width, graphicWidth, paneHeight } = useWindowDimensions();
    const { analytics } = useContext(AnalyticsContext);

    const signinOrVerifyRef = useRef(null);
    const orderRef = useRef(null);

    useEffect(() => {
        analytics();
    }, []);


    const setState = () => {

    };

    useEffect(() => {
        setState();
        //rochavYareachRef.current?.setSise(paneHeight, graphicWidth);

    }, [height, width, viewState]);


    return (
        <div>
            <MetaTags>
                <title>הלכות קידוש החודש לרמב"ם פרק יא</title>
                <meta name="description" content='בפרק יא קידוש החודש לרמב"ם יובא ביאור על סוד מהות הגלגל וסוד חלוקתו למידות, וכן שורש מידות הזמן הנגזרים מתנועתו.' />
            </MetaTags>
            <CreateOrVerifyEmail state={state} history={history} ref={signinOrVerifyRef} />
            <ContactAndOrder history={history} ref={orderRef} hidden={true} nessage={ORDER_MESSAGE} />
            <DualPaneManager
                isText={true}
                graphicPane={<Chapter11OpenContent />}

                dataPane={<Chapter11content
                    setViewState={setViewState}
                />}
                height={height}
                width={width}
            />

        </div>
    );
};


export default Chapter11;