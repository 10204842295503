import { useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import * as THREE from "three";
import { GRAPHIC_PADDING } from "../apis/commonConsts";
import { loadEarth, loadRing, loadBall, loadSun, createLine, createTriangle, loadMazalotEx, loadGradientRing, removeMesh, spiralRing, planeText } from "../apis/loaders";
import { speedFormat } from "../apis/orbits/orbitsGeneral";
import { calcMenatHaMslul } from "../apis/orbits/sun-orbits";
import { textTexture } from "../apis/textures";

import { Matrix, Point3D, SpeedUnits } from "../utiles/hebrew-calculator";
import { getGraphicWidthHeight } from "../utiles/helperFunctions";
import { updateGeometryMyMatrix, Vector3D } from "../utiles/threeDHelpers";

export const AUTO_RUN_MAZALOT = 1;
export const AUTO_RUN_GOVA = 2;
export const AUTO_RUN_SHEMESH = 4;
export const RUN_FROM_OUT = 8;
export const NO_LINES = 16;
export const MHALACH_SHEMESH = 32;
export const CIRCLE_SHEMESH = 64;
export const MHALACH_SHEMESH_AND_IKAR = 128;
export const KAV_MEKOM_GOVA = 256;
export const MASLUL_SHEMESH = 512;
export const MASLUL_SHEMESH_MORE_THAN_180 = 1024;
export const MENAT_MASLUL_TEXT = 2048;
export const MEKOM_SHEMESH_AMITI = 4096;


const MHALACH_SHEMESH_MESH_NAME = "mhalach_shemesh_mesh_name";
const CIRCLE_SHEMESH_MESH_NAME = "circle_shemesh_mesh_name";

const IKAR_MESH_NAME = "ikar_mesh_name";
const MHALACH_SHEMESH_FROM_IKAR_MESH_NAME = "mhalach_shemesh_from_ikar_mesh_name";

const KAV_MEKOM_GOVA_NAME = "kav_mekom_gova";
const MASLUL_SHEMESH_PATH_NAME = "maslul_shemesh_path";

const MASLUL_SHEMESH_MORE_THAN_180_NAME = "maslul_shemesh_more_than_180_name";

const MENAT_MASLUL_TEXT_NAME = "menat_maslul_text_name";

const MEKOM_SHEMESH_AMITI_NAME = "mekom_shemesh_amiti_name";


const SunPaths = forwardRef((props, ref) => {
    // console.log('SunPaths');
    const mountRef = useRef(null);

    const [cameraRef, setCameraRef] = useState(null);
    const [rendererRef, setRendererRef] = useState(null);
    const [width, setWidth] = useState(500);
    const [height, setHeight] = useState(500);

    const vars = useRef({
        stateToRun: AUTO_RUN_MAZALOT,
        all: 90,
        globalXRotation: 25,
        maalotHaMazal: 0,
        maalotGovaShemesh: 0,
        maalotShemes: 0,
        updateAllObject: null, // calback function
        shemeshSpeed: 0.5,
        govaSpeed: 0.5,
        mazalSpeed: 0.2,
        ikarShemesh: 0
    });

    const isStopRun = useRef(false);


    useImperativeHandle(ref, () => ({

        setRunState(runState) {
            vars.current.stateToRun = runState;
            vars.current.maalotHaMazal = 0;
            vars.current.maalotGovaShemesh = 0;
            vars.current.maalotShemes = 0;
            vars.current.updateAllObject();
        },
        setSise(h, w) {
            setWidth(w);
            setHeight(h);
            vars.current.updateAllObject();
        },
        setPaths(haMazal, govaShemesh, shemesh) {
            vars.current.maalotHaMazal = haMazal;
            vars.current.maalotGovaShemesh = govaShemesh - 90;
            vars.current.maalotShemes = shemesh - 90;
            vars.current.updateAllObject();
        },
        setGlobalXRotation(xRotation) {
            vars.current.globalXRotation = xRotation;
            vars.current.updateAllObject();
        },
        setSpeeds(s, g, m = 0.2) {
            vars.current.shemeshSpeed = s;
            vars.current.govaSpeed = g;
            vars.current.mazalSpeed = m;
            vars.current.updateAllObject();
        },
        setIkarShemesh(ikar) {
            vars.current.ikarShemesh = ikar;
            vars.current.updateAllObject();
        }

    }));



    useEffect(() => {
        if (!cameraRef || !rendererRef) {
            return;
        }

        const { w, h } = getGraphicWidthHeight();

        cameraRef.aspect = w / h;
        cameraRef.updateProjectionMatrix();
        rendererRef.setSize(w, h);
    }, [height, width]);

    const setGroupPosition = (group, point3D) => {
        group.position.x = point3D.x;
        group.position.y = point3D.y;
        group.position.z = point3D.z;

    };

    useEffect(() => {
        const { w, h } = getGraphicWidthHeight();


        var scene = new THREE.Scene();
        var camera = new THREE.PerspectiveCamera(60, w / h, 0.1, 20000);
        var renderer = new THREE.WebGLRenderer();

        renderer.setSize(w, h);


        mountRef.current.appendChild(renderer.domElement);

        var earthAmbientLight = new THREE.AmbientLight(0x1a1a1a);
        scene.add(earthAmbientLight);

        const earthPoint = new Point3D(0, 0, -1000);
        var earthLight = new THREE.PointLight(0xffffff, 3, Math.abs(earthPoint.z) + 100);
        scene.add(earthLight);

        earthLight.position.z = 0;

        const maslulRadius = 50;
        const maslulWidth = 7;
        const maslulShemeshRadius = 300;
        const sunMaslulWidth = 10;
        const mazalotRadius = 400;
        loadEarth(scene, 15);
        const { geometry: mazalotGeometry } = loadMazalotEx(scene, mazalotRadius);
        const { geometry: emzaMslulGeometry } = loadRing(scene, maslulRadius, maslulWidth, 0xffffff);
        const govaHaShemesh = loadBall(scene, 7, 0x80b918);
        const { mesh: maslulShemesh, geometry: maslulShemeshGeometry } = loadRing(scene, maslulShemeshRadius, sunMaslulWidth, 0xffff00);
        const { mesh: background } = loadGradientRing(scene, 2000, 2000);
        const sun = loadSun(scene);
        const menatMaslulText = planeText();
        menatMaslulText.name = MENAT_MASLUL_TEXT_NAME;
        menatMaslulText.position.y = -460;

        const mekomShemeshAmiti = planeText();
        mekomShemeshAmiti.name = MEKOM_SHEMESH_AMITI_NAME;
        mekomShemeshAmiti.position.y = -460;

        camera.position.z = 5;
        setGroupPosition(scene, earthPoint);

        const mat = new Matrix();

        background.position.z = -3000;

        setCameraRef(camera);
        setRendererRef(renderer);

        vars.current.updateAllObject = () => {
            let govaHaShemeshPoint = new Point3D(0, 0, -maslulRadius + maslulWidth / 2);
            mat.init();
            mat.rotMulY((vars.current.maalotGovaShemesh /* - maalotHaMazal*/));
            mat.rotMulX(-23.5);
            mat.rotMulY((- vars.current.maalotHaMazal) - 90);
            mat.rotMulY(vars.current.all);
            mat.rotMulX(-vars.current.globalXRotation);
            govaHaShemeshPoint = mat.transform(govaHaShemeshPoint);

            setGroupPosition(govaHaShemesh, govaHaShemeshPoint);
            setGroupPosition(maslulShemesh, govaHaShemeshPoint);

            let sunPoint = new Point3D(0, 0, maslulShemeshRadius - sunMaslulWidth / 2);
            mat.init();
            mat.rotMulY((vars.current.maalotHaMazal + vars.current.maalotShemes));
            mat.rotMulX(23.5);
            mat.rotMulY((-vars.current.maalotHaMazal) + 90);
            mat.rotMulY(vars.current.all);
            mat.rotMulX(-vars.current.globalXRotation);
            sunPoint = mat.transform(sunPoint);
            setGroupPosition(sun, sunPoint.plusEqual(govaHaShemeshPoint));


            mat.init();
            mat.rotMulX(23.5 + 90);
            mat.rotMulY((-vars.current.maalotHaMazal) + 90);
            mat.rotMulY(vars.current.all);
            mat.rotMulX(-vars.current.globalXRotation);

            updateGeometryMyMatrix(maslulShemeshGeometry, mat);


            mat.init();
            mat.rotMulX(23.5);
            mat.rotMulY((-vars.current.maalotHaMazal) + 90);
            mat.rotMulY(vars.current.all);
            mat.rotMulX(-vars.current.globalXRotation);

            updateGeometryMyMatrix(mazalotGeometry, mat);

            mat.init();
            mat.rotMulX(23.5 + 90);
            mat.rotMulY(-vars.current.maalotHaMazal + 90);
            mat.rotMulY(vars.current.all);
            mat.rotMulX(-vars.current.globalXRotation);

            updateGeometryMyMatrix(emzaMslulGeometry, mat);

            const isDrawLines = (vars.current.stateToRun & NO_LINES) === 0;

            const middelPoint = new Point3D(0, 0, 0);
            const earthSunvector = new Vector3D(middelPoint, sunPoint);

            let toScale = mazalotRadius / earthSunvector.product;
            const earthSunvectorScale = earthSunvector.scale(toScale);
            if (isDrawLines) {
                createLine(scene, "earthSun", earthSunvectorScale.lineGeometry, 2);
            }
            else {
                removeMesh(scene, "earthSun");
            }


            const govaSunVector = new Vector3D(govaHaShemeshPoint, sunPoint);
            createLine(scene, "govaSun", govaSunVector.lineGeometry, 2, 0x00ff00);

            const diffPoint = govaHaShemeshPoint.minus(middelPoint);
            const v = new Vector3D(govaHaShemeshPoint.minus(diffPoint), sunPoint.minus(diffPoint));
            toScale = mazalotRadius / v.product;
            const ed2 = v.scale(toScale);
            if (isDrawLines) {
                createLine(scene, "ed2", ed2.lineGeometry, 2, 0x00ff00);
            }
            else {
                removeMesh(scene, "ed2");
            }


            const ajustPoint = new Point3D(0, -1, 0);
            if (isDrawLines) {
                createTriangle(scene, "tri", ajustPoint.plus(ed2.origin), ajustPoint.plus(ed2.destanation), ajustPoint.plus(earthSunvectorScale.destanation));
            }
            else {
                removeMesh(scene, "tri");
            }

            if ((vars.current.stateToRun & MHALACH_SHEMESH) === MHALACH_SHEMESH) {
                removeMesh(scene, MHALACH_SHEMESH_MESH_NAME);
                const outer = maslulShemeshRadius - sunMaslulWidth - 4;

                const { mesh: mhalchShemesh, geometry: mhalchShemeshGeometry } =
                    loadRing(scene, outer, sunMaslulWidth, 0x00ff00, 0, -(vars.current.maalotShemes + 90) % 360 / 180 * Math.PI);

                mhalchShemesh.name = MHALACH_SHEMESH_MESH_NAME;

                setGroupPosition(mhalchShemesh, govaHaShemeshPoint);
                mat.init();
                mat.rotMulX(23.5 + 90);
                mat.rotMulY((-vars.current.maalotHaMazal) + 90);
                mat.rotMulY(vars.current.all);
                mat.rotMulX(-vars.current.globalXRotation);

                updateGeometryMyMatrix(mhalchShemeshGeometry, mat);

            }
            else {
                removeMesh(scene, MHALACH_SHEMESH_MESH_NAME);
            }

            if ((vars.current.stateToRun & CIRCLE_SHEMESH) === CIRCLE_SHEMESH) {
                removeMesh(scene, CIRCLE_SHEMESH_MESH_NAME);
                const outer = maslulShemeshRadius - 3 * sunMaslulWidth - 4;
                const circle = (vars.current.maalotShemes + 90) / 360
                if (circle >= 1) {
                    const { mesh: m, geometry: g } = spiralRing(scene, circle, outer, 30);
                    m.name = CIRCLE_SHEMESH_MESH_NAME;

                    setGroupPosition(m, govaHaShemeshPoint);
                    mat.init();
                    updateGeometryMyMatrix(g, mat);
                }
            }
            else {
                removeMesh(scene, CIRCLE_SHEMESH_MESH_NAME);
            }

            const drawIkar = () => {
                const outer = maslulShemeshRadius - sunMaslulWidth - 4;
                const { mesh: m1, geometry: g1 } =
                    loadRing(scene, outer, sunMaslulWidth, 0xaaaaff, 0, -(vars.current.ikarShemesh) / 180 * Math.PI);
                m1.name = IKAR_MESH_NAME;

                setGroupPosition(m1, govaHaShemeshPoint);
                mat.init();
                mat.rotMulX(23.5 + 90);
                mat.rotMulY((-vars.current.maalotHaMazal) + 90);
                mat.rotMulY(vars.current.all);
                mat.rotMulX(-vars.current.globalXRotation);

                updateGeometryMyMatrix(g1, mat);

            };

            const drawMaslulminIkar = () => {

                const outer = maslulShemeshRadius - 2 * sunMaslulWidth - 4;
                const ikar = (vars.current.maalotShemes + 90 < 360) ? -vars.current.ikarShemesh : 0;
                const maalotShemesh = vars.current.maalotShemes + 90 + ikar;
                const { mesh: mhalchShemesh, geometry: mhalchShemeshGeometry } =
                    loadRing(scene, outer, sunMaslulWidth, 0x00ff00, ikar / 180 * Math.PI, -(maalotShemesh) % 360 / 180 * Math.PI);

                mhalchShemesh.name = MHALACH_SHEMESH_FROM_IKAR_MESH_NAME;

                setGroupPosition(mhalchShemesh, govaHaShemeshPoint);
                mat.init();
                mat.rotMulX(23.5 + 90);
                mat.rotMulY((-vars.current.maalotHaMazal) + 90);
                mat.rotMulY(vars.current.all);
                mat.rotMulX(-vars.current.globalXRotation);

                updateGeometryMyMatrix(mhalchShemeshGeometry, mat);
            };

            if ((vars.current.stateToRun & MHALACH_SHEMESH_AND_IKAR) === MHALACH_SHEMESH_AND_IKAR) {
                removeMesh(scene, IKAR_MESH_NAME);
                removeMesh(scene, MHALACH_SHEMESH_FROM_IKAR_MESH_NAME);
                drawIkar();
                drawMaslulminIkar();
            }
            else {
                removeMesh(scene, IKAR_MESH_NAME);
                removeMesh(scene, MHALACH_SHEMESH_FROM_IKAR_MESH_NAME);
            }

            const kavMekomGova = () => {
                let govaangleOnSunPath = new Point3D(0, 0, -maslulShemeshRadius - maslulRadius);
                mat.init();
                mat.rotMulY((vars.current.maalotGovaShemesh /* - maalotHaMazal*/));
                mat.rotMulX(-23.5);
                mat.rotMulY((- vars.current.maalotHaMazal) - 90);
                mat.rotMulY(vars.current.all);
                mat.rotMulX(-vars.current.globalXRotation);

                govaangleOnSunPath = mat.transform(govaangleOnSunPath);
                const govaToMaslulVector = new Vector3D(govaHaShemeshPoint, govaangleOnSunPath);

                createLine(scene, KAV_MEKOM_GOVA_NAME,
                    govaToMaslulVector.lineGeometry, 1, 0xbc5aa3);
            };

            const maslulShemeshPath = () => {
                const outer = maslulShemeshRadius - 2 * sunMaslulWidth - 4;
                let maslulPath = (vars.current.maalotShemes) % 360 - (vars.current.maalotGovaShemesh) % 360;
                if (maslulPath < 0) {
                    maslulPath += 360;
                }

                const { mesh: maslulShemesh, geometry: maslulShemeshGeometry } =
                    loadRing(scene, outer, sunMaslulWidth, 0xbc5aa3,
                        -(vars.current.maalotGovaShemesh + 90) / 180 * Math.PI,
                        - maslulPath / 180 * Math.PI);

                maslulShemesh.name = MASLUL_SHEMESH_PATH_NAME;

                setGroupPosition(maslulShemesh, govaHaShemeshPoint);
                mat.init();
                mat.rotMulX(23.5 + 90);
                mat.rotMulY((-vars.current.maalotHaMazal) + 90);
                mat.rotMulY(vars.current.all);
                mat.rotMulX(-vars.current.globalXRotation);

                updateGeometryMyMatrix(maslulShemeshGeometry, mat);
            };

            if ((vars.current.stateToRun & MASLUL_SHEMESH) === MASLUL_SHEMESH) {
                removeMesh(scene, MASLUL_SHEMESH_PATH_NAME);
                maslulShemeshPath();
            }
            else {
                removeMesh(scene, MASLUL_SHEMESH_PATH_NAME);
            }

            if ((vars.current.stateToRun & KAV_MEKOM_GOVA) === KAV_MEKOM_GOVA) {
                removeMesh(scene, KAV_MEKOM_GOVA_NAME);
                kavMekomGova();
            }
            else {
                removeMesh(scene, KAV_MEKOM_GOVA_NAME);
            }


            const maslulShemeshMoreThan180Path = () => {
                const outer = maslulShemeshRadius - 4 * sunMaslulWidth - 4;
                let maslulPath = (vars.current.maalotShemes) % 360 - (vars.current.maalotGovaShemesh) % 360;
                if (maslulPath < 0) {
                    maslulPath += 360;
                }

                if (maslulPath < 180) {
                    return;
                }

                const { mesh: maslulShemesh, geometry: maslulShemeshGeometry } =
                    loadRing(scene, outer, sunMaslulWidth, 0xED213A,
                        -(vars.current.maalotGovaShemesh + 90) / 180 * Math.PI - maslulPath / 180 * Math.PI,
                        - (360 - maslulPath) / 180 * Math.PI);

                maslulShemesh.name = MASLUL_SHEMESH_MORE_THAN_180_NAME;

                setGroupPosition(maslulShemesh, govaHaShemeshPoint);
                mat.init();
                mat.rotMulX(23.5 + 90);
                mat.rotMulY((-vars.current.maalotHaMazal) + 90);
                mat.rotMulY(vars.current.all);
                mat.rotMulX(-vars.current.globalXRotation);

                updateGeometryMyMatrix(maslulShemeshGeometry, mat);
            };

            if ((vars.current.stateToRun & MASLUL_SHEMESH_MORE_THAN_180) === MASLUL_SHEMESH_MORE_THAN_180) {
                removeMesh(scene, MASLUL_SHEMESH_MORE_THAN_180_NAME);
                maslulShemeshMoreThan180Path();
            }
            else {
                removeMesh(scene, MASLUL_SHEMESH_MORE_THAN_180_NAME);
            }

            const getMenatMaslul = () => {
                let maslulShemesh =
                    (vars.current.maalotShemes - vars.current.maalotGovaShemesh) % 360;

                if (maslulShemesh < 0) {
                    maslulShemesh += 360;
                }
                const menatMaslul = calcMenatHaMslul(maslulShemesh / 180 * Math.PI);

                return menatMaslul;
            };

            const getMenatMaslulString = () => {
                const menatMaslul = getMenatMaslul();

                const sign = menatMaslul > 0 ? "-" : "+";

                return  `(${sign}) ${speedFormat(new SpeedUnits(Math.abs(menatMaslul / 360)), 3)}`;
            };

            if ((vars.current.stateToRun & MENAT_MASLUL_TEXT) === MENAT_MASLUL_TEXT) {
                const mesh = scene.getObjectByName(MENAT_MASLUL_TEXT_NAME);
                if (!mesh) {
                    scene.add(menatMaslulText);
                }

                menatMaslulText.material.map.dispose();
                menatMaslulText.material.map = textTexture(getMenatMaslulString());

            }
            else {
                removeMesh(scene, MENAT_MASLUL_TEXT_NAME);
            }

            const getShemeshAmitiString = () => {
                let shemeshAmiti = (vars.current.maalotShemes + 90 - getMenatMaslul()) % 360;
                if (shemeshAmiti < 0) {
                    shemeshAmiti += 360;
                }

                return speedFormat(new SpeedUnits(shemeshAmiti / 360));
            };

            if ((vars.current.stateToRun & MEKOM_SHEMESH_AMITI) === MEKOM_SHEMESH_AMITI) {
                const mesh = scene.getObjectByName(MEKOM_SHEMESH_AMITI_NAME);
                if (!mesh) {
                    scene.add(mekomShemeshAmiti);
                }

                mekomShemeshAmiti.material.map.dispose();
                mekomShemeshAmiti.material.map = textTexture(getShemeshAmitiString());

            }
            else {
                removeMesh(scene, MEKOM_SHEMESH_AMITI_NAME);
            }

        };


        const autoRun = () => {
            const s = vars.current.stateToRun;
            if (s & AUTO_RUN_MAZALOT || s & AUTO_RUN_SHEMESH || s & AUTO_RUN_GOVA) {
                if (s & AUTO_RUN_MAZALOT) {
                    vars.current.maalotHaMazal += vars.current.mazalSpeed;
                }
                if (s & AUTO_RUN_SHEMESH) {
                    vars.current.maalotShemes += vars.current.shemeshSpeed;
                }
                if (s & AUTO_RUN_GOVA) {
                    vars.current.maalotGovaShemesh += vars.current.govaSpeed;
                }
                vars.current.updateAllObject();
            }
        };

        var animate = function () {
            if (isStopRun.current) {
                return;
            }
            requestAnimationFrame(animate);

            autoRun();

            renderer.render(scene, camera);
        };

        const copymountRef = mountRef;
        animate();

        return () => {
        
            isStopRun.current = true;
            mountRef.current?.removeChild(renderer.domElement);
            copymountRef.current?.removeChild(renderer.domElement);
        };

    }, []);

    return (
        <div ref={mountRef} style={{ padding: GRAPHIC_PADDING }} />

    );
});

export default SunPaths;
