import React, { useContext, useState } from "react";
import "firebase/auth";
import firebase from "firebase";
import ReCAPTCHA from "react-google-recaptcha";
import { MetaTags } from "react-meta-tags";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import validator from "validator";
import { Link } from "react-router-dom";
import { Context as Authcontext } from "../context/Authcontext";

const SigninScreen = ({ history }) => {
    // console.log(firebase.auth());
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const [isEmail, setIsEmail] = useState(true);
    const [isPpass, setIsPass] = useState(true);
    const [error, setError] = useState('');
    const [notARobot, setNotARobot] = useState(false);

    

    const { state } = useContext(Authcontext);

    const validateEmail = () => {
        const v = validator.isEmail(email);
        setIsEmail(v);
        return v;
    };

    const validatePass = () => {
        const v = validator.isLength(pass, { min: 4, max: 32 });
        setIsPass(v);
        return v;
    };

    const signout = () => {
        return (
            <Container>
                <Row className="justify-content-md-center">
                    <Col lg={6} md={8} xs={12}>
                        <Row className="text-center">
                            <h4>התנתק מאתר קידוש החודש לרמב"ם</h4>
                            <hr />
                            <Button onClick={() => {
                                firebase.auth().signOut().then(() => {
                                    // Sign-out successful.
                                }).catch((error) => {
                                    // An error happened.
                                    setError('ארעה שגיאה בהתנתקות');
                                });

                            }}>התנתק</Button>
                        </Row>
                    </Col>
                </Row>
            </Container>
        );
    };

    const signin = () => {
        return (
            <Container>
                <Row className="justify-content-md-center">
                    <Col lg={6} md={8} xs={12} >
                        <Row className="text-center">
                            <h4>התחברות לאתר קידוש החודש לרמב"ם</h4>
                            <hr />
                        </Row>
                        <Row>
                            <Form>
                                <Form.Group as={Row} className="mb-3" controlId="formBasicEmail">
                                    <Form.Label column sm="2">אימייל</Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                            type="email" placeholder="Enter email"
                                            isInvalid={!isEmail}
                                            onBlur={() => validateEmail()}
                                        />
                                        {!isEmail ? <Form.Text style={{ color: 'red' }}>
                                            יש למלא כתובת אימייל חוקית
                                        </Form.Text> : null}
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3" controlId="formBasicPassword">
                                    <Form.Label column sm="2">סיסמא</Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            value={pass}
                                            onChange={e => setPass(e.target.value)}
                                            onBlur={() => validatePass()}
                                            isInvalid={!isPpass}
                                            type="password" placeholder="Password" />
                                        {!isPpass ? <Form.Text style={{ color: 'red' }}>
                                            אורך הסיסמא צריך להיות בין 6 ל 32 תויים
                                        </Form.Text> : null}
                                    </Col>
                                </Form.Group>
                                <ReCAPTCHA
                                    sitekey="6LdwAAwcAAAAAJEglFPUzEuV_KfjiIejMnCC86vb"
                                    onChange={v => setNotARobot(v !== null)}
                                />
                                <Button variant="primary" type="submit"
                                    disabled={!notARobot}
                                    onClick={e => {
                                        e.preventDefault();
                                        setError("");
                                        // if (!notARobot) {
                                        //     setError("יש לסמן את הכפתור אני לא רובוט i'm not a robot");
                                        // }
                                        // else 
                                        if (validateEmail() && validatePass()) {
                                            firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
                                                .then(() => {
                                                    firebase.auth().signInWithEmailAndPassword(email, pass)
                                                        .then((userCredential) => {
                                                            // Signed in
                                                            // var user = userCredential.user;
                                                            // console.log(userCredential.user);
                                                            // ...
                                                            history.push('/');
                                                        })
                                                        .catch((error) => {
                                                            // var errorCode = error.code;
                                                            var errorMessage = error.message;
                                                            console.log(errorMessage);
                                                        });
                                                })
                                                .catch((error) => {
                                                    // Handle Errors here.
                                                    // var errorCode = error.code;
                                                    var errorMessage = error.message;
                                                    console.log(errorMessage);
                                                });
                                        } else {
                                            setError('יש לוודא כי כל הפרטום מולאו בצורה תקינה');
                                        }
                                    }}
                                >
                                    התחבר
                                </Button>
                                <br />
                                {error === '' ? null : <Form.Text dir="rtl" style={{ color: 'red' }}>{error}</Form.Text>}

                            </Form>
                        </Row>
                        <Row className="text-center">
                            <Link to="/resetpass">שכחתי את הסיסמא</Link>
                            <Link to="/signup">אם אינך רשום לחץ כאן להרשמה</Link>
                        </Row>
                    </Col>
                </Row>
            </Container>

        );
    };

    return (
        <>
            <MetaTags>
                <title>התחברות לאתר קידוש החודש לרמב"ם</title>
                <meta name="description" content='טופס התחברות לאתר קידוש החודש לרמב"ם.' />
            </MetaTags>
            {state.email === '' ? signin() : signout()}
        </>
    )
};



export default SigninScreen;
