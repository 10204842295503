import React from "react";
import { Container, Row } from "react-bootstrap";

const Hakdama = () => {
    return (
        <>
            <Container>
                <Row>
                    <h3 dir="rtl">דבר עורך האתר</h3>
                    <div dir="rtl">
                        <p>
                            הנני להודיע כי אתר זה נבנה על פי תוכנה שכבר יצא לה שם בין המומחים להלכות קידוש החודש ונבנתה על טהרת הלכות קידוש החודש לרמב"ם.
                            ללא פניה לשדות זרים ה' ישמרנו.
                            וכל תוצאות קיצי הראיה שאנו רוצים לדעת עלו אחת לאחת עם חישובי הרמב"ם ועד היום אפשר לסמוך על תוצאותיה.
                        </p>
                        <p>
                            וברוב חקירותיי להניח ידי על שיטה מזוקקת, גיליתי כי רוב אלה שניסו לפרש הלכות אלה פשעו בשתים.
                        </p>
                        <p>
                            <strong>אחת</strong> עברו על דברי הרמב"ם עצמו בהלכות קידוש החודש פרק י"ט הלכה ט"ז "הרי בארנו חשבונות כל הדרכים שצריכין להם בידיעת הראיה ובחקירת העדים כדי שיהיה הכל ידוע למבינים
                            <strong> ולא יחסרו דרך מדרכי התורה ולא ישוטטו לבקש אחריה בספרים אחרים. דרשו מעל ספר ה' וקראו אחת מהנה לא נעדרה".</strong>
                        </p>
                        <p>
                            והרי ספר היד החזקה אינו ספר מליצות אלא ספר הלכות הקובע כי אין לשוטט בשדות זרים. וכמה הפליג בעל הגנת אגוז בגנות העוסקים בחוכמת הגלגלים מתוך ספרים זרים.
                            (אשר העידו עליו גדולי האחרונים ובהם האר"י הקדוש והבית יוסף כי עליו יש לסמוך). ונראה לי לצטט חלק מדבריו.
                            אמנם העונש הגדול והאשמה הגדולה לעוברים בעמק הבכא והם אנשי זמנינו בני ישראל אשר נראה אותו מניחים יסוד תורתינו אשר מתוכה יוכלו להשיג אם יתעסקו בלימודה,
                            וילכו אחרי ההבל ויהבלו, בהיות מתעסקים בחוכמת עכו"ם בלי סדרים, זורקים היתר ולוקחים איסור.
                            אפרים רועה רוח ורודף קדים וברית עם חכמי האומות יכרותו, וכו' להתעסק בחכמת היוונים ואבדה חכמה מבנים.
                            וכן מגדיל לאמר בהביאו ממסכת סנהדרין צ' ע"א "ואלו שאין להם חלק לעולם הבא וכו' אף הקורא בספרים חיצונים".
                        </p>
                        <p>
                            ועוד <strong>שנית</strong> עברו על הלכה מפורשת פרק י"ב הלכה ב' כי לירח אין גובה כשאר שבעת הגלגלים.
                            "<strong>ואותה נקודה של גלגל השמש ושאר הכוכבים חוץ מן הירח</strong> וכו' '".
                            הנה בפרק ט"ו הביאו את שיטת הערלים הסותרת את שיטת הרמב"ם ומוסיפים עוד גלגל על מה שביאר הרמב"ם,
                            ושיבשו החשבונות כך שכל מי שילך בדרכם יצלול במים אדירים ויעלה חרס בידו (פרק י"א הלכה ב').
                        </p>
                        <a target="_blank" href="https://sites.google.com/site/kidushhahodesh/%D7%90%D7%A1%D7%98%D7%A8%D7%95%D7%A0%D7%95%D7%9E%D7%99%D7%94-%D7%A2%D7%91%D7%A8%D7%99%D7%AA-%D7%A7%D7%99%D7%93%D7%95%D7%A9-%D7%94%D7%97%D7%95%D7%93%D7%A9-%D7%9C%D7%A8%D7%9E%D7%91%D7%9D-jewish-astronomy/%D7%A7%D7%99%D7%93%D7%95%D7%A9-%D7%94%D7%97%D7%95%D7%93%D7%A9-%D7%9C%D7%A8%D7%9E%D7%91%D7%9D-%D7%9E%D7%91%D7%95%D7%90%D7%A8-%D7%A2%D7%99-%D7%9E%D7%90%D7%95%D7%AA-%D7%9E%D7%A6%D7%92%D7%95%D7%AA/%D7%94%D7%9C%D7%9B%D7%95%D7%AA-%D7%A7%D7%99%D7%93%D7%95%D7%A9-%D7%94%D7%97%D7%95%D7%93%D7%A9-%D7%A4%D7%A8%D7%A7-%D7%98%D7%95/%D7%A9%D7%99%D7%98%D7%AA-%D7%94%D7%A8%D7%9E%D7%91%D7%9D-%D7%91%D7%97%D7%99%D7%A9%D7%95%D7%91-%D7%94%D7%9E%D7%A8%D7%97%D7%A7-%D7%94%D7%9B%D7%A4%D7%95%D7%9C-%D7%91%D7%94%D7%9C%D7%9B%D7%95%D7%AA-%D7%A7%D7%99%D7%93%D7%95%D7%A9-%D7%94%D7%97%D7%95%D7%93%D7%A9">ביאור כיצד שיבשו החשבונות.</a>
                        <div>לכן עצתי ובקשתי שאם תראו דרכים שאינם מתוארים כפי שאני מתאר באתר זה פרק ט"ו וכן בתוכנה. ותראו צורות המשובשות כפי שמובא כאן <a target="_blank" href="https://sites.google.com/site/kidushhahodesh/%D7%90%D7%A1%D7%98%D7%A8%D7%95%D7%A0%D7%95%D7%9E%D7%99%D7%94-%D7%A2%D7%91%D7%A8%D7%99%D7%AA-%D7%A7%D7%99%D7%93%D7%95%D7%A9-%D7%94%D7%97%D7%95%D7%93%D7%A9-%D7%9C%D7%A8%D7%9E%D7%91%D7%9D-jewish-astronomy/%D7%A7%D7%99%D7%93%D7%95%D7%A9-%D7%94%D7%97%D7%95%D7%93%D7%A9-%D7%9C%D7%A8%D7%9E%D7%91%D7%9D-%D7%9E%D7%91%D7%95%D7%90%D7%A8-%D7%A2%D7%99-%D7%9E%D7%90%D7%95%D7%AA-%D7%9E%D7%A6%D7%92%D7%95%D7%AA/%D7%94%D7%9C%D7%9B%D7%95%D7%AA-%D7%A7%D7%99%D7%93%D7%95%D7%A9-%D7%94%D7%97%D7%95%D7%93%D7%A9-%D7%A4%D7%A8%D7%A7-%D7%98%D7%95/%D7%A9%D7%99%D7%98%D7%AA-%D7%94%D7%A8%D7%9E%D7%91%D7%9D-%D7%91%D7%97%D7%99%D7%A9%D7%95%D7%91-%D7%94%D7%9E%D7%A8%D7%97%D7%A7-%D7%94%D7%9B%D7%A4%D7%95%D7%9C-%D7%91%D7%94%D7%9C%D7%9B%D7%95%D7%AA-%D7%A7%D7%99%D7%93%D7%95%D7%A9-%D7%94%D7%97%D7%95%D7%93%D7%A9">ביאור כיצד שיבשו החשבונות</a>.</div>
                        
                        <p>
                            התרחקו מחיבורים אלו ואל תשיתו ליבכם להם. ואלו שהוציאו לאור ספרים עם השיבושים הנ"ל ידאגו להרחיק מכשול מן הלומדים.
                        </p>
                    </div>
                </Row>
            </Container>
        </>
    );
};

export default Hakdama;