import React, { useContext } from "react";
import { Container, Row, Table } from "react-bootstrap";
import { getMaslulRochav, getMekomRosh, getYareachAmiti, menatMaslulRochavString } from "../../apis/orbits/orbitsGeneral";
import { Context as Authcontext } from "../../context/Authcontext";
import { VIEW_C16_STATE_1, VIEW_C16_STATE_10, VIEW_C16_STATE_11, VIEW_C16_STATE_12, VIEW_C16_STATE_13, VIEW_C16_STATE_14, VIEW_C16_STATE_15, VIEW_C16_STATE_16, VIEW_C16_STATE_17, VIEW_C16_STATE_18, VIEW_C16_STATE_19, VIEW_C16_STATE_2, VIEW_C16_STATE_20, VIEW_C16_STATE_21, VIEW_C16_STATE_22, VIEW_C16_STATE_23, VIEW_C16_STATE_24, VIEW_C16_STATE_25, VIEW_C16_STATE_26, VIEW_C16_STATE_27, VIEW_C16_STATE_28, VIEW_C16_STATE_29, VIEW_C16_STATE_3, VIEW_C16_STATE_30, VIEW_C16_STATE_31, VIEW_C16_STATE_32, VIEW_C16_STATE_33, VIEW_C16_STATE_34, VIEW_C16_STATE_35, VIEW_C16_STATE_36, VIEW_C16_STATE_4, VIEW_C16_STATE_5, VIEW_C16_STATE_6, VIEW_C16_STATE_7, VIEW_C16_STATE_8, VIEW_C16_STATE_9, VIEW_C16_STATE_OPEN } from "../../screens/chapters/Chapter16";


import AccodionSpan from "../AccodionSpan";
import DaysFromIkar2 from "../controls/DaysFromIkar2";

const RochavYareachTable = ({ days }) => {

    const rows = () => {
        return (
            <>
                <tr>
                    <td>מקום הירח האמיתי</td>
                    <td>{getYareachAmiti(days).amitiString}</td>
                </tr>
                <tr>
                    <td>מקום הראש</td>
                    <td>{getMekomRosh(days).mekomRoshString}</td>
                </tr>
                 <tr>
                    <td>מסלול רוחב הירח</td>
                    <td>{getMaslulRochav(days).maslulRochavString}</td>
                </tr>
                  <tr>
                    <td>רוחב הירח</td>
                    <td>{menatMaslulRochavString(getMaslulRochav(days).maslulRochav)}</td>
                </tr>
          </>
        );
    };

    return (
        <div style={{ textAlign: 'center' }}>
            <Table responsive bordered dir="rtl" color="red">
                <thead className="info-header">
                    <tr>
                        <th colSpan="2">חישוב רוחב הירח</th>
                    </tr>
                </thead>
                <tbody className="info-body">
                    {rows()}
                </tbody>
            </Table>
        </div>
    );
};


const Chapter16Content = ({ setViewState, params }) => {

    const { state } = useContext(Authcontext);

    return (
        <Container className="chapter-container">
            <Row>
                <h4 className="chpter-header">פרק טז</h4>
                <h6 className="paragrph">חישוב מקום הראש ומקום הזנב, ונטית הירח בעגולתו לצפון או לדרום והוא נקרא רוחב הירח</h6>
                {/* <p style={{ textAlign: 'center', color: 'red' }}>פרק זה בשלבי בניה</p> */}
                <div className="chapter-p" dir="rtl">
                    <strong className="span-6" onClick={() => setViewState(VIEW_C16_STATE_OPEN)}>א. </strong>
                    העגולה שסובבת בה הירח תמיד היא נוטה מעל העגולה שסובבת בה השמש תמיד, <span className="span-1" onClick={() => setViewState(VIEW_C16_STATE_1)}>חציה נוטה לצפון וחציה נוטה לדרום</span>,
                    ושתי נקודות יש בה זו כנגד זו שבהן פוגעות שתי העגולות זו בזו,
                    לפיכך כשיהיה הירח <span className="span-2" onClick={() => setViewState(VIEW_C16_STATE_2)}>באחת</span>
                    <span className="span-3" onClick={() => setViewState(VIEW_C16_STATE_3)}>משתיהן</span> נמצא סובב בעגולה של שמש כנגד השמש בשוה,
                    ואם יצא הירח מאחת משתי הנקודות נמצא מהלך לצפון השמש או לדרומה.
                    הנקודה שממנה יתחיל הירח לנטות לצפון השמש היא הנקראת <span className="span-4" onClick={() => setViewState(VIEW_C16_STATE_4)}>ראש</span>,
                    והנקודה שממנה יתחיל הירח לנטות לדרום השמש היא הנקראת <span className="span-5" onClick={() => setViewState(VIEW_C16_STATE_5)}>זנב</span>,
                    ומהלך שוה יש לזה הראש שאין בו לא תוספת ולא גרעון,
                    והוא <span className="span-1" onClick={() => setViewState(VIEW_C16_STATE_6)}>הולך במזלות אחורנית</span> מטלה לדגים לדלי וכן הוא סובב תמיד.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>ב. </strong>
                    מהלך הראש האמצעי ביום אחד שלשה חלקים ואחת עשרה שניות,
                    נמצא מהלכו בעשרה ימים אחד ושלשים חלקים ושבע וארבעים שניות,
                    ונמצא מהלכו <span className="span-2" onClick={() => setViewState(VIEW_C16_STATE_7)}>במאה יום</span> חמש מעלות ושבעה עשר חלקים ושלש וארבעים שניות, סימנם הי"ז מ"ג,
                    ונמצא מהלכו <span className="span-3" onClick={() => setViewState(VIEW_C16_STATE_8)}>באלף יום</span> שתים וחמשים מעלות ושבעה וחמשים חלקים ועשר שניות, סימנם נ"ב נז"י,
                    ונמצא שארית מהלכו <span className="span-4" onClick={() => setViewState(VIEW_C16_STATE_9)}>בעשרת אלפים יום</span> מאה ותשע וששים מעלות ואחד ושלשים חלקים וארבעים שניות, סימנם קס"ט לא"מ,
                    ונמצא מהלכו לתשעה ועשרים יום מעלה אחת ושנים ושלשים חלקים ותשע שניות, סימנם א' לב"ט,
                    ונמצא מהלכו <span className="span-5" onClick={() => setViewState(VIEW_C16_STATE_10)}>לשנה סדורה</span> שמונה עשרה מעלות וארבעה וארבעים חלקים ושתים וארבעים שניות, סימנם י"ח מ"ד מ"ב,
                    ואמצע הראש בתחלת ליל חמשי שהוא העיקר היה מאה ושמונים מעלות ושבעה וחמשים חלקים ושמונה ועשרים שניות סימנם <span className="span-1" onClick={() => setViewState(VIEW_C16_STATE_11)}>ק"פ נ"ז כ"ח</span>.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>ג. </strong>
                    אם תרצה לידע מקום הראש בכל עת שתרצה,
                    תוציא אמצעו לאותה העת כדרך שתוציא אמצע השמש ואמצע הירח,
                    ותגרע האמצע משלש מאות וששים מעלות, והנשאר הוא מקום הראש באותה העת, וכנגדו לעולם יהיה מקום הזנב.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>ד. </strong>
                    כיצד הרי שרצינו לידע מקום הראש לתחלת ליל ערב שבת שיומו שני לחדש אייר משנה זו שהיא שנת העיקר,
                    ומנין הימים הגמורים מתחלת ליל העיקר עד תחלת ליל זו שאנו רוצים לידע מקום הראש בו תשעה ועשרים יום.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>ה. </strong>
                    תוציא אמצע הראש לעת הזאת על הדרך שידעת, והוא שתוסיף מהלכו לתשעה ועשרים יום על העיקר,
                    יצא לך אמצע הראש מאה ושתים ושמונים מעלות ותשעה ועשרים חלקים ושבע ושלשים שניות, סימנם <span className="span-1" onClick={() => setViewState(VIEW_C16_STATE_17)}>קפ"ב כ"ט ל"ז</span>,
                    תגרע אמצע זה משלש מאות וששים ישאר לך מאה ושבע ושבעים מעלות ושלשים חלקים ושלש ועשרים שניות, סימנם <span className="span-2" onClick={() => setViewState(VIEW_C16_STATE_18)}>קע"ז לכ"ג</span>,
                    וזה הוא מקום הראש, ואל תפנה אל השניות, נמצא מקום הראש במזל בתולה בשבע ועשרים מעלות ושלשים חלקים,
                    ומקום הזנב כנגדו במזל דגים בשבע ועשרים מעלות ושלשים חלקים.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>ו. </strong>
                    לעולם יהיה בין הראש ובין הזנב חצי הגלגל בשוה,
                    לפיכך כל מזל שתמצא בו מקום הראש יהיה הזנב במזל שביעי ממנו בכמו מנין המעלות והחלקים בשוה,
                    אם יהיה הראש בעשר מעלות במזל פלוני יהיה הזנב בעשר מעלות ממזל שביעי ממנו.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>ז. </strong>
                    ומאחר שתדע מקום הראש ומקום הזנב ומקום הירח האמתי,
                    התבונן בשלשתן, אם מצאת הירח <span className="span-1" onClick={() => setViewState(VIEW_C16_STATE_12)}>עם הראש</span>
                    או <span className="span-2" onClick={() => setViewState(VIEW_C16_STATE_13)}>עם הזנב</span> במעלה אחת וחלק אחד, תדע שאין הירח נוטה לא לצפון השמש ולא לדרומה,
                    ואם ראית מקום הירח <span className="span-3" onClick={() => setViewState(VIEW_C16_STATE_14)}>לפני מקום הראש</span> והוא הולך כנגד הזנב,
                    תדע שהירח נוטה לצפון השמש,
                    ואם היה הירח <span className="span-4" onClick={() => setViewState(VIEW_C16_STATE_15)}>לפני מקום הזנב</span> והרי הוא הולך כנגד הראש, תדע שהירח נוטה לדרום השמש.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>ח. </strong>
                    הנטיה שנוטה הירח לצפון השמש או לדרומה, היא הנקראת רוחב הירח,
                    ואם היה נוטה לצפון נקרא רוחב צפוני,
                    ואם היה נוטה לדרום נקרא רוחב דרומי,
                    ואם היה הירח באחת משתי הנקודות לא יהיה לו רוחב כמו שביארנו.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>ט. </strong>
                    לעולם לא יהיה רוחב הירח יתר על חמש מעלות בין בצפון בין בדרום,
                    <span className="span-5" onClick={() => setViewState(VIEW_C16_STATE_16)}>אלא כך הוא דרכו</span> יתחיל מן הראש ויתרחק ממנו מעט מעט, והמרחק הולך ונוסף עד שיגיע לחמש מעלות,
                    ויחזור ויתקרב מעט מעט עד שלא יהיה לו רוחב כשיגיע לזנב,
                    ויחזור ויתרחק מעט מעט והמרחק נוסף עד שיגיע לחמש מעלות, ויחזור ויתקרב עד שלא יהיה לו רוחב.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>י. </strong>
                    אם תרצה לידע רוחב הירח כמה הוא בכל עת שתרצה, ואם צפוני הוא או דרומי,
                    תוציא מקום הראש ומקום הירח האמתי לאותה העת,
                    ותגרע מקום הראש ממקום הירח האמתי,
                    והנשאר הוא הנקרא מסלול הרוחב,
                    ואם יהיה מסלול הרוחב <span className="span-1" onClick={() => setViewState(VIEW_C16_STATE_19)}>ממעלה אחת עד מאה ושמונים</span>, תדע שרוחב הירח צפוני,
                    ואם היה המסלול <span className="span-2" onClick={() => setViewState(VIEW_C16_STATE_20)}>יתר על מאה ושמונים</span> תדע שרוחב הירח דרומי,
                    ואם היה <span className="span-3" onClick={() => setViewState(VIEW_C16_STATE_21)}>מאה ושמונים בשוה</span> או
                    <span className="span-4" onClick={() => setViewState(VIEW_C16_STATE_22)}>שלש מאות וששים בשוה</span> אין לירח רוחב כלל,
                    ותחזור ותראה מנת מסלול הרוחב כמה היא,
                    והוא שיעור נטייתו לצפון או לדרום,
                    והוא הנקרא רוחב הירח הדרומי או הצפוני כמו שביארנו.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>יא. </strong>
                    וכמה היא מנת מסלול הרוחב, אם יהיה מסלול הרוחב <span className="span-5" onClick={() => setViewState(VIEW_C16_STATE_23)}>עשר מעלות</span> תהיה מנתו שתים וחמשים חלקים,
                    ואם יהיה המסלול הזה <span className="span-1" onClick={() => setViewState(VIEW_C16_STATE_24)}>עשרים מעלות</span> תהיה מנתו מעלה אחת ושלשה וארבעים חלקים,
                    ואם יהיה המסלול <span className="span-2" onClick={() => setViewState(VIEW_C16_STATE_25)}>שלשים</span> תהיה מנתו שתי מעלות ושלשים חלקים,
                    ואם יהיה המסלול <span className="span-3" onClick={() => setViewState(VIEW_C16_STATE_26)}>ארבעים</span> תהיה מנתו שלש מעלות ושלשה עשר חלקים,
                    ואם יהיה המסלול <span className="span-4" onClick={() => setViewState(VIEW_C16_STATE_27)}>חמשים מעלות</span> תהיה מנתו שלש מעלות וחמשים חלקים,
                    ואם יהיה המסלול <span className="span-5" onClick={() => setViewState(VIEW_C16_STATE_28)}>ששים</span> תהיה מנתו ארבע מעלות ועשרים חלקים,
                    ואם יהיה המסלול <span className="span-1" onClick={() => setViewState(VIEW_C16_STATE_29)}>שבעים</span> תהיה מנתו ארבע מעלות ושנים וארבעים חלקים,
                    ואם יהיה המסלול <span className="span-2" onClick={() => setViewState(VIEW_C16_STATE_30)}>שמונים</span> תהיה מנתו ארבע מעלות וחמשה וחמשים חלקים,
                    ואם יהיה המסלול <span className="span-3" onClick={() => setViewState(VIEW_C16_STATE_31)}>תשעים</span> תהיה מנתו חמש מעלות.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>יב. </strong>
                    ואם יהיו אחדים עם העשרות תקח הראוי להם לפי היתר שבין שתי המנות כמו שעשית במסלול השמש ובמסלול הירח,
                    כיצד הרי שהיה מסלול הרוחב <span className="span-4" onClick={() => setViewState(VIEW_C16_STATE_32)}>שלש וחמשים מעלות</span>,
                    וכבר ידעת שאילו היה המסלול חמשים היתה מנתו שלש מעלות וחמשים חלקים,
                    ואילו היה המסלול ששים היתה מנתו ארבע מעלות ועשרים חלקים,
                    נמצא היתר בין שתי המנות שלשים חלקים שלשה חלקים לכל מעלה,
                    ונמצא לפי חשבון מסלול זה שהוא שלש וחמשים מנתו שלש מעלות ותשעה וחמשים חלקים,
                    ועל דרך זו תעשה בכל מנין ומנין.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>יג. </strong>
                    מאחר שתדע מנות של מסלול הרוחב עד תשעים כמו שהודענוך,
                    תדע מנות של כל מניינות המסלול,
                    שאם יהיה המסלול יתר על תשעים עד מאה ושמונים תגרע המסלול ממאה ושמונים והנשאר תדע בו המנה.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>יד. </strong>
                    וכן אם היה המסלול יתר ממאה ושמונים עד מאתים ושבעים תגרע ממנו מאה ושמונים והנשאר תדע בו המנה.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>טו. </strong>
                    ואם היה המסלול יתר על מאתים ושבעים עד שלש מאות וששים, תגרע אותו משלש מאות וששים והנשאר תדע בו המנה.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>טז. </strong>
                    כיצד הרי שהיה המסלול <span className="span-5" onClick={() => setViewState(VIEW_C16_STATE_33)}>מאה וחמשים</span> תגרע אותו ממאה ושמונים נשאר שלשים,
                    וכבר ידעת שמנת שלשים שתי מעלות ושלשים חלקים וכך תהיה מנת מאה וחמשים שתי מעלות ושלשים חלקים.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>יז. </strong>
                    הרי שהיה המסלול <span className="span-1" onClick={() => setViewState(VIEW_C16_STATE_34)}>מאתים</span>, תגרע ממנו מאה ושמונים ישאר עשרים,
                    וכבר ידעת שמנת עשרים היא מעלה אחת ושלשה וארבעים חלקים,
                    וכך תהיה מנת מאתים (תהיה) מעלה אחת ושלשה וארבעים חלקים.
                </div>
                <p></p>

                <div className="chapter-p" dir="rtl">
                    <strong>יח. </strong>
                    הרי שהיה המסלול <span className="span-2" onClick={() => setViewState(VIEW_C16_STATE_35)}>שלש מאות</span> תגרע אותו משלש מאות וששים נשאר ששים,
                    וכבר ידעת שמנת ששים ארבע מעלות ועשרים חלקים,
                    וכך היא מנת שלש מאות ארבע מעלות ועשרים חלקים, ועל דרך זו בכל המניינות.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <AccodionSpan setState={() => setViewState(VIEW_C16_STATE_36)} className="span-4" collapseItem={state.useContent === true ? <DaysFromIkar2 params={params}><RochavYareachTable days={params.days}/></DaysFromIkar2> : <></>}>רוחב הירח</AccodionSpan>
                </div>
                <div className="chapter-p" dir="rtl">
                    <strong>יט. </strong>
                    הרי שרצינו לידע רוחב הירח כמה הוא ובאיזה רוח הוא אם צפוני או דרומי בתחלת ליל ערב שבת שני לחדש אייר משנה זו,
                    וכבר ידעת שמקום הירח האמתי היה בליל זה בשמונה עשרה מעלות וששה ושלשים חלקים ממזל שור, סימנו י"ח ל"ו,
                    ומקום הראש היה באותה העת בשבע ועשרים מעלות ושלשים חלקים ממזל בתולה, סימנו כז"ל,
                    תגרע מקום הראש ממקום הירח, יצא לך מסלול הרוחב מאתים אחת ושלשים מעלות וששה חלקים, סימנו רל"א ו',
                    לפי שאין משגיחין על החלקים בכל (המסלול) [מסלול], ונמצאת המנה של מסלול זה בדרכים שביארנו בפרק זה שלש מעלות ושלשה וחמשים חלקים,
                    וזה הוא רוחב הירח בתחלת ליל זה, והוא דרומי שהרי המסלול יתר על מאה ושמונים.
                </div>
                <p></p>

            </Row>
        </Container>
    );
};

export default Chapter16Content;