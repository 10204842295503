import React, { useState } from "react";
import "firebase/auth";
import firebase from "firebase";
import "firebase/database"
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import validator from "validator";
import { MetaTags } from "react-meta-tags";
import ReCAPTCHA from "react-google-recaptcha";
import sendVerificationEmail from "../apis/sendVerificationEmail";
import { Link } from "react-router-dom";

const SignupScreen = ({ history }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const [isName, setIsName] = useState(true);
    const [isEmail, setIsEmail] = useState(true);
    const [isPpass, setIsPass] = useState(true);
    const [error, setError] = useState('');
    const [notARobot, setNotARobot] = useState(false);
    const [agreeToPolicy, setEgreeToPolicy,] = useState(false);

    const validateName = () => {
        const v = validator.isLength(name, { min: 3, max: 32 });
        setIsName(v);
        return v;
    };

    const validateEmail = () => {
        const v = validator.isEmail(email);
        setIsEmail(v);
        return v;
    };

    const validatePass = () => {
        const v = validator.isLength(pass, { min: 4, max: 32 });
        setIsPass(v);
        return v;
    };


    const verifyEmail = async (email) => {
        await sendVerificationEmail(email, history, setError);
    };

    const writeUserData = (name, email) => {
        const userId = btoa(email);

        firebase.database().ref('users/' + userId).set({
            username: name,
            email: email,
            verified: false,
            useContent: false,
            createDate: Date.now()

        }).then(v => { }).catch(e => console.log(e));
    }

    return (
        <>
            <MetaTags>
                <title>הרשמה לאתר קידוש החודש לרמב"ם</title>
                <meta name="description" content='טופס הרשמה לאתר קידוש החודש לרמב"ם.' />
            </MetaTags>

            <Container>
                <Row className="justify-content-md-center">
                    <Col lg={6} md={8} xs={12} >
                        <Row className="text-center">
                            <h4>טופס הרשמה לאתר קידוש החודש לרמב"ם</h4>
                            <hr />
                        </Row>
                        <Row>
                            <Form>
                                <Form.Group as={Row} className="mb-3" controlId="formBasicName">
                                    <Form.Label column sm="2">שם מלא</Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            value={name}
                                            onChange={e => setName(e.target.value)}
                                            type="text" placeholder="Enter full name"
                                            isInvalid={!isName}
                                            onBlur={() => validateName()}
                                        />
                                        {!isName ? <Form.Text style={{ color: 'red' }}>
                                            יש למלא שם ושם משפחה לא ארוך מ 32 תוים
                                        </Form.Text> : null}
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3" controlId="formBasicEmail">
                                    <Form.Label column sm="2">אימייל</Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                            type="email" placeholder="Enter email"
                                            isInvalid={!isEmail}
                                            onBlur={() => validateEmail()}
                                        />
                                        {!isEmail ? <Form.Text style={{ color: 'red' }}>
                                            יש למלא כתובת אימייל חוקית
                                        </Form.Text> : null}
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3" controlId="formBasicPassword">
                                    <Form.Label column sm="2">סיסמא</Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            value={pass}
                                            onChange={e => setPass(e.target.value)}
                                            onBlur={() => validatePass()}
                                            isInvalid={!isPpass}
                                            type="password" placeholder="Password" />
                                        {!isPpass ? <Form.Text style={{ color: 'red' }}>
                                            אורך הסיסמא צריך להיות בין 6 ל 32 תויים
                                        </Form.Text> : null}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="formBasicCheckbox">
                                    <Col sm="6">
                                        <Form.Check
                                            checked={agreeToPolicy}
                                            onChange={e => setEgreeToPolicy(e.target.checked)}
                                            type="checkbox" label="מאשר את תנאי האתר" />
                                    </Col>
                                    <Col sm="6"><Link to="/תנאי-שימוש">תנאי שימוש ופרטיות</Link></Col>
                                </Form.Group>
                                <ReCAPTCHA
                                    sitekey="6LdwAAwcAAAAAJEglFPUzEuV_KfjiIejMnCC86vb"
                                    onChange={v => setNotARobot(v !== null)}

                                />
                                <Button variant="primary" type="submit"
                                    disabled={!agreeToPolicy || !notARobot}
                                    onClick={e => {
                                        e.preventDefault();
                                        setError("");

                                        if (validateName() && validateEmail() && validatePass()) {
                                            firebase.auth().createUserWithEmailAndPassword(email, pass)
                                                .then((userCredential) => {
                                                    // Signed in 
                                                    // var user = userCredential.user;
                                                    // console.log(user);
                                                    // ...
                                                    writeUserData(name, email);
                                                    verifyEmail(email);
                                                })
                                                .catch((error) => {
                                                    //   var errorCode = error.code;
                                                    var errorMessage = error.message;
                                                    setError(errorMessage);
                                                    // ..
                                                });
                                        } else {
                                            setError('יש לוודא כי כל הפרטום מולאו בצורה תקינה');
                                        }
                                    }}
                                >
                                    הירשם
                                </Button>
                                <br />
                                {error === '' ? null : <Form.Text dir="rtl" style={{ color: 'red' }}>{error}</Form.Text>}

                            </Form>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default SignupScreen;