import { DaysPast, getDateFromDaysPast, TimeAccessor, YearTypesAndCycles, YMDs } from "../utiles/hebrew-calculator";

export const normalMonths = ['תשרי', 'מר חשון', 'כסלו', 'טבת', 'שבט', 'אדר', 'ניסן', 'אייר', 'סיון', 'תמוז', 'אב', 'אלול'];
//['', '', '', '', '', '', '', '', '', '', '', ''];
export const leapMonths = ['תשרי', 'מר חשון', 'כסלו', 'טבת', 'שבט', 'אדר א', 'אדר ב', 'ניסן', 'אייר', 'סיון', 'תמוז', 'אב', 'אלול'];
//['', '', '', '', '', '', '', '', '', '', '', '', ''];
export const monthDays = ['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ז', 'ח', 'ט', 'י', 'יא', 'יב', 'יג', 'יד', 'טו', 'טז', 'יז', 'יח', 'יט', 'כ', 'כא', 'כב', 'כג', 'כד', 'כה', 'כו', 'כז', 'כח', 'כט', 'ל'];

export const hundreds = ['ק', 'ר', 'ש', 'ת', 'תק', 'תר', 'תש', 'תת', 'תתק'];
export const tens = ['י', 'כ', 'ל', 'מ', 'נ', 'ס', 'ע', 'פ', 'צ'];
export const ones = ['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ז', 'ח', 'ט'];

export const getTensOnes = (years) => {
    switch (years) {
        case 15:
            return 'טו';
        case 16:
            return 'טז';
        default:
            break;
    }
    let text = '';
    let remain = Math.floor(years / 10);
    if (remain > 0) {
        text += `${tens[remain - 1]}`;
    }

    years -= remain * 10;
    if (years > 0) {
        text += `${ones[years - 1]}`;
    }

    return text;
}

export const getHebrewYearFormat = (years = 4938) => {
    let remain = Math.floor(years / 1000);
    let text = '';
    if (remain > 0) {
        text += `${ones[remain - 1]}' `;
    }
    years -= remain * 1000;

    remain = Math.floor(years / 100);
    if (remain > 0) {
        text += `${hundreds[remain - 1]}`;
    }

    text += getTensOnes(years % 100);

    return text;
};

export const getHebrewDateFormat = (ymd = new YMDs(4938, 8, 3)) => {
    
    const ytc = new YearTypesAndCycles(ymd.years);
    const month = ytc.isleapYear ? leapMonths[ymd.months - 1] : normalMonths[ymd.months - 1];
    const text = `${monthDays[ymd.mDays - 1]} ${month} ${getHebrewYearFormat(ymd.years)}`;

    return text;
};

export const getNewDateFromOrigin = (daysToAdd = 100, original = new YMDs(4938, 8, 3)) => {
    const newDp = (new DaysPast(original)).daysPastTimeAccessor.plus(
        new TimeAccessor(daysToAdd), false);
        // console.log(newDp);
    return getDateFromDaysPast(newDp);
};

