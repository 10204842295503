import React from "react";
import { Container, Row, Button, Col } from "react-bootstrap";

const ConnectionEmailSentScreen = ({ history }) => {

    return (
        <>
            <Container>
                <Row>
                    <h3 className="text-center" dir="rtl">הודעתך נשלחה ההצלחה ניצור איתך קשר בהקדם</h3>
                </Row>
                <Row className="justify-content-center">
                    <Col sm={2} className="text-center">
                        <Button className="outline-danger" onClick={
                            () => history.push('/')
                        }>סגור</Button>
                    </Col>
                </Row>

            </Container>
        </>
    );
};

export default ConnectionEmailSentScreen;