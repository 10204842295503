import { useState, useEffect } from 'react';
import { GRAPHIC_PADDING, HEADER_HEIGHT } from '../apis/commonConsts';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  let graphicWidth, dataWidth, paneHeight;
  if (height > width) {
    paneHeight = height / 2 - GRAPHIC_PADDING - HEADER_HEIGHT;
    dataWidth = width - GRAPHIC_PADDING;
    graphicWidth = width - 2 * GRAPHIC_PADDING;
  } else {
    paneHeight = height - 2 * GRAPHIC_PADDING - HEADER_HEIGHT;
    dataWidth = graphicWidth = width / 2 - GRAPHIC_PADDING;
  }
  return {
    width,
    height,
    graphicWidth,
    dataWidth,
    paneHeight
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
