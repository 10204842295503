import { alpha } from "@material-ui/core";
import { Matrix, Point3D, SpeedUnits, YMDs } from "../../utiles/hebrew-calculator";
import { calcMenatHaMslul } from "./sun-orbits";


export const ikarDate = new YMDs(4938, 8, 3);

export const emzaSemeshSpeed = new SpeedUnits(0, 0, 59, 8, 19, 48);
export const govaShemeshSpeed = new SpeedUnits(0, 0, 0, 0, 9);
export const emzaYareachSpeed = new SpeedUnits(0, 13, 10, 35, 1, 48);
export const emzaMaslulYareachSpeed = new SpeedUnits(0, 13, 3, 53, 55, 48);
export const emzaRoshSpeed = new SpeedUnits(0, 0, 3, 10, 37, 48);


export const ikarShemesh = new SpeedUnits(0, 7, 3, 32);
export const ikarGovaShemesh = new SpeedUnits(0, 86, 45, 8);
export const ikarEmzaYareach = new SpeedUnits(0, 31, 14, 43);
export const ikarEmzaMaslulYareach = new SpeedUnits(0, 84, 28, 42);
export const ikarEmzaRosh = new SpeedUnits(0, 180, 57, 28);


export const getShemeshdegree = (days) => {
    const v = emzaSemeshSpeed.degreesCount * days + ikarShemesh.degreesCount;
    return v;
};

export const getGovaShemeshDegree = (days) => {
    const v = govaShemeshSpeed.degreesCount * days + ikarGovaShemesh.degreesCount;
    return v;
};

export const speedFormat = (speed = emzaSemeshSpeed, accuracy = 3, roundTo360 = true) => {
    speed = roundTo360 ? new SpeedUnits(0, speed.degreesCount % 360) : speed;
    const paramsName = ['מעגלים', 'מעלות', 'חלקים', 'שניות', 'שלישיות', 'רבעיות', 'חמישיות'];
    let text = '';
    for (let i = 0; i < speed.keys.length; i++) {
        const key = speed.keys[i];
        const val = speed[key];
        if (val > 0) {
            if (text.length > 0) {
                text += ', '
            }
            if (accuracy <= i) {
                if (val < (speed.ratios[i] - 1) && (i + 1) < speed.keys.length) {
                    const subVal = Math.round(speed[speed.keys[i + 1]] / speed.ratios[i + 1])
                    text += `${val + subVal} ${paramsName[i]}`;
                }
                else {
                    text += `${val} ${paramsName[i]}`;
                }

                break;
            }
            text += `${val} ${paramsName[i]}`;
        }
    }

    return text;
};

export const startUTC = 1633261151386; // 3/10/2021
// const startHebDate = new YMDs(5782, 1, 27);
export const msInDay = 24 * 3600 * 1000;
export const getCompleteDaysFromStartUTC = () => {
    const current = new Date();
    const currentUtcMs = current.getTime() + current.getTimezoneOffset() * 60000;
    const diffDays = Math.floor((currentUtcMs - startUTC) / msInDay);
    return diffDays;
};


export const getDaysFromIkar = () => {
    // const ikarPastDays = (new DaysPast(ikarDate)).daysPast;
    // const startPosDays = (new DaysPast(startHebDate)).daysPast;
    const daysPastFromIkar = 308087 + getCompleteDaysFromStartUTC();
    return daysPastFromIkar;
};

export const getEmzaShemeshString = (days) => {
    return speedFormat(new SpeedUnits(getShemeshdegree(days) / 360))
};

export const getGovaShemeshString = (days) => {
    return speedFormat(new SpeedUnits(getGovaShemeshDegree(days) / 360))
};

export const getMaslulShemesh = (days) => {
    let maslulShemesh = (getShemeshdegree(days) - getGovaShemeshDegree(days)) % 360;
    if (maslulShemesh < 0) {
        maslulShemesh += 360;
    }

    const maslulShemeshString = speedFormat(new SpeedUnits(maslulShemesh / 360));

    return { maslulShemesh, maslulShemeshString };

};

export const getMenatMaslul = (days) => {
    const menatMaslul = calcMenatHaMslul(getMaslulShemesh(days).maslulShemesh / 180 * Math.PI);
    const sign = menatMaslul > 0 ? "-" : "+";

    const menatMaslulString = `(${sign}) ${speedFormat(new SpeedUnits(Math.abs(menatMaslul / 360)), 3)}`;

    return { menatMaslul, menatMaslulString };
};

export const getMekomShemeshAmiti = (days) => {
    let shemeshAmiti = (getShemeshdegree(days) - getMenatMaslul(days).menatMaslul) % 360;
    if (shemeshAmiti < 0) {
        shemeshAmiti += 360;
    }

    const shemeshAmitiString = speedFormat(new SpeedUnits(shemeshAmiti / 360));

    return { shemeshAmiti, shemeshAmitiString };
};

export const aliyaYesharaPoint = (angle, alfa = 23.5) => {
    const mat = new Matrix();
    mat.init();
    mat.rotMulY(angle);
    mat.rotMulX(alfa);
    const pt = mat.transform(new Point3D(1, 0, 0));

    return pt;
};

export const getAliyaYesharaAngle = (angle, alfa = 23.5) => {
    const pt = aliyaYesharaPoint(angle, alfa);
    const rad = Math.atan2(pt.z, pt.x);
    const deg = rad / Math.PI * 180;

    return { rad, deg };
};

export const turnYByZAndAlfa = (angle, alfa = 32) => {
    const alfaDiameter = Math.sin(alfa / 180 * Math.PI);
    const lengthLineLength = Math.tan(angle / 180 * Math.PI) * alfaDiameter;
    const lengthLineRadius = Math.cos(alfa / 180 * Math.PI);
    const rad = Math.asin(lengthLineLength / lengthLineRadius);
    const deg = rad * 180 / Math.PI;

    return { rad, deg };
};

export const getEmzaYareachReiya = (days) => {
    let emzaYareach = (ikarEmzaYareach.degreesCount + emzaYareachSpeed.degreesCount * days) % 360;
    let hisurYetur = hisurYeturEmzaYareach(getShemeshdegree(days)).hisurYetur;

    let yareachReiya = (emzaYareach + hisurYetur) % 360;
    if (yareachReiya < 0) {
        yareachReiya += 360;
    }

    const yareachReiyaString = speedFormat(new SpeedUnits(yareachReiya / 360));

    return { yareachReiya, yareachReiyaString };
}

export const getEmzaMaslulYareach = (days) => {
    const emzaMaslul = (ikarEmzaMaslulYareach.degreesCount +
        emzaMaslulYareachSpeed.degreesCount * days) % 360;

    const emzaMaslulString = speedFormat(new SpeedUnits(emzaMaslul / 360));

    return { emzaMaslul, emzaMaslulString };
};

export const getMerchakAndKaful = (days) => {
    const shemesh = getShemeshdegree(days);
    const yareachReiya = getEmzaYareachReiya(days).yareachReiya;
    let merchak = (yareachReiya - shemesh) % 360;
    if (merchak < 0) {
        merchak += 360;
    }

    const kaful = merchak * 2;
    const merchakString = speedFormat(new SpeedUnits(merchak / 360));
    const kafulString = speedFormat(new SpeedUnits(kaful / 360), 3, false);

    return { merchak, merchakString, kaful, kafulString };
};

export const getMenatMerchakKaful = (kaful, imagenaryWill = 0.195) => {
    let gama = ((kaful % 360) < 180 ? 180 - kaful : kaful - 180) / 180 * Math.PI;
    let c = Math.sqrt(1 + imagenaryWill * imagenaryWill -
        2 * imagenaryWill * Math.cos(gama));

    let rad = Math.asin(imagenaryWill * (Math.sin(gama) / c));
    if (kaful > 180) {
        rad = -rad;
    }

    const deg = rad / Math.PI * 180;
    const sign = deg < 0 ? "-" : "+";
    const degString = `(${sign}) ${speedFormat(new SpeedUnits(Math.abs(deg) / 360), 2)}`;

    return { rad, deg, degString };
};

export const getMaslulNachon = (days) => {
    const maslulYareach = getEmzaMaslulYareach(days).emzaMaslul;
    const menatKaful = getMenatMerchakKaful(getMerchakAndKaful(days).kaful).deg;

    let nachon = (maslulYareach + menatKaful) % 360;
    if (nachon < 0) {
        nachon += 360;
    }

    const nachonString = speedFormat(new SpeedUnits(nachon / 360));

    return { nachon, nachonString };
};


export const hisurYeturEmzaYareach = (mazaAngle) => {
    const zTurn = Math.asin(aliyaYesharaPoint(mazaAngle).y) * 180 / Math.PI;
    const dayPart = turnYByZAndAlfa(zTurn).deg / 360;

    const hisurYetur = emzaYareachSpeed.degreesCount * dayPart -
        emzaSemeshSpeed.degreesCount * dayPart;

    const sign = hisurYetur > 0 ? "-" : "+";

    const hisurYeturString = `(${sign}) ${speedFormat(new SpeedUnits(Math.abs(hisurYetur / 360)), 3)}`;

    return { hisurYetur, hisurYeturString };
};

export const threePointsAngle = (axis = new Point3D(), p1 = new Point3D(), p2 = new Point3D()) => {
    const v1 = p1.minus(axis);
    const v2 = p2.minus(axis);
    const angle = Math.acos((v1.x * v2.x + v1.y * v2.y + v1.z * v2.z) /
        (Math.sqrt(v1.x * v1.x + v1.y * v1.y + v1.z * v1.z) *
            Math.sqrt(v2.x * v2.x + v2.y * v2.y + v2.z * v2.z)));

    return angle;
};

export const getMenatMaslulYareach = (maslulNachon, emzaYareachRadius = 1, maslulRadius = Math.tan((5 + 8.6 / 60) / 180 * Math.PI)) => {
    const y = Math.sin(maslulNachon / 180 * Math.PI) * maslulRadius;
    const x = Math.cos(maslulNachon / 180 * Math.PI) * maslulRadius + emzaYareachRadius;
    const rad = Math.atan2(y, x);
    const deg = rad * 180 / Math.PI;

    return { rad, deg };
};

export const getMenatMaslulNachon = (days) => {
    const nachon = getMaslulNachon(days).nachon;
    const mana = getMenatMaslulYareach(nachon).deg;
    const sign = mana > 0 ? "-" : "+";

    const manaString = `(${sign}) ${speedFormat(new SpeedUnits(Math.abs(mana / 360)), 2)}`;

    return { mana, manaString };
};

export const getYareachAmiti = (days) => {
    const yareachReiya = getEmzaYareachReiya(days).yareachReiya;
    const menatMaslulNachon = getMenatMaslulNachon(days).mana;

    let amiti = (yareachReiya - menatMaslulNachon) % 360;
    if (amiti < 0) {
        amiti += 360;
    }

    const amitiString = speedFormat(new SpeedUnits(amiti / 360));

    return { amiti, amitiString };
};

export const menatMaslulRochav = (maslulRochav, alfa = 5) => {
    let rad = Math.asin(Math.sin(maslulRochav / 180 * Math.PI) * Math.sin(alfa / 180 * Math.PI));
    const deg = rad * 180 / Math.PI;

    return { rad, deg };
};
export const menatMaslulRochavString = (maslulRochav) => {
    const deg = menatMaslulRochav(maslulRochav).deg;
    // console.log(deg);
    const str = speedFormat(new SpeedUnits(Math.abs(deg / 360)), 2);

    const sn = d => d < 0 ? "צפון" : "דרום";

    const z = "צפון";
    const d = "דרום";
    const sign = str === "" ? "0" : deg < 0 ? d : z;
    // console.log(sign);

    const manaString = `${sign} ${str}`;

    return manaString;
};

export const getMahalchRosh = (days) => {
    const mahalachEmzaRosh =
        (ikarEmzaRosh.degreesCount +
            emzaRoshSpeed.degreesCount * days) % 360;

    return mahalachEmzaRosh;
};

export const getMekomRosh = (days) => {
    const mahalachEmzaRosh = getMahalchRosh(days);

    const mekomRosh = 360 - mahalachEmzaRosh;
    const mekomRoshString = speedFormat(new SpeedUnits(mekomRosh / 360), 2);

    return { mekomRosh, mekomRoshString };

};

export const getMaslulRochav = (days) => {
    let maslulRochav = (getYareachAmiti(days).amiti - getMekomRosh(days).mekomRosh) % 360;
    if (maslulRochav < 0) {
        maslulRochav += 360;
    }

    const maslulRochavString = speedFormat(new SpeedUnits(maslulRochav / 360), 2);

    return { maslulRochav, maslulRochavString };
};

export const keepIn360Range = (angle) => {
    let a = angle % 360;
    if (a < 0) {
        a += 360;
    }

    return a;
};


