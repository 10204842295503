import React, { useState } from "react";
import firebase from "firebase";
import "firebase/auth";
import ReCAPTCHA from "react-google-recaptcha";
import { Col, Container, Form, Row, Button } from "react-bootstrap";
import validator from "validator";


const ForgotPassScreen = ({ history }) => {
    const [notARobot, setNotARobot] = useState(false);
    const [email, setEmail] = useState('');
    const [isEmail, setIsEmail] = useState(true);
    const [error, setError] = useState('');

    const validateEmail = () => {
        const v = validator.isEmail(email);
        setIsEmail(v);
        return v;
    };

    return (
        <>
            <Container>
                <Row className="justify-content-md-center">
                    <Col lg={6} md={8} xs={12}>
                        <Row>
                            <h3 className="text-center">איתחול סיסמא</h3>
                            <hr />
                        </Row>
                        <Row>
                            <Form>
                                <Form.Group as={Row} className="mb-3" controlId="formBasicEmail">
                                    <Form.Label column sm="2">אימייל לאתחול</Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                            type="email" placeholder="Enter email"
                                            isInvalid={!isEmail}
                                            onBlur={() => validateEmail()}
                                        />
                                        {!isEmail ? <Form.Text style={{ color: 'red' }}>
                                            יש למלא כתובת אימייל חוקית
                                        </Form.Text> : null}
                                    </Col>
                                </Form.Group>
                                <ReCAPTCHA
                                    sitekey="6LdwAAwcAAAAAJEglFPUzEuV_KfjiIejMnCC86vb"
                                    onChange={v => setNotARobot(v !== null)}
                                />
                                <Button
                                    variant="primary" type="submit"
                                    disabled={!notARobot}
                                    onClick={e => {
                                        e.preventDefault();
                                        setError("");
                                        
                                        firebase.auth().languageCode = 'he';
                                        firebase.auth().sendPasswordResetEmail(email)
                                            .then(() => {
                                                // Password reset email sent!
                                                // ..
                                                history.push(`/emailsended/${email}?reason=${'לאיתחול סיסמא'}`);
                                            })
                                            .catch((error) => {
                                                // var errorCode = error.code;
                                                var errorMessage = error.message;
                                                // ..
                                                setError('ארעה שגיאה בשליחת אימייל אתחול הסיסמה.');
                                            });
                                    }}
                                >
                                    שלח אימייל לאתחול
                                </Button>
                                <br />
                                {error === '' ? null : <Form.Text dir="rtl" style={{ color: 'red' }}>{error}</Form.Text>}
                            </Form>
                        </Row>

                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default ForgotPassScreen;