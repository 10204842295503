import React from "react";

import "./DualPaneManager.css";
import { Row, Col } from "react-bootstrap";
import { getScrollbarWidth } from "../utiles/helperFunctions";
import { Link } from "react-router-dom";

const Footer = ({ width }) => {
    return (
        <div className="footer-div" style={{ width: width - 25 }}>
            <p className="text-center footer-p">The entirety of this site is protected by copyright &copy; 2021  Hillel Skolnik הלל סקולניק</p>
            <Row className="text-center">
                <Link to="/תנאי-שימוש">תנאי שימוש</Link>
            </Row>

        </div>
    )
};

const DualPaneManager = ({ graphicPane, dataPane, height, width, isText = false }) => {


    const layout = () => {
        const scroolWidth = getScrollbarWidth();
        const minDiff = 1;

        if (height > width) {
            if (isText) {
                return (
                    <Row style={{ margin: 0 }}>
                        <Row className="dual-pane" style={{ overflowY: 'scroll', height: (height - 60) / 2 - 20, width: width - scroolWidth - minDiff }} >
                            {graphicPane}
                        </Row>
                        <Row className="dual-pane" style={{ overflowY: 'scroll', height: (height - 60) / 2 - 20, width: width - scroolWidth - minDiff }}>
                            {dataPane}
                        </Row>
                        <Row>
                            <Footer width={width} />
                        </Row>
                    </Row>
                );

            }
            return (
                <Row style={{ margin: 0 }}>
                    <Row >
                        {graphicPane}
                    </Row>
                    <Row className="dual-pane" style={{ overflowY: 'scroll', height: (height - 60) / 2 - 20, width: width - scroolWidth - minDiff }}>
                        {dataPane}
                    </Row>
                    <Row>
                        <Footer width={width} />
                    </Row>
                </Row>
            );

        } else {
            if (isText) {
                return (
                    <>
                        <Row style={{ margin: 0 }}>
                            <Col xs={6} style={{
                                paddingLeft: 9,
                                paddingRight: 0,
                                overflowY: 'scroll', height: height - 60, width: width / 2 - scroolWidth - minDiff
                            }}>
                                <Row className="dual-pane" style={{ marginRight: 0 }}>
                                    {graphicPane}
                                </Row>
                            </Col>
                            <Col xs={6} style={{
                                paddingLeft: 9,
                                paddingRight: 0,
                                overflowY: 'scroll', height: height - 60, width: width / 2 - scroolWidth - minDiff
                            }}>
                                <Row className="dual-pane" style={{ marginRight: 0 }}>
                                    {dataPane}
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{ margin: 0 }}>
                            <Footer width={width} />
                        </Row>
                    </>
                );

            }
            return (
                <>
                    <Row style={{ margin: 0 }}>
                        <Col xs={6} style={{ padding: 0 }}>
                            {graphicPane}
                        </Col>
                        <Col xs={6} style={{
                            paddingLeft: 9,
                            paddingRight: 0,
                            overflowY: 'scroll', height: height - 60, width: width / 2 - scroolWidth - minDiff
                        }}>
                            <Row className="dual-pane" style={{ marginRight: 0 }}>
                                {dataPane}
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{ margin: 0 }}>
                        <Footer width={width} />
                    </Row>
                </>
            );
        }
    };

    return (
        <div>
            {layout()}
        </div>
    );
};


export default DualPaneManager;