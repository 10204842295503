import React from "react";
import { Col, Nav, Row } from "react-bootstrap";
import { CHAPTER_11, CHAPTER_12, CHAPTER_13, CHAPTER_14, CHAPTER_15, CHAPTER_16, CHAPTER_17 } from "../apis/commonConsts";
import Hakdama from "./chaptersContents/Hakdama";
import ContactAndOrder from "./ContactAndOrder";
import DayInfocomponent from "./DayInfoComponent";
import "./HomePageContent.css"

const HomePageContent = ({ history }) => {


    return (
        <div style={{ textAlign: 'center' }}>
            <h1>כאן יבנה אתר קידוש החודש לרמב"ם</h1>
            <h3>בדף זה ניתן לראות את המחשת מהלכי החמה על פי פרק יג הלכות קידוש החודש לרמב"ם</h3>
            <p dir="rtl">ניתן להזמין תוכנות המחשה תלת מימדיות עד להשלמת האתר. לכל המזמין את  התוכנות תהיה גישה חופשית לכל תוכן האתר. לנרשמים גישה מורחבת. לכל השאר גישה מצומצמת.</p>
            <Nav variant="pills" activeKey="1" className="justify-content-center">
                <Nav.Item>
                    <Nav.Link eventKey="1" target="_blank" href="http://kiddushhachodesh.net/%D7%94%D7%96%D7%9E%D7%A0%D7%95%D7%AA">תוכנות להזמנה</Nav.Link>
                </Nav.Item>
            </Nav>
            <br></br>
            <ContactAndOrder history={history} />
            <Row className="justify-content-center">
            <Col sm={4} xs={5} lg={2} className="home-p"
                    onClick={() => history.push(CHAPTER_11)}
                >
                    <Row className="justify-content-center">
                        <p >פרק יא</p>
                    </Row>
                    <Row className="justify-content-center">
                        <img
                            src='/chpters/chapter11-512.png'
                            className="img-responsive"
                            title='קידוש החודש לרמב"ם פרק יא'
                            alt='קידוש החודש לרמב"ם פרק יא' />
                    </Row>
                </Col>
                <Col sm={4} xs={5} lg={2} className="home-p"
                    onClick={() => history.push(CHAPTER_12)}
                >
                    <Row className="justify-content-center">
                        <p >פרק יב</p>
                    </Row>
                    <Row className="justify-content-center">
                        <img
                            src='/chpters/chpter12-512.png'
                            className="img-responsive"
                            title='קידוש החודש לרמב"ם פרק יב'
                            alt='קידוש החודש לרמב"ם פרק יב' />
                    </Row>
                </Col>
                <Col sm={4} xs={5} lg={2} className="home-p"
                    onClick={() => history.push(CHAPTER_13)}
                >
                    <Row className="justify-content-center">
                        <p >פרק יג</p>
                    </Row>
                    <Row className="justify-content-center">
                        <img
                            src='/chpters/chpter13-512.png'
                            className="img-responsive"
                            title='קידוש החודש לרמב"ם פרק יג'
                            alt='קידוש החודש לרמב"ם פרק יג' />
                    </Row>
                </Col>
                <Col sm={4} xs={5} lg={2} className="home-p"
                    onClick={() => history.push(CHAPTER_14)}
                >
                    <Row className="justify-content-center">
                        <p >פרק יד</p>
                    </Row>
                    <Row className="justify-content-center">
                        <img
                            src='/chpters/chapter14-512.png'
                            className="img-responsive"
                            title='קידוש החודש לרמב"ם פרק יד'
                            alt='קידוש החודש לרמב"ם פרק יד' />
                    </Row>
                </Col>
                <Col sm={4} xs={5} lg={2} className="home-p"
                    onClick={() => history.push(CHAPTER_15)}
                >
                    <Row className="justify-content-center">
                        <p >פרק טו</p>
                    </Row>
                    <Row className="justify-content-center">
                        <img
                            src='/chpters/chapter15-512.png'
                            className="img-responsive"
                            title='קידוש החודש פרק טו מרחק הכפול'
                            alt='קידוש החודש לרמב"ם פרק טו' />
                    </Row>
                </Col>
                <Col sm={4} xs={5} lg={2} className="home-p"
                    onClick={() => history.push(CHAPTER_16)}
                >
                    <Row className="justify-content-center">
                        <p >פרק טז</p>
                    </Row>
                    <Row className="justify-content-center">
                        <img
                            src='/chpters/chapter16-512.png'
                            className="img-responsive"
                            title='קידוש החודש פרק טז רוחב הירח'
                            alt='קידוש החודש לרמב"ם פרק טז' />
                    </Row>
                </Col>
                <Col sm={4} xs={5} lg={2} className="home-p"
                    onClick={() => history.push(CHAPTER_17)}
                >
                    <Row className="justify-content-center">
                        <p >פרק יז</p>
                    </Row>
                    <Row className="justify-content-center">
                        <img
                            src='/chpters/chapter17-512.png'
                            className="img-responsive"
                            title='קידוש החודש פרק יז קיצי הראיה'
                            alt='קידוש החודש לרמב"ם פרק יז' />
                    </Row>
                </Col>
            </Row>
            <br />
            <Hakdama />
            <br />
            <DayInfocomponent />
        </div>
    );
};

export default HomePageContent;