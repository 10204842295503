export const getScrollbarWidth = () => {

    // Creating invisible container
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll'; // forcing scrollbar to appear
    outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
    document.body.appendChild(outer);

    // Creating inner element and placing it in the container
    const inner = document.createElement('div');
    outer.appendChild(inner);

    // Calculating difference between container's full width and the child width
    const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

    // Removing temporary elements from the DOM
    outer.parentNode.removeChild(outer);

    return scrollbarWidth;

};

export const getGraphicWidthHeight = () => {
    const { innerWidth, innerHeight } = window;
    const minHeight = 62;
    const scroolWidth = getScrollbarWidth();
    const minDiff = 4;
    let w;
    let h;
    if (innerHeight > innerWidth) {
        w = innerWidth - scroolWidth - minDiff;
        h = innerHeight / 2;

    } else {
        w = innerWidth / 2 - minDiff;
        h = innerHeight - minHeight;

    }

    return { w, h };
}
