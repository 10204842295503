import React, { useEffect, useRef, useContext, useState } from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";

import { AnalyticsContext } from "../../context/AnalyticsContext";
import DualPaneManager from "../DualPaneManager";
import { MetaTags } from "react-meta-tags";
import CreateOrVerifyEmail from "../../components/CreateOrVerifyEmail";
import ContactAndOrder from "../../components/ContactAndOrder";
import { Context as Authcontext } from "../../context/Authcontext";
import { ORDER_MESSAGE } from "../../apis/commonConsts";
import Chapter15content from "../../components/chaptersContents/Chapter15content";
import MerchakKaful, { AUTO_MERCHAK_KAFUL_LIMIT, AUTO_RUN_MERCHAK_KAFUL, DRAW_ALL, DRAW_MERCHAK, DRAW_MERCHAK_KAFUL, EMZA_MASLUL_YAREACH, EMZA_SHEMES } from "../../components/3d/MerchakKaful";
import { emzaSemeshSpeed, emzaYareachSpeed, getEmzaYareachReiya, getMaslulNachon } from "../../apis/orbits/orbitsGeneral";
import EmzaYareach, { MAHALACH_EMZA_MASLUL_YAREACH, MAHALACH_EMZA_YAREACH, MENAT_MASLUL_YAREACH_TEXT, YAREACH_AMITI } from "../../components/3d/EmzaYareach";

export const VIEW_C15_STATE_OPEN = 0;
export const VIEW_C15_STATE_1 = 1;
export const VIEW_C15_STATE_2 = 2;
export const VIEW_C15_STATE_3 = 3;
export const VIEW_C15_STATE_4 = 4;
export const VIEW_C15_STATE_5 = 5;

export const VIEW_C15_STATE_6 = 6;

export const VIEW_C15_STATE_7 = 7;
export const VIEW_C15_STATE_8 = 8;
export const VIEW_C15_STATE_9 = 9;
export const VIEW_C15_STATE_10 = 10;
export const VIEW_C15_STATE_11 = 11;
export const VIEW_C15_STATE_12 = 12;
export const VIEW_C15_STATE_13 = 13;
export const VIEW_C15_STATE_14 = 14;
export const VIEW_C15_STATE_15 = 15;
export const VIEW_C15_STATE_16 = 16;

export const VIEW_C15_STATE_17 = 17;
export const VIEW_C15_STATE_18 = 18;
export const VIEW_C15_STATE_19 = 19;
export const VIEW_C15_STATE_20 = 20;

export const VIEW_C15_STATE_21 = 21;
export const VIEW_C15_STATE_22 = 22;
export const VIEW_C15_STATE_23 = 23;
export const VIEW_C15_STATE_24 = 24;
export const VIEW_C15_STATE_25 = 25;
export const VIEW_C15_STATE_26 = 26;
export const VIEW_C15_STATE_27 = 27;
export const VIEW_C15_STATE_28 = 28;
export const VIEW_C15_STATE_29 = 29;
export const VIEW_C15_STATE_30 = 30;
export const VIEW_C15_STATE_31 = 31;
export const VIEW_C15_STATE_32 = 32;
export const VIEW_C15_STATE_33 = 33;
export const VIEW_C15_STATE_34 = 34;
export const VIEW_C15_STATE_35 = 35;
export const VIEW_C15_STATE_36 = 36;
export const VIEW_C15_STATE_37 = 37;
export const VIEW_C15_STATE_38 = 38;

export const VIEW_C15_STATE_40 = 40;



const Chapter15 = ({ history }) => {

    const [viewState, setViewState] = useState(VIEW_C15_STATE_OPEN);
    const { state } = useContext(Authcontext);
    const { height, width, graphicWidth, paneHeight } = useWindowDimensions();
    const { analytics } = useContext(AnalyticsContext);

    const [days, setDays] = useState(29);

    const signinOrVerifyRef = useRef(null);
    const orderRef = useRef(null);

    const merchakKfulRef = useRef();
    const emzaYareachRef = useRef();

    useEffect(() => {
        analytics();
    }, []);

    const calculatatYareachAmiti = () => {
        if (viewState !== VIEW_C15_STATE_40) {
            return;
        }
        emzaYareachRef.current.setEmzaYareach(getEmzaYareachReiya(days).yareachReiya);
        emzaYareachRef.current.setEmzaMaslulYareach(getMaslulNachon(days).nachon);

    };

    useEffect(() => {
        calculatatYareachAmiti();
    }, [days]);

    const moonMinusSunSpeed = emzaYareachSpeed.degreesCount - emzaSemeshSpeed.degreesCount;

    const setState = () => {
        switch (viewState) {
            case VIEW_C15_STATE_OPEN:
                merchakKfulRef.current.setRunState(AUTO_RUN_MERCHAK_KAFUL | DRAW_ALL)
                break;
            case VIEW_C15_STATE_1:
                merchakKfulRef.current.setRunState(AUTO_RUN_MERCHAK_KAFUL);
                break;
            case VIEW_C15_STATE_2:
                if (signinOrVerifyRef.current.showIfNeed()) {
                    break;
                }
                merchakKfulRef.current.setRunState(AUTO_RUN_MERCHAK_KAFUL | EMZA_MASLUL_YAREACH);
                break;
            case VIEW_C15_STATE_3:
                if (signinOrVerifyRef.current.showIfNeed()) {
                    break;
                }
                merchakKfulRef.current.setRunState(AUTO_RUN_MERCHAK_KAFUL |
                    EMZA_MASLUL_YAREACH | EMZA_SHEMES);
                break;
            case VIEW_C15_STATE_4:
                if (signinOrVerifyRef.current.showIfNeed()) {
                    break;
                }
                merchakKfulRef.current.setRunState(AUTO_RUN_MERCHAK_KAFUL |
                    EMZA_MASLUL_YAREACH | EMZA_SHEMES |
                    DRAW_MERCHAK);
                break;
            case VIEW_C15_STATE_5:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                merchakKfulRef.current.setRunState(AUTO_RUN_MERCHAK_KAFUL |
                    EMZA_MASLUL_YAREACH | EMZA_SHEMES |
                    DRAW_MERCHAK | DRAW_MERCHAK_KAFUL);
                break;
            case VIEW_C15_STATE_6:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                merchakKfulRef.current.setRunState(AUTO_MERCHAK_KAFUL_LIMIT | DRAW_ALL);
                break;
            case VIEW_C15_STATE_7:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                merchakKfulRef.current.setRunState(DRAW_ALL);
                merchakKfulRef.current.setDays(5 / 2 / moonMinusSunSpeed);
                break;
            case VIEW_C15_STATE_8:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                merchakKfulRef.current.setRunState(DRAW_ALL);
                merchakKfulRef.current.setDays(11 / 2 / moonMinusSunSpeed);
                break;
            case VIEW_C15_STATE_9:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                merchakKfulRef.current.setRunState(DRAW_ALL);
                merchakKfulRef.current.setDays(18 / 2 / moonMinusSunSpeed);
                break;
            case VIEW_C15_STATE_10:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                merchakKfulRef.current.setRunState(DRAW_ALL);
                merchakKfulRef.current.setDays(24 / 2 / moonMinusSunSpeed);
                break;
            case VIEW_C15_STATE_11:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                merchakKfulRef.current.setRunState(DRAW_ALL);
                merchakKfulRef.current.setDays(31 / 2 / moonMinusSunSpeed);
                break;
            case VIEW_C15_STATE_12:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                merchakKfulRef.current.setRunState(DRAW_ALL);
                merchakKfulRef.current.setDays(38 / 2 / moonMinusSunSpeed);
                break;
            case VIEW_C15_STATE_13:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                merchakKfulRef.current.setRunState(DRAW_ALL);
                merchakKfulRef.current.setDays(45 / 2 / moonMinusSunSpeed);
                break;
            case VIEW_C15_STATE_14:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                merchakKfulRef.current.setRunState(DRAW_ALL);
                merchakKfulRef.current.setDays(51 / 2 / moonMinusSunSpeed);
                break;
            case VIEW_C15_STATE_15:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                merchakKfulRef.current.setRunState(DRAW_ALL);
                merchakKfulRef.current.setDays(59 / 2 / moonMinusSunSpeed);
                break;
            case VIEW_C15_STATE_16:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                merchakKfulRef.current.setRunState(DRAW_ALL);
                merchakKfulRef.current.setDays(63 / 2 / moonMinusSunSpeed);
                break;
            case VIEW_C15_STATE_17:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                emzaYareachRef.current.setRunState(MAHALACH_EMZA_YAREACH |
                    MAHALACH_EMZA_MASLUL_YAREACH | YAREACH_AMITI);
                emzaYareachRef.current.setEmzaYareach(90);
                emzaYareachRef.current.setEmzaMaslulYareach(90);
                break;
            case VIEW_C15_STATE_18:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                emzaYareachRef.current.setRunState(MAHALACH_EMZA_YAREACH |
                    MAHALACH_EMZA_MASLUL_YAREACH | YAREACH_AMITI);
                emzaYareachRef.current.setEmzaYareach(90);
                emzaYareachRef.current.setEmzaMaslulYareach(270);
                break;
            case VIEW_C15_STATE_19:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                emzaYareachRef.current.setRunState(MAHALACH_EMZA_YAREACH |
                    MAHALACH_EMZA_MASLUL_YAREACH | YAREACH_AMITI);
                emzaYareachRef.current.setEmzaYareach(90);
                emzaYareachRef.current.setEmzaMaslulYareach(180);
                break;
            case VIEW_C15_STATE_20:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                emzaYareachRef.current.setRunState(MAHALACH_EMZA_YAREACH |
                    MAHALACH_EMZA_MASLUL_YAREACH | YAREACH_AMITI);
                emzaYareachRef.current.setEmzaYareach(90);
                emzaYareachRef.current.setEmzaMaslulYareach(0);
                break;
            case VIEW_C15_STATE_21:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                emzaYareachRef.current.setRunState(MAHALACH_EMZA_YAREACH |
                    MAHALACH_EMZA_MASLUL_YAREACH | YAREACH_AMITI |
                    MENAT_MASLUL_YAREACH_TEXT);
                emzaYareachRef.current.setEmzaYareach(90);
                emzaYareachRef.current.setEmzaMaslulYareach(10);
                break;
            case VIEW_C15_STATE_22:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                emzaYareachRef.current.setRunState(MAHALACH_EMZA_YAREACH |
                    MAHALACH_EMZA_MASLUL_YAREACH | YAREACH_AMITI |
                    MENAT_MASLUL_YAREACH_TEXT);
                emzaYareachRef.current.setEmzaYareach(90);
                emzaYareachRef.current.setEmzaMaslulYareach(20);
                break;
            case VIEW_C15_STATE_23:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                emzaYareachRef.current.setRunState(MAHALACH_EMZA_YAREACH |
                    MAHALACH_EMZA_MASLUL_YAREACH | YAREACH_AMITI |
                    MENAT_MASLUL_YAREACH_TEXT);
                emzaYareachRef.current.setEmzaYareach(90);
                emzaYareachRef.current.setEmzaMaslulYareach(30);
                break;
            case VIEW_C15_STATE_24:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                emzaYareachRef.current.setRunState(MAHALACH_EMZA_YAREACH |
                    MAHALACH_EMZA_MASLUL_YAREACH | YAREACH_AMITI |
                    MENAT_MASLUL_YAREACH_TEXT);
                emzaYareachRef.current.setEmzaYareach(90);
                emzaYareachRef.current.setEmzaMaslulYareach(40);
                break;
            case VIEW_C15_STATE_25:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                emzaYareachRef.current.setRunState(MAHALACH_EMZA_YAREACH |
                    MAHALACH_EMZA_MASLUL_YAREACH | YAREACH_AMITI |
                    MENAT_MASLUL_YAREACH_TEXT);
                emzaYareachRef.current.setEmzaYareach(90);
                emzaYareachRef.current.setEmzaMaslulYareach(50);
                break;
            case VIEW_C15_STATE_26:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                emzaYareachRef.current.setRunState(MAHALACH_EMZA_YAREACH |
                    MAHALACH_EMZA_MASLUL_YAREACH | YAREACH_AMITI |
                    MENAT_MASLUL_YAREACH_TEXT);
                emzaYareachRef.current.setEmzaYareach(90);
                emzaYareachRef.current.setEmzaMaslulYareach(60);
                break;
            case VIEW_C15_STATE_27:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                emzaYareachRef.current.setRunState(MAHALACH_EMZA_YAREACH |
                    MAHALACH_EMZA_MASLUL_YAREACH | YAREACH_AMITI |
                    MENAT_MASLUL_YAREACH_TEXT);
                emzaYareachRef.current.setEmzaYareach(90);
                emzaYareachRef.current.setEmzaMaslulYareach(70);
                break;
            case VIEW_C15_STATE_28:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                emzaYareachRef.current.setRunState(MAHALACH_EMZA_YAREACH |
                    MAHALACH_EMZA_MASLUL_YAREACH | YAREACH_AMITI |
                    MENAT_MASLUL_YAREACH_TEXT);
                emzaYareachRef.current.setEmzaYareach(90);
                emzaYareachRef.current.setEmzaMaslulYareach(80);
                break;
            case VIEW_C15_STATE_29:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                emzaYareachRef.current.setRunState(MAHALACH_EMZA_YAREACH |
                    MAHALACH_EMZA_MASLUL_YAREACH | YAREACH_AMITI |
                    MENAT_MASLUL_YAREACH_TEXT);
                emzaYareachRef.current.setEmzaYareach(90);
                emzaYareachRef.current.setEmzaMaslulYareach(90);
                break;
            case VIEW_C15_STATE_30:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                emzaYareachRef.current.setRunState(MAHALACH_EMZA_YAREACH |
                    MAHALACH_EMZA_MASLUL_YAREACH | YAREACH_AMITI |
                    MENAT_MASLUL_YAREACH_TEXT);
                emzaYareachRef.current.setEmzaYareach(90);
                emzaYareachRef.current.setEmzaMaslulYareach(100);
                break;
            case VIEW_C15_STATE_31:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                emzaYareachRef.current.setRunState(MAHALACH_EMZA_YAREACH |
                    MAHALACH_EMZA_MASLUL_YAREACH | YAREACH_AMITI |
                    MENAT_MASLUL_YAREACH_TEXT);
                emzaYareachRef.current.setEmzaYareach(90);
                emzaYareachRef.current.setEmzaMaslulYareach(110);
                break;
            case VIEW_C15_STATE_32:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                emzaYareachRef.current.setRunState(MAHALACH_EMZA_YAREACH |
                    MAHALACH_EMZA_MASLUL_YAREACH | YAREACH_AMITI |
                    MENAT_MASLUL_YAREACH_TEXT);
                emzaYareachRef.current.setEmzaYareach(90);
                emzaYareachRef.current.setEmzaMaslulYareach(120);
                break;
            case VIEW_C15_STATE_33:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                emzaYareachRef.current.setRunState(MAHALACH_EMZA_YAREACH |
                    MAHALACH_EMZA_MASLUL_YAREACH | YAREACH_AMITI |
                    MENAT_MASLUL_YAREACH_TEXT);
                emzaYareachRef.current.setEmzaYareach(90);
                emzaYareachRef.current.setEmzaMaslulYareach(130);
                break;
            case VIEW_C15_STATE_34:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                emzaYareachRef.current.setRunState(MAHALACH_EMZA_YAREACH |
                    MAHALACH_EMZA_MASLUL_YAREACH | YAREACH_AMITI |
                    MENAT_MASLUL_YAREACH_TEXT);
                emzaYareachRef.current.setEmzaYareach(90);
                emzaYareachRef.current.setEmzaMaslulYareach(140);
                break;
            case VIEW_C15_STATE_35:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                emzaYareachRef.current.setRunState(MAHALACH_EMZA_YAREACH |
                    MAHALACH_EMZA_MASLUL_YAREACH | YAREACH_AMITI |
                    MENAT_MASLUL_YAREACH_TEXT);
                emzaYareachRef.current.setEmzaYareach(90);
                emzaYareachRef.current.setEmzaMaslulYareach(150);
                break;
            case VIEW_C15_STATE_36:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                emzaYareachRef.current.setRunState(MAHALACH_EMZA_YAREACH |
                    MAHALACH_EMZA_MASLUL_YAREACH | YAREACH_AMITI |
                    MENAT_MASLUL_YAREACH_TEXT);
                emzaYareachRef.current.setEmzaYareach(90);
                emzaYareachRef.current.setEmzaMaslulYareach(160);
                break;
            case VIEW_C15_STATE_37:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                emzaYareachRef.current.setRunState(MAHALACH_EMZA_YAREACH |
                    MAHALACH_EMZA_MASLUL_YAREACH | YAREACH_AMITI |
                    MENAT_MASLUL_YAREACH_TEXT);
                emzaYareachRef.current.setEmzaYareach(90);
                emzaYareachRef.current.setEmzaMaslulYareach(170);
                break;
            case VIEW_C15_STATE_38:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                emzaYareachRef.current.setRunState(MAHALACH_EMZA_YAREACH |
                    MAHALACH_EMZA_MASLUL_YAREACH | YAREACH_AMITI |
                    MENAT_MASLUL_YAREACH_TEXT);
                emzaYareachRef.current.setEmzaYareach(90);
                emzaYareachRef.current.setEmzaMaslulYareach(180);
                break;
            case VIEW_C15_STATE_40:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                emzaYareachRef.current.setRunState(MAHALACH_EMZA_YAREACH |
                    MAHALACH_EMZA_MASLUL_YAREACH | YAREACH_AMITI |
                    MENAT_MASLUL_YAREACH_TEXT);
                calculatatYareachAmiti();
                break;

            default:
                break;
        }
    };

    useEffect(() => {
        setState();
        merchakKfulRef.current?.setSise(paneHeight, graphicWidth);
        emzaYareachRef.current?.setSise(paneHeight, graphicWidth);

    }, [height, width, viewState]);

    const get3D = () => {
        if (viewState <= 16) {
            return <MerchakKaful ref={merchakKfulRef} />;
        }

        return <EmzaYareach ref={emzaYareachRef} />;
    };

    return (
        <div>
            <MetaTags>
                <title>הלכות קידוש החודש לרמב"ם פרק טו</title>
                <meta name="description" content='בפרק טו הלכות קידוש החודש לרמב"ם יבואר חישוב המרחק הכפול ומקום הירח האמיתי.' />
            </MetaTags>
            <CreateOrVerifyEmail state={state} history={history} ref={signinOrVerifyRef} />
            <ContactAndOrder history={history} ref={orderRef} hidden={true} nessage={ORDER_MESSAGE} />
            <DualPaneManager
                graphicPane={get3D()}

                dataPane={< Chapter15content
                    setViewState={setViewState}
                    params={{ days, setDays }} />}
                height={height}
                width={width}
            />

        </div>
    );

};


export default Chapter15;