import React, { useEffect, useRef } from "react";



const WheelCycles = ({ width }) => {

    const canvasRef = useRef(null);
    const ctxRef = useRef(null);

    const vals = useRef({
        height: 400,
        steps: 500,
        pos: 0,
        heightResio: 0.55,
        margin: 0.125,
        gradient: null,
        drawObjs: [
            {
                diameter: 0.21,
                cycleColor: 'red',
                cyclePosColor: 'yellow',
                lastAngle: 180,
                stepAng: 0,
                stepX: 0,
                array: []
            },
            {
                diameter: 0.39,
                cycleColor: '#8A2BE2',
                cyclePosColor: '#1E60FF',
                lastAngle: 180,
                stepAng: 0,
                stepX: 0,
                array: []
            }
        ]
    });


    const draw = () => {
        vals.current.height = width * vals.current.heightResio;

        ctxRef.current.save();

        // Create circular clipping region
        ctxRef.current.beginPath();
        ctxRef.current.ellipse(width / 2, vals.current.height / 2, width / 2,
            vals.current.height / 2, 0, 0, Math.PI * 2);
        ctxRef.current.clip();



        ctxRef.current.fillStyle = vals.current.gradient;
        ctxRef.current.fillRect(0, 0, width, vals.current.height);

        const inside = 5;
        ctxRef.current.strokeStyle = "#E0FFFF";
        ctxRef.current.lineWidth = 2;
        ctxRef.current.beginPath();
        ctxRef.current.ellipse(width / 2, 
            vals.current.height / 2, width / 2 - inside,
            vals.current.height / 2 - inside, 0, 0, Math.PI * 2);
        ctxRef.current.stroke();

        vals.current.pos++;
        if (vals.current.pos >= vals.current.steps) {
            vals.current.pos = 0;
        }

        ctxRef.current.strokeStyle = "#444444";
        ctxRef.current.beginPath();
        ctxRef.current.moveTo(0, vals.current.height / 2);
        ctxRef.current.lineTo(width, vals.current.height / 2);
        ctxRef.current.stroke();

        ctxRef.current.lineWidth = 6;

        // const textUp = "גלים עליונים";
        // const textDown = "גלים תחתונים";
        // const font = `900 ${vals.current.height / 9}px Arial`;
        // ctxRef.current.font = font;
        // ctxRef.current.fillStyle = '#ADFF2F';//#7B68EE
        // ctxRef.current.textAlign = "center";
        // ctxRef.current.fillText(textUp, width / 2, vals.current.height * 0.14);
        // ctxRef.current.fillText(textDown, width / 2, vals.current.height * 0.92);

        const middlePoint = width * 0.25;
        const yStart = vals.current.height / 2;

        ctxRef.current.strokeStyle = "#FF00FF";
        ctxRef.current.beginPath();
        ctxRef.current.arc(middlePoint, yStart, width * 0.005, 0, Math.PI * 2, false);
        ctxRef.current.stroke();


        vals.current.drawObjs.forEach(element => {
            const radius = width * element.diameter / 2

            const xCurrent = element.diameter * width * 0.5 + middlePoint + element.stepX * width * vals.current.pos;
            element.array[vals.current.pos].x = xCurrent;
            element.array[vals.current.pos].y = Math.sin(element.lastAngle / 180 * Math.PI) * radius + yStart;
            element.lastAngle -= element.stepAng;
            if (element.lastAngle < 0) {
                element.lastAngle += 360;
            }

            ctxRef.current.strokeStyle = element.cycleColor;
            if (vals.current.pos > 1) {
                ctxRef.current.save();
                ctxRef.current.beginPath();
                ctxRef.current.moveTo(element.array[1].x, element.array[1].y);
                for (let i = 1; i <= vals.current.pos; i++) {
                    ctxRef.current.lineTo(element.array[i].x, element.array[i].y);

                }
                ctxRef.current.stroke();
                ctxRef.current.restore();
            }



            // if (vals.current.pos < (vals.current.steps - 2)) {
            //     ctxRef.current.save();
            //     ctxRef.current.beginPath();
            //     ctxRef.current.moveTo(element.array[vals.current.pos + 1].x, element.array[vals.current.pos + 1].y);
            //     for (let i = vals.current.pos + 2; i < vals.current.steps; i++) {
            //         ctxRef.current.lineTo(element.array[i].x, element.array[i].y);
            //     }
            //     ctxRef.current.stroke();
            //     ctxRef.current.restore();
            // } 



            ctxRef.current.beginPath();
            ctxRef.current.arc(middlePoint, yStart, radius, 0, Math.PI * 2, false);
            ctxRef.current.stroke();

            ctxRef.current.strokeStyle = element.cyclePosColor;

            const x = -Math.cos(element.lastAngle / 180 * Math.PI) * radius;
            const y = Math.sin(element.lastAngle / 180 * Math.PI) * radius;

            ctxRef.current.beginPath();
            ctxRef.current.arc(middlePoint + x, yStart + y, radius * 0.06, 0, Math.PI * 2, false);
            ctxRef.current.stroke();

            ctxRef.current.save();
            ctxRef.current.beginPath();
            ctxRef.current.moveTo(middlePoint + x, yStart + y);
            ctxRef.current.lineTo(element.array[vals.current.pos].x, element.array[vals.current.pos].y);
            ctxRef.current.stroke();
            ctxRef.current.restore();

            ctxRef.current.beginPath();
            ctxRef.current.arc(element.array[vals.current.pos].x, element.array[vals.current.pos].y, radius * 0.06, 0, Math.PI * 2, false);
            ctxRef.current.stroke();

            
        });
        
        ctxRef.current.restore();
        window.requestAnimationFrame(draw);
    };

    const init = () => {
        vals.current.height = width * vals.current.heightResio;
        ctxRef.current = canvasRef.current.getContext("2d");

        var gradient = ctxRef.current.createLinearGradient(0, 0, width, 0);
        gradient.addColorStop(0, "#0f0c29");//#2F0743"#141517"
        gradient.addColorStop(0.5, "#302b63");//"#6A9113"
        gradient.addColorStop(1, "#24243e");

        vals.current.gradient = gradient;

        vals.current.drawObjs.forEach(element => {
            const length = (1 - vals.current.margin * 2);
            element.stepAng = (360 * length / (element.diameter)) / vals.current.steps;
            element.stepX = (length / (Math.PI * 2 * element.diameter)) / (vals.current.steps);



            const startX = width * vals.current.margin;

            const radius = width * element.diameter / 2
            const y = vals.current.height - radius * 2;
            for (let index = 0; index < vals.current.steps; index++) {
                const x = startX + index * element.stepX * width;
                element.array.push({ x, y });
            }
        });
        window.requestAnimationFrame(draw);
    };
    useEffect(() => {
        init();
    }, []);

    return (
        <>
            <canvas ref={canvasRef} width={width} height={vals.current.height | width * vals.current.heightResio} />
        </>
    );

};

export default WheelCycles;