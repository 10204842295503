import React, { useEffect, useRef, useContext, useState } from "react";
import useWindowDimensions from "../hooks/useWindowDimensions";

import SunPaths, { AUTO_RUN_MAZALOT } from "../components/SunPaths";
import { AnalyticsContext } from "../context/AnalyticsContext";
import HomeSunPaths from "../components/HomeSunPath";
import './HomeScreen.css'
import DualPaneManager from "./DualPaneManager";
import HomePageContent from "../components/HomePageContent";
import MetaTags from 'react-meta-tags';

const HomeScreen = ({history}) => {
    
    const childRef = useRef();
    const { height, width, graphicWidth, paneHeight } = useWindowDimensions();
    const { analytics } = useContext(AnalyticsContext);
    const [runState, setRunState] = useState(AUTO_RUN_MAZALOT);
    const [manual, setManual] = useState(false);
    

    useEffect(() => {
        analytics();        
    }, []);


    useEffect(() => {
        childRef.current?.setRunState(runState);
        childRef.current?.setSise(paneHeight, graphicWidth);
    }, [height, width, runState]);

    // const setRunState = (runState) => {
    //     childRef.current?.setRunState(runState);
    // };

    const setPaths = (haMazal, govaShemesh, shemesh) => {
        childRef.current?.setPaths(haMazal, govaShemesh, shemesh);
    };

    return (
        <div>
            <MetaTags>
                <title>הלכות קידוש החודש לרמב"ם</title>
                <meta name="description" content='הלכות קידוש החודש לרמב"ם בו מבואר חישוב קיצי הראיה לדעת אם יראה הירח ביום שנבקש לדעת, והכל ע"י מצגות תלת מימדיות המבארות הלכות קידוש החודש, מצגת קידוש החודש, לכל הלכה והלכה.' />
            </MetaTags>
            <DualPaneManager
                graphicPane={<SunPaths ref={childRef} />}
                dataPane={<><HomeSunPaths
                    manual={manual}
                    setManual={setManual}
                    runState={runState}
                    setRunState={setRunState}
                    setPaths={setPaths} /> <HomePageContent history={history} /></>}
                height={height}
                width={width}
            />
        </div>
    );
};

export default HomeScreen;