import React, { useContext } from "react";
import { Container, Row, Table } from "react-bootstrap";

import { Context as Authcontext } from "../../context/Authcontext";
import { VIEW_C17_STATE_1, VIEW_C17_STATE_2, VIEW_C17_STATE_3, VIEW_C17_STATE_OPEN } from "../../screens/chapters/Chapter17";


const Chapter17Content = ({ setViewState, params }) => {
    const { state } = useContext(Authcontext);

    return (
        <Container className="chapter-container">
            <h4 className="chpter-header">פרק יז</h4>
            <h6 className="paragrph">חישוב אורך ראשון, מזלות קיצרי השקיעה וארוכי השקיעה, שינוי המראה, אורך שני, רוחב שני, אורך שלישי, אורך רביעי,  רוחב המדינה וקשת השקיעה, וקיצי הראיה</h6>
            <p style={{ textAlign: 'center', color: 'red' }}>פרק זה בשלבי בניה</p>
            <div className="chapter-p" dir="rtl">
                <strong className="span-6" onClick={() => setViewState(VIEW_C17_STATE_OPEN)}>א. </strong>
                כל הדברים שהקדמנו כדי שיהיו עתידים ומוכנים לידיעת הראייה,
                וכשתרצה לדעת זאת תתחיל ותחשוב ותוציא מקום השמש האמתי ומקום הירח האמתי ומקום הראש לשעת הראייה,
                ותגרע מקום השמש האמתי ממקום הירח האמתי והנשאר הוא הנקרא אורך <span className="span-1" onClick={() => setViewState(VIEW_C17_STATE_1)}>ראשון</span>.
            </div>
            <p></p>
            <div className="chapter-p" dir="rtl">
                <strong>ב. </strong>
                ומאחר שתדע מקום הראש ומקום הירח תדע <span className="span-2" onClick={() => setViewState(VIEW_C17_STATE_2)}>רוחב הירח כמה הוא</span>,
                ואם הוא רוחב צפוני או דרומי והוא הנקרא רוחב ראשון,
                והזהר באורך הזה הראשון וברוחב הראשון ויהיו שניהם מוכנים לפניך.
            </div>
            <p></p>
            <div dir="rtl" style={{ padding: 5, marginBottom: 5, color: "green", fontSize: 18, textAlign: "center", border: "2px solid blue", borderRadius: 10 }}>
                <strong className="span-3" onClick={() => setViewState(VIEW_C17_STATE_3)}>קשת השקיעה</strong> הם מעלות קו השוה היורדות מתחת לאופק המערבי,
                מזמן שמרכז השמש יעמוד על אופק מערב עד שמרכז הירח יגיע לאופק מערב. מהלך קשת השקיעה לעולם 15 מעלות לשעה בקירוב גדול.
            </div>
            <div dir="rtl" style={{ padding: 5, color: "#0000d9", fontSize: 18, textAlign: "center", border: "2px solid #8f8f1c", borderRadius: 10 }}>
                בהלכה ג מדובר <strong style={{ color: "#ffff88", backgroundColor: "#030a63", paddingLeft: 5, paddingRight: 5, borderRadius: 6, border: "2px solid red" }}>במזלות העולים</strong>,
                שבהם קשת השקיעה לעולם ארוכה מאורך הראשון.
                דהיינו מתחילת מזל גדי עד סוף מזל תאומים. ובאורך ראשון של 15 מעלות,
                קשת השקיעה
                תהיה תמיד גדולה מ 16 מעלות ו20 חלקים.
                גדלים אלו מספיקים כדי שנוכל לראות את הירח זמן קצר לאחר השקיעה.
            </div>
            <div className="chapter-p" dir="rtl">
                <strong>ג. </strong>
                והתבונן באורך זה הראשון,
                אם יצא לך תשע מעלות בשוה או פחות,
                תדע בודאי שאי אפשר לעולם שיראה הירח באותו הלילה בכל ארץ ישראל ואין אתה צריך חשבון אחר,
                ואם יהיה האורך הראשון יתר על חמש עשרה מעלות תדע בודאי שהירח יראה בכל ארץ ישראל ואין אתה צריך לחשבון אחר,
                ואם יהיה האורך הראשון מתשע מעלות ועד חמש עשרה תצטרך לדרוש ולחקור בחשבונות הראייה, עד שתדע אם יראה או לא יראה.
            </div>
            <p></p>
            <div dir="rtl" style={{ padding: 5, color: "#0000d9", fontSize: 18, textAlign: "center", border: "2px solid #fbc9fc", borderRadius: 10 }}>
                בהלכה ד מדובר <strong style={{ color: "#fce1fc", backgroundColor: "#030a63", paddingLeft: 5, paddingRight: 5, borderRadius: 6, border: "2px solid red" }}>במזלות היורדים</strong>,
                שבהם נצטרך אורך ראשון גדול יותר מאשר במזלות העולים כדי לראות את הירח זמן קצר לאחר השקיעה,
                היות וקשת השקיעה לעולם קצרה מאורך הראשון.
                דהיינו מתחילת מזל סרטן עד סוף מזל קשת. לכן באורך ראשון של למעלה מ 24 מעלות,
                קשת השקיעה
                תהיה תמיד גדולה מ 16 מעלות ו20 חלקים.
                גדלים אלו מספיקים כדי שנוכל לראות את הירח זמן קצר לאחר השקיעה.
            </div>
            <div className="chapter-p" dir="rtl">
                <strong>ד. </strong>
                במה דברים אמורים בשהיה מקום הירח האמתי מתחלת מזל גדי עד סוף מזל תאומים,
                אבל אם היה מקום הירח מתחלת מזל סרטן עד סוף מזל קשת ויהיה האורך הראשון עשר מעלות בשווה או פחות,
                תדע שאין הירח נראה כלל באותו הלילה בכל ארץ ישראל,
                ואם היה האורך הראשון יתר על ארבע ועשרים מעלות ודאי יראה בכל גבול ישראל,
                ואם יהיה האורך הראשון מעשר מעלות עד ארבע ועשרים תצטרך לדרוש ולחקור בחשבונות הראייה עד שתדע אם יראה או לא יראה.
            </div>
            <p></p>
            <div className="chapter-p" dir="rtl">
                <strong>ה. </strong>
                ואלו הן חשבונות הראייה:
                התבונן וראה הירח באיזה מזל הוא,
                אם יהיה במזל טלה תגרע מן האורך הראשון תשעה וחמשים חלקים,
                ואם יהיה במזל שור תגרע מן האורך מעלה אחת,
                ואם יהיה במזל תאומים תגרע מן האורך שמונה וחמשים חלקים,
                ואם יהיה במזל סרטן תגרע מן האורך שנים וחמשים חלקים,
                ואם יהיה במזל אריה תגרע מן האורך שלשה וארבעים חלקים,
                ואם יהיה במזל בתולה תגרע מן האורך שבעה ושלשים חלקים,
                ואם יהיה במזל מאזנים תגרע מן האורך ארבעה ושלשים חלקים,
                ואם יהיה במזל עקרב תגרע מן האורך ארבעה ושלשים חלקים,
                ואם יהיה במזל קשת תגרע מן האורך ששה ושלשים חלקים,
                ואם יהיה במזל גדי תגרע מן האורך ארבעה וארבעים חלקים,
                ואם יהיה במזל דלי תגרע מן האורך שלשה וחמשים חלקים,
                ואם יהיה במזל דגים תגרע מן האורך שמונה וחמשים חלקים,
                והנשאר מן האורך אחר שתגרע ממנו אלו החלקים הוא הנקרא אורך שני.
            </div>
            <p></p>
            <div className="chapter-p" dir="rtl">
                <strong>ו. </strong>
                ולמה גורעין חלקים אלו,
                לפי שמקום הירח האמתי אינו המקום שיראה בו אלא שינוי יש ביניהם באורך וברוחב,
                והוא הנקרא שינוי המראה,
                ושינוי מראה האורך בשעת הראייה לעולם גורעין אותו מן האורך כמו שבארנו.
            </div>
            <p></p>
            <div className="chapter-p" dir="rtl">
                <strong>ז. </strong>
                אבל שינוי מראה הרוחב,
                אם היה רוחב הירח צפוני גורעין חלקים של שינוי מראה הרוחב מן הרוחב הראשון,
                ואם היה רוחב הירח דרומי מוסיפין החלקים של שינוי מראה הרוחב על הרוחב הראשון,
                ומה שיהיה הרוחב הראשון אחר שמוסיפין עליו או גורעין ממנו אותם החלקים הוא הנקרא רוחב שני.
            </div>
            <p></p>
            <div className="chapter-p" dir="rtl">
                <strong>ח. </strong>
                וכמה הם החלקים שמוסיפין או גורעין אותן,
                אם יהיה הירח במזל טלה תשעה חלקים,
                ואם יהיה במזל שור עשרה חלקים,
                ואם יהיה במזל תאומים ששה עשר חלקים,
                ואם יהיה במזל סרטן שבעה ועשרים חלקים,
                ואם יהיה במזל אריה שמונה ושלשים חלקים,
                ואם יהיה במזל בתולה ארבעה וארבעים חלקים,
                ואם יהיה במזל מאזנים ששה וארבעים חלקים,
                ואם יהיה במזל עקרב חמשה וארבעים חלקים,
                ואם יהיה במזל קשת ארבעה וארבעים חלקים,
                ואם יהיה במזל גדי ששה ושלשים חלקים,
                ואם יהיה במזל דלי שבעה ועשרים חלקים,
                ואם יהיה במזל דגים שנים עשר חלקים.
            </div>
            <p></p>
            <div className="chapter-p" dir="rtl">
                <strong>ט. </strong>
                ומאחר שתדע חלקים אלו תגרע אותן מן הרוחב הראשון או תוסיף אותן עליו כמו שהודענוך ויצא לך הרוחב השני,
                וכבר ידעת אם הוא צפוני או דרומי,
                ותדע כמה מעלות וכמה חלקים נעשה זה הרוחב השני ותכין אותו לפניך ויהיה עתיד.
            </div>
            <p></p>
            <div className="chapter-p" dir="rtl">
                <strong>י. </strong>
                ואחר כך תחזור ותקח מן הרוחב השני הזה מקצתו, מפני שהירח נלוז מעט במעגלו,
                וכמה הוא המקצת שתקח ממנו,
                אם יהיה מקום הירח מתחלת מזל טלה עד עשרים מעלה ממנו, או מתחלת מזל מאזנים עד עשרים מעלה ממנו, תקח מן הרוחב השני שני חמשיו,
                ואם יהיה הירח מעשרים ממזל טלה עד עשר מעלות ממזל שור או מעשרים ממזל מאזנים עד עשר מעלות ממזל עקרב תקח מן הרוחב השני שלישיתו,
                ואם יהיה הירח מעשר מעלות ממזל שור עד עשרים ממנו או מעשר מעלות ממזל עקרב עד עשרים ממנו תקח מן הרוחב השני רביעיתו,
                ואם יהיה הירח מעשרים מעלות ממזל שור עד סופו או מעשרים ממזל עקרב עד סופו תקח מן הרוחב השני חמישיתו,
                ואם יהיה הירח מתחלת מזל תאומים עד עשר מעלות ממנו או מתחלת מזל קשת עד עשר מעלות ממנו תקח מן הרוחב השני שתותו,
                ואם יהיה הירח מעשר מעלות ממזל תאומים ועד עשרים ממנו או מעשר ממזל קשת עד עשרים ממנו תקח מן הרוחב השני חצי שתותו,
                ואם יהיה מקום הירח מעשרים ממזל תאומים עד חמש ועשרים ממנו או מעשרים ממזל קשת עד חמש ועשרים ממנו תקח מן הרוחב השני רביע שתותו,
                ואם יהיה מקום הירח מחמש ועשרים ממזל תאומים עד חמש מעלות ממזל סרטן או מחמש ועשרים ממזל קשת עד חמש מעלות ממזל גדי לא תקח כלום,
                לפי שאין כאן נליזת מעגל,
                ואם יהיה הירח מחמש ממזל סרטן עד עשר ממנו או מחמש ממזל גדי עד עשר ממנו תקח מן הרוחב השני רביע שתותו.
                ואם יהיה מקום הירח מעשר ממזל סרטן עד עשרים ממנו או מעשר ממזל גדי עד עשרים ממנו תקח מן הרוחב השני חצי שתותו,
                ואם יהיה מקום הירח מעשרים ממזל סרטן עד סופו או מעשרים ממזל גדי עד סופו תקח מן הרוחב השני שתותו,
                ואם יהיה הירח מתחלת מזל אריה עד עשר מעלות ממנו או מתחלת מזל דלי עד עשר מעלות ממנו תקח מן הרוחב השני חמישיתו,
                ואם יהיה הירח מעשר מעלות ממזל אריה עד עשרים ממנו או מעשר ממזל דלי עד עשרים ממנו תקח מן הרוחב השני רביעיתו,
                ואם יהיה הירח מעשרים ממזל אריה עד עשר ממזל בתולה או מעשרים ממזל דלי עד עשר ממזל דגים תקח מן הרוחב השני שלישיתו,
                ואם יהיה הירח מעשר ממזל בתולה עד סופו או מעשר ממזל דגים עד סופו תקח מן הרוחב השני שני חמשיו,
                וזאת המקצת שתקח מן הרוחב השני היא הנקראת מעגל הירח.
            </div>
            <p></p>
            <div className="chapter-p" dir="rtl">
                <strong>יא. </strong>
                ואחר כך תחזור ותתבונן ברוחב הירח ותראה אם הוא צפוני או דרומי,
                אם היה צפוני תגרע מעגל הירח הזה מן האורך השני,
                ואם היה רוחב הירח דרומי תוסיף המעגל הזה על האורך השני,
                במה דברים אמורים בשהיה מקום הירח מתחלת מזל גדי עד סוף מזל תאומים,
                אבל אם היה הירח מתחלת מזל סרטן עד סוף מזל קשת יהיה הדרך הפך,
                שאם יהיה רוחב הירח צפוני תוסיף המעגל על האורך השני,
                ואם היה רוחב הירח דרומי תגרע המעגל מן האורך השני,
                ומה שיהיה האורך השני אחר שתוסיף עליו או תגרע ממנו הוא הנקרא אורך שלישי,
                ודע שאם לא יהיה שם נליזת מעגל ולא נתן החשבון לקחת מן הרוחב השני כלום,
                יהיה האורך השני עצמו הוא האורך השלישי בלא פחות ובלא יתר.
            </div>
            <p></p>
            <div className="chapter-p" dir="rtl">
                <strong>יב. </strong>
                ואחר כך תחזור ותראה האורך השלישי הזה והוא המעלות שבין הירח והשמש באיזה מזל הוא,
                אם יהיה במזל דגים או במזל טלה, תוסיף על האורך השלישי שתותו,
                ואם יהיה האורך במזל דלי או במזל שור, תוסיף על האורך השלישי חמישיתו,
                ואם יהיה האורך במזל גדי או במזל תאומים, תוסיף על האורך השלישי שתותו,
                ואם יהיה האורך במזל קשת או במזל סרטן, תניח האורך השלישי כמות שהוא ולא תוסיף עליו ולא תגרע ממנו,
                ואם היה האורך במזל עקרב או במזל אריה, תגרע מן האורך השלישי חמישיתו,
                ואם יהיה האורך במזל מאזנים או במזל בתולה, תגרע מן האורך השלישי שלישיתו,
                ומה שיהיה האורך השלישי אחר שתוסיף עליו או תגרע ממנו או תניח אותו כמות שהוא, הוא הנקרא אורך רביעי,
                ואחר כך תחזור אצל רוחב הירח הראשון ותקח ממנו שני שלישיו לעולם, וזה הוא הנקרא מנת גובה המדינה,
                ותתבונן ותראה אם יהיה רוחב הירח צפוני, תוסיף מנת גובה המדינה על האורך הרביעי,
                ואם יהיה רוחב הירח דרומי, תגרע מנת גובה המדינה מן האורך הרביעי,
                ומה שיהיה האורך הרביעי אחר שגורעין ממנו או מוסיפין עליו הוא הנקרא קשת הראייה.
            </div>
            <p></p>
            <div className="chapter-p" dir="rtl">
                <strong>יג. </strong>
                כיצד הרי שבאנו לחקור אם יראה הירח בליל ערב שבת שני לחדש אייר משנה זו או לא יראה,
                תוציא מקום השמש האמתי ומקום הירח האמתי ורוחב הירח לשעה זו כמו שהודענוך,
                יצא לך מקום השמש האמתי בשבע מעלות ותשעה חלקים ממזל שור, סימנו ז"ט,
                ויצא לך מקום הירח האמתי בשמונה עשרה מעלות וששה ושלשים חלקים ממזל שור, סימנו י"ח ל"ו,
                ויצא לך רוחב הירח ברוח דרום שלש מעלות ושלשה וחמשים חלקים, סימנו גנ"ג,
                וזה הוא הרוחב הראשון,
                ותגרע מקום השמש ממקום הירח ישאר אחת עשרה מעלות ושבעה ועשרים חלקים, סימנו י"א כ"ז,
                וזה הוא האורך הראשון,
                ולפי שהיה הירח במזל שור יהיה שינוי מראה האורך מעלה אחת וראוי לגרוע אותה מן האורך הראשון,
                יצא לך האורך השני עשר מעלות ושבעה ועשרים חלקים, סימנו יכ"ז,
                וכן יהיה שינוי מראה הרוחב עשרה חלקים,
                ולפי שרוחב הירח היה דרומי ראוי להוסיף עליו שינוי המראה שהוא עשרה חלקים,
                יצא לך הרוחב השני ארבע מעלות ושלשה חלקים, סימנו ד"ג,
                ולפי שהיה הירח בשמונה עשרה מעלות ממזל שור ראוי ליקח מן הרוחב השני רביעיתו והוא הנקרא מעגל הירח,
                יצא לך מעגל הירח לעת זו מעלה אחת וחלק אחד לפי שאין מדקדקין בשניות.
            </div>
            <p></p>
            <div className="chapter-p" dir="rtl">
                <strong>יד. </strong>
                ולפי שרוחב הירח דרומי ומקום הירח האמתי בין ראש גדי וראש סרטן,
                ראוי להוסיף המעגל על האורך השני, יצא לך האורך השלישי אחת עשרה מעלות ושמונה ועשרים חלקים, סימנו י"א כ"ח,
                ולפי שהאורך הזה במזל שור ראוי להוסיף על האורך השלישי חמישיתו שהוא שתי מעלות ושמונה עשרה חלקים,
                ויצא לך האורך הרביעי שלש עשרה מעלות וששה וארבעים חלקים, סימנו י"ג מ"ו,
                וחזרנו אצל הרוחב הראשון ולקחנו שני שלישיו יצא מנת גובה המדינה והוא שתי מעלות וחמשה ושלשים חלקים,
                ולפי שהיה הרוחב דרומי ראוי לגרוע מנת גובה המדינה מן האורך הרביעי,
                ישאר לך אחת עשרה מעלות ואחד עשר חלקים, סימנו י"א י"א,
                וזו היא קשת הראייה בלילה הזה, ועל דרך זו תעשה ותדע קשת הראייה כמה מעלות וכמה חלקים יש בה בכל ליל ראייה שתרצה לעולם.
            </div>
            <p></p>
            <div className="chapter-p" dir="rtl">
                <strong>טו. </strong>
                ואחר שתצא קשת זו תבין בה, ודע שאם תהיה קשת הראייה תשע מעלות או פחות אי אפשר שיראה בכל ארץ ישראל,
                ואם תהיה קשת הראייה יתר על ארבע עשרה מעלות אי אפשר שלא יראה ויהיה גלוי לכל ארץ ישראל.
            </div>
            <p></p>
            <div className="chapter-p" dir="rtl">
                <strong>טז. </strong>
                ואם תהיה קשת הראייה מתחלת מעלה עשירית עד סוף מעלת ארבע עשרה,
                תערוך קשת הראייה אל האורך הראשון ותדע אם יראה הירח או לא יראה מן הקצין שיש לו, והן הנקראין קיצי הראייה.
            </div>
            <p></p>
            <div className="chapter-p" dir="rtl">
                <strong>יז. </strong>
                ואלו הן קיצי הראייה, אם תהיה קשת הראייה מותר על תשע מעלות עד סוף עשר מעלות או יתר על עשר,
                ויהיה האורך הראשון שלש עשרה מעלות או יתר, ודאי יראה, ואם תהיה הקשת פחות מזה או יהיה האורך פחות מזה לא יראה.
            </div>
            <p></p>
            <div className="chapter-p" dir="rtl">
                <strong>יח. </strong>
                ואם תהיה קשת הראייה מותר על עשר מעלות עד סוף אחת עשרה מעלות או יתר על אחת עשרה,
                ויהיה האורך הראשון שתים עשרה מעלות או יתר, ודאי יראה, ואם תהיה הקשת פחות מזה או יהיה האורך פחות מזה לא יראה.
            </div>
            <p></p>
            <div className="chapter-p" dir="rtl">
                <strong>יט. </strong>
                ואם תהיה קשת הראייה מותר על אחת עשרה מעלות עד סוף שתים עשרה מעלות או יתר על י"ב,
                והיה האורך הראשון אחת עשרה מעלות או יתר ודאי יראה, ואם תהיה הקשת פחות מזה או יהיה האורך פחות מזה לא יראה.
            </div>
            <p></p>
            <div className="chapter-p" dir="rtl">
                <strong>כ. </strong>
                ואם תהיה קשת הראייה מותר על שתים עשרה מעלות עד סוף שלש עשרה מעלות או יתר על שלש עשרה,
                ויהיה האורך הראשון עשר מעלות או יתר ודאי יראה, ואם תהיה הקשת פחות מזה או יהיה האורך פחות מזה לא יראה.
            </div>
            <p></p>
            <div className="chapter-p" dir="rtl">
                <strong>כא. </strong>
                ואם תהיה קשת הראייה מותר על י"ג מעלות עד סוף ארבע עשרה מעלות או יתר על ארבע עשרה,
                ויהיה האורך הראשון תשע מעלות או יתר ודאי יראה, ואם תהיה הקשת פחות מזה או יהיה האורך פחות מזה לא יראה,
                ועד כאן סוף הקצין.
            </div>
            <p></p>
            <div className="chapter-p" dir="rtl">
                <strong>כב. </strong>
                כיצד באנו להתבונן בקשת הראייה של ליל ערב שבת שני לחדש אייר משנה זו,
                יצא לנו בחשבון קשת הראייה אחת עשרה מעלות ואחד עשר חלקים כמו שידעת,
                ולפי שהיתה קשת הראייה בין עשר עד ארבע עשרה ערכנו אותה אל האורך הראשון,
                וכבר ידעת שהאורך היה בליל זה אחת עשרה מעלות ושבעה ועשרים חלקים,
                ולפי שהיתה קשת הראייה יתר על אחת עשרה מעלות והיה האורך הראשון יתר על [אחת] עשרה,
                יודע שודאי יראה בליל זה לפי הקצין הקצובות, וכן תעשה בכל קשת וקשת עם האורך הראשון שלה.
            </div>
            <p></p>
            <div className="chapter-p" dir="rtl">
                <strong>כג. </strong>
                וכבר ראית מן המעשים האלו כמה חשבונות יש בו וכמה תוספות וכמה גירועין אחר שיגענו הרבה עד שהמצינו דרכים קרובים שאין בחשבונם עומק גדול,
                שהירח עקלקלות גדולות יש במעגלותיו,
                ולפיכך אמרו חכמים שמש ידע מבואו ירח לא ידע מבואו,
                ואמרו חכמים פעמים בא בארוכה פעמים בא בקצרה,
                כמו שתראה מחשבונות אלו שפעמים תוסיף ופעמים תגרע עד שתצא קשת הראייה,
                ופעמים תהיה קשת הראייה ארוכה ופעמים קצרה כמו שביארנו.
            </div>
            <p></p>
            <div className="chapter-p" dir="rtl">
                <strong>כד. </strong>
                וטעם כל אלו החשבונות ומפני מה מוסיפים מנין זה ומפני מה גורעין,
                והיאך נודע כל דבר ודבר מאלו הדברים, והראיה על כל דבר ודבר,
                היא חכמת התקופות והגימטריות שחברו בה חכמי יון ספרים הרבה והם הנמצאים עכשיו ביד החכמים,
                אבל הספרים שחברו חכמי ישראל שהיו בימי הנביאים מבני יששכר לא הגיעו אלינו,
                ומאחר שכל אלו הדברים בראיות ברורות הם שאין בהם דופי ואי אפשר לאדם להרהר אחריהם,
                אין חוששין למחבר בין שחברו אותו נביאים בין שחברו אותם גוים,
                שכל דבר שנתגלה טעמו ונודעה אמתתו בראיות שאין בהם דופי אין סומכין על זה האיש שאמרו או שלמדו אלא על הראייה שנתגלתה והטעם שנודע.
            </div>
            <p></p>
        </Container>
    );

};


export default Chapter17Content;