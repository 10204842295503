import { useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import * as THREE from "three";
import { GRAPHIC_PADDING } from "../../apis/commonConsts";
import { createLine, loadBall, loadEarth, loadGradientRing, loadMoon, loadRing, loadRingTexture, planeText, ringWithTexture, removeMesh } from "../../apis/loaders";
import { emzaMaslulYareachSpeed, emzaYareachSpeed, getMenatMaslulYareach, speedFormat } from "../../apis/orbits/orbitsGeneral";
import { textTexture } from "../../apis/textures";

import { Matrix, Point3D, SpeedUnits } from "../../utiles/hebrew-calculator";
import { getGraphicWidthHeight } from "../../utiles/helperFunctions";
import { setGroupPosition, Vector3D } from "../../utiles/threeDHelpers";

export const AUTO_RUN_EMZA_MASLUL_YAREACH = 1;
export const AUTO_RUN_EMZA_YAREACH = 2;
export const MAHALACH_EMZA_YAREACH = 4;
export const MAHALACH_EMZA_MASLUL_YAREACH = 8;
export const YAREACH_AMITI = 16;
export const MENAT_MASLUL_YAREACH_TEXT = 32;




const MAHALACH_EMZA_YAREACH_NAME = "mahalach_emza_yareach_name";
const MAHALACH_EMZA_MASLUL_YAREACH_NAME = "mahalach_emza_maslul_yareach_name";

const MAHALACH_YAREACH_AMITI_NAME = "mahalach_yareach_amiti";
const LINE_YAREACH_AMITI_NAME = "mekom_yareach_amiti";
const ARC_MENAT_MASLUL_NAME = "arc_menat_maslul";
const MENAT_MASLUL_YAREACH_TEXT_NEME = "menat_maslul_yareach_neme";


const EmzaYareach = forwardRef((props, ref) => {
    const mountRef = useRef(null);

    const [cameraRef, setCameraRef] = useState(null);
    const [rendererRef, setRendererRef] = useState(null);
    const [width, setWidth] = useState(500);
    const [height, setHeight] = useState(500);

    const vars = useRef({
        stateToRun: AUTO_RUN_EMZA_MASLUL_YAREACH,
        updateAllObject: null, // calback function
        emzaMslulYareach: 0,
        emzaYareach: 0,
        multiplyEmzaMslulYareachBy: 0.05,
        multiplyEmzaYareachBy: 0.05

    });

    const isStopRun = useRef(false);

    useEffect(() => {
        if (!cameraRef || !rendererRef) {
            return;
        }

        const { w, h } = getGraphicWidthHeight();

        cameraRef.aspect = w / h;
        cameraRef.updateProjectionMatrix();
        rendererRef.setSize(w, h);
    }, [height, width]);


    useImperativeHandle(ref, () => ({

        setRunState(runState) {
            vars.current.stateToRun = runState;
            vars.current.emzaMslulYareach = 0;
            vars.current.emzaYareach = 0;
            vars.current.updateAllObject();
        },
        setSise(h, w) {
            setWidth(w);
            setHeight(h);
            vars.current.updateAllObject();
        },
        setEmzaYareach(ey) {
            vars.current.emzaYareach = ey;
            vars.current.updateAllObject();
        },
        setEmzaMaslulYareach(emy) {
            vars.current.emzaMslulYareach = emy;
            vars.current.updateAllObject();
        }
    }));

    const isVisible = (state) => {
        return (state & vars.current.stateToRun) === state;
    };


    useEffect(() => {
        const { w, h } = getGraphicWidthHeight();


        var scene = new THREE.Scene();
        var camera = new THREE.PerspectiveCamera(60, w / h, 0.1, 20000);
        var renderer = new THREE.WebGLRenderer();


        renderer.setSize(w, h);


        mountRef.current.appendChild(renderer.domElement);

        const earthPoint = new Point3D(0, 0, -1000);
        const emzaYareachRadius = 300;
        const emzaYareachWidth = 6;
        const emzaMaslulYareachRadius = 26;
        const emzaMaslulYareachWidth = 6;

        const mahalachEmzaYareach = 385;
        const mahalachEmzaYareachWidth = 10;

        const mahalachEmzaMaslulYareach = emzaMaslulYareachRadius + 45;
        const mahalachEmzaMaslulYareachWidth = 10;
        const yareachAmitiRadius = 400;
        const yareachAmitiWidth = 10;

        const emzaYareachPoint = new Point3D(-emzaYareachRadius + emzaYareachWidth / 2, 0, 0);
        const emzaMaslulYareachPoint = new Point3D(-emzaMaslulYareachRadius + emzaMaslulYareachWidth / 2, 0, 0);
        const yareachAmitiPoint = new Point3D(-yareachAmitiRadius);

        const { mesh: background } = loadGradientRing(scene, 2000, 2000);
        background.position.z = -3000;

        loadRing(scene, emzaYareachRadius, emzaYareachWidth, 0xffff00);
        const { mesh: emzaMaslulYareachMesh } = loadRing(scene, emzaMaslulYareachRadius, emzaMaslulYareachWidth, 0xff8e00);

        const moon = loadMoon(scene, emzaMaslulYareachWidth + 2);
        moon.rotation.y = Math.PI / 2;

        const earth = loadEarth(scene);

        const { mesh: maslulAngle } = loadRingTexture(scene, 50, 10);

        const mercazMaslul = loadBall(scene, emzaMaslulYareachWidth / 2 + 1, 0xff0000);

        const menatMaslulYareachText = planeText();
        menatMaslulYareachText.name = MENAT_MASLUL_YAREACH_TEXT_NEME;
        menatMaslulYareachText.position.y = -520;
        scene.add(menatMaslulYareachText);

        ringWithTexture(scene);

        camera.position.z = 5;
        setGroupPosition(scene, earthPoint);

        setCameraRef(camera);
        setRendererRef(renderer);

        const mat = new Matrix();
        vars.current.updateAllObject = () => {
            mat.init();
            mat.rotMulZ(-vars.current.emzaYareach);
            let ptEmzaYareach = mat.transform(emzaYareachPoint);
            setGroupPosition(emzaMaslulYareachMesh, ptEmzaYareach);
            setGroupPosition(maslulAngle, ptEmzaYareach);
            setGroupPosition(mercazMaslul, ptEmzaYareach);
            maslulAngle.rotation.z = -vars.current.emzaYareach / 180 * Math.PI;

            mat.init();
            mat.rotMulZ(vars.current.emzaMslulYareach - vars.current.emzaYareach);
            let ptEmzaMaslul = mat.transform(emzaMaslulYareachPoint);
            ptEmzaMaslul.plusEqual(ptEmzaYareach);
            setGroupPosition(moon, ptEmzaMaslul);

            if (isVisible(MAHALACH_EMZA_YAREACH)) {
                removeMesh(scene, MAHALACH_EMZA_YAREACH_NAME);
                const { mesh: mahalachEmzaYareachMesh }
                    = loadRing(scene, mahalachEmzaYareach,
                        mahalachEmzaYareachWidth, 0xff0000, 0,
                        -(vars.current.emzaYareach % 360) / 180 * Math.PI);

                mahalachEmzaYareachMesh.name = MAHALACH_EMZA_YAREACH_NAME;
                mahalachEmzaYareachMesh.rotation.z = Math.PI;
            } else {
                removeMesh(scene, MAHALACH_EMZA_YAREACH_NAME);
            }

            if (isVisible(MAHALACH_EMZA_MASLUL_YAREACH)) {
                removeMesh(scene, MAHALACH_EMZA_MASLUL_YAREACH_NAME);
                const { mesh: mahalachEmzaMaslulYareachMesh } =
                    loadRing(scene, mahalachEmzaMaslulYareach,
                        mahalachEmzaMaslulYareachWidth, 0xec38bc, 0,
                        (vars.current.emzaMslulYareach % 360) / 180 * Math.PI);

                setGroupPosition(mahalachEmzaMaslulYareachMesh, ptEmzaYareach);
                mahalachEmzaMaslulYareachMesh.name = MAHALACH_EMZA_MASLUL_YAREACH_NAME;
                mahalachEmzaMaslulYareachMesh.rotation.z = Math.PI - (vars.current.emzaYareach % 360) / 180 * Math.PI;

            } else {
                removeMesh(scene, MAHALACH_EMZA_MASLUL_YAREACH_NAME);
            }

            const menatMaslul = -getMenatMaslulYareach(
                vars.current.emzaMslulYareach,
                emzaYareachRadius,
                emzaMaslulYareachRadius).deg;
            const yareachAmiti = menatMaslul + vars.current.emzaYareach;

            mat.init();
            mat.rotMulZ(-yareachAmiti);
            const ptYareachAmiti = mat.transform(yareachAmitiPoint);

            const color =  0x66aaff;
            const yareachAmitiVector = new Vector3D(earthPoint, ptYareachAmiti);
            createLine(scene,
                LINE_YAREACH_AMITI_NAME,
                yareachAmitiVector.lineGeometry,
                1, color).visible = isVisible(YAREACH_AMITI);

            removeMesh(scene, MAHALACH_YAREACH_AMITI_NAME);
            const { mesh: yareachAmitiMesh } =
                loadRing(scene,
                    yareachAmitiRadius, yareachAmitiWidth,
                    color, Math.PI, -yareachAmiti / 180 * Math.PI);
            yareachAmitiMesh.visible = isVisible(YAREACH_AMITI);
            yareachAmitiMesh.name = MAHALACH_YAREACH_AMITI_NAME;

            removeMesh(scene, ARC_MENAT_MASLUL_NAME);
            const { mesh: menatMaslulMesh } = loadRing(scene,
                mahalachEmzaYareach, mahalachEmzaYareach,
                color,
                -(vars.current.emzaYareach % 360) / 180 * Math.PI + Math.PI,
                -menatMaslul / 180 * Math.PI);

            menatMaslulMesh.name = ARC_MENAT_MASLUL_NAME;
            menatMaslulMesh.visible = isVisible(YAREACH_AMITI);
            menatMaslulMesh.position.z = -1;

            menatMaslulYareachText.visible = isVisible(MENAT_MASLUL_YAREACH_TEXT);

            if (menatMaslulYareachText.visible) {
                const menat = getMenatMaslulYareach(vars.current.emzaMslulYareach).deg;
                const sign = menat > 0 ? "-" : "+";
                const text = `(${sign}) ${speedFormat(new SpeedUnits(Math.abs(menat / 360)), 2)}`;

                menatMaslulYareachText.material.map.dispose();
                menatMaslulYareachText.material.map = textTexture(text);
            }

        };

        const autoRun = () => {
            const s = vars.current.stateToRun;
            if ((s & AUTO_RUN_EMZA_MASLUL_YAREACH) || (s & AUTO_RUN_EMZA_YAREACH)) {
                if (s & AUTO_RUN_EMZA_MASLUL_YAREACH) {
                    vars.current.emzaMslulYareach +=
                        emzaMaslulYareachSpeed.degreesCount *
                        vars.current.multiplyEmzaMslulYareachBy;
                    if (vars.current.emzaMslulYareach > 360) {
                        vars.current.emzaMslulYareach -= 360;
                    }
                }

                if (s & AUTO_RUN_EMZA_YAREACH) {
                    vars.current.emzaYareach +=
                        emzaYareachSpeed.degreesCount * vars.current.multiplyEmzaYareachBy;
                    if (vars.current.emzaYareach > 360) {
                        vars.current.emzaYareach -= 360;
                    }

                }
                vars.current.updateAllObject();
            }
        };

        var animate = function () {
            if (isStopRun.current) {
                return;
            }
            requestAnimationFrame(animate);

            autoRun();

            renderer.render(scene, camera);
        };

        const copymountRef = mountRef;
        animate();

        return () => {
            mountRef.current?.removeChild(renderer.domElement);
            copymountRef.current?.removeChild(renderer.domElement);
            isStopRun.current = true;
        };
        
    }, []);

    return (
        <div ref={mountRef} style={{ padding: GRAPHIC_PADDING }} />

    );

});


export default EmzaYareach;