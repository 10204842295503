import React, { useContext, useEffect } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import useWindowDimensions from "./hooks/useWindowDimensions";

import useUserStatus from "./hooks/useUserStatus";
import { Context as Authcontext } from "./context/Authcontext";
import { getScrollbarWidth } from "./utiles/helperFunctions";
import { CHAPTER_11, CHAPTER_12, CHAPTER_13, CHAPTER_14, CHAPTER_15, CHAPTER_16, CHAPTER_17 } from "./apis/commonConsts";

const Header = ({ history }) => {

    const user = useUserStatus();
    const { state, updateState } = useContext(Authcontext);

    const { width, height } = useWindowDimensions();

    const isUserUpdated = () => {
        for (const key in user) {
            if (Object.hasOwnProperty.call(user, key)) {
                if (state[key] !== user[key]) {
                    return true;
                }
            }
        }

        return false;
    };

    useEffect(() => {
        if (isUserUpdated()) {
            updateState(user);
            // console.log(user, state);
        }


    }, [user]);

    const getUserName = () => {
        if (user.verified) {
            return <Nav.Link style={{ color: 'lightgreen' }}>{user.username.split(/[ ,]+/)[0]}</Nav.Link>;
        } else if (state.email !== '') {
            return <Nav.Link style={{ color: 'red' }}>לא מאומת</Nav.Link>;
        }


        return null;
    }
    const scroolWidth = getScrollbarWidth();
    const trueWidth = height > width ? width - scroolWidth - 4 : width - scroolWidth - 4;
    return (
        <Navbar style={{
            width: trueWidth,
            marginLeft: 3
        }}
            className="align-items-lg-center" collapseOnSelect expand="sm" bg="dark" variant="dark">
            <Container>
                <Navbar.Brand ><Link style={{ textDecoration: 'none', color: 'gray' }} to="/">קידוש החודש לרמב"ם</Link></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <NavDropdown title="פרקים" id="collasible-nav-dropdown">
                            {/* <NavDropdown.Item href="#action/3.1">פרק יא</NavDropdown.Item> */}
                            <LinkContainer to={CHAPTER_11}>
                                <NavDropdown.Item>פרק יא</NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to={CHAPTER_12}>
                                <NavDropdown.Item>פרק יב</NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to={CHAPTER_13}>
                                <NavDropdown.Item>פרק יג</NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to={CHAPTER_14}>
                                <NavDropdown.Item>פרק יד</NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to={CHAPTER_15}>
                                <NavDropdown.Item>פרק טו</NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to={CHAPTER_16}>
                                <NavDropdown.Item>פרק טז</NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to={CHAPTER_17}>
                                <NavDropdown.Item>פרק יז</NavDropdown.Item>
                            </LinkContainer>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="https://sites.google.com/site/kidushhahodesh/%D7%90%D7%A1%D7%98%D7%A8%D7%95%D7%A0%D7%95%D7%9E%D7%99%D7%94-%D7%A2%D7%91%D7%A8%D7%99%D7%AA-%D7%A7%D7%99%D7%93%D7%95%D7%A9-%D7%94%D7%97%D7%95%D7%93%D7%A9-%D7%9C%D7%A8%D7%9E%D7%91%D7%9D-jewish-astronomy/%D7%A7%D7%99%D7%93%D7%95%D7%A9-%D7%94%D7%97%D7%95%D7%93%D7%A9-%D7%9C%D7%A8%D7%9E%D7%91%D7%9D-%D7%9E%D7%91%D7%95%D7%90%D7%A8-%D7%A2%D7%99-%D7%9E%D7%90%D7%95%D7%AA-%D7%9E%D7%A6%D7%92%D7%95%D7%AA/%D7%94%D7%9C%D7%9B%D7%95%D7%AA-%D7%A7%D7%99%D7%93%D7%95%D7%A9-%D7%94%D7%97%D7%95%D7%93%D7%A9-%D7%A4%D7%A8%D7%A7-%D7%98%D7%95/%D7%A9%D7%99%D7%98%D7%AA-%D7%94%D7%A8%D7%9E%D7%91%D7%9D-%D7%91%D7%97%D7%99%D7%A9%D7%95%D7%91-%D7%94%D7%9E%D7%A8%D7%97%D7%A7-%D7%94%D7%9B%D7%A4%D7%95%D7%9C-%D7%91%D7%94%D7%9C%D7%9B%D7%95%D7%AA-%D7%A7%D7%99%D7%93%D7%95%D7%A9-%D7%94%D7%97%D7%95%D7%93%D7%A9">ביאור על המרחק הכפול</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                    <Nav>
                        <Nav.Item>
                            {getUserName()}
                        </Nav.Item>
                        <Nav.Link target="_blank" href="http://kiddushhachodesh.net/">פרקים ו - י</Nav.Link>
                        <Nav.Item style={{ padding: 8 }}>
                            <Link to="/signin" style={{ textDecoration: 'none', color: 'gray' }} >{state.email === '' ? 'התחברות' : 'התנתקות'}</Link>
                        </Nav.Item>
                        <Nav.Item style={{ padding: 8 }}>
                            <Link style={{ textDecoration: 'none', color: 'gray' }} to="/signup">הרשמה</Link>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Header;