
export const hebrewMazalut = ['טלה','שור','תאומים','סרטן','אריה','בתולה','מאזנים','עקרב','קשת','גדי','דלי','דגים'];
export const HEADER_HEIGHT = 57;
export const GRAPHIC_PADDING = 3;
export const ORDER_MESSAGE = "כדי לקבל גישה לכל המצגות יש לבצע הזמנה של אחת מהתוכנות.";

export const CHAPTER_11 = "/קידוש-החודש-פרק-יא";
export const CHAPTER_12 = "/קידוש-החודש-פרק-יב";
export const CHAPTER_13 = "/קידוש-החודש-פרק-יג";
export const CHAPTER_14 = "/קידוש-החודש-פרק-יד";
export const CHAPTER_15 = "/קידוש-החודש-פרק-טו";
export const CHAPTER_16 = "/קידוש-החודש-פרק-טז";
export const CHAPTER_17 = "/קידוש-החודש-פרק-יז";