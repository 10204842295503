import React, { useContext } from "react";
import { Container, Row, Table } from "react-bootstrap";
import { getEmzaMaslulYareach, getEmzaShemeshString, getEmzaYareachReiya, getMerchakAndKaful, getMenatMerchakKaful, getMaslulNachon, getMenatMaslulNachon, getYareachAmiti } from "../../apis/orbits/orbitsGeneral";
import { Context as Authcontext } from "../../context/Authcontext";
import { VIEW_C15_STATE_1, VIEW_C15_STATE_10, VIEW_C15_STATE_11, VIEW_C15_STATE_12, VIEW_C15_STATE_13, VIEW_C15_STATE_14, VIEW_C15_STATE_15, VIEW_C15_STATE_16, VIEW_C15_STATE_17, VIEW_C15_STATE_18, VIEW_C15_STATE_19, VIEW_C15_STATE_2, VIEW_C15_STATE_20, VIEW_C15_STATE_21, VIEW_C15_STATE_22, VIEW_C15_STATE_23, VIEW_C15_STATE_24, VIEW_C15_STATE_25, VIEW_C15_STATE_26, VIEW_C15_STATE_27, VIEW_C15_STATE_28, VIEW_C15_STATE_29, VIEW_C15_STATE_3, VIEW_C15_STATE_30, VIEW_C15_STATE_31, VIEW_C15_STATE_32, VIEW_C15_STATE_33, VIEW_C15_STATE_34, VIEW_C15_STATE_35, VIEW_C15_STATE_36, VIEW_C15_STATE_37, VIEW_C15_STATE_38, VIEW_C15_STATE_4, VIEW_C15_STATE_40, VIEW_C15_STATE_5, VIEW_C15_STATE_6, VIEW_C15_STATE_7, VIEW_C15_STATE_8, VIEW_C15_STATE_9, VIEW_C15_STATE_OPEN } from "../../screens/chapters/Chapter15";
import AccodionSpan from "../AccodionSpan";
import DaysFromIkar2 from "../controls/DaysFromIkar2";


const MekomYareachAmiti = ({days}) => {

    const rows = () => {
        return (
        <>
            <tr>
                <td>מקום אמצע השמש</td>
                <td>{getEmzaShemeshString(days)}</td>
            </tr>
            <tr>
                <td>מקום אמצע הירח לשעת ראיה</td>
                <td>{getEmzaYareachReiya(days).yareachReiyaString}</td>
            </tr>
            <tr>
                <td>מקום אמצע מסלול הירח</td>
                <td>{getEmzaMaslulYareach(days).emzaMaslulString}</td>
            </tr>
            <tr>
                <td>המרחק</td>
                <td>{getMerchakAndKaful(days).merchakString}</td>
            </tr>
            <tr>
                <td>המרחק הכפול</td>
                <td>{getMerchakAndKaful(days).kafulString}</td>
            </tr>
            <tr>
                <td>מנת המרחק הכפול</td>
                <td>{getMenatMerchakKaful(getMerchakAndKaful(days).kaful).degString}</td>
            </tr>
            <tr>
                <td>מסלול הנכון</td>
                <td>{getMaslulNachon(days).nachonString}</td>
            </tr>
            <tr>
                <td>מנת המסלול הנכון</td>
                <td>{getMenatMaslulNachon(days).manaString}</td>
            </tr>
            <tr>
                <td>מקום הירח האמיתי</td>
                <td>{getYareachAmiti(days).amitiString}</td>
            </tr>
        </>
        );
    };

    return (
        <div  style={{ textAlign: 'center' }}>
            <Table responsive bordered dir="rtl" color="red">
                <thead className="info-header">
                    <tr>
                        <th colSpan="2">חישוב מקום הירח האמיתי</th>                        
                    </tr>
                </thead>
                <tbody className="info-body">
                    {rows()}
                </tbody>
            </Table>
        </div>
    );
};


const Chapter15content = ({ setViewState, params }) => {

    const { state } = useContext(Authcontext);
    return (
        <Container className="chapter-container">
            <Row>
                <h4 className="chpter-header">פרק טו</h4>
                <h6 className="paragrph">חישוב מרחק הכפול ומנתו, ומקום הירח האמיתי</h6>
                {/* <p style={{ textAlign: 'center', color: 'red' }}>פרק זה בשלבי בניה</p> */}

                <div className="chapter-p" dir="rtl">
                    <strong className="span-6" onClick={() => setViewState(VIEW_C15_STATE_OPEN)}>א. </strong>
                    אם תרצה לידע מקום הירח האמתי בכל יום שתרצה,
                    תוציא תחלה <span className="span-1" onClick={() => setViewState(VIEW_C15_STATE_1)}>אמצע הירח לשעת הראייה</span> לאותו הלילה שתרצה,
                    וכן תוציא <span className="span-2" onClick={() => setViewState(VIEW_C15_STATE_2)}>אמצע המסלול</span>
                    <span className="span-3" onClick={() => setViewState(VIEW_C15_STATE_3)}>ואמצע השמש</span> לאותו העת,
                    <span className="span-4" onClick={() => setViewState(VIEW_C15_STATE_4)}>ותגרע אמצע השמש מאמצע הירח</span>,
                    והנשאר תכפול אותו, וזה הוא הנקרא <span className="span-5" onClick={() => setViewState(VIEW_C15_STATE_5)}>מרחק הכפול</span>.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>ב. </strong>
                    וכבר הודענו שלא באנו בכל אלו החשבונות שעשינו בפרקים אלו אלא לדעת ראיית הירח,
                    ולעולם אי אפשר שיהיה מרחק זה הכפול בליל הראייה שיראה בה הירח אלא מחמש מעלות עד שתים וששים מעלות, <span className="span-1" onClick={() => setViewState(VIEW_C15_STATE_6)}>ואי אפשר שיוסיף על זה ולא יגרע ממנו</span>.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>ג. </strong>
                    והואיל והדבר כן, התבונן במרחק זה הכפול, אם יהיה המרחק הכפול <span className="span-2" onClick={() => setViewState(VIEW_C15_STATE_7)}>חמש מעלות או קרוב לחמש</span> אין חוששין לתוספת ולא תוסיף כלום,
                    ואם יהיה המרחק הכפול משש מעלות עד אחת עשרה מעלות תוסיף על אמצע המסלול <span className="span-3" onClick={() => setViewState(VIEW_C15_STATE_8)}>מעלה אחת</span>,
                    ואם יהיה המרחק הכפול משתים עשרה מעלות עד שמונה עשרה מעלות תוסיף על אמצע המסלול <span className="span-4" onClick={() => setViewState(VIEW_C15_STATE_9)}>שתי מעלות</span>,
                    ואם יהיה המרחק הכפול מתשע עשרה מעלות עד ארבע ועשרים מעלות תוסיף על אמצע המסלול <span className="span-5" onClick={() => setViewState(VIEW_C15_STATE_10)}>שלש מעלות</span>,
                    ואם יהיה המרחק הכפול מחמש ועשרים מעלות עד אחת ושלשים מעלות תוסיף על אמצע המסלול <span className="span-1" onClick={() => setViewState(VIEW_C15_STATE_11)}>ארבע מעלות</span>,
                    ואם יהיה המרחק הכפול משתים ושלשים מעלות עד שמונה ושלשים מעלות תוסיף על אמצע המסלול <span className="span-2" onClick={() => setViewState(VIEW_C15_STATE_12)}>חמש מעלות</span>,
                    ואם יהיה המרחק הכפול מתשע ושלשים מעלות עד חמש וארבעים מעלות תוסיף על אמצע המסלול <span className="span-3" onClick={() => setViewState(VIEW_C15_STATE_13)}>שש מעלות</span>,
                    ואם יהיה המרחק הכפול משש וארבעים מעלות עד אחת וחמשים מעלות תוסיף על אמצע המסלול <span className="span-4" onClick={() => setViewState(VIEW_C15_STATE_14)}>שבע מעלות</span>,
                    ואם יהיה המרחק הכפול משתים וחמשים מעלות עד תשע וחמשים מעלות תוסיף על אמצע המסלול <span className="span-5" onClick={() => setViewState(VIEW_C15_STATE_15)}>שמונה מעלות</span>,
                    ואם יהיה המרחק הכפול מששים מעלות עד שלש וששים מעלות תוסיף על אמצע המסלול <span className="span-1" onClick={() => setViewState(VIEW_C15_STATE_16)}>תשע מעלות</span>,
                    ומה שיהיה אמצע המסלול אחר שתוסיף עליו מעלות אלו הוא הנקרא מסלול הנכון.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>ד. </strong>
                    ואחר כך תראה כמה מעלות הוא המסלול הנכון, <span className="span-2" onClick={() => setViewState(VIEW_C15_STATE_17)}>אם היה פחות ממאה ושמונים מעלות תגרע מנת המסלול הזה הנכון</span> מאמצע הירח לשעת הראייה,
                    ואם היה המסלול הנכון <span className="span-3" onClick={() => setViewState(VIEW_C15_STATE_18)}>יתר על מאה ושמונים מעלות עד שלש מאות וששים תוסיף מנת זה המסלול הנכון</span> על אמצע הירח לשעת הראייה,
                    ומה שיהיה האמצע אחר שתוסיף עליו או תגרע ממנו הוא מקום הירח האמתי לשעת הראייה.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>ה. </strong>
                    ודע שאם יהיה המסלול הנכון <span className="span-4" onClick={() => setViewState(VIEW_C15_STATE_19)}>מאה ושמונים בשוה</span>
                    <span className="span-5" onClick={() => setViewState(VIEW_C15_STATE_20)}>או שלש מאות וששים בשוה</span> אין לו מנה,
                    אלא יהיה מקום הירח האמצעי לשעת הראייה הוא מקומו האמתי.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>ו. </strong>
                    וכמה היא מנת המסלול, אם יהיה המסלול הנכון <span className="span-1" onClick={() => setViewState(VIEW_C15_STATE_21)}>עשר מעלות</span> תהיה מנתו חמשים חלקים,
                    ואם יהיה המסלול הנכון <span className="span-2" onClick={() => setViewState(VIEW_C15_STATE_22)}>עשרים מעלות</span> תהיה מנתו מעלה אחת ושמונה ושלשים חלקים,
                    ואם יהיה <span className="span-3" onClick={() => setViewState(VIEW_C15_STATE_23)}>שלשים</span> תהיה מנתו שתי מעלות וארבעה ועשרים חלקים,
                    ואם יהיה <span className="span-4" onClick={() => setViewState(VIEW_C15_STATE_24)}>ארבעים</span> תהיה מנתו שלש מעלות וששה חלקים,
                    ואם יהיה <span className="span-5" onClick={() => setViewState(VIEW_C15_STATE_25)}>חמשים</span> תהיה מנתו שלש מעלות וארבעה וארבעים חלקים,
                    ואם יהיה <span className="span-1" onClick={() => setViewState(VIEW_C15_STATE_26)}>ששים</span> תהיה מנתו ארבע מעלות וששה עשר חלקים,
                    ואם יהיה <span className="span-2" onClick={() => setViewState(VIEW_C15_STATE_27)}>שבעים</span> תהיה מנתו ארבע מעלות ואחד וארבעים חלקים,
                    ואם יהיה <span className="span-3" onClick={() => setViewState(VIEW_C15_STATE_28)}>שמונים</span> תהיה מנתו חמש מעלות,
                    ואם יהיה <span className="span-4" onClick={() => setViewState(VIEW_C15_STATE_29)}>תשעים</span> תהיה מנתו חמש מעלות וחמשה חלקים,
                    ואם יהיה <span className="span-5" onClick={() => setViewState(VIEW_C15_STATE_30)}>מאה</span> תהיה מנתו חמש מעלות ושמונה חלקים,
                    ואם יהיה <span className="span-1" onClick={() => setViewState(VIEW_C15_STATE_31)}>מאה ועשר</span> תהיה מנתו ארבע מעלות ותשעה וחמשים חלקים,
                    ואם יהיה <span className="span-2" onClick={() => setViewState(VIEW_C15_STATE_32)}>מאה ועשרים</span> תהיה מנתו ארבע מעלות וארבעים חלקים,
                    ואם יהיה <span className="span-3" onClick={() => setViewState(VIEW_C15_STATE_33)}>מאה ושלשים</span> תהיה מנתו ארבע מעלות ואחד עשר חלקים,
                    ואם יהיה <span className="span-4" onClick={() => setViewState(VIEW_C15_STATE_34)}>מאה וארבעים</span> תהיה מנתו שלש מעלות ושלשה ושלשים חלקים,
                    ואם יהיה <span className="span-5" onClick={() => setViewState(VIEW_C15_STATE_35)}>מאה וחמשים</span> תהיה מנתו שתי מעלות ושמונה וארבעים חלקים,
                    ואם יהיה <span className="span-1" onClick={() => setViewState(VIEW_C15_STATE_36)}>מאה וששים</span> תהיה מנתו מעלה אחת וששה וחמשים חלקים,
                    ואם יהיה <span className="span-2" onClick={() => setViewState(VIEW_C15_STATE_37)}>מאה ושבעים</span> תהיה מנתו תשעה וחמשים חלקים,
                    ואם יהיה <span className="span-3" onClick={() => setViewState(VIEW_C15_STATE_38)}>מאה ושמונים בשוה</span> אין לו מנה כמו שאמרנו אלא מקום הירח האמצעי הוא המקום האמתי.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>ז. </strong>
                    ואם יהיה המסלול הנכון יתר על מאה ושמונים מעלות, תגרע אותו משלש מאות וששים ותדע מנתו כדרך שעשית במסלול השמש,
                    וכן אם יהיו במנין המסלול אחדים עם העשרות תקח מן היתר שבין שתי המנות כפי האחדים,
                    כדרך שביארנו במסלול השמש במנות שלו כך תעשה במסלול הנכון במנות שלו.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <AccodionSpan setState={() => setViewState(VIEW_C15_STATE_40)} className="span-4" collapseItem={state.useContent === true ? <DaysFromIkar2 params={params}><MekomYareachAmiti days={params.days}/></DaysFromIkar2> : <></>}>מקום הירח האמתי</AccodionSpan>
                </div>

                <div className="chapter-p" dir="rtl">
                    <strong>ח. </strong>
                    כיצד הרי שרצינו לידע מקום הירח האמתי בתחלת ליל ערב שבת שיומו שני לחדש אייר משנה זו שהיא שנת העיקר,
                    ומנין הימים הגמורים מתחלת ליל העיקר עד תחילת ליל זה שאנו רוצים לידע מקום הירח האמתי בו תשעה ועשרים יום,
                    תוציא אמצע השמש תחלה לליל זה, יצא לך אמצעה חמש ושלשים מעלות ושמונה ושלשים חלקים ושלש ושלשים שניות,
                    סימנם ל"ה ל"ח ל"ג, ותוציא אמצע הירח לשעת הראייה לעת זו,
                    יצא לך אמצעו שלש וחמשים מעלות וששה ושלשים חלקים ותשע ושלשים שניות, סימנם נ"ג ל"ו ל"ט,
                    ותוציא אמצע המסלול לעת זו יצא לך אמצעו מאה ושלש מעלות ואחד ועשרים חלקים ושש וארבעים שניות, סימנם ק"ג כ"א מ"ו,
                    תגרע אמצע השמש מאמצע הירח ישאר שבע עשרה מעלות ושמונה וחמשים חלקים ושש שניות,
                    וזה הוא המרחק, תכפול אותו יצא לך המרחק הכפול חמש ושלשים מעלות ושש וחמשים חלקים ושתים עשרה שניות, סימנם ל"ה נ"ו י"ב,
                    לפיכך תוסיף על אמצע המסלול חמש מעלות כמו שהודענו ויצא לך המסלול הנכון מאה ושמונה מעלות ואחד ועשרים חלקים ואין מקפידין על החלקים במסלול כדרך שביארנו בשמש.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>ט. </strong>
                    ובאנו לחקור על מנת זה המסלול הנכון שהוא מאה ושמונה נמצאת מנה שלו חמש מעלות וחלק אחד,
                    ולפי שהמסלול הנכון היה פחות ממאה ושמונים תגרע המנה שהוא חמש מעלות וחלק אחד מן אמצע הירח,
                    ישאר שמונה וארבעים מעלות וחמשה ושלשים חלקים ותשע ושלשים שניות, תעשה השניות חלק ותוסיף על החלקים,
                    ונמצא מקום הירח האמתי בשעה זו במזל שור בשמונה עשרה מעלות וששה ושלשים חלקים ממעלת תשע עשרה,
                    סימנם י"ח ל"ו, ועל הדרך הזה תדע מקום הירח האמתי בכל עת ראייה שתרצה מתחלת שנה זו שהיא העיקר עד סוף העולם.
                </div>
                <p></p>
            </Row>
        </Container>
    );
};

export default Chapter15content;