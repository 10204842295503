import { Matrix, Point3D } from "./hebrew-calculator";
import * as THREE from "three";


export const P3DToV3 = (p) => {
    return new THREE.Vector3(p.x, p.y, p.z);
};



export class Vector3D {
    constructor(p3Dorigin = new Point3D(), p3Ddestination = new Point3D()) {
        this.origin = p3Dorigin;
        this.destanation = p3Ddestination;
    }

    get product() {
        return this.destanation.minus(this.origin).product;
    }

    get lineGeometry() {
        const points = [P3DToV3(this.origin), P3DToV3(this.destanation)];
        const geometry = new THREE.BufferGeometry().setFromPoints(points);

        return geometry;
    }

    scale(n) {
        const p = this.destanation.minus(this.origin);
        p.scale(n);
        return new Vector3D(this.origin, this.origin.plus(p));
    }

};

export class Matrix4 extends THREE.Matrix4 {
    static degToRad = Math.PI / 180.0;
    constructor() {
        super();
        this.identity();
    }

    rotMulX (theta) {
        const mat = new Matrix4();
        mat.makeRotationX(theta * Matrix4.degToRad)
        this.multiply(mat);
    }
    rotMulY (theta) {
        const mat = new Matrix4();
        mat.makeRotationY(theta * Matrix4.degToRad)
        this.multiply(mat);
    }
    rotMulZ (theta) {
        const mat = new Matrix4();
        mat.makeRotationZ(theta * Matrix4.degToRad)
        this.multiply(mat);
    }

    init() {
        this.identity();
    }
}

export const point3DToEuler = (p = new Point3D(), euler = new THREE.Euler()) => {
    euler.x = p.x;
    euler.y = p.y;
    euler.z = p.z;
};

export const updateGeometryMyMatrix = (g = new THREE.BufferGeometry(), m = new Matrix()) => {
    const position = g.getAttribute('position');
    const { originPoints } = g.userData;
    
    for (let i = 0; i < position.count; i++) {
        const p = m.transform(originPoints[i]);
        position.setXYZ(i, p.x, p.y, p.z);
    }

    position.needsUpdate = true;
};

export const setGroupPosition = (group, point3D) => {
    group.position.x = point3D.x;
    group.position.y = point3D.y;
    group.position.z = point3D.z;

};

