
const D = Math.PI / 180.0;
export const calcMenatHaMslul = (maslulHaShemesh, memuza = 28.87708884) => {
	let menatHaMslul = 
		Math.atan(Math.sin(maslulHaShemesh) / ( + memuza +
		Math.cos(maslulHaShemesh)));
	menatHaMslul /= D;

	return menatHaMslul;

};

export const shemesAmity = (shmEmzai, govaSemesh, memuza = 28.87708884) => {

	const maslulHaShemesh = (shmEmzai - govaSemesh);
	const menatHaMslul = calcMenatHaMslul(maslulHaShemesh * D, memuza);

	return shmEmzai - menatHaMslul;
};
