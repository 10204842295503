import React, { useEffect, useState } from "react";
import { Container, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { getGraphicWidthHeight, getScrollbarWidth } from "../../utiles/helperFunctions";
import WheelCycles from "../2d/WheelCycles";

import "./chaptersStyle.css"

const Chapter11OpenContent = () => {

    const { graphicWidth: width } = useWindowDimensions();
    return (
        <Container className="chapter-container" >
            <h1 className="chpter-header">נקודה בהיכלא</h1>
            <h4 className="paragrph">והוא ביאור עניין הגלגל תכונותיו מידותיו תנועתו והזמן הנגזר מתנועתו</h4>
            <div className="chapter-p text-center" dir="rtl">
                בביאור זה יבאו העניינים בדרך קצרה ובמקום אחר יבואר בהרחבה.
            </div>
            <div className="chapter-p" dir="rtl">
                היות וראיתי מכשול גדול בספרים וביאורים בני זמנינו להלכות קידוש החודש לרמב"ם אשר אנשים אפרתיים רעו רוח ורדפו קדים ונטו אחר חוכמת יוונים והביאו ראיות שיש בהם דופי ושיבשו חשבונות ראית הקיצים, קיצי הראיה.
            </div>
            <p></p>
            <div className="chapter-p" dir="rtl">
                ראיתי לנכון להביא אותות ומופתים על קדושת חשבון הגלגל אשר יושב לפנים ולפנים בתוך היכל השם.
                וקראתי לחיבור זה נקודה בהיכלא. נקודה בהחזרת ק' ל - א' = 66 = גלגל, היכלא = 66 = גלגל.
                נקודה כנגד חכמה, היכלא כנגד בינה, גלגל עם הכולל = בינה.
                לכן מלחמתם הראשונה של בני ישראל בכניסתם לארץ היתה בחשבון גימטריא שם י-ה-ו-ה = 366,
                כדי לבטל חשבונות זרים, ולהיות חשבונות עם קדושים חוכמתכם ובינתכם לעיני הגויים.
            </div>
            <div className="chapter-p text-center" dir="rtl">
                דרשו מעל <strong>ספר י-ה-ו-ה</strong> (גימטריא חשבון) וקראו אחת מהנה לא נעדרה (ישעיהו לד. טז).
            </div>
            <p></p>
            <div className="chapter-p text-center" dir="rtl">
                ולכן סיים הרמב"ם הלכות קידוש החודש בפסוק זה,
                היות היד החזקה אינה ספר מליצות אלא הלכות סדורות.
                להורות לנו כי מי שלא טרח בחשבון צורת תנועת הגלגלים באלו הפרקים שהם עולים אחד לאחד עם חשבון חיבור זה המכובד.
                כלך לך מכתוב ספרים ופירושים להביא חשבונות משובשים, אשר את שם י-ה-ו-ה מבזים.
            </div>
            <p></p>
            <div className="text-center">
                <WheelCycles width={width * 0.8} />
            </div>
            <p></p>
            <h5 className="paragrph">יסוד תנועת הגלגל במספרו י"א</h5>
            <div className="chapter-p" dir="rtl">
                אם נצרף את האותיות א ב ג ד ה ו ז ח ט י י"א יצא חשבונן 1+2+3+4+5+6+7+8+9+10+11 = 66 הוא גימטריא גלגל.
            </div>
            <p></p>
            <h5 className="paragrph">סבוב הגלגל יוצר קול</h5>
            <div className="chapter-p" dir="rtl">
                סבוב = 60+2+6+2 = 70 גלגל = 3+30+3+30 = 66 ,
                66 + 70 = 136 גימטריא קול. מכאן הביטוי השגור גלי קול.
                סיבוב הגלגל יוצר שני גלים. יוצא גל + גל + סבוב = קול.
                לכן אמר יהושוע פרק י' פסוק י"ב  "שמש בגבעון דום וגו'" שקול השמש הוא סיבובו. לכן אם ישתוק יתבטל סיבובו ובמקומו יעצור ויעמוד.
            </div>
            <p></p>
            <h5 className="paragrph">עריבות הקול ממספר קולות</h5>
            <div className="chapter-p" dir="rtl">
                קול מגל פשוט אין בו עריבות, רק מורכבות הקולות הנקראת הרמוניה יכול להיות בה עריבות. קול + קול = 136 + 136 = 272 = ערב.
                דהיינו משני קולות יצא קול שלישי. וזהו הביטוי השגור "קול ערב" סכומם שלוש פעמים קול גימטריא 408.
                וכן מובא בגנת אגוז "הגלגלים יש להם קולות עריבות בעת תנועתם".
                וכן בדרך אפשר, יש לומר כי הגלגל התשיעי המניע כל הגלגלים נקרא "ערבות" על שם ערבות קול תנועתם של הגלגלים שהוא מניע.
                וכן יובנו הפסוקים "בלי נשמע קולם בכל הארץ יצא קום ובקצה תבל מליהם" (תהילים י"ט ד' – ה').
            </div>
            <p></p>
            <h5 className="paragrph">אופן מתאר מבנה הגלגל</h5>
            <div className="chapter-p" dir="rtl">
                תיבת אופן תתאר את מיבנה הגלגל. <strong>א'</strong> תתייחס לנקודתו האמצעית של הגלגל שבלעדיו אין גלגל. <strong>ו'</strong> תתייחס לפנים מלשון פונה, של הגלגל שהם ארבעת הרוחות מעלה ומטה, שכל פינה מרחקה שוה מהנקודה האמצעית.
                אופן גימטריא קול עם הכולל, דהיינו קול 136 כולל התיבה כולה 1 = 136+1 = 137 = אופן.
                מכאן נבין משמעות הביטוי השגור בברכות קריאת שמע והאופנים וכו' ברעש גדול, רעש דהיינו קול.
            </div>
            <p></p>
            <h5 className="paragrph" dir="rtl">שם ש-ד-י שאמר לעולמו די מראה יחס הקוטר להיקף בעולם הבריאה והוא היחס הנקרא "פאי"</h5>
            <div className="chapter-p" dir="rtl">
                מעלות העולמות הפרטיים מתוארים במספרים עשיה אחדות, יצירה עשרות, בריאה מאות.
                ועל כן כשנאמר שבעולם הבריאה שמעלתו מאה אמר די, נדע שיחס קוטר להיקף של 100 במספרים שלמים ללא שברים הוא 314 גימטריא ש-ד-י.
                והרי ידוע כי שם ש-ד-י הוא סודו של שרו של עולם ששמו כשם רבו דהיינו מטטרו"ן המנהיג את הגלגלים על פי ציווי קונו.
            </div>
            <p></p>
            <h5 className="paragrph">הגלגל והאופן יעידו כי תכונת יחס קוטר להיקף הנקרא "פאי" נגזר משם ש-ד-י</h5>
            <div className="chapter-p" dir="rtl">
                גלגל אם נחשב מילוי אותיותיו גימל = 83 למד = 74 גימל = 83 למד = 74 = 83+74+83+74 = 314 = ש-ד-י = מטטרו"ן.
                וכן אופן אלף = 111 וו = 12 פה = 85 נון = 106 = 111+12+85+106 = 314 = ש-ד-י = מטטרו"ן.
            </div>
            <p></p>
            <h5 className="paragrph" dir="rtl">כ"ב אותיות הכוכב ויחס הקוטר להיקף מורים כי מציאותם משם י-ה-ו-ה</h5>
            <div className="chapter-p" dir="rtl">
                שם י-ה-ו-ה גימטריא 26 ניקח אותיות הקדמיות י' יהיה ט', ה' יהיה ד', ו' יהיה ה', ה' יהיה ד'
                יצא <strong>טדהד</strong> גימטריא 22 שהם מספר אותיות אב, נחבר 26 ל 22 יחדיו יצא 48 גימטריא כוכב.
                מכלל הכוכבים שבעה הם כוכבי לכת א. שבתאי ב. צדק ג. מאדים ד. חמה ה. נגה ו. כוכב ז. לבנה, שהם כנגד אותיות <strong>בגדכפרת</strong>, שהם כנגד שלושת האבות וארבעת האימהות.
                ועוד הרבה סוגי שביעיות. נחלק 22 ב 7 יצא לנו 3.14 שהוא יחס הקוטר להיקף בדיוק של מאיות (כפי הטעם ב שם ש-ד-י ). יוצא שהכל משם י-ה-ו-ה מתהווה.
            </div>
            <p></p>
            <h5 className="paragrph" dir="rtl">הדיוק הוא לפי הצורך</h5>
            <div className="chapter-p" dir="rtl">
                בכתובים ובדברי חכמינו אין מדייקים יותר מן הצורך לדוגמא הים של שלמה מלכים א' כ"ג עשר באמה משפתו עד שפתו וקוה (קרי) (כתיב וקו ) שלושים,
                אם נחלק כתיב קו בקרי קוה נכפיל בשלושים ונחלק ב 10 כך: 111/106*30/10 = 3.1415 יוצא שארבעת המספרים אחר הנקודה הם זהים לפאי,
                זאת אומרת דיוק של אחת חלקי חמשת אלפים באמה.
                וכן עדות גמורה בדברי חז"ל ידוע להם דיוק של 776 ספרות אחר הנקודה כמניין ועל פי סוד "נקודה אמצעית" גימטריא 776.
                וכך הוא החשבון, תסכום את 776 מספרים שאחרי נקודה כמניין "נקודה אמצעית" יצא סכומם 3477,
                תסכום 611 מספרים אחרי הנקודה כמניין "אמצעית" יצא סכומם 2701,
                בצע פעולת חיסור 3477 – 2701 = 776 יצא מספר 776 שהוא גימטריא "נקודה אמצעית", והוא מופת שאין להכזיבו ובמקום אחר יתבאר.
            </div>
            <p></p>
            <h5 className="paragrph" dir="rtl">טעם ראשון חלוקת הגלגל ל 360 מעלות</h5>
            <div className="chapter-p" dir="rtl">
                ע"פ פירוש הגר"א לספר היצירה פרק ד' משנה ח' כי כל כוכב מהשבעה שהם כנגד "<strong>בגד כפרת</strong>" מצורף מששת הכוכבים הנותרים לכן צירוף של ששה יהיה 1*2*3*4*5*6 = 720.
                והיות ותנועת הכוכבים היא פעם לקדים ופעם לאחור לכן נחלק 720 לשנים שהם 360 מעלות כנגד כל כיוון בתנועת הגלגל.
            </div>
            <p></p>
            <h5 className="paragrph" dir="rtl">טעם חלוקת הגלגל ל 360 מעלות הוא היות 360 קבוע המאפיין את המימד התשעי שהוא כנגד ספירת היסוד</h5>
            <p className="chapter-p" dir="rtl">
                קבועי הממדים משמעותם לארבע ממדים כנגד ארבעת אותיות של השם י-ה-ו-ה,
                או לעשרה ממדים כפי הספירות הכלולות בארבע אותיותיו חכמה כנגד י', בינה כנגד ה', דעת אין כנגדה אות, ששת המידות מחסד עד יסוד כנגד ו', ומלכות כנגד ה' אחרונה של השם.
            </p>
            <p className="chapter-p" dir="rtl">
                חשבון קבוע המימד כך הוא, ניקח את מספר המימד הקודם ונכפול אותו בסדרה החשבונית של המימד הנוכחי.
                מימד ראשון יהיה 0 * 1 = 0 והוא כנגד אות י', מימד שני 1 * (1+2) = 3, שהם כנגד שלושת הצירים של המרחב,
                וכו' והוא כנגד אות ה', מימד שלישי 2 * (1+2+3) = 12 שהם 12 גבולי אלכסון, 12 הקוים בין ששת פאות הקובייה וכו' והוא כנגד אות ו',
                מימד רביעי 3 * (1+2+3+4) = 30 שהם 30 כלים דמלכות אצילות הידועים ליודעי חן, והוא כנגד אות ה'.
                אגב אורחא אם נחבר את ארבעת הקבועים 0 +3 + 12 + 30 = 45 שהם גימטריא אדם שהם גימטריא מה שזהו שם י-ה-ו-ה במילוי א' כזה יו"ד ה"א וא"ו ה"א = 45.
            </p>
            <p className="chapter-p" dir="rtl">
                עכשיו כשנבוא למזמור קל"ו (גימטריא קול ) פסוק ט' שהוא כנגד ספירת יסוד המדבר על ירח וכוכבים,
                הנה קבוע התשיעי חשבונו 8 * (1+2+3+4+5+6+7+8+9) = 8 * 45 = 360 שהם 360 מעלות שלפיהם אנו קובעים מקום הכוכב בגלגל.
                וכן אנו רואים בפסוק (ד"ה א' כ"ט י"א) לך ה' הגדולה והגבורה וכו' כשמגיע לספירת יסוד אינו קורא אותה בשמה או בכינוי הידוע לה אלא אומר <strong>כ</strong>י <strong>כ</strong>ל <strong>ב</strong>שמים <strong>ו</strong>בארץ ראשי תיבות אותיות <strong>כוכב</strong> לומר לנו כי ספירת יסוד ענינה כוכבים.
            </p>
            <p></p>
            <h5 className="paragrph" dir="rtl">המימד העשירי הוא מילוי שם ש-ד-י והוא עוביו של הרקיע</h5>
            <div className="chapter-p" dir="rtl">
                הנה המימד העשירי הוא כנגד אות ה' אחרונה של השם.
                וחשבונו 9 * (1+2+3+4+5+6+7+8+9+10) = 9 * 55 = 495 = דמות אדם = תצ"ה,
                תצ"ה עם הכולל = 496 = <strong>מלכות</strong>,
                שהיא כנגד אות ה' האחרונה של שם ה',
                ולכן טעמו של בעל הרוקח שמבאר בסודי רזיי אות ו' בעניין דומה כי יש להוסיף ל תצ"ה ה' של השם יצא חשבונו ת"ק = 500,
                ובביאור בעל הרוקח בספר היצירה שם ש-ד-י כי מלויו ש<strong>י"ן</strong>  ד<strong>ל"ת</strong> י<strong>ו"ד</strong> 60 + 430 + 10 = 500 הוא עוביו של הרקיע ת"ק שנה.
                ובא בד בבד עם ביאור בעל גינת אגוז כי הגלגל נולד מאמיתת העשירי, ומתנועע מכח העשירי שהוא שד"י.
            </div>
            <p></p>



            <h5 className="paragrph" dir="rtl">השימוש בכולל מגלה בפרטים את הכלל שממנו הפרטים</h5>
            <div className="chapter-p" dir="rtl">
                כדי להבין ענין זה נתבונן תחילה בקושיית הגר"א בפירושו על ספר היצירה, מדוע שם ה' מינינו 26,
                הרי מן הראוי שיהיה מנינו 27 שהם כ"ב אותיות הא"ב וחמשת אותיות הסופיות.
                ותירץ היות ושם ה' מורה על ההשתלשלות י' צמצום, ה' התרחבות, ו' המשכה, ה' אחרונה התרחבות, וחוזר חלילה. הרי ההשתלשלות התחילה מ ב' של <strong style={{ fontSize: 24 }}>ב</strong><strong>ראשית</strong>,
                ו א' למעלה מההשתלשלות לכן אנו מונים מ ב' עד ץ' (א' ב' ... ת' ך' ם' נ' ף' ץ') שהם 26 אותיות.
            </div>
            <p></p>
            <p className="chapter-p" dir="rtl">
                עכשיו לאחר תירוצו של הגר"א נתבונן בכתר שהוא הדרגא שלמעלה מההשתלשלות הכוללת את ההשתלשלות ומה שלמעלה מן ההשתלשלות.
                הנה כ-ת-ר <strong>כ"ף תי"ו רי"ש</strong> = 100 + 416 + 510 = 1026 = 1000 + 26, הנה יחזור אֶלף (בסגול) לאָלף (בקמץ).
                יוצא 26 + 1 = 27 שהם כ"ז אותיות הא"ב הכוללות את הסופיות, וכן יש לציין כי צורת אות <strong>א'</strong> רומזת על מנין שם ה' כך:
                י' עלינה ו' באמצע, י' למטה = 10 + 6 + 10 = 26 = כ"ו. מכאן ראינו כי הכתר בהחזרת אֶלף ל א' שהוא שם ה' עם הכולל,
                דהיינו 26 + 1 = 27 וכן כולל את שם ה' בכלל ופרט, <strong>בפרט כ"ו</strong>, <strong>בכלל א'</strong>, כפי ש <strong>א'</strong> שרומזת על שם ה' בצורתה.
            </p>
            <p></p>
            <h5 className="paragrph" dir="rtl">מקיף הגלגל והכדור הם מהאותיות היוצאות מדבר י-ה-ו-ה</h5>
            <p className="chapter-p" dir="rtl">
                הנה, מקיף = כדור = 230. עם הכולל יצא 231 שהם רל"א.
            </p>
            <p className="chapter-p" dir="rtl">
                עם נחבר את האותיות בזוגות כך שכל אות תצטרף עם כל שאר האותיות יצא לנו 231 צירופים שהם כל צירופי זוגות האותיות.
                והם נקראים <strong>רל"א</strong> שערים. יוצא שהאותיות הם מקיפי הכדור והגלגל.
            </p>
            <p className="chapter-p" dir="rtl">
                רל"א עם הכולל יצא <strong>רל"ב</strong> שהוא 232 עולה גימטריא <strong>דבר י-ה-ו-ה</strong> = 206 + 26 = 232, לומר לנו כי כל העיגולים המקיפים והגלגלים מדיבור הקב"ה הם באים.
                וכן כ"ו שהם "עשרים וששה" יוצא 70 + 300 + 200 + 10 + 40 + 6 + 300 + 300 + 5 = 1231 שהם אֶלף + רל"א, אֶלף יחזור ל א' ויהיה רל"א + א' = 231 + 1 = 232.
                יוצא כי שם ה' שהוא כ"ו הוא הוא <strong>דבר י-ה-ו-ה</strong> יוצר הגלגל.
            </p>
            <h5 className="paragrph" dir="rtl">קבוע המימד התשעי ועוד קול הוא המימד העשירי שהוא המלכות שעניינה פה , שנאמר "באשר דבר מלך שלטון"</h5>
            <p className="chapter-p" dir="rtl">
                הנה המימד העשירי חשבונו 9 * (1+2+3+4+5+6+7+8+9+10) = 9 * 55 = 495 = תצ"ה.
            </p>
            <p className="chapter-p" dir="rtl">
                תצ"ה הוא מנין כל האותיות מ א' עד צ', אם נוסיף אחד יכלול כל האותיות היות ו ק' + ר' + ש' + ת = 100 + 200 + 300 +400 = 1000, <strong>אֶלף יחזור ל אָלף שהוא 1</strong>,
                כך ש 495 + 1 = 496 הכולל כל האותיות והוא גימטריא <strong>מלכות</strong> = 40 + 30 + 20 + 6 + 400 = <strong>496</strong>.
            </p>
            <p className="chapter-p" dir="rtl">
                הנה המימד התשיעי שהוא מעלות תנועת הגלגלים שהם 360 כחשבון 8 * (1+2+3+4+5+6+7+8+9) = 8 * 45 = 360.
            </p>
            <p className="chapter-p" dir="rtl">
                הנה עם נוסיף למימד התשיעי המיצג את מידת תנועת הגלגלים 136 שהוא גימטריא קול נקבל
            </p>
            <p className="chapter-p" dir="rtl">
                <strong>360 + 136 = 496 = מלכות</strong>,
            </p>
            <p className="chapter-p" dir="rtl">
                שהיא מייצגת את הפה שממנו יוצא הדיבור, שהוא מורכב מקול הבא מתנועת הגלגלים, ומחיתוך אותיות כפי שראינו שמלכות מייצגת את כל אותיות הא"ב.
            </p>
            <p></p>




            <h5 className="paragrph" dir="rtl">תתר"ף 1080 חלקים בהם מחולקת השעה שורשם בספירת הבינה</h5>
            <div className="chapter-p" dir="rtl">
                הנה על בני יששכר נאמר (דברי הימים א' י"ב ל"ג) ידעי בינה לעתים הנה עתים ע = 70, ת = 400, י = 10 ם = 600, 70+400+10+600 = 1080 = תתר"ף חלקים.
            </div>
            <p></p>
            <h5 className="paragrph" dir="rtl">שם ה' שהוא קיום חלקי הגלגל ותנועתו משים אותו טבע שהוא סוד תתר"ף 1080 חלקי השעה </h5>
            <div className="chapter-p" dir="rtl">
                ביאור זה מיוסד על יסודות המבוארים בספר גינת אגוז בהרחבה וכאן יובאו מסקנותיו לביאור עניינינו בקצרה.
            </div>
            <p className="chapter-p" dir="rtl">
                קיום הגלגל ותנועתו הם משם ה', חציו הראשון של שם י-ה מקיים הגלגל על חלקיו, חציו השני ו-ה מקיים תנועתו. יוצא מכך:
            </p>
            <p className="chapter-p" dir="rtl">

            </p>
            <p className="chapter-p" dir="rtl">
                חלקי הגלגל: י-ה + גלגל = 15  + 66 = 81 = טבע.
            </p>
            <p className="chapter-p" dir="rtl">
                תנועת הגלגל: ו-ה + סובב = 11 + 70 = 81 = טבע.
            </p>
            <p className="chapter-p" dir="rtl">
                טבע = 81 = אף = א' חוזר ל אלף = 1000, ף נשאר 80 יוצא 1080 שהם תתר"ף חלקי השעה.
            </p>
            <p className="chapter-p" dir="rtl">
                טבע גימטריא כסא והוא עולם הבריאה הנקרא כסא ששם מקננת ספירת הבינה, מכאן שכל המידות מספירת בינה נגזרות, מאמיתת שמו ית'.
            </p>
            <h5 className="paragrph" dir="rtl">שנים עשר המזלות הם כנגד שנים עשר צירופי שם י-ה-ו-ה שהוא קבוע מימד השלישי</h5>
            <div className="chapter-p" dir="rtl">
                קבוע המימד השלישי באותיות שם ה' הוא כנגד אות ו', שהוא כנגד ששת הקצוות,
                שהם ארבעת הרוחות ומעלה ומטה, וחשבונו 2 * (1+2+3) = 2 * 6 = 12 והוא כנגד שנים עשר גבולי אלכסון,
                שהם כנגד שנים עשר צירופי שם ה' שהם כנגד שנים עשר המזלות וכו'
            </div>
            <div className="text-center">
                <img src="/chpters/chapter11/שנים-עשר-גבולי-אלכסון.png"
                    className="img-figure"
                    title="שנים עשר גבולי אלכסון"
                    alt="שנים עשר גבולי אלכסון" />
            </div>
            <p></p>
            <h2 className="paragrph" dir="rtl">שבעת הכוכבים</h2>
            <h5 className="paragrph" dir="rtl">והוא ביאור עניינם של עשרת קבועי הממדים ויחסם לספירות ולשמות ה' העומדים כנגדם</h5>
            <p className="chapter-p" dir="rtl">
                באור עשרת קבועי הממדים מקבילים לעשרת הפסוקים הראשונים ממזמור קל"ו תהלים, והם כנגד עשרת הספירות.
            </p>
            <p className="chapter-p" dir="rtl">
                <strong>מימד ראשון</strong> קבוע מספרו הוא  0 * 1 = 0, היות והחכמה מאין תמצא, והיא נקודת הגלגל האמצעית.
            </p>
            <p className="chapter-p" dir="rtl">
                <strong>מימד שני</strong> קבוע מספרו הוא 1 * (1+2) = 3, והוא כנגד שלושת צירי הסיבוב, והוא כנגד הבינה.
            </p>
            <p className="chapter-p" dir="rtl">
                <strong>מימד שלישי</strong> קבוע מספרו הוא 2 * (1+2+3) = 12, והוא כנגד 12 גבולי אלכסון אשר במרחב התלת ממדי כמתואר בציור למעלה, והוא כנגד הדעת.
            </p>
            <h3 className="paragrph" dir="rtl">שבעת המדות</h3>
            <p className="chapter-p" dir="rtl">
                בשבעת קבועי הממדים של המדות נראה כי קבוע הממד יתאר <strong>כוונת המידה ורצונה</strong> שתרצה להוציא לפועל. ואילו הקבוע בתוספת א' דהיינו 1 יתאר את <strong>מהות</strong> פעולתה.
                ובדרך משל נוכל לומר בעניין הכוכבים, כי קבוע המימד יתאר את הילוך הכוכב אחורה במסלולו ( רטרו בלעז ), ואילו קבוע המימד בתוספת א' יתאר את הילוך הכוכב בכיוון מסלולו.
            </p>
            <p className="chapter-p" dir="rtl">
                <strong>מימד רביעי</strong>, והוא ראשון מן המידות, והוא כנגד ספירת חסד, מספר קבוע המימד 3 * (1+2+3+4) = 30,
                והוא כנגד 30 שמות וכינויים הכוללים את שלושת הקוים חסד דין ורחמים (שערי אורה שער חמישי לרי"ג'),
                בהם נכללים ששת המידות של ז"א ( פנים קטנות ) ונקראים גם <strong>למ"ד</strong> כלים של זו"ן (זכר ונקבה), שחסד תחילתם והוא כולל אותם.
                והרי חסד הוא השפעה ואם ישפיעה יתר על המידה לא יוכלו המקבלים לקבל השפעתו, לכן מספר קבוע המימד הוא כנגד למד כלים למדוד השפעתו לפי ערך המקבלים.
                אמנם עם נוסיף א' על למד נקבל 31 = <strong>א-ל</strong>, שהיא מהות פעולת החסד, והוא השם השייך למדת החסד, כפי שנאמר "<strong>חסד א-ל</strong> כל היום" (תהילים נ"ב ג' ).
            </p>
            <p className="chapter-p" dir="rtl">
                <strong>מימד חמישי</strong>, והוא שני מן המידות, והוא כנגד ספירת הגבורה, מספר קבוע המימד 4 * (1+2+3+4+5) = 60,
                הנה 60 מראה על גילוי ששת המידות כפי שהם כלולות כל אחת מעשר, אך היות והגילוי גדול מהכיל,
                לכן בהוספת א' + ס', א' המרמזת על 26 בצורתה י' עליון = 10, ו' באמצע = 6, י' תחתון = 10, יוצא 26. ס = 60, א =&gt; 26 יוצא חשבון צירוף שניהם 26 + 60 = 86 = <strong>א-ל-הים</strong>,
                שהוא שם מדת ה<strong>גבורה</strong> וטבעו לצמצם ולהסתיר, כמובא בפסוק (תהילים פ"ד י"ב ) "שמש ומגן י-ה-ו-ה א-ל-הים",
                פירוש הפסוק, שמש (גילוי) <strong>מגן (הסתר)</strong>, כן השמות י-ה-ו-ה (גילוי) <strong>א-ל-הים (הסתר)</strong>.
                יוצא מכך כשאנו מוסיפים על הקבוע מימד החמישי שהוא ס, אות א' יוצא ס"א הרומז לשם א-ל-קים כאשר ביארנו,
                אשר תכונתו שהוא <strong>מצמצם ומסתיר</strong> מירב האור כך שלא יתבטל המקבל ממירב שפע האור, ויקבל המקבל השפע כפי שיכול להכיל.
            </p>
            <p className="chapter-p" dir="rtl">
                <strong>מימד הששי</strong>, והא שלישי מן המידות, והוא כנגד ספירת התפארת, מספר קבוע המימד 5 * (1+2+3+4+5+6) = 105,
                וענינו , המשכת <strong>נ' שערי בינה</strong> דרך תפארת (שערי אורה שער חמישי) שעניינה אות <strong>ו'</strong> לספירת יסוד אשר שמה "<strong>א-ל חי</strong>",
                שהרי אחד מכינויי <strong>הבינה</strong> <strong>חיים</strong>, וכן נקראת א-להים אף שנכתבת י-ה-ו-ה, בינה עם הכולל גימטריא <strong>חיים</strong>,
                לכן נקראת ספירת יסוד על שם שתי האותיות ראשונות מכל אחד מהכינויים, <strong>א-ל</strong>הים <strong>חי</strong>ים "<strong>א-ל חי</strong>",
                לומר לך כי היסוד הוא כלי מוכן לקבלת ההשפעה מבינה, לכן חשבון ההמשכת השפע יוצא כך,
                חמישים שערי בינה = 50, ו' תפארת = 6, "א-ל חי" = 49 = 50 + 6 + 49 = 105,
                ולפעמים תקרא ספירת יסוד "<strong>כל</strong>" על שם המשכת שפע חמישים שערי בינה אליה בפועל בסוד <strong>נו"ן</strong> = 50 + 6 + 50 = 106 = <strong>קו</strong>,
                שהוא על שם אחד הכינויים של ספירת תפארת הנקראת "<strong>קו</strong> האמצעי", שהוא תיאור אופן מעבר השפע <strong>מבינה וחמישים שעריה</strong>,
                דרך <strong>ו' של תפארת</strong>, אל <strong>יסוד הנקראת כל</strong> = 50. אשר אופן כתיבת אות <strong>נו"ן</strong> מתאר את אופן מעבר השפע.
            </p>
            <p className="chapter-p" dir="rtl">
                <strong>מימד השביעי</strong>, והוא רביעי מן המידות, והוא כנגד ספירת <strong>נצח</strong>, מספר קבוע המימד 6 * (1+2+3+4+5+6+7) = 168,
                שהם מספר שעות השבוע, המבטא את עיינין הזמן, שהוא אחד משלושת הפירושים שהשם <strong>צבא</strong> סובל,
                והוא נגזר מהשם הקדוש השייך לספירת הנצח, והוא "י-ה-ו-ה <strong>צבא</strong>-ות", שנאמר "צבא לאנוש עלי ארץ" (איוב ז' א') <strong>צבא</strong> פירושו <strong>זמן קצוב</strong>.
                והנה בהוספת א' נקבל את התכלית הרצוי כלשון הפסוק השביעי במזמור "<strong>לעשה אורים גדולים</strong>",
                שזה היה לפני הקטרוג הלבנה "אי אפשר לשני מלכים להשתמש בכתר <strong>אחד</strong>" דהיינו שמאור אחד ישלוט על י"ב מזלות שהוא חשבון 1 + 12 = 13 = <strong>אחד</strong>,
                דבר שכן אפשרי על ידי התכללות, שכל אחד כלול מהשני, שמשמעותו החשבונית היא כפל אחד בשני. ולעניינו 13 * 13 = 169,
                שהוא חשבון המימד השביעי בתוספת א' = 168 + 1 = 169, וכאן יבוא פירוש נוסף ל<strong>צבא</strong> והוא לשון <strong>צביון ויופי</strong> בהתכללות אחד מהשני.
                ובמשך השבוע הוא השעה האחרונה של השבוע, זמן סעודה שלישית, שהרי ערב שבת מכנים את השבת בתפלה "<strong>בה</strong>",
                ביום השבת "<strong>בו</strong>", ובמנחת שבת אומרים "<strong>בם</strong>", המכוון כנגד התכללות הרצונות,
                והרי עת רצון הוא זמן התגלות <strong>י"ג</strong> מידות הרחמים, והנה מנחת שבת נקראת <strong>רעוא דרעוין</strong> פירוש <strong>רצון הרצונות</strong> שהוא התכללותם,
                וחשבונו <strong>י"ג</strong> פעמים <strong>י"ג</strong> = <strong>13 * 13</strong> = 169, שהם משך שעות השבוע כפי שמתכללים באחד, שהוא רומז על גלוי שם י-ה-ו-ה,  כ"ו = י"ג + י"ג = 13 + 13 = 26.
            </p>
            <p className="chapter-p" dir="rtl">
                <strong>מימד השמיני</strong>, והוא חמישי מן המידות, והוא כנגד ספירת <strong>הוד</strong>, מספר קבוע המימד 7 * (1+2+3+4+5+6+7+8) = 252,
                ויוצא חשבונה פעמים סוס = סוס 126, 2 * 126 = 252, והיא מרכבת הסוסים כנגד אותיות הא"ב,
                המעלה את בקשתינו והודאתנו למעלה, וממשיכה השפע למטה,
                כפי שכל זה מבואר באריכות במאמרי "וסוס אשר רכב עליו המלך" ממאמרי האדמו"ר הזקן בליקוטי תורה, ועל ידי בנו האדמו"ר האמצעי בשערי אורה,
                המבוסס על הפסוק, "כי תרכב על סוסיך מרכבתיך ישועה" (חבקוק ג' ח'),
                הנה אם נוסיף א' על 252 נקבל 253 שהוא סכום חיבור האותיות ה א"ב על פי סדרם כך: (3+2+1 + .... + 22+21+20) = 253.
                דבר המראה כי האותיות הא"ב הם מעלי הבקשה וממשיכי השפע. והנה אותיות הם מלשון אתא בוקר,
                כי הרעיון מאיר ע"י צירוף האותיות לתיבות ומשפטים, שהוא ענין ממשלת השמש ביום להאיר כל החפצים, לכן הפסוק השמיני במזמור הוא "את השמש לממשלת ביום".
            </p>
            <p className="chapter-p" dir="rtl">
                <strong>המימד התשיעי</strong>, והא שישי במידות, והוא כנגד ספירת <strong>יסוד</strong>, מספר קבוע המימד 8 * (1+2+3+4+5+6+7+8+9) = 360,
                והוא כנגד חלוקת גלגלי שבעת הכוכבים ל 360 מעלות, ובהם אנו משתמשים לקביעת מקומם.
                אם נוסיף א' על 360 נקבל 361 שהוא תנועת הגלגל התשיעי 361 מעלות בכל יום (בקירוב) ממזרח למערב,
                שהוא הפוך מעיקר תנועת שבעת הכוכבים, אשר תנועתם ממערב למזרח. והשמש נעה קרוב למעלה אחת בכל יום ממערב למזרח,
                לכן יראה לנו כי היא נעה בכיוון הגלגל התשיעי 360 בכל יום, לכן נקרא שמו הגלגל היומי בדרך השאלה,
                כי הוא חיבור תנועת הגלגל התשיעי פחות תנועת השמש הזזה מעט בכיוון הפוך.
                והנה <strong>361</strong> הוא <strong>י"ט</strong> פעמים <strong>י"ט</strong>, שהרי <strong>י"ט</strong> שנה הוא זמנו של מחזור קטן, הבא להשוות בין שנות הלבנה לשנות החמה,
                לכן גם בפרק <strong>ט'</strong>, וגם בפרק <strong>י'</strong> בהלכות קידוש החודש דיבר הרמב"ם, על שני אופנים שעל פי הם נקבע כל אחד ממחזורי <strong>הי"ט</strong> שנים,
                ובהתכללות שניהם אפשר באמת להשוות שנות החמה לשנות הלבנה עד שנגיע ליעוד "והיה אור הלבנה כאור החמה וגו'".
            </p>
            <p className="chapter-p" dir="rtl">
                <strong>המימד העשירי</strong>, והוא השביעי במידות, והוא כנגד ספירת <strong>מלכות</strong>, מספר קבוע המימד 9 * (1+2+3+4+5+6+7+8+9+10) = תצ"ה 495,
                והוא צירוף חשבון האותיות מ א' עד צ', וכשנצרף את האותיות מ ק' עד ת' יצא חשבונן אֶלֶף אשר יחזור לאָלֶף שהוא אחד,
                נוסיף את ה<strong>אחד</strong> ל<strong>תצ"ה</strong> יצא <strong>495 + 1 = 496 = מלכות</strong>. הנה מלכות ענינה פה ודיבור שהם כל אותיות הא"ב הבאים ממידת ה<strong>הוד</strong> שעניינה אותיות כפי שביארנו במימד השמיני,
                והנה מימד התשיעי מספר קבוע המימד הוא <strong>360</strong>, ואם נוסיף עליו <strong>קול = 136</strong> יצא <strong>360 + 136 = 496 = מלכות</strong>,
                והנה קול זה בא ממידת ה<strong>נצח</strong>, אשר מספר קבוע המימד ועוד הכולל הוא <strong>169</strong> והוא חשבון <strong>גל קול</strong>.
            </p>
            <p className="chapter-p" dir="rtl">
                הנה ראינו כי הקול ואותיות הדיבור באו למידת המלכות מנצח והוד אשר השם המשותף להם הוא <strong>צבא-ות</strong> = 499 ויחד עם הכולל יהיה חשבונו 500 = <strong>ת"ק</strong>,
                ובביאור בעל הרוקח בספר היצירה שם <strong>ש-ד-י</strong> כי מלויו ש<strong>י"ן</strong>  ד<strong>ל"ת</strong> י<strong>ו"ד</strong> 60 + 430 + 10 = 500 הוא עוביו של הרקיע <strong>ת"ק</strong> שנה.
                ובא בד בבד עם ביאור בעל גינת אגוז כי הגלגל נולד מאמיתת העשירי, ומתנועע מכח העשירי שהוא <strong>שד"י</strong>.
            </p>
            <p className="chapter-p" dir="rtl">

            </p>
            <p className="chapter-p" dir="rtl">

            </p>
            <p className="chapter-p" dir="rtl">

            </p>

            <div className="chapter-p" dir="rtl">

            </div>
            <p></p>
            <h5 className="paragrph" dir="rtl"></h5>
            <div className="chapter-p" dir="rtl">

            </div>
            <h5 className="paragrph" dir="rtl"></h5>
            <div className="chapter-p" dir="rtl">

            </div>
            <p></p>            
            <p className="text-center">-----&lt;&gt;-----</p>
        </Container>

    );

};


export default Chapter11OpenContent;