import React, { useEffect, useRef, useContext, useState } from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";

import { AnalyticsContext } from "../../context/AnalyticsContext";
import DualPaneManager from "../DualPaneManager";
import { MetaTags } from "react-meta-tags";
import CreateOrVerifyEmail from "../../components/CreateOrVerifyEmail";
import ContactAndOrder from "../../components/ContactAndOrder";
import { Context as Authcontext } from "../../context/Authcontext";
import { ORDER_MESSAGE } from "../../apis/commonConsts";
import Chapter14content from "../../components/chaptersContents/Chapter14content";
import EmzaYareachShaatReiya, { AUTO_RUN_EMZAYAREACH_REIYA, RUN_FROM_OUT_EMZAYAREACH_REIYA } from "../../components/3d/EmzaYareachShaatReiya";
import EmzaYareach, { AUTO_RUN_EMZA_MASLUL_YAREACH, AUTO_RUN_EMZA_YAREACH, MAHALACH_EMZA_MASLUL_YAREACH, MAHALACH_EMZA_YAREACH } from "../../components/3d/EmzaYareach";
import { emzaMaslulYareachSpeed, emzaYareachSpeed, ikarEmzaMaslulYareach, ikarEmzaYareach } from "../../apis/orbits/orbitsGeneral";

export const VIEW_C14_STATE_OPEN = 0;

export const VIEW_C14_STATE_1 = 1;
export const VIEW_C14_STATE_2 = 2;

export const VIEW_C14_STATE_3 = 3;
export const VIEW_C14_STATE_4 = 4;
export const VIEW_C14_STATE_5 = 5;
export const VIEW_C14_STATE_6 = 6;
export const VIEW_C14_STATE_7 = 7;
export const VIEW_C14_STATE_8 = 8;
export const VIEW_C14_STATE_9 = 9;

export const VIEW_C14_STATE_10 = 10;
export const VIEW_C14_STATE_11 = 11;
export const VIEW_C14_STATE_12 = 12;
export const VIEW_C14_STATE_13 = 13;
export const VIEW_C14_STATE_14 = 14;
export const VIEW_C14_STATE_15 = 15;
export const VIEW_C14_STATE_16 = 16;

export const VIEW_C14_STATE_17 = 17;
export const VIEW_C14_STATE_18 = 18;


export const VIEW_C14_STATE_40 = 40;
export const VIEW_C14_STATE_41 = 41;
export const VIEW_C14_STATE_42 = 42;
export const VIEW_C14_STATE_43 = 43;
export const VIEW_C14_STATE_44 = 44;
export const VIEW_C14_STATE_45 = 45;
export const VIEW_C14_STATE_46 = 46;
export const VIEW_C14_STATE_47 = 47;

const Chapter14 = ({ history }) => {

    const [viewState, setViewState] = useState(VIEW_C14_STATE_OPEN);
    const { state } = useContext(Authcontext);
    const { height, width, graphicWidth, paneHeight } = useWindowDimensions();
    const { analytics } = useContext(AnalyticsContext);
    const signinOrVerifyRef = useRef(null);
    const orderRef = useRef(null);

    const emzaYareachShaatReiyaRef = useRef();
    const emzaYareachRef = useRef();

    useEffect(() => {
        analytics();
    }, []);

    const get3D = () => {
        switch (viewState) {
            case VIEW_C14_STATE_OPEN:
            case VIEW_C14_STATE_40:
            case VIEW_C14_STATE_41:
            case VIEW_C14_STATE_42:
            case VIEW_C14_STATE_43:
            case VIEW_C14_STATE_44:
            case VIEW_C14_STATE_45:
            case VIEW_C14_STATE_46:
            case VIEW_C14_STATE_47:

                return <EmzaYareachShaatReiya ref={emzaYareachShaatReiyaRef} />;

            default:
                return <EmzaYareach ref={emzaYareachRef} />;
        }

    };

    const setState = () => {
        switch (viewState) {
            default:
                break;
            case VIEW_C14_STATE_OPEN:
                emzaYareachShaatReiyaRef.current.setRunState(AUTO_RUN_EMZAYAREACH_REIYA);
                break;
            case VIEW_C14_STATE_1:
                emzaYareachRef.current.setRunState(AUTO_RUN_EMZA_MASLUL_YAREACH
                    | MAHALACH_EMZA_MASLUL_YAREACH);
                break;
            case VIEW_C14_STATE_2:
                emzaYareachRef.current.setRunState(AUTO_RUN_EMZA_MASLUL_YAREACH |
                    AUTO_RUN_EMZA_YAREACH | MAHALACH_EMZA_YAREACH
                    | MAHALACH_EMZA_MASLUL_YAREACH);
                break;
            case VIEW_C14_STATE_3:
                if (signinOrVerifyRef.current.showIfNeed()) {
                    break;
                }
                emzaYareachRef.current.setRunState(MAHALACH_EMZA_YAREACH);
                emzaYareachRef.current.setEmzaYareach(emzaYareachSpeed.degreesCount);
                break;
            case VIEW_C14_STATE_4:
                if (signinOrVerifyRef.current.showIfNeed()) {
                    break;
                }
                emzaYareachRef.current.setRunState(MAHALACH_EMZA_YAREACH);
                emzaYareachRef.current.setEmzaYareach(emzaYareachSpeed.degreesCount * 10);
                break;
            case VIEW_C14_STATE_5:
                if (signinOrVerifyRef.current.showIfNeed()) {
                    break;
                }
                emzaYareachRef.current.setRunState(MAHALACH_EMZA_YAREACH);
                emzaYareachRef.current.setEmzaYareach(emzaYareachSpeed.degreesCount * 100);
                break;
            case VIEW_C14_STATE_6:
                if (signinOrVerifyRef.current.showIfNeed()) {
                    break;
                }
                emzaYareachRef.current.setRunState(MAHALACH_EMZA_YAREACH);
                emzaYareachRef.current.setEmzaYareach(emzaYareachSpeed.degreesCount * 1000);
                break;
            case VIEW_C14_STATE_7:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                emzaYareachRef.current.setRunState(MAHALACH_EMZA_YAREACH);
                emzaYareachRef.current.setEmzaYareach(emzaYareachSpeed.degreesCount * 10000);
                break;
            case VIEW_C14_STATE_8:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                emzaYareachRef.current.setRunState(MAHALACH_EMZA_YAREACH);
                emzaYareachRef.current.setEmzaYareach(emzaYareachSpeed.degreesCount * 29);
                break;
            case VIEW_C14_STATE_9:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                emzaYareachRef.current.setRunState(MAHALACH_EMZA_YAREACH);
                emzaYareachRef.current.setEmzaYareach(emzaYareachSpeed.degreesCount * 354);
                break;
            case VIEW_C14_STATE_10:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                emzaYareachRef.current.setRunState(MAHALACH_EMZA_MASLUL_YAREACH);
                emzaYareachRef.current.setEmzaMaslulYareach(emzaMaslulYareachSpeed.degreesCount);
                break;
            case VIEW_C14_STATE_11:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                emzaYareachRef.current.setRunState(MAHALACH_EMZA_MASLUL_YAREACH);
                emzaYareachRef.current.setEmzaMaslulYareach(emzaMaslulYareachSpeed.degreesCount * 10);
                break;
            case VIEW_C14_STATE_12:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                emzaYareachRef.current.setRunState(MAHALACH_EMZA_MASLUL_YAREACH);
                emzaYareachRef.current.setEmzaMaslulYareach(emzaMaslulYareachSpeed.degreesCount * 100);
                break;
            case VIEW_C14_STATE_13:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                emzaYareachRef.current.setRunState(MAHALACH_EMZA_MASLUL_YAREACH);
                emzaYareachRef.current.setEmzaMaslulYareach(emzaMaslulYareachSpeed.degreesCount * 1000);
                break;
            case VIEW_C14_STATE_14:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                emzaYareachRef.current.setRunState(MAHALACH_EMZA_MASLUL_YAREACH);
                emzaYareachRef.current.setEmzaMaslulYareach(emzaMaslulYareachSpeed.degreesCount * 10000);
                break;
            case VIEW_C14_STATE_15:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                emzaYareachRef.current.setRunState(MAHALACH_EMZA_MASLUL_YAREACH);
                emzaYareachRef.current.setEmzaMaslulYareach(emzaMaslulYareachSpeed.degreesCount * 29);
                break;
            case VIEW_C14_STATE_16:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                emzaYareachRef.current.setRunState(MAHALACH_EMZA_MASLUL_YAREACH);
                emzaYareachRef.current.setEmzaMaslulYareach(emzaMaslulYareachSpeed.degreesCount * 354);
                break;
            case VIEW_C14_STATE_17:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                emzaYareachRef.current.setRunState(MAHALACH_EMZA_YAREACH);
                emzaYareachRef.current.setEmzaYareach(ikarEmzaYareach.degreesCount);
                break;
            case VIEW_C14_STATE_18:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                emzaYareachRef.current.setRunState(MAHALACH_EMZA_MASLUL_YAREACH);
                emzaYareachRef.current.setEmzaMaslulYareach(ikarEmzaMaslulYareach.degreesCount);
                break;
            case VIEW_C14_STATE_40:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                emzaYareachShaatReiyaRef.current.setRunState(RUN_FROM_OUT_EMZAYAREACH_REIYA);
                emzaYareachShaatReiyaRef.current.setMazal(0);
                break;
            case VIEW_C14_STATE_41:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                emzaYareachShaatReiyaRef.current.setRunState(RUN_FROM_OUT_EMZAYAREACH_REIYA);
                emzaYareachShaatReiyaRef.current.setMazal(30);
                break;
            case VIEW_C14_STATE_42:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                emzaYareachShaatReiyaRef.current.setRunState(RUN_FROM_OUT_EMZAYAREACH_REIYA);
                emzaYareachShaatReiyaRef.current.setMazal(90);
                break;
            case VIEW_C14_STATE_43:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                emzaYareachShaatReiyaRef.current.setRunState(RUN_FROM_OUT_EMZAYAREACH_REIYA);
                emzaYareachShaatReiyaRef.current.setMazal(150);
                break;
            case VIEW_C14_STATE_44:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                emzaYareachShaatReiyaRef.current.setRunState(RUN_FROM_OUT_EMZAYAREACH_REIYA);
                emzaYareachShaatReiyaRef.current.setMazal(180);
                break;
            case VIEW_C14_STATE_45:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                emzaYareachShaatReiyaRef.current.setRunState(RUN_FROM_OUT_EMZAYAREACH_REIYA);
                emzaYareachShaatReiyaRef.current.setMazal(210);
                break;
            case VIEW_C14_STATE_46:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                emzaYareachShaatReiyaRef.current.setRunState(RUN_FROM_OUT_EMZAYAREACH_REIYA);
                emzaYareachShaatReiyaRef.current.setMazal(270);
                break;
            case VIEW_C14_STATE_47:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                emzaYareachShaatReiyaRef.current.setRunState(RUN_FROM_OUT_EMZAYAREACH_REIYA);
                emzaYareachShaatReiyaRef.current.setMazal(330);
                break;
        }
    };

    useEffect(() => {
        setState();
        emzaYareachShaatReiyaRef.current?.setSise(paneHeight, graphicWidth);
        emzaYareachRef.current?.setSise(paneHeight, graphicWidth);
    }, [height, width, viewState]);

    return (
        <div>
            <MetaTags>
                <title>הלכות קידוש החודש לרמב"ם פרק יד</title>
                <meta name="description" content='בפרק יד הלכות קידוש החודש לרמב"ם יבואר מהלכי אמצע המסלול, אמצע הירח, ואמצע הירח לשעת ראיה.' />
            </MetaTags>
            <CreateOrVerifyEmail state={state} history={history} ref={signinOrVerifyRef} />
            <ContactAndOrder history={history} ref={orderRef} hidden={true} nessage={ORDER_MESSAGE} />
            <DualPaneManager
                graphicPane={get3D()}
                dataPane={<Chapter14content
                    setViewState={setViewState}
                />}
                height={height}
                width={width}
            />

        </div>
    );

};

export default Chapter14;