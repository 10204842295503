import { useState, useEffect } from 'react';
import firebase from "firebase";
import "firebase/auth";
import { getUserSnapshotByEmail } from "../apis/firebaseRealtimDb";


const useUserStatus = () => {
    const [user, setUser] = useState({ username: '', email: '', verified: false, useContent: false });

    useEffect(() => {

        firebase.auth().onAuthStateChanged(async (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                const e = user.email;
                // console.log(user);
                const snap = await getUserSnapshotByEmail(e);
                if (snap?.exists()) {
                    const v = snap.val();
                    // console.log(v);
                    setUser({ username: v.username, email: e, verified: v.verified, useContent: v.useContent });
                    // console.log(user);
                }

                // console.log(user);
                // ...
            } else {
                // User is signed out
                // ...
                setUser({ username: '', email: '', verified: false, useContent: false });
            }
        });

    }, []);

    return user;

};

export default useUserStatus;