import React from "react";
import { Container, Row } from "react-bootstrap";

import "./chaptersStyle.css"


const Chapter11content = ({ setViewState, useContent, params }) => {

    return (
        <Container className="chapter-container">
            <Row>
                <h4 className="chpter-header">פרק יא</h4>
                <h6 className="paragrph">אבני היסוד לחישוב קיצי הראיה</h6>
                <p style={{ textAlign: 'center', color: 'red' }}>פרק זה בשלבי בניה</p>
                <div className="chapter-p" dir="rtl">
                    <strong>א. </strong>
                    לפי שאמרנו בהלכות אלו שבית דין היו מחשבין בדקדוק ויודעים אם יראה הירח או לא יראה,
                    ידענו שכל מי שרוחו נכונה ולבו תאב לדברי החכמות ולעמוד על הסודות יתאוה לידע אותן הדרכים שמחשבין בהם עד שידע אדם אם יראה הירח בליל זה או לא יראה.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>ב. </strong>
                    ודרכי החשבון יש בהן מחלוקות גדולות בין חכמי הגוים הקדמונים שחקרו על חשבון התקופות והגימטריאות,
                    ואנשים חכמים גדולים נשתבשו בהן ונתעלמו מהן דברים ונולדו להן ספיקות,
                    ויש מי שמדקדק הרבה ולא פגע בדרך הנכונה בחשבון ראיית הירח אלא צלל במים אדירים והעלה חרס בידו.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>ג. </strong>
                    ולפי אורך הימים ורוב הבדיקות והחקירות נודעו למקצת החכמים דרכי חשבון זה,
                    ועוד שיש לנו בעיקרים אלו קבלות מפי החכמים וראיות שלא נכתבו בספרים הידועים לכל,
                    ומפני כל אלו הדברים כשר בעיני לבאר דרכי חשבון זה כדי שיהיה נכון למי שמלאו לבו לקרבה אל המלאכה לעשות אותה.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>ד. </strong>
                    ואל יהיו דרכים אלו קלים בעיניך מפני שאין אנו צריכין להם בזמן הזה,
                    שאלו הדרכים דרכים רחוקים ועמוקים הן,
                    והוא סוד העיבור שהיו החכמים הגדולים יודעים אותו ואינן מוסרין אותו לכל אדם אלא לסמוכים נבונים,
                    אבל זה החשבון שמחשבין בזמן שאין שם בית דין לקבוע על הראייה שאנו מחשבין בו היום אפילו תינוקות של בית רבן מגיעין עד סופו בשלשה וארבעה ימים.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>ה. </strong>
                    שמא יתבונן חכם מחכמי האומות או מחכמי ישראל שלמדו חכמת יון בדרכים אלו שאני מחשב בהן לראיית הירח ויראה קירוב מעט במקצת הדרכים,
                    ויעלה על דעתו שנתעלם ממנו דבר זה ולא ידענו שיש באותה הדרך קירוב,
                    אל יעלה זה על דעתו אלא כל דבר שלא דקדקנו בו מפני שידענו בעיקרי הגימטריאות בראיות ברורות שאין דבר זה מפסיד בידיעת הראייה ואין חוששין לו,
                    לפיכך לא דקדקנו בו.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>ו. </strong>
                    וכן כשיראה בדרך מן הדרכים חסרון מעט מחשבון הראוי לאותה הדרך,
                    בכוונה עשינו זה לפי שיש כנגדו יתרון בדרך אחרת עד שיצא הדבר לאמתו בדרכים קרובים בלא חשבון ארוך,
                    כדי שלא יבהל האדם שאינו רגיל בדברים אלו ברוב החשבונות שאין מועילין בראיית הירח.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>ז. </strong>
                    העיקרים שצריך אדם לידע תחלה לכל חשבונות האיצטגנינות,
                    בין לדרכי חשבון הראייה בין לשאר דברים, אלו הן: הגלגל מוחלק בשלש מאות וששים מעלות,
                    כל מזל ומזל שלשים מעלות, וההתחלה מתחילת מזל טלה וכל מעלה ומעלה ששים חלקים,
                    וכל חלק וחלק ששים שניות, וכל שניה ושניה ששים שלישיות, וכן תדקדק החשבון ותחלק כל זמן שתרצה.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>ח. </strong>
                    לפיכך אם יצא לך בחשבון שכוכב פלוני מקומו בגלגל בשבעים מעלות ושלשים חלקים וארבעים שניות,
                    תדע שכוכב זה הוא במזל תאומים בחצי מעלת אחת עשרה ממזל זה,
                    לפי שמזל טלה שלשים מעלות ומזל שור שלשים מעלות נשאר עשר מעלות ומחצה ממזל תאומים וארבעים שניות מחצי המעלה האחרון.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>ט. </strong>
                    וכן אם יצא מקומו בגלגל בשלש מאות ועשרים מעלות,
                    תדע שכוכב זה במזל דלי בעשרים מעלה בו,
                    ועל דרך זו בכל המניינות,
                    וסדר המזלות כך הוא: טלה שור תאומים סרטן אריה בתולה מאזנים עקרב קשת גדי דלי דגים.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>י. </strong>
                    בכל החשבונות כולם כשתקבץ שארית לשארית או כשתוסיף מנין על מנין,
                    תקבץ כל מין עם מינו, השניות עם השניות והחלקים עם החלקים והמעלות עם המעלות,
                    וכל שיתקבץ מן השניות ששים תשים אותו חלק אחד ותוסיפו על החלקים,
                    וכל שיתקבץ מן החלקים ששים תשים אותו מעלה ותוסיף אותה על המעלות,
                    וכשתקבץ המעלות תשליך אותן שלש מאות וששים שלש מאות וששים והנשאר משלש מאות וששים ולמטה הוא שתופסין לחשבון.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>יא. </strong>
                    בכל החשבונות כולן כשתרצה לגרוע מנין ממנין,
                    אם יהיה זה שגורעין אותו יתר על זה שגורעין ממנו אפילו בחלק אחד תוסיף על זה שגורעין ממנו שלש מאות וששים מעלות כדי שיהא אפשר לגרוע זה המנין ממנו.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>יב. </strong>
                    כיצד הרי שהצריכך החשבון לגרוע מאתים מעלות וחמשים חלקים וארבעים שניות, סימנם רנ"מ,
                    ממאה מעלות ועשרים חלקים ושלשים שניות, סימנן קכ"ל,
                    תוסיף על המאה שלש מאות וששים יהיו המעלות ארבע מאות וששים ותתחיל לגרוע השניות מן השניות תבא לגרוע ארבעים משלשים אי אפשר תרים חלק אחד מן העשרים חלקים ותעשה אותו ששים שניות ותוסיף על השלשים ונמצאו השניות תשעים,
                    תגרע מהם הארבעים ישאר חמשים שניות, ותחזור לגרוע חמשים חלקים מתשעה עשר חלקים שכבר הרימות מהם חלק אחד ועשיתו שניות,
                    ואי אפשר לגרוע חמשים מתשעה עשר, לפיכך תרים מעלה אחת מן המעלות ותעשה אותה ששים חלקים ותוסיף על התשעה עשר ונמצאו החלקים תשעה ושבעים,
                    תגרע מהן החמשים ישאר תשעה ועשרים חלקים, ותחזור לגרוע המאתים מעלות מן ארבע מאות ותשע וחמשים מעלות שכבר הרימות מעלה אחת ועשיתה חלקים,
                    ישאר מאתים ותשע וחמשים מעלות ונמצא השאר סימנו רנ"ט כט"נ, ועל דרך זו בכל גרעון וגרעון.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>יג. </strong>
                    השמש והירח וכן שאר השבעה כוכבים מהלך כל אחד ואחד מהן בגלגל שלו מהלך שוה,
                    אין בו לא קלות ולא כבדות אלא כמו מהלכו היום כמו מהלכו אמש כמו מהלכו למחר כמו מהלכו בכל יום ויום,
                    וגלגל של כל אחד מהם אע"פ שהוא מקיף את העולם אין הארץ באמצעו.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>יד. </strong>
                    לפיכך אם תערוך מהלך כל אחד מהן לגלגל המקיף את העולם שהארץ באמצעו שהוא גלגל המזלות,
                    ישתנה הלוכו ונמצא מהלכו ביום זה בגלגל המזלות פחות או יתר על מהלכו אמש או על מהלכו למחר.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>טו. </strong>
                    המהלך השוה שמהלך הכוכב או השמש או הירח בגלגלו הוא הנקרא אמצע המהלך,
                    והמהלך שיהיה בגלגל המזלות שהוא פעמים יתר ופעמים חסר הוא המהלך האמתי, ובו יהיה מקום השמש או מקום הירח האמתי.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>טז. </strong>
                    כבר אמרנו שאלו הדרכים שאנו מבארים בהלכות אלו אינן אלא לחשבון ראיית הירח בלבד,
                    לפיכך עשינו העיקר שממנו מתחילין לעולם לחשבון זה מתחילת ליל חמישי שיומו יום שלישי לחדש ניסן משנה זו שהיא שנת שבע עשרה ממחזור ר"ס,
                    שהיא שנת שמונה ושלשים ותשע מאות וארבעת אלפים ליצירה, שהיא שנת תשע ושמונים וארבע מאות ואלף לשטרות,
                    שהיא שנת תשע ומאה ואלף לחרבן בית שני, וזו השנה היא שאנו קוראים אותה שנת העיקר בחשבון זה.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>יז. </strong>
                    ולפי שהראייה לא תהיה אלא בארץ ישראל כמו שביארנו,
                    עשינו כל דרכי חשבון זה בנויים על עיר ירושלם ולשאר המקומות הסובבין אותה בכמו ששה או שבעה ימים שבהן רואין את הירח תמיד ובאים ומעידים בבית דין,
                    ומקום זה הוא נוטה מתחת הקו השוה המסבב באמצע העולם כנגד רוח צפונית בכמו שתים ושלשים מעלות עד חמש ושלשים ועד תשע ועשרים,
                    וכן הוא נוטה מאמצע הישוב כנגד רוח מערב בכמו ארבע ועשרים מעלות עד שבע ועשרים ועד אחת ועשרים.
                </div>
                <p></p>
            </Row>
        </Container>
    );
};

export default Chapter11content;
