import React, { useEffect, useRef, useContext, useState } from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";

import SunPaths, { AUTO_RUN_GOVA, AUTO_RUN_SHEMESH, KAV_MEKOM_GOVA, MASLUL_SHEMESH, MASLUL_SHEMESH_MORE_THAN_180, MEKOM_SHEMESH_AMITI, MENAT_MASLUL_TEXT, NO_LINES, RUN_FROM_OUT } from "../../components/SunPaths";
import { AnalyticsContext } from "../../context/AnalyticsContext";
import DualPaneManager from "../DualPaneManager";
import { MetaTags } from "react-meta-tags";
import CreateOrVerifyEmail from "../../components/CreateOrVerifyEmail";
import ContactAndOrder from "../../components/ContactAndOrder";

import { ORDER_MESSAGE } from "../../apis/commonConsts";
import Chapter13content from "../../components/chaptersContents/Chapter13content";
import { emzaSemeshSpeed, govaShemeshSpeed, ikarGovaShemesh, ikarShemesh } from "../../apis/orbits/orbitsGeneral";

export const VIEW_C13_STATE_OPEN = 0;
export const VIEW_C13_STATE_1 = 1;
export const VIEW_C13_STATE_2 = 2;
export const VIEW_C13_STATE_3 = 3;
export const VIEW_C13_STATE_4 = 4;
export const VIEW_C13_STATE_5 = 5;
export const VIEW_C13_STATE_6 = 6;
export const VIEW_C13_STATE_7 = 7;

export const VIEW_C13_STATE_8 = 8;
export const VIEW_C13_STATE_9 = 9;
export const VIEW_C13_STATE_10 = 10;
export const VIEW_C13_STATE_11 = 11;
export const VIEW_C13_STATE_12 = 12;
export const VIEW_C13_STATE_13 = 13;
export const VIEW_C13_STATE_14 = 14;
export const VIEW_C13_STATE_15 = 15;
export const VIEW_C13_STATE_16 = 16;
export const VIEW_C13_STATE_17 = 17;
export const VIEW_C13_STATE_18 = 18;
export const VIEW_C13_STATE_19 = 19;
export const VIEW_C13_STATE_20 = 20;
export const VIEW_C13_STATE_21 = 21;
export const VIEW_C13_STATE_22 = 22;
export const VIEW_C13_STATE_23 = 23;
export const VIEW_C13_STATE_24 = 24;
export const VIEW_C13_STATE_25 = 25;

export const VIEW_C13_STATE_26 = 26;
export const VIEW_C13_STATE_27 = 27;

export const VIEW_C13_STATE_28 = 28;
export const VIEW_C13_STATE_29 = 29;

export const VIEW_C13_STATE_30 = 30;


const Chapter13 = ({ history }) => {

    const sunPathsRef = useRef();
    const [viewState, setViewState] = useState(VIEW_C13_STATE_OPEN);
    const { height, width, graphicWidth, paneHeight } = useWindowDimensions();
    const { analytics } = useContext(AnalyticsContext);
    const [days, setDays] = useState(100);

    const signinOrVerifyRef = useRef(null);
    const orderRef = useRef(null);

    const calculateMekomShemeshAmiti = () => {
        if (VIEW_C13_STATE_30 !== viewState) {
            return;
        }
        const s = emzaSemeshSpeed.degreesCount * days + ikarShemesh.degreesCount;
        const g = govaShemeshSpeed.degreesCount * days + ikarGovaShemesh.degreesCount;

        sunPathsRef.current?.setPaths(0, g, s);
    };

    useEffect(() => {
        calculateMekomShemeshAmiti();
    }, [days]);

    useEffect(() => {
        analytics();
    }, []);

    const setState = () => {
        switch (viewState) {
            case VIEW_C13_STATE_OPEN:
            default:
                sunPathsRef.current?.setRunState(AUTO_RUN_GOVA | AUTO_RUN_SHEMESH | NO_LINES | MASLUL_SHEMESH | KAV_MEKOM_GOVA);
                sunPathsRef.current?.setGlobalXRotation(67);
                sunPathsRef.current?.setSpeeds(0.5, 0.05);
                break;
            case VIEW_C13_STATE_1:
                sunPathsRef.current?.setRunState(AUTO_RUN_GOVA | AUTO_RUN_SHEMESH | NO_LINES);
                sunPathsRef.current?.setGlobalXRotation(67);
                sunPathsRef.current?.setSpeeds(0.5, 0.05);
                break;
            case VIEW_C13_STATE_2:
                sunPathsRef.current?.setRunState(AUTO_RUN_GOVA | AUTO_RUN_SHEMESH | NO_LINES | KAV_MEKOM_GOVA);
                sunPathsRef.current?.setGlobalXRotation(67);
                sunPathsRef.current?.setSpeeds(0.5, 0.05);
                break;
            case VIEW_C13_STATE_3:
                sunPathsRef.current?.setRunState(AUTO_RUN_GOVA | AUTO_RUN_SHEMESH | NO_LINES | MASLUL_SHEMESH | KAV_MEKOM_GOVA);
                sunPathsRef.current?.setGlobalXRotation(67);
                sunPathsRef.current?.setSpeeds(0.5, 0.05);
                break;
            case VIEW_C13_STATE_4:
                if (signinOrVerifyRef.current.showIfNeed()) {
                    break;
                }
                sunPathsRef.current?.setRunState(RUN_FROM_OUT | MASLUL_SHEMESH | KAV_MEKOM_GOVA);
                sunPathsRef.current?.setGlobalXRotation(67);
                sunPathsRef.current?.setPaths(0, 0, 120);

                break;
            case VIEW_C13_STATE_5:
                if (signinOrVerifyRef.current.showIfNeed()) {
                    break;
                }
                sunPathsRef.current?.setRunState(RUN_FROM_OUT | MASLUL_SHEMESH | KAV_MEKOM_GOVA);
                sunPathsRef.current?.setGlobalXRotation(67);
                sunPathsRef.current?.setPaths(0, 0, 240);
                break;
            case VIEW_C13_STATE_6:
                if (signinOrVerifyRef.current.showIfNeed()) {
                    break;
                }
                sunPathsRef.current?.setRunState(RUN_FROM_OUT | MASLUL_SHEMESH | KAV_MEKOM_GOVA);
                sunPathsRef.current?.setGlobalXRotation(67);
                sunPathsRef.current?.setPaths(0, 0, 180);

                break;
            case VIEW_C13_STATE_7:
                if (signinOrVerifyRef.current.showIfNeed()) {
                    break;
                }
                sunPathsRef.current?.setRunState(RUN_FROM_OUT | MASLUL_SHEMESH | KAV_MEKOM_GOVA);
                sunPathsRef.current?.setGlobalXRotation(67);
                sunPathsRef.current?.setPaths(0, 0, 360);
                break;
            case VIEW_C13_STATE_8:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                sunPathsRef.current?.setRunState(RUN_FROM_OUT | MASLUL_SHEMESH | KAV_MEKOM_GOVA | MENAT_MASLUL_TEXT);
                sunPathsRef.current?.setGlobalXRotation(67);
                sunPathsRef.current?.setPaths(0, 0, 10);
                break;
            case VIEW_C13_STATE_9:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                sunPathsRef.current?.setRunState(RUN_FROM_OUT | MASLUL_SHEMESH | KAV_MEKOM_GOVA | MENAT_MASLUL_TEXT);
                sunPathsRef.current?.setGlobalXRotation(67);
                sunPathsRef.current?.setPaths(0, 0, 20);
                break;
            case VIEW_C13_STATE_10:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                sunPathsRef.current?.setRunState(RUN_FROM_OUT | MASLUL_SHEMESH | KAV_MEKOM_GOVA | MENAT_MASLUL_TEXT);
                sunPathsRef.current?.setGlobalXRotation(67);
                sunPathsRef.current?.setPaths(0, 0, 30);
                break;
            case VIEW_C13_STATE_11:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                sunPathsRef.current?.setRunState(RUN_FROM_OUT | MASLUL_SHEMESH | KAV_MEKOM_GOVA | MENAT_MASLUL_TEXT);
                sunPathsRef.current?.setGlobalXRotation(67);
                sunPathsRef.current?.setPaths(0, 0, 40);
                break;
            case VIEW_C13_STATE_12:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                sunPathsRef.current?.setRunState(RUN_FROM_OUT | MASLUL_SHEMESH | KAV_MEKOM_GOVA | MENAT_MASLUL_TEXT);
                sunPathsRef.current?.setGlobalXRotation(67);
                sunPathsRef.current?.setPaths(0, 0, 50);
                break;
            case VIEW_C13_STATE_13:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                sunPathsRef.current?.setRunState(RUN_FROM_OUT | MASLUL_SHEMESH | KAV_MEKOM_GOVA | MENAT_MASLUL_TEXT);
                sunPathsRef.current?.setGlobalXRotation(67);
                sunPathsRef.current?.setPaths(0, 0, 60);
                break;
            case VIEW_C13_STATE_14:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                sunPathsRef.current?.setRunState(RUN_FROM_OUT | MASLUL_SHEMESH | KAV_MEKOM_GOVA | MENAT_MASLUL_TEXT);
                sunPathsRef.current?.setGlobalXRotation(67);
                sunPathsRef.current?.setPaths(0, 0, 70);
                break;
            case VIEW_C13_STATE_15:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                sunPathsRef.current?.setRunState(RUN_FROM_OUT | MASLUL_SHEMESH | KAV_MEKOM_GOVA | MENAT_MASLUL_TEXT);
                sunPathsRef.current?.setGlobalXRotation(67);
                sunPathsRef.current?.setPaths(0, 0, 80);
                break;
            case VIEW_C13_STATE_16:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                sunPathsRef.current?.setRunState(RUN_FROM_OUT | MASLUL_SHEMESH | KAV_MEKOM_GOVA | MENAT_MASLUL_TEXT);
                sunPathsRef.current?.setGlobalXRotation(67);
                sunPathsRef.current?.setPaths(0, 0, 90);
                break;
            case VIEW_C13_STATE_17:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                sunPathsRef.current?.setRunState(RUN_FROM_OUT | MASLUL_SHEMESH | KAV_MEKOM_GOVA | MENAT_MASLUL_TEXT);
                sunPathsRef.current?.setGlobalXRotation(67);
                sunPathsRef.current?.setPaths(0, 0, 100);
                break;
            case VIEW_C13_STATE_18:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                sunPathsRef.current?.setRunState(RUN_FROM_OUT | MASLUL_SHEMESH | KAV_MEKOM_GOVA | MENAT_MASLUL_TEXT);
                sunPathsRef.current?.setGlobalXRotation(67);
                sunPathsRef.current?.setPaths(0, 0, 110);
                break;
            case VIEW_C13_STATE_19:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                sunPathsRef.current?.setRunState(RUN_FROM_OUT | MASLUL_SHEMESH | KAV_MEKOM_GOVA | MENAT_MASLUL_TEXT);
                sunPathsRef.current?.setGlobalXRotation(67);
                sunPathsRef.current?.setPaths(0, 0, 120);
                break;
            case VIEW_C13_STATE_20:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                sunPathsRef.current?.setRunState(RUN_FROM_OUT | MASLUL_SHEMESH | KAV_MEKOM_GOVA | MENAT_MASLUL_TEXT);
                sunPathsRef.current?.setGlobalXRotation(67);
                sunPathsRef.current?.setPaths(0, 0, 130);
                break;
            case VIEW_C13_STATE_21:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                sunPathsRef.current?.setRunState(RUN_FROM_OUT | MASLUL_SHEMESH | KAV_MEKOM_GOVA | MENAT_MASLUL_TEXT);
                sunPathsRef.current?.setGlobalXRotation(67);
                sunPathsRef.current?.setPaths(0, 0, 140);
                break;
            case VIEW_C13_STATE_22:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                sunPathsRef.current?.setRunState(RUN_FROM_OUT | MASLUL_SHEMESH | KAV_MEKOM_GOVA | MENAT_MASLUL_TEXT);
                sunPathsRef.current?.setGlobalXRotation(67);
                sunPathsRef.current?.setPaths(0, 0, 150);
                break;
            case VIEW_C13_STATE_23:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                sunPathsRef.current?.setRunState(RUN_FROM_OUT | MASLUL_SHEMESH | KAV_MEKOM_GOVA | MENAT_MASLUL_TEXT);
                sunPathsRef.current?.setGlobalXRotation(67);
                sunPathsRef.current?.setPaths(0, 0, 160);
                break;
            case VIEW_C13_STATE_24:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                sunPathsRef.current?.setRunState(RUN_FROM_OUT | MASLUL_SHEMESH | KAV_MEKOM_GOVA | MENAT_MASLUL_TEXT);
                sunPathsRef.current?.setGlobalXRotation(67);
                sunPathsRef.current?.setPaths(0, 0, 170);
                break;
            case VIEW_C13_STATE_25:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                sunPathsRef.current?.setRunState(RUN_FROM_OUT | MASLUL_SHEMESH | KAV_MEKOM_GOVA | MENAT_MASLUL_TEXT);
                sunPathsRef.current?.setGlobalXRotation(67);
                sunPathsRef.current?.setPaths(0, 0, 180);
                break;
            case VIEW_C13_STATE_26:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                sunPathsRef.current?.setRunState(RUN_FROM_OUT | MASLUL_SHEMESH | KAV_MEKOM_GOVA |
                    MASLUL_SHEMESH_MORE_THAN_180 | MENAT_MASLUL_TEXT);
                sunPathsRef.current?.setGlobalXRotation(67);
                sunPathsRef.current?.setPaths(0, 0, 200);
                break;
            case VIEW_C13_STATE_27:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                sunPathsRef.current?.setRunState(RUN_FROM_OUT | MASLUL_SHEMESH | KAV_MEKOM_GOVA |
                    MASLUL_SHEMESH_MORE_THAN_180 | MENAT_MASLUL_TEXT);
                sunPathsRef.current?.setGlobalXRotation(67);
                sunPathsRef.current?.setPaths(0, 0, 300);
                break;
            case VIEW_C13_STATE_28:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                sunPathsRef.current?.setRunState(RUN_FROM_OUT | MASLUL_SHEMESH | KAV_MEKOM_GOVA |
                    MASLUL_SHEMESH_MORE_THAN_180 | MENAT_MASLUL_TEXT);
                sunPathsRef.current?.setGlobalXRotation(67);
                sunPathsRef.current?.setPaths(0, 0, 65);
                break;
            case VIEW_C13_STATE_29:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                sunPathsRef.current?.setRunState(RUN_FROM_OUT | MASLUL_SHEMESH | KAV_MEKOM_GOVA |
                    MASLUL_SHEMESH_MORE_THAN_180 | MENAT_MASLUL_TEXT);
                sunPathsRef.current?.setGlobalXRotation(67);
                sunPathsRef.current?.setPaths(0, 0, 67);
                break;
            case VIEW_C13_STATE_30:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                sunPathsRef.current?.setRunState(RUN_FROM_OUT | MASLUL_SHEMESH | KAV_MEKOM_GOVA |
                    MASLUL_SHEMESH_MORE_THAN_180 | MEKOM_SHEMESH_AMITI);
                sunPathsRef.current?.setGlobalXRotation(67);
                calculateMekomShemeshAmiti();
                break;
        }

    };


    useEffect(() => {
        setState();
        sunPathsRef.current?.setSise(paneHeight, graphicWidth);
    }, [height, width, viewState]);

    return (
        <div>
            <MetaTags>
                <title>הלכות קידוש החודש לרמב"ם פרק יג</title>
                <meta name="description" content='פרק יג הלכות קידוש החודש לרמב"ם ובו יבואר מסלול השמש מה הוא ומקום השמש האמיתי' />
            </MetaTags>
            <CreateOrVerifyEmail history={history} ref={signinOrVerifyRef} />
            <ContactAndOrder history={history} ref={orderRef} hidden={true} nessage={ORDER_MESSAGE} />
            <DualPaneManager
                graphicPane={<SunPaths ref={sunPathsRef} />}
                dataPane={<Chapter13content
                    setViewState={setViewState}
                    params={{ days, setDays }}
                />}
                height={height}
                width={width}
            />
        </div>
    );
};

export default Chapter13;