import React, { useEffect, useState } from "react";
import { Row, Table } from "react-bootstrap";
import { getHebrewDateFormat, getNewDateFromOrigin } from "../apis/dateConverters";
import { getDaysFromIkar, getEmzaMaslulYareach, getEmzaShemeshString, getEmzaYareachReiya, getGovaShemeshString, getMaslulNachon, getMaslulRochav, getMaslulShemesh, getMekomRosh, getMekomShemeshAmiti, getMenatMaslul, getMenatMaslulNachon, getMenatMerchakKaful, getMerchakAndKaful, getYareachAmiti, menatMaslulRochavString } from "../apis/orbits/orbitsGeneral";
import "./DayInfocomponent.css"

const DayInfocomponent = () => {

    const [theDate, setTheDate] = useState(getHebrewDateFormat(getNewDateFromOrigin(getDaysFromIkar() + 1)));
    const [daysFromIkar, setDataFromIkar] = useState(getDaysFromIkar() + 1);


    useEffect(() => {

        const timerHandle = setInterval(() => {
            setTheDate(getHebrewDateFormat(getNewDateFromOrigin(getDaysFromIkar() + 1)));
            setDataFromIkar(getDaysFromIkar() + 1);
        }, 600000)

        return () => clearInterval(timerHandle);

    }, []);

    const rows = () => {
        return (
            <>
                <tr>
                    <td>הערכים לתאריך</td>
                    <td>{theDate}</td>
                </tr>
                <tr>
                    <td>ימים שעברו מיום העיקר עד השקיעה</td>
                    <td>{daysFromIkar}</td>
                </tr>
                <tr>
                    <td>מקום אמצע השמש</td>
                    <td>{getEmzaShemeshString(daysFromIkar)}</td>
                </tr>
                <tr>
                    <td>מקום גובה השמש</td>
                    <td>{getGovaShemeshString(daysFromIkar)}</td>
                </tr>
                <tr>
                    <td>זוית מסלול השמש</td>
                    <td>{getMaslulShemesh(daysFromIkar).maslulShemeshString}</td>
                </tr>
                <tr>
                    <td>זוית מנת מסלול השמש</td>
                    <td>{getMenatMaslul(daysFromIkar).menatMaslulString}</td>
                </tr>
                <tr>
                    <td>מקום השמש האמיתי</td>
                    <td>{getMekomShemeshAmiti(daysFromIkar).shemeshAmitiString}</td>
                </tr>
                <tr>
                    <td>מקום אמצע הירח לשעת ראיה</td>
                    <td>{getEmzaYareachReiya(daysFromIkar).yareachReiyaString}</td>
                </tr>
                <tr>
                    <td>מקום אמצע מסלול הירח</td>
                    <td>{getEmzaMaslulYareach(daysFromIkar).emzaMaslulString}</td>
                </tr>
                <tr>
                    <td>המרחק</td>
                    <td>{getMerchakAndKaful(daysFromIkar).merchakString}</td>
                </tr>
                <tr>
                    <td>המרחק הכפול</td>
                    <td>{getMerchakAndKaful(daysFromIkar).kafulString}</td>
                </tr>
                <tr>
                    <td>מנת המרחק הכפול</td>
                    <td>{getMenatMerchakKaful(getMerchakAndKaful(daysFromIkar).kaful).degString}</td>
                </tr>
                <tr>
                    <td>מסלול הנכון</td>
                    <td>{getMaslulNachon(daysFromIkar).nachonString}</td>
                </tr>
                <tr>
                    <td>מנת המסלול הנכון</td>
                    <td>{getMenatMaslulNachon(daysFromIkar).manaString}</td>
                </tr>
                <tr>
                    <td>מקום הירח האמיתי</td>
                    <td>{getYareachAmiti(daysFromIkar).amitiString}</td>
                </tr>
                <tr>
                    <td>מקום הראש</td>
                    <td>{getMekomRosh(daysFromIkar).mekomRoshString}</td>
                </tr>
                <tr>
                    <td>מסלול רוחב הירח</td>
                    <td>{getMaslulRochav(daysFromIkar).maslulRochavString}</td>
                </tr>
                <tr>
                    <td>רוחב הירח</td>
                    <td>{menatMaslulRochavString(getMaslulRochav(daysFromIkar).maslulRochav)}</td>
                </tr>
            </>
        );
    };

    return (
        <Row>
            <Table responsive bordered dir="rtl" color="red">
                <thead className="info-header">
                    <tr>
                        <th>תיאור</th>
                        <th>ערך</th>
                    </tr>
                </thead>
                <tbody className="info-body">
                    {rows()}
                </tbody>
            </Table>
        </Row>
    )
};

export default DayInfocomponent;