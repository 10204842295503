import React from "react";
import { Col, Row } from "react-bootstrap";
import { ikarDate } from "../../apis/orbits/orbitsGeneral";

import { FaPlusCircle, FaMinusCircle} from "react-icons/fa"
import { getNewDateFromOrigin,  getHebrewDateFormat} from "../../apis/dateConverters";



const DaysFromIkar2 = ({ params, children }) => {
    const { days, setDays } = params;


    return (
        <div>
            <Row>
                <Col lg={4} md={6}>
                    <p className="text-center">{getHebrewDateFormat(ikarDate)}</p>
                </Col>
                <Col lg={4} md={6} style={{ textAlign: 'center' }}>
                    <Row className="justify-content-center">
                        <Col sm={4}  style={{padding: 0, marginBottom: 4}}>
                           <FaPlusCircle
                           fontSize={24}
                           onClick={() => setDays(days + 1)} />
                        </Col>
                        <Col sm={4} style={{padding: 0}}>

                            <input style={{ textAlign: 'center', width: 90 }}

                                type="number"
                                value={days}
                                onChange={e => setDays(parseInt(e.currentTarget.value))}
                            />
                        </Col>
                        <Col sm={4} style={{padding: 0, marginBottom: 4}}>
                            <FaMinusCircle 
                            fontSize={24}
                            onClick={() => setDays(days - 1)}/>
                        </Col>

                    </Row>
                </Col>
                <Col lg={4} md={6}>
                    <p className="text-center">{getHebrewDateFormat(getNewDateFromOrigin(days, ikarDate))}</p>
                </Col>
            </Row>
            <Row>
                {children}
            </Row>
        </div>
    );
};


export default DaysFromIkar2;