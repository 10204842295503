import * as THREE from "three";
import earthJpg from "../textures/earth.jpg";
import sunPng from "../textures/pl_sun.jpg";
import moonPng from "../textures/moon0.jpg"
import { Point3D } from "../utiles/hebrew-calculator";
import { circleCords, flatMazalotTexture, mazalotInnerAlfaMapTexture, mazalotTexture, plusMinusYreachEmzaee, spiralTexture, textAlfaMap, textTexture, twoVerticalColore, verticalGradientTexure, verticalLines, worldLineAlfaMapTexture } from "./textures";


export const cylinderWithTexture = (scene, radius, height = 50, thetaStart = -60, thetaLength = 120, texture = plusMinusYreachEmzaee()) => {

    const geometry = new THREE.CylinderGeometry(radius, radius, height, 20, 1, true, thetaStart / 180 * Math.PI, thetaLength / 180 * Math.PI);
    const material = new THREE.MeshBasicMaterial({ map: texture, side: THREE.FrontSide });
    const mesh = new THREE.Mesh(geometry, material);
    

    scene.add(mesh);

    return mesh;
};

export const cylinderLine = (scene, radius, height = 50, color = 0xFFFF00) => {
    const geometry = new THREE.CylinderGeometry(radius, radius, height, 20, 1, true, -Math.PI / 360, Math.PI / 180);
    const material = new THREE.MeshBasicMaterial({ color, side: THREE.FrontSide });
    const mesh = new THREE.Mesh(geometry, material);
    

    scene.add(mesh);

    return mesh;
};

export const textueSphere = (scene, radius = 302, texture = twoVerticalColore(), widthSegments = 40, heightSegments= 40) => {
    
    const geometry = new THREE.SphereGeometry(radius, widthSegments, heightSegments, 0, Math.PI);
    const material = new THREE.MeshBasicMaterial({ map: texture, side: THREE.DoubleSide });
    const mesh = new THREE.Mesh(geometry, material);
    

    scene.add(mesh);

    return mesh;

};

export const colorSphere = (scene, radius = 10, color = 0xff0000, widthSegments = 20, heightSegments= 20) => {
    const colorSphereGroup = new THREE.Group();
    scene.add(colorSphereGroup);
    const geometry = new THREE.SphereGeometry(radius, widthSegments, heightSegments, 0);
    const material = new THREE.MeshBasicMaterial({ color, side: THREE.DoubleSide, });
    const mesh = new THREE.Mesh(geometry, material);
    colorSphereGroup.add(mesh);

    return colorSphereGroup;
};

export const loadMoon = (scene, moonRadius = 15, widthSegments = 20, heightSegments= 20,  loader = new THREE.TextureLoader()) => {
    const moonGroup = new THREE.Group();
    moonGroup.name = "moonGroup";
    scene.add(moonGroup);

    loader.load(moonPng, (texture) => {
        const geometry = new THREE.SphereGeometry(moonRadius, widthSegments, heightSegments, 0);
        // const material = new THREE.MeshPhongMaterial({ map: texture, specular: 0xffffff, shininess: 2 });
        const material = new THREE.MeshBasicMaterial({ map: texture, side: THREE.DoubleSide });
        const mesh = new THREE.Mesh(geometry, material);
        moonGroup.add(mesh);

    }, () => { }, (err) => {
        console.log(err);
    });

    return moonGroup;
};

export const loadEarth = (scene, earthRadius = 50, onload = null, widthSegments = 20, heightSegments= 20,  loader = new THREE.TextureLoader()) => {

    const earthGroup = new THREE.Group();
    earthGroup.name = "earthGroup";
    scene.add(earthGroup);

    loader.load(earthJpg, (texture) => {
        const geometry = new THREE.SphereGeometry(earthRadius, widthSegments, heightSegments, 0);
        // const material = new THREE.MeshPhongMaterial({ map: texture, specular: 0xffffff, shininess: 2 });
        const material = new THREE.MeshBasicMaterial({ map: texture, side: THREE.DoubleSide });
        const mesh = new THREE.Mesh(geometry, material);        
        earthGroup.add(mesh);

        if (onload !== null) {
            onload(mesh);
        }

    }, () => { }, (err) => {
        console.log(err);
    });

    return earthGroup;
};

export const loadSun = (scene, radius = 20, onLoad = undefined) => {
    const loader = new THREE.TextureLoader();

    const sunGroup = new THREE.Group();
    sunGroup.name = "sunGroup";
    scene.add(sunGroup);

    loader.load(sunPng, (texture) => {
        const sunMaterial = new THREE.MeshBasicMaterial({ map: texture, opacity: 0.6, transparent: true });
        const sunGeometry = new THREE.SphereGeometry(radius, 20, 20);
        const sunMesh = new THREE.Mesh(sunGeometry, sunMaterial);

        const sunMaterial2 = new THREE.MeshBasicMaterial({ color: 0xffffff });
        const sunGeometry2 = new THREE.SphereGeometry(radius * 0.9, 20, 20);
        const sunMesh2 = new THREE.Mesh(sunGeometry2, sunMaterial2);
        sunGroup.add(sunMesh);
        sunGroup.add(sunMesh2);

        if (onLoad) {
            onLoad(sunMaterial, sunMaterial2);
        }


    }, () => { }, err => {
        console.log(err);
    });

    return sunGroup;

};

export const loadMazalotEx = (scene, radius = 500, lookFromWest = false) => {
    const mazalotGroup = new THREE.Group();
    scene.add(mazalotGroup);
    const halfHeight = 10;
    const texture = mazalotTexture(lookFromWest);


    const geometry = new THREE.PlaneGeometry();

    const indices = [];
    const vertices = [];
    const originPoints = [];
    const uvs = [];
    const widthSise = 2 * Math.PI;
    const widthSegments = 60;
    const widthGegmentSise = widthSise / widthSegments;

    const heightSize = halfHeight * 2 / 180 * Math.PI;
    const heightSegments = 30;
    const heightSegmentSise = heightSize / heightSegments;
    const heightStart =  -heightSize / 2;


    for (let i = 0; i <= heightSegments; i++) {
        const y = -Math.sin(heightStart + i * heightSegmentSise) * radius;

        for (let j = 0; j <= widthSegments; j++) {
            const angle = j * widthGegmentSise;
            const x = Math.cos(angle) * radius;
            const z = -Math.sin(angle) * radius;

            vertices.push(x, y, z);
            originPoints.push(new Point3D(x, y, z));
            uvs.push(j / widthSegments, i / heightSegments);
        }
    }

    //generate indices (data for element array buffer)
    for (let i = 0; i < heightSegments; i++) {

        for (let j = 0; j < widthSegments; j++) {

            const a = i * (widthSegments + 1) + (j + 1);
            const b = i * (widthSegments + 1) + j;
            const c = (i + 1) * (widthSegments + 1) + j;
            const d = (i + 1) * (widthSegments + 1) + (j + 1);

            // generate two faces (triangles) per iteration

            indices.push(a, b, d); // face one
            indices.push(b, c, d); // face two
        }
    }
    
    geometry.setIndex(indices);
    geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));

    geometry.setAttribute('uv', new THREE.Float32BufferAttribute(uvs, 2))

    geometry.userData = { originPoints };
    // const geometry = new THREE.SphereGeometry(radius, 60, 20, 0, 2 * Math.PI, Math.PI * (90 - halfHeight) / 180, Math.PI * halfHeight * 2 / 180);
    const material = new THREE.MeshBasicMaterial({ map: texture, side: THREE.BackSide })
    const mesh = new THREE.Mesh(geometry, material);
    mesh.name = "loadMazalotEx";

    mazalotGroup.add(mesh);

    geometry.elementsNeedUpdate = geometry.verticesNeedUpdate = true;

    return { mazalot: mazalotGroup, geometry, material };

};

export const worldLineSphere = (scene, radius = 410, color = 0xffffff ) => {
    const texture = worldLineAlfaMapTexture('#888888');
    const geometry = new THREE.SphereGeometry(radius, 60, 30);
    const side = THREE.DoubleSide;
    const material = new THREE.MeshBasicMaterial({ transparent: true, alphaMap: texture, color, side});
    const mesh = new THREE.Mesh(geometry, material);
    scene.add(mesh);

    return mesh;

};


export const transperantSphere = (scene, isBack = true, radius = 400, color = 0xffffff, width = 5, lookFromWest = false) => {
    const halfHeight = Math.PI / 180 * width;
    const texture = mazalotInnerAlfaMapTexture(isBack, lookFromWest);
    const geometry = new THREE.SphereGeometry(radius, 60, 8, 0, Math.PI * 2, Math.PI / 2 - halfHeight, halfHeight * 2);
    const side = isBack ? THREE.BackSide : THREE.FrontSide;
    const material = new THREE.MeshBasicMaterial({ transparent: true, alphaMap: texture, color, side});
    const mesh = new THREE.Mesh(geometry, material);
    scene.add(mesh);

    return mesh;

};

export const ringWithTexture = (scene, radius = 500, width = 100, texture = flatMazalotTexture()) => {
    
    const geometry = new THREE.RingGeometry(radius- width, radius, 60, 10);
    const material = new THREE.MeshBasicMaterial({ map: texture, side: THREE.DoubleSide});

    const mesh = new THREE.Mesh(geometry, material);
    scene.add(mesh);

    return mesh;

};

export const plateTransperantText = (scene, text, color = 0x0000ff, y = 0.7, width = 512, height = 128) => {
    const texture = textAlfaMap(text, y, width, height);
    const geometry = new THREE.PlaneGeometry(width, height);
    const material = new THREE.MeshBasicMaterial({ transparent: true, color , alphaMap: texture, side: THREE.DoubleSide});

    const mesh = new THREE.Mesh(geometry, material);
    scene.add(mesh);

    return mesh;
};


export const planeText = (width = 500, height = 50, defaultText = "", textWidth = 1024, textHieght = 128, font = "900 72px Arial",) => {
    const texture = textTexture(defaultText, "white", font, '#ff0000', '#ff0000',
    '#aa0000', '#ffffff',
    textWidth, textHieght, 4);
    const geometry = new THREE.PlaneGeometry(width, height);
    const material = new THREE.MeshBasicMaterial({ map: texture, side: THREE.DoubleSide });

    const mesh = new THREE.Mesh(geometry, material);

    return mesh;

};

export const loadLinedRing = (scene, radius = 450, width = 150) => {
    const texture = verticalLines();
    const geometry = new THREE.RingGeometry(radius - width, radius, radius, 40);
    const material = new THREE.MeshBasicMaterial({ map: texture, side: THREE.DoubleSide });
    const mesh = new THREE.Mesh(geometry, material);
    scene.add(mesh); 

    return mesh;
};

export const loadGradientRing = (scene, radius = 700, width = 700, texture = verticalGradientTexure()) => {
    
    const geometry = new THREE.RingGeometry(radius - width, radius, radius, 20);
    const material = new THREE.MeshBasicMaterial({ map: texture, side: THREE.DoubleSide });
    const mesh = new THREE.Mesh(geometry, material);
    // mesh.name = ""
    scene.add(mesh); 

    const pos = geometry.getAttribute('position');
    const originPoints = [];
    for (let i = 0; i < pos.count; i++) {
        originPoints.push(new Point3D(pos.getX(i), pos.getY(i), pos.getZ(i)));
        
    }
    geometry.userData = { originPoints };

    return { mesh, geometry };
};

export const RingTransperantText = (scene, text, radius = 500, width = 50, color = 0xffffff, arc = Math.PI / 2) => {
    const texture = text();
    const geometry = RingGeometry(radius, width, arc);
    const material = new THREE.MeshBasicMaterial({ color, transparent: true, alphaMap: texture, side: THREE.DoubleSide });
    const mesh = new THREE.Mesh(geometry, material);
    // mesh.name = ""
    scene.add(mesh); 

    return mesh;
};

export const RingText = (scene, text, radius = 490, width = 100, arc = Math.PI / 2) => {
    const texture = text();
    const geometry = RingGeometry(radius, width, arc);
    const material = new THREE.MeshBasicMaterial({ map: texture, side: THREE.DoubleSide });
    const mesh = new THREE.Mesh(geometry, material);
    // mesh.name = ""
    scene.add(mesh); 

    return mesh;
};

export const RingGeometry = (radius = 400, width = 50, arc = Math.PI * 2) => {
    const geometry = new THREE.PlaneGeometry();

    const indices = [];
    const vertices = [];
    const originPoints = [];
    const uvs = [];

    const widthSegments = 60;
    const widthSegmentSise = arc / widthSegments;

    const heightSegments = 10;
    const heightSegmentSise = width / heightSegments;
    const heightStart =  radius - width;

    for (let i = 0; i <= heightSegments; i++) {
        const r = heightStart + i * heightSegmentSise;

        for (let j = 0; j <= widthSegments; j++) {
            const angle = j * widthSegmentSise;
            const y = Math.sin(angle) * r;
            const x = Math.cos(angle) * r;
            const z =0;

            vertices.push(x, y, z);
            originPoints.push(new Point3D(x, y, z));
            uvs.push(j / widthSegments, i / heightSegments);
        }
    }

    //generate indices (data for element array buffer)
    for (let i = 0; i < heightSegments; i++) {

        for (let j = 0; j < widthSegments; j++) {

            const a = i * (widthSegments + 1) + (j + 1);
            const b = i * (widthSegments + 1) + j;
            const c = (i + 1) * (widthSegments + 1) + j;
            const d = (i + 1) * (widthSegments + 1) + (j + 1);

            // generate two faces (triangles) per iteration

            indices.push(a, b, d); // face one
            indices.push(b, c, d); // face two
        }
    }

    geometry.setIndex(indices);
    geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));

    geometry.setAttribute('uv', new THREE.Float32BufferAttribute(uvs, 2))

    geometry.userData = { originPoints };

    return geometry;
};

export const spiralRing = (scene, circle, radius = 50, width = 7, zFactor = 75) => {
    const texture = spiralTexture();
    const geometry = new THREE.PlaneGeometry();

    circle = Math.floor(circle);
    

    const indices = [];
    const vertices = [];
    const originPoints = [];
    const uvs = [];

    const widthSise = circle * 2 * Math.PI;
    const widthSegments = circle * 60;
    const widthSegmentSise = widthSise / widthSegments;

    const heightSegments = 10;
    const heightSegmentSise = width / heightSegments;
    const heightStart =  radius - width;

    const angleFactor = zFactor / ( 2 * Math.PI);

    for (let i = 0; i <= heightSegments; i++) {
        const r = heightStart + i * heightSegmentSise;

        for (let j = 0; j <= widthSegments; j++) {
            const angle = j * widthSegmentSise;
            const y = Math.sin(angle) * r;
            const x = Math.cos(angle) * r;
            const z = -angle * angleFactor;

            vertices.push(x, y, z);
            originPoints.push(new Point3D(x, y, z));
            uvs.push(j / widthSegments, i / heightSegments);
        }
    }

    //generate indices (data for element array buffer)
    for (let i = 0; i < heightSegments; i++) {

        for (let j = 0; j < widthSegments; j++) {

            const a = i * (widthSegments + 1) + (j + 1);
            const b = i * (widthSegments + 1) + j;
            const c = (i + 1) * (widthSegments + 1) + j;
            const d = (i + 1) * (widthSegments + 1) + (j + 1);

            // generate two faces (triangles) per iteration

            indices.push(a, b, d); // face one
            indices.push(b, c, d); // face two
        }
    }

    geometry.setIndex(indices);
    geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));

    geometry.setAttribute('uv', new THREE.Float32BufferAttribute(uvs, 2))

    geometry.userData = { originPoints };

    const material = new THREE.MeshBasicMaterial({ map: texture, side: THREE.DoubleSide })
    const mesh = new THREE.Mesh(geometry, material);

    scene.add(mesh);
    mesh.rotation.z = Math.PI;

    return { mesh, geometry };

};

export const loadRingTexture = (scene, radius = 50, width = 15, texture = circleCords(), start = 0, length = Math.PI * 2) => {


    const geometry = new THREE.RingGeometry(radius - width, radius, radius, 6, start, length);
    const material = new THREE.MeshBasicMaterial({ map: texture, side: THREE.DoubleSide });
    const mesh = new THREE.Mesh(geometry, material);
    // mesh.name = ""
    scene.add(mesh);

    const pos = geometry.getAttribute('position');
    const originPoints = [];
    for (let i = 0; i < pos.count; i++) {
        originPoints.push(new Point3D(pos.getX(i), pos.getY(i), pos.getZ(i)));
        
    }
    geometry.userData = { originPoints };

    return { mesh, geometry };
};

export const loadRing = (scene, radius = 50, width = 7, color = 0xff0000, start = 0, length = Math.PI * 2) => {


    const geometry = new THREE.RingGeometry(radius - width, radius, radius, 6, start, length);
    const material = new THREE.MeshBasicMaterial({ color, side: THREE.DoubleSide });
    const mesh = new THREE.Mesh(geometry, material);
    // mesh.name = ""
    scene.add(mesh);

    const pos = geometry.getAttribute('position');
    const originPoints = [];
    for (let i = 0; i < pos.count; i++) {
        originPoints.push(new Point3D(pos.getX(i), pos.getY(i), pos.getZ(i)));
        
    }
    geometry.userData = { originPoints };

    return { mesh, geometry };
};

export const loadBall = (scene, radius = 50, color = 0xffff00) => {

    const geometry = new THREE.SphereGeometry(radius, 20, 20, 0);
    const material = new THREE.MeshBasicMaterial({ color });
    const mesh = new THREE.Mesh(geometry, material);
    scene.add(mesh);

    return mesh;
};

export const loadTorus = (scene, radius = 400, tubeRadius = 2, color = 0x0000ff, arc = Math.PI * 2) => {

    const geometry = new THREE.TorusGeometry(radius, tubeRadius, 8, 60, arc);
    const material = new THREE.MeshBasicMaterial({ color });
    const mesh = new THREE.Mesh(geometry, material);
    scene.add(mesh);

    return mesh;
};

export const removeMesh = (scene, name) => {
    const mesh = scene.getObjectByName(name);
    if (mesh) {
        mesh.material.dispose();
        mesh.geometry.dispose();
        scene.remove(mesh)
    }
}

export const createLine = (scene, lineName, geometry = new THREE.BufferGeometry(), width = 1, color = 0x0000ff) => {
    removeMesh(scene, lineName);
    const material = new THREE.LineBasicMaterial({ color, linewidth: width });
    const line = new THREE.Line(geometry, material);
    line.name = lineName;
    scene.add(line);

    return line;
};

export const createTriangle = (scene, name, p1, p2, p3, color = 0x0000ff) => {
    removeMesh(scene, name);

    const geometry = new THREE.BufferGeometry();
    geometry.setFromPoints([p1, p2, p3]);
    const material = new THREE.MeshBasicMaterial({ color, side: THREE.DoubleSide });
    const mesh = new THREE.Mesh(geometry, material);
    mesh.name = name;
    scene.add(mesh);

    return mesh;
};

