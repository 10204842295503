import React from 'react';
import { useRef } from 'react';
import { Route, BrowserRouter } from 'react-router-dom';
import SignupScreen from "./screens/SignupScreen";
import { AnalyticsProvider } from './context/AnalyticsContext';
import Header from './Headers';

import HomeScreen from './screens/HomeScreen';
import VerifyScreen from './screens/VerifyScreen';
import EmailSendedScreen from './screens/EmailSendedSreen';
import SigninScreen from './screens/SigninScreen';
import { Provider as AuthProvider } from './context/Authcontext';
import ConnectionEmailSentScreen from './screens/ConnectionEmailSentScrren';
import ForgotPassScreen from './screens/ForgotPassScreen';
import Chapter12 from './screens/chapters/Chapter12';
import Privacy from './screens/Privacy';
import Chapter13 from './screens/chapters/Chapter13';
import Chapter14 from './screens/chapters/Chapter14';
import { CHAPTER_11, CHAPTER_12, CHAPTER_13, CHAPTER_14, CHAPTER_15, CHAPTER_16, CHAPTER_17 } from './apis/commonConsts';
import Chapter15 from './screens/chapters/Chapter15';
import Chapter16 from './screens/chapters/Chapter16';
import Chapter17 from './screens/chapters/Chapter17';
import Chapter11 from './screens/chapters/Chapter11';

const App = () => {

  const targetRef = useRef();
  // const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  // useLayoutEffect(() => {
  //   // console.log(targetRef);
  //   if (targetRef.current) {
  //     setDimensions({
  //       width: targetRef.current.offsetWidth,
  //       height: targetRef.current.offsetHeight
  //     });
  //   }

  // }, []);

  // console.log(dimensions);
  return (
    <div ref={targetRef} className="App">
      <Header />
      <Route path="/verify/:email" exact component={VerifyScreen} />

      <Route path="/" exact component={HomeScreen} />
      <Route path="/signup" exact component={SignupScreen} />
      <Route path="/emailsended/:email" exact component={EmailSendedScreen} />
      <Route path="/signin" exact component={SigninScreen} />
      <Route path="/connectionsent" exact component={ConnectionEmailSentScreen} />
      <Route path="/resetpass" exact component={ForgotPassScreen} />
      <Route path="/פרק-יב" exact component={Chapter12} />
      <Route path="/פרק-יג" exact component={Chapter13} />
      <Route path="/פרק-יד" exact component={Chapter14} />
      <Route path={CHAPTER_11} exact component={Chapter11} />
      <Route path={CHAPTER_12} exact component={Chapter12} />
      <Route path={CHAPTER_13} exact component={Chapter13} />
      <Route path={CHAPTER_14} exact component={Chapter14} />
      <Route path={CHAPTER_15} exact component={Chapter15} />
      <Route path={CHAPTER_16} exact component={Chapter16} />
      <Route path={CHAPTER_17} exact component={Chapter17} />
      <Route path="/תנאי-שימוש" exact component={Privacy} />

    </div>
  );
};

export default () => {
  return (
    <AuthProvider>
      <AnalyticsProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AnalyticsProvider>
    </AuthProvider>
  );
};
