import React, { useEffect, useState, useContext } from "react";
import firebase from "firebase";
import "firebase/database";
import "firebase/auth";
import { Context as Authcontext } from "../context/Authcontext";

import { Button, Container, Nav, Row } from "react-bootstrap";


const VerifyScreen = ({ match, history }) => {
    const [localState, setEmail] = useState(null);
    const [errorMessage, setErrorMessage ] = useState('');
    const { updateState } = useContext(Authcontext);

    const updateVerifiedState = (base64, user) => {
        
        // A post entry.
        user.verified = true;
        // Write the new post's data simultaneously in the posts list and the user's post list.
        var updates = {};
        updates['/users/' + base64] = user;


        firebase.database().ref().update(updates).then(e => {
            setEmail(user);
            updateState(user);
        }).catch(e => {
            setErrorMessage("הראה שגיאה באימות כתובת האימייל");
        });
    };

    const getUsetToVerify = (base64) => {
        const dbRef = firebase.database().ref();
        dbRef.child("users").child(base64).get().then((snapshot) => {
            if (snapshot.exists()) {
                const user = snapshot.val();                
                updateVerifiedState(base64, user);
            } else {
                setErrorMessage("כתובת האיממיל שלך לא נמצאה במאגר הנתונים.");
            }
        }).catch((error) => {
            setErrorMessage("ארעה שגיאה. יש לוודא כי אימות כתובת המייל נעשתה באותו מכשיר שנרשמת. או לחילופין להתחבר עם כתובת האימייל והסיסמא במכשיר בו אתה נמצא ואז ללחוץ על קישור האימות מהמייל שנישלח אליך. במידה וגם נסיון זה לא צלח העבר את מייל האימות שקיבלתה ל site@kiddushhachodesh.net.");
            //console.log(error);
        });

    };

    useEffect(() => {
        const base64 = match.params.email;
        // const e = atob(base64);
        // firebase.auth().signInWithEmailLink(e, window.location.href).then(res => {
        //     // console.log(res);
        //     getUsetToVerify(base64);
        // }).catch(err => {
        //     // console.log(err);
        // });
        getUsetToVerify(base64);

    }, []);

    const drawVerified = () => {
        return <Row className="justify-content-center">
            <h3 dir="rtl" className="text-center">ל {localState.username} שלום וברכה כתובת האימייל {localState.email} אומתה בהצלחה.</h3>
            <Nav>
                <Nav.Link className="btn-outline-primary" href="https://kiddushhodesh.com/">סגור</Nav.Link>
            </Nav>
        </Row>
    };

    return (
        <>
            <Container>
                {localState !== null ? drawVerified() :
                <h3 dir="rtl">{errorMessage}</h3>}
            </Container>
            
        </>
    );
};




export default VerifyScreen;