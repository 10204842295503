import React from "react";
import { Col, Row } from "react-bootstrap";
import { getHebrewDateFormat, getNewDateFromOrigin } from "../apis/dateConverters";
import { ikarShemesh, emzaSemeshSpeed, speedFormat, ikarDate } from "../apis/orbits/orbitsGeneral";
import { SpeedUnits } from "../utiles/hebrew-calculator";

import { FaPlusCircle, FaMinusCircle} from "react-icons/fa"


{/* <link
  rel="stylesheet"
  href="https://fonts.googleapis.com/icon?family=Material+Icons"
/> */}
const DaysFromIkar = ({ params }) => {
    const { days, setDays, shemeshPlusIkar } = params;


    // console.log(new DaysPast(ekar));

    const getTimesSunSpeed = () => {
        const path = (new SpeedUnits(emzaSemeshSpeed.cyclesCount * days));
        const text = `${speedFormat(emzaSemeshSpeed)} * ${days} =  ${speedFormat(path)}`;
        return text;
    }

    return (
        <div>
            <Row>
                <Col lg={4} md={6}>
                    <p className="text-center">{getHebrewDateFormat(ikarDate)}</p>
                </Col>
                <Col lg={4} md={6} style={{ textAlign: 'center' }}>
                    <Row className="justify-content-center">
                        <Col sm={4}  style={{padding: 0, marginBottom: 4}}>
                           <FaPlusCircle
                           fontSize={24}
                           onClick={() => setDays(days + 1)} />
                        </Col>
                        <Col sm={4} style={{padding: 0}}>

                            <input style={{ textAlign: 'center', width: 60 }}

                                type="number"
                                value={days}
                                onChange={e => setDays(parseInt(e.currentTarget.value))}
                            />
                        </Col>
                        <Col sm={4} style={{padding: 0, marginBottom: 4}}>
                            <FaMinusCircle 
                            fontSize={24}
                            onClick={() => setDays(days - 1)}/>
                        </Col>

                    </Row>
                </Col>
                <Col lg={4} md={6}>
                    <p className="text-center">{getHebrewDateFormat(getNewDateFromOrigin(days, ikarDate))}</p>
                </Col>
            </Row>
            <Row>
                <Col lg={4} md={6}>
                    <p className="text-center">{speedFormat(ikarShemesh)}</p>
                </Col>
                <Col lg={4} md={6}>
                    <p className="text-center">{getTimesSunSpeed()}</p>
                </Col>
                <Col lg={4} md={6}>
                    <p style={{ color: 'red', fontWeight: 'bolder' }} className="text-center">{speedFormat(new SpeedUnits(shemeshPlusIkar / 360))}</p>
                </Col>
            </Row>
        </div>
    );
};


export default DaysFromIkar;