import { Col } from "react-bootstrap";
import * as THREE from "three";
import { hebrewMazalut } from "./commonConsts";

const canvasAndContext = (width = 2160, height = 128) => {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    return {
        canvas,
        ctx
    };
};

export const flatMazalotTexture = (diameter = 512) => {
    const { canvas, ctx } = canvasAndContext(diameter, diameter);
    const half = diameter / 2;
    const inner = half * 0.8;

    ctx.strokeStyle = 'white';
    ctx.lineWidth = 4;

    ctx.beginPath();
    ctx.arc(half, half, half, 0, Math.PI * 2);
    ctx.arc(half, half, inner, 0, Math.PI * 2);
    ctx.stroke();
    ctx.clip('evenodd');

    var my_gradient = ctx.createRadialGradient(half, half, inner, half, half, half);
    const middle = "#03045e";//'#DC281E';
    my_gradient.addColorStop(0.5, middle);

    const sideCol = "#0096c7";//"#f00000";
    my_gradient.addColorStop(0, sideCol);
    my_gradient.addColorStop(1, sideCol);

    ctx.fillStyle = my_gradient;
    ctx.fillRect(0, 0, diameter, diameter);


    for (let i = 0; i < 12; i++) {
        ctx.save();
        ctx.translate(diameter / 2, diameter / 2);
        ctx.rotate(Math.PI / 6 * i);
        ctx.translate(-diameter / 2, -diameter / 2);
        ctx.moveTo(diameter / 2, 0);
        ctx.lineTo(diameter / 2, diameter - inner);

        ctx.restore();
    }
    ctx.stroke();

    ctx.textAlign = "center";
    ctx.direction = "rtl";
    ctx.fillStyle = '#ffffff';
    ctx.font = "900 36px Arial";

    for (let i = 0; i < 12; i++) {
        ctx.save();
        ctx.translate(diameter / 2, diameter / 2);
        ctx.rotate(Math.PI / 6 * i - Math.PI / 2 + Math.PI / 12);
        ctx.translate(-diameter / 2, -diameter / 2);
        ctx.fillText(hebrewMazalut[i], diameter / 2, 37);

        ctx.restore();
    }

    const texture = new THREE.CanvasTexture(canvas);

    return texture;

}

export const circleCords = (color = 'red', cords = 2, lineWidth = 6, diameter = 256) => {
    const { canvas, ctx } = canvasAndContext(diameter, diameter);

    ctx.fillStyle = '#ffffff';
    ctx.fillRect(0, 0, diameter, diameter)
    ctx.strokeStyle = color;
    ctx.lineWidth = lineWidth;

    for (let i = 0; i < cords; i++) {
        ctx.save();
        ctx.translate(diameter / 2, diameter / 2);
        ctx.rotate(Math.PI / cords * i);
        ctx.translate(-diameter / 2, -diameter / 2);
        ctx.moveTo(diameter / 2, 0);
        ctx.lineTo(diameter / 2, diameter);

        ctx.restore();
    }

    ctx.stroke();

    const texture = new THREE.CanvasTexture(canvas);

    return texture;
};

export const plusMinusYreachEmzaee = () => {
    const width = 512, height = 128;
    const { canvas, ctx } = canvasAndContext(width, height);
    const my_gradient = ctx.createLinearGradient(0, 0, 0, canvas.height);
    my_gradient.addColorStop(0, '#bb0000');
    my_gradient.addColorStop(0.75, '#bb3333');
    my_gradient.addColorStop(1, '#bb0000');


    ctx.fillStyle = my_gradient;

    ctx.fillRect(0, 0, width, height);

    ctx.lineWidth = 4;
    ctx.strokeStyle = '#ffffff';
    ctx.strokeRect(ctx.lineWidth / 2, ctx.lineWidth / 2, canvas.width - ctx.lineWidth, canvas.height - ctx.lineWidth);

    ctx.textAlign = "center";
    ctx.direction = "rtl";
    ctx.fillStyle = '#ffffff';
    ctx.font = "900 36px Arial";

    const firstLine = 'חיסור ויתור אמצע הירח לשעת ראיה';

    ctx.fillText(firstLine, width / 2, height / 4 + 18);

    const d = width / 120;

    const left = width / 2 - 15 * d;
    const right = width / 2 + 15 * d;

    ctx.moveTo(left, height / 2);
    ctx.lineTo(left, height);

    ctx.moveTo((left + width / 2) / 2, height * 3 / 4);
    ctx.lineTo((left + width / 2) / 2, height);

    ctx.moveTo((width / 2), height * 3 / 4);
    ctx.lineTo((width / 2), height);

    ctx.moveTo((right + width / 2) / 2, height * 3 / 4);
    ctx.lineTo((right + width / 2) / 2, height);

    ctx.moveTo(right, height / 2);
    ctx.lineTo(right, height);

    ctx.stroke();

    ctx.font = "900 20px Arial";
    ctx.fillText('30', right + 20, height / 2 + 20);
    ctx.fillText('30-', left - 25, height / 2 + 20);
    ctx.fillText('חלקים', width / 2, height / 2 + 20);




    const texture = new THREE.CanvasTexture(canvas);

    return texture;
};

export const twoVerticalColore = (leftColor = '#000000', rightColor = '#000000', width = 256, height = 256) => {
    const { canvas, ctx } = canvasAndContext(width, height);
    ctx.fillStyle = leftColor;
    ctx.fillRect(0, 0, width / 4, height);
    ctx.fillStyle = rightColor;
    ctx.fillRect(width / 4, 0, width / 4, height);

    const texture = new THREE.CanvasTexture(canvas);

    return texture;
};

export const textTexture = (text, textColor = '#ffffff',
    font = "900 72px Arial",
    startcolor = '#ff0000', endColor = '#ff0000',
    middle = '#aa0000', linecolor = '#ffffff',
    width = 1024, height = 128, lineWidth = 4, scaleY = 1, scaleX = 1) => {

    const { canvas, ctx } = canvasAndContext(width, height);

    const my_gradient = ctx.createLinearGradient(0, 0, 0, canvas.height);
    my_gradient.addColorStop(0, startcolor);
    my_gradient.addColorStop(0.75, middle);
    my_gradient.addColorStop(1, endColor);
    ctx.fillStyle = my_gradient;
    ctx.fillRect(0, 0, width, height);
    ctx.lineWidth = lineWidth;
    ctx.strokeStyle = linecolor;
    ctx.strokeRect(ctx.lineWidth / 2, ctx.lineWidth / 2, canvas.width - ctx.lineWidth, canvas.height - ctx.lineWidth);
    // ctx.moveTo(0, canvas.height - ctx.lineWidth / 2);
    // ctx.lineTo(canvas.width, canvas.height - ctx.lineWidth / 2);


    ctx.fillStyle = textColor;
    ctx.font = font;
    ctx.textAlign = "center";
    ctx.direction = "rtl";

    ctx.translate(width / 2, height / 2)
    ctx.scale(scaleX, scaleY)
    ctx.fillText(text, 0, 18);
    ctx.stroke();

    const texture = new THREE.CanvasTexture(canvas);

    return texture;
};

export const verticalLines = (backGroungColor = '#00E0E0', lineColor = '#FFCC00', lines = 10, width = 256, height = 256, lineWidth = 2) => {
    const { canvas, ctx } = canvasAndContext(width, height);
    const my_gradient = ctx.createLinearGradient(0, 0, width, 0);
    my_gradient.addColorStop(0, '#dddd00');
    my_gradient.addColorStop(1, '#0000cc');
    ctx.fillStyle = my_gradient;//backGroungColor;
    ctx.fillRect(0, 0, width, height);
    ctx.lineWidth = lineWidth;
    ctx.strokeStyle = lineColor;
    const stepSize = height / lines;
    const halfStepSize = stepSize / 2;
    for (let i = 0; i < lines; i++) {
        ctx.moveTo(0, halfStepSize + i * stepSize + lineWidth / 2);
        ctx.lineTo(width, halfStepSize + i * stepSize + lineWidth / 2);
    }
    ctx.stroke();

    const texture = new THREE.CanvasTexture(canvas);

    return texture;
};

export const spiralTexture = (startcolor = '#ffa0a0', endColor = '#ff0000', middle = '#ffffff', linecolor = '#ff0000', width = 2048, height = 128, lineWidth = 4) => {
    const { canvas, ctx } = canvasAndContext(width, height);
    const my_gradient = ctx.createLinearGradient(0, 0, 0, canvas.height);
    my_gradient.addColorStop(0, startcolor);
    my_gradient.addColorStop(0.75, middle);
    my_gradient.addColorStop(1, endColor);
    ctx.fillStyle = my_gradient;
    ctx.fillRect(0, 0, width, height);
    ctx.lineWidth = lineWidth;
    ctx.strokeStyle = linecolor;
    ctx.moveTo(0, canvas.height - ctx.lineWidth / 2);
    ctx.lineTo(canvas.width, canvas.height - ctx.lineWidth / 2);
    ctx.stroke();

    const texture = new THREE.CanvasTexture(canvas);

    return texture;
};

export const verticalGradientTexure = (width = 354, colorsStop = [{ offset: 1, color: "#000000" }, { offset: 0.9, color: "#03045e" }, { offset: 0, color: "#0096c7" }]) => {
    const { canvas, ctx } = canvasAndContext(width, width);
    const x = width / 2;

    var my_gradient = ctx.createRadialGradient(x, x, 0, x, x, x);
    for (let i = 0; i < colorsStop.length; i++) {
        const c = colorsStop[i];
        my_gradient.addColorStop(c.offset, c.color);
    }
    ctx.fillStyle = my_gradient;
    ctx.fillRect(0, 0, width, width);
    const texture = new THREE.CanvasTexture(canvas);

    return texture;
};

export const mazalotTexture = (lookFromWest = false) => {
    const { canvas, ctx } = canvasAndContext(2340, 72);
    var my_gradient = ctx.createLinearGradient(0, 0, 0, canvas.height);
    my_gradient.addColorStop(0.5, "#DC281E");

    const sideCol = "#f00000";
    my_gradient.addColorStop(0, sideCol);
    my_gradient.addColorStop(1, sideCol);


    ctx.fillStyle = 'red';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    // ctx.fillStyle = 'blue';
    // ctx.fillRect(100, 20, canvas.width / 2, canvas.height / 2);
    ctx.lineWidth = 4;
    const steps = 12;
    const step = canvas.width / steps;

    ctx.strokeStyle = '#ffffff';
    ctx.font = "900 60px Arial";
    ctx.textAlign = "center";

    ctx.moveTo(0, ctx.lineWidth / 2);
    ctx.lineTo(canvas.width, ctx.lineWidth / 2);
    ctx.moveTo(0, canvas.height - ctx.lineWidth / 2);
    ctx.lineTo(canvas.width, canvas.height - ctx.lineWidth / 2);

    ctx.fillStyle = 'white';



    for (var i = 0; i < steps; i++) {
        const pos = lookFromWest ? (steps - 1) - i : i;
        ctx.moveTo(pos * step + ctx.lineWidth / 2, 0);
        ctx.lineTo(pos * step + ctx.lineWidth / 2, canvas.height);
        const text = hebrewMazalut[i];

        ctx.save();

        ctx.translate((11.5 - pos) * step, canvas.height / 2);
        ctx.scale(-1, -1);
        ctx.fillText(text, 0, 18);

        ctx.restore();
    }
    ctx.stroke();

    ctx.setTransform(-1, 0, 0, 1, 0, canvas.height);
    // const {canvas: canvas2 , ctx: ctx2} = canvasAndContext();
    // ctx2.drawImage(canvas, 0, 0);
    const texture = new THREE.CanvasTexture(canvas);

    return texture;
};

export const worldLineAlfaMapTexture = (lineColor = "#222222", lineWidth = 4) => {
    const { canvas, ctx } = canvasAndContext(512, 256);
    ctx.fillStyle = 'black';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.strokeStyle = lineColor;

    const steps = 12;
    const step = canvas.width / steps;
    for (var i = 0; i <= steps; i++) {
        ctx.moveTo(i * step, 0);
        ctx.lineTo(i * step, canvas.height);

    }
    ctx.stroke();

    const texture = new THREE.CanvasTexture(canvas);

    return texture;
};

export const textAlfaMap = (text, y = 0.5, width = 512, height = 128, xScale = 1, font = "900 60px Arial", dir = "rtl") => {
    const { canvas, ctx } = canvasAndContext(width, height);
    ctx.fillStyle = 'black';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    ctx.fillStyle = '#ffffff';
    ctx.font = font;
    ctx.textAlign = "center";
    ctx.direction = dir;
    ctx.translate(width / 2, canvas.height * y);
    ctx.scale(xScale, 1);
    ctx.fillText(text, 0, 0);

    const texture = new THREE.CanvasTexture(canvas);

    return texture;
};

export const mazalotInnerAlfaMapTexture = (isBack = true, lookFromWest = false) => {
    const { canvas, ctx } = canvasAndContext(2340, 72);


    ctx.fillStyle = 'black';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    ctx.lineWidth = 4;
    const steps = 12;
    const step = canvas.width / steps;

    ctx.strokeStyle = '#ffffff';
    ctx.font = "900 60px Arial";
    ctx.textAlign = "center";

    ctx.moveTo(0, ctx.lineWidth / 2);
    ctx.lineTo(canvas.width, ctx.lineWidth / 2);
    ctx.moveTo(0, canvas.height - ctx.lineWidth / 2);
    ctx.lineTo(canvas.width, canvas.height - ctx.lineWidth / 2);

    ctx.fillStyle = 'white';

    for (var i = 0; i < steps; i++) {
        const pos = lookFromWest ? (steps - 1) - i : i;
        ctx.moveTo(pos * step + ctx.lineWidth / 2, 0);
        ctx.lineTo(pos * step + ctx.lineWidth / 2, canvas.height);
        const text = hebrewMazalut[i];

        ctx.save();

        ctx.translate((11.5 - pos) * step, canvas.height / 2);
        ctx.scale(isBack ? -1 : 1, 1);
        ctx.fillText(text, 0, 18);

        ctx.restore();
    }
    ctx.stroke();

    // ctx.setTransform(-1, 0, 0, 1, 0, canvas.height);
    const texture = new THREE.CanvasTexture(canvas);

    return texture;
};