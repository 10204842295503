import createDataContext from "./createDataContext";

const AUTH_AUPDATE_STATE = "auth_aupdate_state";

const authReducer = (state, action) => {
    switch (action.type) {
        case AUTH_AUPDATE_STATE:            
            return {...action.payload};    
        default:
            return state;
    }
};

const updateState = dispatch => {
    return user => {
        dispatch({type: AUTH_AUPDATE_STATE, payload: user});
    };
};


export const { Provider, Context } = createDataContext(
    authReducer,
    { updateState },
    { username: '', email: '', verified: false, useContent: false }
);