import firebase from "firebase";
import "firebase/auth";

const getActionCodeSettings = (email) => {
    const userId = btoa(email);
    var actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be in the authorized domains list in the Firebase Console.
        url: `https://kiddushhodesh.com/verify/${userId}`,
        // This must be true.
        handleCodeInApp: true,
        // iOS: {
        //   bundleId: 'com.example.ios'
        // },
        // android: {
        //   packageName: 'com.example.android',
        //   installApp: true,
        //   minimumVersion: '12'
        // },
        // dynamicLinkDomain: 'example.page.link'
    };

    return actionCodeSettings;

};

export default async (email, history, setError, lang = "he") => {
    const actionCodeSettings = getActionCodeSettings(email);
    try {
        firebase.auth().languageCode = lang;
        await firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings);
        history.push(`/emailsended/${email}`);
    } catch (error) {
        if (setError) {
            setError(error);
        }
    
    }

};