import React, { useEffect, useRef, useContext, useState } from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";

import { AnalyticsContext } from "../../context/AnalyticsContext";
import DualPaneManager from "../DualPaneManager";
import { MetaTags } from "react-meta-tags";
import CreateOrVerifyEmail from "../../components/CreateOrVerifyEmail";
import ContactAndOrder from "../../components/ContactAndOrder";
import { Context as Authcontext } from "../../context/Authcontext";
import { CHAPTER_17, ORDER_MESSAGE } from "../../apis/commonConsts";
import Chapter17Content from "../../components/chaptersContents/Chapter17Content";
import KeshetSkiaaOrechRishon, { AUTO_RUN_KESHET_ORECH_RISHON_OPEN, C_17_P_1, C_17_P_3_1 } from "../../components/3d/KeshetSkiaaOrechRishon";
import RochavHaYareach from "../../components/3d/RochavHaYareach";



export const VIEW_C17_STATE_OPEN = 0;
export const VIEW_C17_STATE_1 = 1;
export const VIEW_C17_STATE_2 = 2;

export const VIEW_C17_STATE_3 = 3;

const Chapter17 = ({ history }) => {
    const [viewState, setViewState] = useState(VIEW_C17_STATE_OPEN);
    const { state } = useContext(Authcontext);
    const { height, width, graphicWidth, paneHeight } = useWindowDimensions();
    const { analytics } = useContext(AnalyticsContext);

    const [days, setDays] = useState(29);

    const signinOrVerifyRef = useRef(null);
    const orderRef = useRef(null);

    const keshetSkiaaOrechRishonRef = useRef();
    const rochavYareachRef = useRef();

    useEffect(() => {
        analytics();
    }, []);

    useEffect(() => {

    }, [days]);

    const setState = () => {
        switch (viewState) {
            case VIEW_C17_STATE_OPEN:
            default:
                keshetSkiaaOrechRishonRef.current.setRunState(AUTO_RUN_KESHET_ORECH_RISHON_OPEN)
                break;
            case VIEW_C17_STATE_1:
                keshetSkiaaOrechRishonRef.current.setRunState(C_17_P_1);
                break;
            case VIEW_C17_STATE_2:
                break;
            case VIEW_C17_STATE_3:
                keshetSkiaaOrechRishonRef.current.setRunState(C_17_P_3_1);
        }
    };

    const get3D = () => {
        switch (viewState) {
            case VIEW_C17_STATE_2:
                return <RochavHaYareach ref={rochavYareachRef} />; 
            case VIEW_C17_STATE_OPEN:
            case VIEW_C17_STATE_1:
            default:
                return <KeshetSkiaaOrechRishon ref={keshetSkiaaOrechRishonRef} />;
        }
    };

    useEffect(() => {
        setState();
        keshetSkiaaOrechRishonRef.current?.setSise(paneHeight, graphicWidth);
        rochavYareachRef.current?.setSise(paneHeight, graphicWidth);

    }, [height, width, viewState]);


    return (
        <div>
            <MetaTags>
                <title>הלכות קידוש החודש לרמב"ם פרק יז</title>
                <meta name="description" content='בפרק יז הלכות קידוש החודש לרמב"ם יבואר חישוב אורך ראשון מזלות קיצרי השקיעה וארוכי השקיעה שינוי המראה אורך שני רוחב שני אורך שלישי, אורך רביעי קשת רוחב המדינה וקשת השקיעה, וקיצי הראיה..' />
            </MetaTags>
            <CreateOrVerifyEmail state={state} history={history} ref={signinOrVerifyRef} />
            <ContactAndOrder history={history} ref={orderRef} hidden={true} nessage={ORDER_MESSAGE} />
            <DualPaneManager
                graphicPane={get3D()}

                dataPane={< Chapter17Content
                    setViewState={setViewState}
                    params={{ days, setDays }} />}

                height={height}
                width={width}
            />

        </div>
    );
};


export default Chapter17;