import React from "react";
import { useAccordionButton } from "react-bootstrap";

const ToggeleSpan = ({ children, eventKey, className, setState }) => {
    const spanOnClick = useAccordionButton(eventKey, () => {
        if (setState) {
            setState();
        }
    });

    return (
        <div style={{textAlign: 'center'}}>
            <span
                onClick={spanOnClick}
                className={className}>{children}</span>
        </div>
    );
};

export default ToggeleSpan;
