import { forwardRef, useImperativeHandle, useState, useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import sendVerificationEmail from "../apis/sendVerificationEmail";
import { Context as Authcontext } from "../context/Authcontext";

const CreateOrVerifyEmail = forwardRef(({baseMsg, history}, ref) => {
    const { state } = useContext(Authcontext);
    const [needLogin, setNeedLogin] = useState(false);
    const [needVerify, setNeedVerify] = useState(false);

    const handleNeedLoginClose = () => setNeedLogin(false);
    const handleNeedLoginShow = () => setNeedLogin(true);
    const handleNeedVerifyClose = () => setNeedVerify(false);
    const handleNeedVerifyShow = () => setNeedVerify(true);

    useImperativeHandle(ref, () =>({
        showIfNeed() {
            // return true if action nedded else false
            if (state.email !== '' && state.verified === false) {
                handleNeedVerifyShow();
                return true;
            } else if (state.email === '') {
                handleNeedLoginShow();
                return true;
            }
            return false;
        }
    }));

    return ( 
        <>
            <Modal style={{ margin: 5 }} show={needVerify} onHide={handleNeedVerifyClose}>
                <Modal.Header>
                    <Modal.Title>צריך לאמת את כתובת האימייל</Modal.Title>
                </Modal.Header>
                <Modal.Body>{baseMsg} יש לאמת את כתובת האימייל {state.email}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleNeedVerifyClose}>
                        סגור
                    </Button>
                    <Button variant="primary" onClick={async() => {
                        await sendVerificationEmail(state.email, history, (e) => alert(e));
                        handleNeedVerifyClose();
                        // history.push('/signin');
                    }}>
                        לאימות כתובת האמייל
                    </Button>

                </Modal.Footer>

            </Modal>


            <Modal style={{ margin: 5 }} show={needLogin} onHide={handleNeedLoginClose}>
                <Modal.Header closeButton>
                    <Modal.Title>צריך להתחבר או להרשם</Modal.Title>
                </Modal.Header>
                <Modal.Body>{baseMsg} יש להתחבר או להרשם</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleNeedLoginClose}>
                        סגור
                    </Button>
                    <Button variant="primary" onClick={() => {
                        handleNeedLoginClose();
                        history.push('/signin');
                    }}>
                        להתחברות או הרשמה
                    </Button>

                </Modal.Footer>
            </Modal>
        </>
    );
});

export default CreateOrVerifyEmail;