import React, { useState } from "react";
import { Button, ButtonGroup, Col, Row, ToggleButton } from "react-bootstrap";
import "./HomeSunPaths.css";
import { AUTO_RUN_GOVA, AUTO_RUN_MAZALOT, AUTO_RUN_SHEMESH, RUN_FROM_OUT } from "./SunPaths";
import 'bootstrap/dist/css/bootstrap.min.css';


// import Typography from '@material-ui/core/Typography';
// import HomePageContent from "./HomePageContent";
import { useRef } from "react";
import useWindowDimensions from "../hooks/useWindowDimensions";
import Slider2D from "./controls/Slider2D";

const HomeSunPaths = ({runState, setRunState, setPaths, height, manual, setManual }) => {
    // const [runState, setState] = useState(AUTO_RUN_MAZALOT);
    // const [manual, setManual] = useState(false);
    const { dataWidth: width } = useWindowDimensions();

    const radios = [
        { name: 'המזלות', runState: AUTO_RUN_MAZALOT, variant: 'outline-danger xs' },
        { name: 'גובה השמש', runState: AUTO_RUN_GOVA, variant: 'outline-success xs' },
        { name: 'השמש', runState: AUTO_RUN_SHEMESH, variant: 'outline-warning xs' }
    ];

    const paths = useRef({ shemeshPath: 0, govaPath: 0, mazalhPath: 0 });



    const setShemeshPath = (v) => {
        paths.current.shemeshPath = v
        if (setPaths)
            setPaths(paths.current.mazalhPath, paths.current.govaPath, v);
    };
    const setMazalPath = (v) => {
        paths.current.mazalhPath = v
        if (setPaths)
            setPaths(v, paths.current.govaPath, paths.current.shemeshPath);
    };
    const setGovaPath = (v) => {
        paths.current.govaPath = v
        if (setPaths)
            setPaths(paths.current.mazalhPath, v, paths.current.shemeshPath);

    };

    const widthLessThan = width < 600;

    const trackWidth = widthLessThan ? width * 0.8 : width / 4;

    const selectControle = () => {
        if (manual) {
            return (<div className="frame">
                <Row>
                    <Col lg={10}>
                        <Row>
                            <Col lg={4}>
                                <Slider2D width={trackWidth}
                                    onChange={setShemeshPath}
                                    text="מסלול השמש"
                                    bodyColor="orange"
                                />
                            </Col>

                            <Col lg={4}>
                                <Slider2D width={trackWidth}
                                    onChange={setMazalPath}
                                    text="מהלך המזלות"
                                    bodyColor={"red"}
                                />
                            </Col>

                            <Col lg={4}>
                                <Slider2D width={trackWidth}
                                    onChange={setGovaPath}
                                    text="גובה השמש"
                                    bodyColor={"#80b918"}
                                />
                            </Col>

                        </Row>
                    </Col>
                    <Col ls={2} style={{ paddingTop: 5 }}>
                        <Button variant="primary" size="sm"
                            onClick={() => {
                                // setState(AUTO_RUN_MAZALOT);
                                setManual(false);
                                if (setRunState)
                                    setRunState(AUTO_RUN_MAZALOT);
                            }}
                        >למהלכים</Button>
                    </Col>
                </Row>
            </div >
            );
        }

        return (
            <div className="frame">
                <p>תנועת</p>
                <ButtonGroup>
                    {radios.map((radio, idx) => (
                        <ToggleButton
                            key={idx}
                            id={`radio-${idx}`}
                            type="radio"
                            variant={radio.variant}
                            name="radio"
                            size="sm"
                            value={radio.runState}
                            checked={runState === radio.runState}
                            onChange={(e) => {
                                // setState(parseInt(e.currentTarget.value));
                                if (setRunState) {
                                    setRunState(parseInt(e.currentTarget.value));
                                }

                            }}
                        >
                            {radio.name}
                        </ToggleButton >
                    ))}
                    <ToggleButton
                        key={radios.length}
                        id={`radio-${radios.length}`}
                        type="radio"
                        variant={'outline-primary xs'}
                        name="radio"
                        size="sm"
                        value={RUN_FROM_OUT}
                        checked={false}
                        onChange={(e) => {
                            setManual(true);
                            if (setRunState) {
                                setRunState(e.currentTarget.value);
                            }
                            if (setPaths) {
                                setPaths(0, 0, 0);
                            }

                        }}
                    >
                        ידנית
                    </ToggleButton >

                </ButtonGroup>
            </div>
        );

    }

    return selectControle();






};

export default HomeSunPaths;