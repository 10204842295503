import React, { useEffect, useRef, useContext, useState } from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";

import SunPaths, { AUTO_RUN_GOVA, AUTO_RUN_SHEMESH, CIRCLE_SHEMESH, MHALACH_SHEMESH, MHALACH_SHEMESH_AND_IKAR, NO_LINES, RUN_FROM_OUT } from "../../components/SunPaths";
import { AnalyticsContext } from "../../context/AnalyticsContext";
import DualPaneManager from "../DualPaneManager";
import { MetaTags } from "react-meta-tags";
import Chapter12content from "../../components/chaptersContents/Chapter12Content";
import CreateOrVerifyEmail from "../../components/CreateOrVerifyEmail";
import ContactAndOrder from "../../components/ContactAndOrder";
import { Context as Authcontext } from "../../context/Authcontext";
import { ORDER_MESSAGE } from "../../apis/commonConsts";
import { SpeedUnits } from "../../utiles/hebrew-calculator";
import { emzaSemeshSpeed, ikarShemesh } from "../../apis/orbits/orbitsGeneral";

export const VIEW_C12_STATE_OPEN = 0;
export const VIEW_C12_STATE_1 = 1;
export const VIEW_C12_STATE_2 = 2;
export const VIEW_C12_STATE_3 = 3;
export const VIEW_C12_STATE_4 = 4;
export const VIEW_C12_STATE_5 = 5;
export const VIEW_C12_STATE_6 = 6;
export const VIEW_C12_STATE_7 = 7;
export const VIEW_C12_STATE_8 = 8;



const Chapter12 = ({ history }) => {

    const [days, setDays] = useState(100);
    const [shemeshPlusIkar, setShemeshPlusIkar] = useState(0);
    const { state } = useContext(Authcontext);
    const sunPathsRef = useRef();
    const [viewState, setViewState] = useState(VIEW_C12_STATE_OPEN);
    const { height, width, graphicWidth, paneHeight } = useWindowDimensions();
    const { analytics } = useContext(AnalyticsContext);

    const signinOrVerifyRef = useRef(null);
    const orderRef = useRef(null);

    const day = new SpeedUnits(0, 0, 59, 8);

    useEffect(() => {
        analytics();
    }, []);

    const calculateMaslulShemesh = () => {
        if (VIEW_C12_STATE_8 !== viewState) {
            return;
        }
        const v = emzaSemeshSpeed.degreesCount * days + ikarShemesh.degreesCount;

        setShemeshPlusIkar(v);
        sunPathsRef.current.setIkarShemesh(ikarShemesh.degreesCount);
        sunPathsRef.current?.setPaths(0, 80, v);

    };

    useEffect(() => {
        calculateMaslulShemesh();
    }, [days]);

    const setState = () => {
        switch (viewState) {
            case VIEW_C12_STATE_OPEN:
            default:
                sunPathsRef.current?.setRunState(AUTO_RUN_GOVA | AUTO_RUN_SHEMESH | NO_LINES | MHALACH_SHEMESH);
                sunPathsRef.current?.setGlobalXRotation(67);
                sunPathsRef.current?.setSpeeds(0.5, 0.05);
                break;
            case VIEW_C12_STATE_1:
                if (signinOrVerifyRef.current.showIfNeed()) {
                    break;
                }
                sunPathsRef.current?.setRunState(RUN_FROM_OUT | NO_LINES | MHALACH_SHEMESH);
                sunPathsRef.current?.setGlobalXRotation(67);
                sunPathsRef.current?.setPaths(0, 80, new SpeedUnits(0, 9, 51, 23).degreesCount);
                break;
            case VIEW_C12_STATE_2:

                if (signinOrVerifyRef.current.showIfNeed()) {
                    break;
                }
                sunPathsRef.current?.setRunState(RUN_FROM_OUT | NO_LINES | MHALACH_SHEMESH);
                sunPathsRef.current?.setGlobalXRotation(67);
                sunPathsRef.current?.setPaths(0, 80, day.degreesCount * 100);
                break;
            case VIEW_C12_STATE_3:
                if (signinOrVerifyRef.current.showIfNeed()) {
                    break;
                }
                sunPathsRef.current?.setRunState(RUN_FROM_OUT | NO_LINES | MHALACH_SHEMESH | CIRCLE_SHEMESH);
                sunPathsRef.current?.setGlobalXRotation(67);
                sunPathsRef.current?.setPaths(0, 80, day.degreesCount * 1000);
                break;
            case VIEW_C12_STATE_4:
                if (signinOrVerifyRef.current.showIfNeed()) {
                    break;
                }
                sunPathsRef.current?.setRunState(RUN_FROM_OUT | NO_LINES | MHALACH_SHEMESH | CIRCLE_SHEMESH);
                sunPathsRef.current?.setGlobalXRotation(67);
                sunPathsRef.current?.setPaths(0, 80, day.degreesCount * 10000);
                break;
            case VIEW_C12_STATE_5:
                if (signinOrVerifyRef.current.showIfNeed()) {
                    break;
                }
                sunPathsRef.current?.setRunState(RUN_FROM_OUT | NO_LINES | MHALACH_SHEMESH);
                sunPathsRef.current?.setGlobalXRotation(67);
                sunPathsRef.current?.setPaths(0, 80, new SpeedUnits(0, 28, 35, 1).degreesCount);
                break;
            case VIEW_C12_STATE_6:
                if (signinOrVerifyRef.current.showIfNeed()) {
                    break;
                }
                sunPathsRef.current?.setRunState(RUN_FROM_OUT | NO_LINES | MHALACH_SHEMESH);
                sunPathsRef.current?.setGlobalXRotation(67);
                sunPathsRef.current?.setPaths(0, 80, new SpeedUnits(0, 348, 55, 15).degreesCount);
                break;
            case VIEW_C12_STATE_7:
                if (signinOrVerifyRef.current.showIfNeed()) {
                    break;
                }
                sunPathsRef.current?.setRunState(AUTO_RUN_GOVA | AUTO_RUN_SHEMESH | NO_LINES);
                sunPathsRef.current?.setGlobalXRotation(67);
                sunPathsRef.current?.setSpeeds(0.5, 0.5);
                break;
            case VIEW_C12_STATE_8:
                if (signinOrVerifyRef.current.showIfNeed()) {
                    break;
                }
                sunPathsRef.current?.setRunState(RUN_FROM_OUT | NO_LINES | MHALACH_SHEMESH_AND_IKAR | CIRCLE_SHEMESH);
                sunPathsRef.current?.setGlobalXRotation(67);
                calculateMaslulShemesh();
                break;

        }

    };

    useEffect(() => {
        setState();
        sunPathsRef.current?.setSise(paneHeight, graphicWidth);
    }, [height, width, viewState]);

    return (
        <div>
            <MetaTags>
                <title>הלכות קידוש החודש לרמב"ם פרק יב</title>
                <meta name="description" content='בפרק יב הלכות קידוש החודש לרמב"ם יבואר מהלך השמש האמצעי ומהלך גובה השמש.' />
            </MetaTags>
            <CreateOrVerifyEmail state={state} history={history} ref={signinOrVerifyRef} />
            <ContactAndOrder history={history} ref={orderRef} hidden={true} nessage={ORDER_MESSAGE} />
            <DualPaneManager
                graphicPane={<SunPaths ref={sunPathsRef} />}
                dataPane={<Chapter12content
                    params={{ days, setDays, shemeshPlusIkar }}
                    setViewState={setViewState}
                    useContent={state.useContent === true} />}
                height={height}
                width={width}
            />

        </div>
    );
};


export default Chapter12;