import { useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import * as THREE from "three";
import { GRAPHIC_PADDING } from "../../apis/commonConsts";
import { createLine, loadBall, loadEarth, loadGradientRing, loadMoon, loadRing, loadRingTexture, loadSun, ringWithTexture, removeMesh } from "../../apis/loaders";
import { emzaMaslulYareachSpeed, emzaSemeshSpeed, emzaYareachSpeed, hisurYeturEmzaYareach, threePointsAngle } from "../../apis/orbits/orbitsGeneral";

import { Matrix, Point3D } from "../../utiles/hebrew-calculator";
import { getGraphicWidthHeight } from "../../utiles/helperFunctions";
import { setGroupPosition, Vector3D } from "../../utiles/threeDHelpers";

export const AUTO_RUN_MERCHAK_KAFUL = 1;
export const EMZA_MASLUL_YAREACH = 2;
export const EMZA_SHEMES = 4;
export const DRAW_MERCHAK = 8;
export const DRAW_MERCHAK_KAFUL = 16;
export const DRAW_ALL = 62;
export const AUTO_MERCHAK_KAFUL_LIMIT = 64;

const LINE_MIDDLE_EMZA_YAREACH = "line_middle_emza_yareach";
const LINE_MIDDLE_EMZA_SHEMESH = "line_middle_emza_shemesh";
const ARC_MERHAK = "arc_merhak";
const ARC_MERHAK_KAFUL = "arc_merhak_kaful";
const LINE_KAFUL = "line_kaful";
const LINE_TUSEFET = "line_tusefet";
const ARC_TUSEFET = "arc_tusefet";
const ARC_MASLUL_YAREAACH = "arc_maslul_yareaach";

const MerchakKaful = forwardRef((props, ref) => {

    const mountRef = useRef(null);

    const [cameraRef, setCameraRef] = useState(null);
    const [rendererRef, setRendererRef] = useState(null);
    const [width, setWidth] = useState(500);
    const [height, setHeight] = useState(500);

    const vars = useRef({
        stateToRun: AUTO_RUN_MERCHAK_KAFUL | DRAW_ALL,
        updateAllObject: null, // calback function
        days: 0,
        sunStart: 90,
        moonStart: 90,
        kafulLimit: 0,
        count: 0

    });

    const isStopRun = useRef(false);

    useEffect(() => {
        if (!cameraRef || !rendererRef) {
            return;
        }

        const { w, h } = getGraphicWidthHeight();

        cameraRef.aspect = w / h;
        cameraRef.updateProjectionMatrix();
        rendererRef.setSize(w, h);
    }, [height, width]);

    useImperativeHandle(ref, () => ({

        setRunState(runState) {
            vars.current.stateToRun = runState;
            vars.current.days = 0;
            vars.current.sunStart = 90;
            vars.current.moonStart = 90;
            vars.current.kafulLimit = 0;
            vars.current.updateAllObject();
        },
        setSise(h, w) {
            setWidth(w);
            setHeight(h);
            vars.current.updateAllObject();
        },
        setStart(s, m) {
            vars.current.sunStart = 0;
            vars.current.moonStart = 0;
            vars.current.updateAllObject();
        },
        setDays(days) {
            vars.current.days = days;
            vars.current.updateAllObject();
        }

    }));

    const isVisible = (state) => {
        return (state & vars.current.stateToRun) === state;
    };

    useEffect(() => {
        const { w, h } = getGraphicWidthHeight();


        var scene = new THREE.Scene();
        var camera = new THREE.PerspectiveCamera(60, w / h, 0.1, 20000);
        var renderer = new THREE.WebGLRenderer();


        renderer.setSize(w, h);


        mountRef.current.appendChild(renderer.domElement);

        const earthPoint = new Point3D(0, 0, -1000);
        const emzaYareachRadius = 300;
        const emzaYareachWidth = 10;
        const emzaMaslulYareachRadius = 55;
        const emzaMaslulYareachWidth = 10;

        const mahalachEmzaMaslulYareach = emzaMaslulYareachRadius + 20;
        const mahalachEmzaMaslulYareachWidth = 10;

        const emzaShemeshRadius = 400;

        const maagalDimyoniRadius = Math.round(emzaYareachRadius * 0.195 * 2);
        const maagalDimyoniWidth = 4;



        const emzaYareachPoint = new Point3D(-emzaYareachRadius + emzaYareachWidth / 2, 0, 0);
        const emzaMaslulYareachPoint = new Point3D(-emzaMaslulYareachRadius + emzaMaslulYareachWidth / 2, 0, 0);
        const emzaSunPoint = new Point3D(-emzaShemeshRadius);
        const opositeKafulPoint = new Point3D(-maagalDimyoniRadius + maagalDimyoniWidth / 2, 0, 0);

        const { mesh: background } = loadGradientRing(scene, 2000, 2000);
        background.position.z = -3000;

        loadRing(scene, emzaYareachRadius, emzaYareachWidth, 0xffff00);
        loadRing(scene, maagalDimyoniRadius, maagalDimyoniWidth, 0x8888ff);
        const { mesh: emzaMaslulYareachMesh } = loadRing(scene, emzaMaslulYareachRadius, emzaMaslulYareachWidth, 0xff8e00);
        const sun = loadSun(scene);

        const moon = loadMoon(scene, emzaMaslulYareachWidth);
        moon.rotation.y = Math.PI / 2;

        loadEarth(scene, 15);
        const opositeKaful = loadBall(scene, 7, 0xff0000);
        const kafulBall = loadBall(scene, 7, 0xff0000);



        camera.position.z = 5;
        setGroupPosition(scene, earthPoint);

        setCameraRef(camera);
        setRendererRef(renderer);

        const mat = new Matrix();
        vars.current.updateAllObject = () => {
            const sunPos = (vars.current.days *
                emzaSemeshSpeed.degreesCount + vars.current.sunStart) % 360;

            const moonPos = (vars.current.days *
                emzaYareachSpeed.degreesCount + vars.current.moonStart +
                hisurYeturEmzaYareach(sunPos).hisurYetur) % 360;

            const emzaMaslulMoonPos = (vars.current.days * emzaMaslulYareachSpeed.degreesCount) % 360;

            mat.init();
            mat.rotMulZ(-moonPos);
            let ptEmzaYareach = mat.transform(emzaYareachPoint);
            setGroupPosition(emzaMaslulYareachMesh, ptEmzaYareach);

            mat.init();
            mat.rotMulZ(-moonPos);
            let ptEmzaMaslulZero = mat.transform(emzaMaslulYareachPoint);
            ptEmzaMaslulZero.plusEqual(ptEmzaYareach);
            // setGroupPosition(moon, ptEmzaMaslulZero);

            mat.init();
            mat.rotMulZ(-sunPos);
            let ptEmzaShemesh = mat.transform(emzaSunPoint);
            setGroupPosition(sun, ptEmzaShemesh);
            sun.visible = isVisible(EMZA_SHEMES);

            const middelPoint = new Point3D(0, 0, 0);
            const toMaslulZero =
                (new Vector3D(middelPoint, ptEmzaYareach)).
                    scale((emzaYareachRadius + emzaMaslulYareachRadius) / emzaYareachRadius);

            createLine(scene,
                LINE_MIDDLE_EMZA_YAREACH,
                toMaslulZero.lineGeometry,
                1, 0xffffff);

            const toSun = new Vector3D(middelPoint, ptEmzaShemesh);

            createLine(scene,
                LINE_MIDDLE_EMZA_SHEMESH,
                toSun.lineGeometry,
                1, 0x33333ff).visible = isVisible(EMZA_SHEMES);

            const drawMerchak = () => {
                let diff = moonPos - sunPos;
                if (diff < 0) {
                    diff += 360;
                }
                removeMesh(scene, ARC_MERHAK);
                const { mesh: drawMerchakMesh } =
                    loadRing(scene, emzaYareachRadius - (emzaMaslulYareachRadius + 10 + emzaYareachWidth * 2), emzaYareachWidth * 2,
                        0x6666ff, 0, diff / 180 * Math.PI);

                drawMerchakMesh.name = ARC_MERHAK;

                drawMerchakMesh.rotation.z = -(diff + sunPos + 180) / 180 * Math.PI;
                drawMerchakMesh.visible = isVisible(DRAW_MERCHAK)

                return diff;

            };

            const merchak = drawMerchak();

            const drawMerchakKaful = () => {
                let double = merchak * 2;
                if (double > 360) {
                    double -= 360;
                }
                removeMesh(scene, ARC_MERHAK_KAFUL);
                const { mesh: drawMerchakKafukMesh } =
                    loadRing(scene, emzaYareachRadius - (emzaMaslulYareachRadius + 30 + emzaYareachWidth * 2), emzaYareachWidth * 2,
                        0xff00ff, 0, double / 180 * Math.PI);

                drawMerchakKafukMesh.name = ARC_MERHAK_KAFUL;

                drawMerchakKafukMesh.rotation.z = (- moonPos + 180) / 180 * Math.PI;
                drawMerchakKafukMesh.visible = isVisible(DRAW_MERCHAK_KAFUL)

                return double;
            };

            vars.current.kafulLimit = drawMerchakKaful();

            mat.init();
            mat.rotMulZ(-moonPos + vars.current.kafulLimit + 180);
            const ptOpositeKaful = mat.transform(opositeKafulPoint);

            setGroupPosition(opositeKaful, ptOpositeKaful);
            opositeKaful.visible = isVisible(DRAW_ALL);

            mat.init();
            mat.rotMulZ(-moonPos + vars.current.kafulLimit);
            const ptKaful = mat.transform(emzaSunPoint);
            setGroupPosition(kafulBall, ptKaful);
            kafulBall.visible = isVisible(DRAW_ALL);

            const kafileLineVector = new Vector3D(ptOpositeKaful, ptKaful);
            createLine(scene,
                LINE_KAFUL,
                kafileLineVector.lineGeometry,
                1, 0xff0000).visible = isVisible(DRAW_ALL);

            //ptEmzaYareach
            let tusefetMaslulYareachVector = new Vector3D(ptOpositeKaful, ptEmzaYareach);
            const vLength = tusefetMaslulYareachVector.product;
            tusefetMaslulYareachVector = tusefetMaslulYareachVector.scale((vLength + emzaMaslulYareachRadius - 3) / vLength);

            createLine(scene,
                LINE_TUSEFET,
                tusefetMaslulYareachVector.lineGeometry,
                1, 0xff0000).visible = isVisible(DRAW_ALL);

            let manatMerchakKaful = threePointsAngle(ptEmzaYareach, toMaslulZero.destanation, tusefetMaslulYareachVector.destanation);
            if (vars.current.kafulLimit > 180) {
                manatMerchakKaful = -manatMerchakKaful;
            }
            removeMesh(scene, ARC_TUSEFET);
            const { mesh: arcTosefet } = loadRing(scene, mahalachEmzaMaslulYareach - mahalachEmzaMaslulYareachWidth, emzaMaslulYareachRadius,
                0xffffff, 0, manatMerchakKaful);

            arcTosefet.name = ARC_TUSEFET
            setGroupPosition(arcTosefet, ptEmzaYareach);
            arcTosefet.rotation.z = (- moonPos + 180) / 180 * Math.PI;
            arcTosefet.visible = isVisible(DRAW_ALL);

            if (isVisible(EMZA_MASLUL_YAREACH)) {
                removeMesh(scene, ARC_MASLUL_YAREAACH);
                const { mesh: mahalachEmzaMaslulYareachMesh } =
                    loadRing(scene, mahalachEmzaMaslulYareach,
                        mahalachEmzaMaslulYareachWidth, 0xff0000, 0,
                        (emzaMaslulMoonPos % 360) / 180 * Math.PI);

                setGroupPosition(mahalachEmzaMaslulYareachMesh, ptEmzaYareach);
                mahalachEmzaMaslulYareachMesh.name = ARC_MASLUL_YAREAACH;
                mahalachEmzaMaslulYareachMesh.rotation.z = Math.PI - (moonPos % 360) / 180 * Math.PI + manatMerchakKaful;

            } else {
                removeMesh(scene, ARC_MASLUL_YAREAACH);
            }

            mat.init();
            mat.rotMulZ(-moonPos + emzaMaslulMoonPos + manatMerchakKaful / Math.PI * 180);
            let ptEmzaMaslul = mat.transform(emzaMaslulYareachPoint);
            ptEmzaMaslul.plusEqual(ptEmzaYareach);
            setGroupPosition(moon, ptEmzaMaslul);
            moon.visible = isVisible(EMZA_MASLUL_YAREACH);

        };
        const delay = 60;
        const autoRun = () => {
            const s = vars.current.stateToRun;
            if ((s & AUTO_RUN_MERCHAK_KAFUL) || (s & AUTO_RUN_MERCHAK_KAFUL)) {
                vars.current.days += 0.025;
                vars.current.updateAllObject();
            }
            else if (isVisible(AUTO_MERCHAK_KAFUL_LIMIT)) {

                if (vars.current.count > delay && vars.current.kafulLimit > 62) {
                    vars.current.count = 0;
                }
                if (vars.current.kafulLimit > 62 && vars.current.count > (delay - 1)) {
                    vars.current.days = 0.2;
                    vars.current.count = 0;
                }
                if (vars.current.count > delay) {
                    vars.current.days += 0.01;
                }

                vars.current.updateAllObject();
            }

            vars.current.count++;
        };

        var animate = function () {
            if (isStopRun.current) {
                return;
            }
            requestAnimationFrame(animate);

            autoRun();

            renderer.render(scene, camera);
        };

        const copymountRef = mountRef;
        animate();

        return () => {
            mountRef.current?.removeChild(renderer.domElement);
            copymountRef.current?.removeChild(renderer.domElement);
            isStopRun.current = true;
        };
        
    }, []);

    return (
        <div ref={mountRef} style={{ padding: GRAPHIC_PADDING }} />

    );
});

export default MerchakKaful;