import React from "react";
import { Accordion } from "react-bootstrap";
import ToggeleSpan from "./ToggeleSpan";


const AccodionSpan = ({ children, collapseItem, className, setState }) => {
    return (
        <Accordion defaultActiveKey="-1">
            <ToggeleSpan className={className} eventKey="0" setState={setState}>
                {children}
            </ToggeleSpan>
            <Accordion.Collapse eventKey="0">
                {collapseItem}
            </Accordion.Collapse>
        </Accordion>
    );
};

export default AccodionSpan;
