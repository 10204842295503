import React, { useContext } from "react";
import { Container, Row, Table } from "react-bootstrap";
import { Context as Authcontext } from "../../context/Authcontext";
import { getEmzaShemeshString, getGovaShemeshString, getMaslulShemesh, getMekomShemeshAmiti, getMenatMaslul } from "../../apis/orbits/orbitsGeneral";
import { VIEW_C13_STATE_OPEN, VIEW_C13_STATE_1, VIEW_C13_STATE_2, VIEW_C13_STATE_3, VIEW_C13_STATE_4, VIEW_C13_STATE_5, VIEW_C13_STATE_6, VIEW_C13_STATE_7, VIEW_C13_STATE_8, VIEW_C13_STATE_9, VIEW_C13_STATE_10, VIEW_C13_STATE_11, VIEW_C13_STATE_12, VIEW_C13_STATE_13, VIEW_C13_STATE_14, VIEW_C13_STATE_15, VIEW_C13_STATE_16, VIEW_C13_STATE_17, VIEW_C13_STATE_18, VIEW_C13_STATE_19, VIEW_C13_STATE_20, VIEW_C13_STATE_21, VIEW_C13_STATE_22, VIEW_C13_STATE_23, VIEW_C13_STATE_24, VIEW_C13_STATE_25, VIEW_C13_STATE_26, VIEW_C13_STATE_27, VIEW_C13_STATE_28, VIEW_C13_STATE_29, VIEW_C13_STATE_30 } from "../../screens/chapters/Chapter13";
import AccodionSpan from "../AccodionSpan";
import DaysFromIkar2 from "../controls/DaysFromIkar2";
import "./chaptersStyle.css"

const MekomShemeshAmiti = ({days}) => {

    const rows = () => {
        return (
        <>
            <tr>
                <td>מקום גובה השמש</td>
                <td>{getGovaShemeshString(days)}</td>
            </tr>
            <tr>
                <td>זוית מסלול השמש</td>
                <td>{getMaslulShemesh(days).maslulShemeshString}</td>
            </tr>
            <tr>
                <td>מקום אמצע השמש</td>
                <td>{getEmzaShemeshString(days)}</td>
            </tr>
            <tr>
                <td>זוית מנת מסלול השמש</td>
                <td>{getMenatMaslul(days).menatMaslulString}</td>
            </tr>
            <tr>
                <td>מקום השמש האמיתי</td>
                <td>{getMekomShemeshAmiti(days).shemeshAmitiString}</td>
            </tr>
        </>
        );
    };

    return (
        <div  style={{ textAlign: 'center' }}>
            <Table responsive bordered dir="rtl" color="red">
                <thead className="info-header">
                    <tr>
                        <th colSpan="2">חישוב מקום השמש האמיתי</th>                        
                    </tr>
                </thead>
                <tbody className="info-body">
                    {rows()}
                </tbody>
            </Table>
        </div>
    );
};

const Chapter13content = ({ setViewState, params }) => {
    const { state } = useContext(Authcontext);
    return (
        <Container className="chapter-container">
            <Row>
                <h4 className="chpter-header">פרק יג</h4>
                <h6 className="paragrph">מסלול השמש ומקום השמש האמיתי</h6>
                
                <p className="chapter-p" dir="rtl">
                    <strong className="span-6" onClick={() => setViewState(VIEW_C13_STATE_OPEN)}>א.</strong><strong> </strong>
                    אם תרצה לידע מקום השמש האמתי בכל יום שתרצה,
                    תוציא תחלה <span className="span-1" onClick={() => setViewState(VIEW_C13_STATE_1)}>מקומה האמצעי</span> לאותו היום על הדרך שביארנו,
                    ותוציא מקום <span className="span-2" onClick={() => setViewState(VIEW_C13_STATE_2)}>גובה השמש</span>,
                    ותגרע מקום גובה השמש ממקום השמש האמצעי והנשאר הוא הנקרא <span className="span-3" onClick={() => setViewState(VIEW_C13_STATE_3)}>מסלול השמש</span>.
                </p>

                <div className="chapter-p" dir="rtl">
                    <strong>ב. </strong>
                    ותראה כמה מעלות הוא מסלול השמש,
                    אם היה המסלול פחות ממאה ושמונים מעלות, <span className="span-4" onClick={() => setViewState(VIEW_C13_STATE_4)}>תגרע מנת המסלול ממקום השמש האמצעי</span>,
                    ואם היה המסלול יתר על מאה ושמונים מעלות עד שלש מאות וששים <span span className="span-5" onClick={() => setViewState(VIEW_C13_STATE_5)}>תוסיף מנת המסלול על מקום השמש האמצעי</span>,
                    ומה שיהיה אחר שתוסיף עליו או תגרע ממנו הוא המקום האמתי.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>ג. </strong>
                    ודע שאם יהיה המסלול <span className="span-1" onClick={() => setViewState(VIEW_C13_STATE_6)}>מאה ושמונים בשוה</span>
                    או <span className="span-2" onClick={() => setViewState(VIEW_C13_STATE_7)}>שלש מאות וששים בשוה</span>, אין לו מנה אלא יהיה המקום האמצעי הוא המקום האמתי.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>ד. </strong>
                    וכמה היא מנת המסלול, אם יהיה המסלול <span className="span-3" onClick={() => setViewState(VIEW_C13_STATE_8)}>עשר מעלות</span>, תהיה מנתו עשרים חלקים,
                    ואם יהיה <span className="span-4" onClick={() => setViewState(VIEW_C13_STATE_9)}>עשרים מעלות</span> תהיה מנתו ארבעים חלקים,
                    ואם יהיה <span className="span-5" onClick={() => setViewState(VIEW_C13_STATE_10)}>שלשים מעלות</span> תהיה מנתו שמונה וחמשים חלקים,
                    ואם יהיה <span className="span-1" onClick={() => setViewState(VIEW_C13_STATE_11)}>ארבעים מעלות</span> תהיה מנתו מעלה אחת וחמשה עשר חלקים,
                    ואם יהיה <span className="span-2" onClick={() => setViewState(VIEW_C13_STATE_12)}>חמשים מעלות</span> תהיה מנתו מעלה אחת ותשעה ועשרים חלקים,
                    ואם יהיה <span className="span-3" onClick={() => setViewState(VIEW_C13_STATE_13)}>ששים מעלות</span> תהיה מנתו מעלה אחת ואחד וארבעים חלקים,
                    ואם יהיה <span className="span-4" onClick={() => setViewState(VIEW_C13_STATE_14)}>שבעים מעלות</span> תהיה מנתו מעלה אחת ואחד וחמשים חלקים,
                    ואם יהיה <span className="span-5" onClick={() => setViewState(VIEW_C13_STATE_15)}>שמונים מעלות</span> תהיה מנתו מעלה אחת ושבעה וחמשים חלקים,
                    ואם יהיה <span className="span-1" onClick={() => setViewState(VIEW_C13_STATE_16)}>תשעים מעלות</span> תהיה מנתו מעלה אחת ותשעה וחמשים חלקים,
                    ואם יהיה <span className="span-2" onClick={() => setViewState(VIEW_C13_STATE_17)}>מאה מעלות</span> תהיה מנתו מעלה אחת ושמונה וחמשים חלקים,
                    ואם יהיה <span className="span-3" onClick={() => setViewState(VIEW_C13_STATE_18)}>מאה ועשר</span> תהיה מנתו מעלה אחת ושלשה וחמשים חלקים,
                    ואם יהיה <span className="span-4" onClick={() => setViewState(VIEW_C13_STATE_19)}>מאה ועשרים</span> תהיה מנתו מעלה אחת וחמשה וארבעים חלקים,
                    ואם יהיה <span className="span-5" onClick={() => setViewState(VIEW_C13_STATE_20)}>מאה ושלשים</span> תהיה מנתו מעלה אחת ושלשה ושלשים חלקים,
                    ואם יהיה <span className="span-1" onClick={() => setViewState(VIEW_C13_STATE_21)}>מאה וארבעים</span> תהיה מנתו מעלה אחת ותשעה עשר חלקים,
                    ואם יהיה <span className="span-2" onClick={() => setViewState(VIEW_C13_STATE_22)}>מאה וחמשים</span> תהיה מנתו מעלה אחת וחלק אחד,
                    ואם יהיה <span className="span-3" onClick={() => setViewState(VIEW_C13_STATE_23)}>מאה וששים</span> תהיה מנתו שנים וארבעים חלקים,
                    ואם יהיה <span className="span-4" onClick={() => setViewState(VIEW_C13_STATE_24)}>מאה ושבעים</span> תהיה מנתו אחד ועשרים חלקים,
                    ואם יהיה <span className="span-5" onClick={() => setViewState(VIEW_C13_STATE_25)}>מאה ושמונים</span> בשוה אין לו מנה כמו שביארנו אלא מקום השמש האמצעי הוא מקומה האמתי.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>ה. </strong>
                    היה המסלול יתר על מאה ושמונים מעלות, תגרע אותו משלש מאות וששים מעלות ותדע מנתו,
                    כיצד הרי שהיה המסלול <span className="span-1" onClick={() => setViewState(VIEW_C13_STATE_26)}>מאתים מעלות</span>,
                    תגרע אותו משלש מאות וששים תשאר מאה וששים מעלות,
                    וכבר הודענו שמנת מאה וששים מעלות שנים וארבעים חלקים,
                    וכן מנת המאתים שנים וארבעים חלקים.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>ו. </strong>
                    וכן אם היה המסלול <span className="span-2" onClick={() => setViewState(VIEW_C13_STATE_27)}>שלש מאות מעלות</span>, תגרע אותו משלש מאות וששים ישאר ששים,
                    וכבר ידעת שמנת ששים מעלות מעלה אחת ואחד וארבעים חלקים, וכן היא מנת השלש מאות מעלות, ועל דרך זו בכל מנין ומנין.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>ז. </strong>
                    הרי שהיה המסלול <span className="span-3" onClick={() => setViewState(VIEW_C13_STATE_28)}>חמש וששים מעלות</span>,
                    וכבר ידענו שמנת הששים היא מעלה אחת ואחד וארבעים חלקים, ומנת השבעים היא מעלה אחת ואחד וחמשים חלקים, נמצא בין שתי המנות עשרה חלקים, ולפי חשבון המעלות יהיה לכל מעלה חלק אחד, ויהיה מנת המסלול שהוא חמש וששים מעלה אחת וששה וארבעים חלקים.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>ח. </strong>
                    וכן אילו היה המסלול <span className="span-4" onClick={() => setViewState(VIEW_C13_STATE_29)}>שבע וששים</span>
                    היתה מנתו מעלה אחת ושמונה וארבעים חלקים, ועל דרך זו תעשה בכל מסלול שיהיה במניינו אחדים עם העשרות, בין בחשבון השמש בין בחשבון הירח.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>ט. </strong>
                    כיצד הרי שרצינו לידע מקום השמש האמתי בתחלת ליל השבת ארבעה עשר יום לחדש תמוז משנה זו,
                    תוציא אמצע השמש תחלה לעת הזאת, וסימנו ק"ה ל"ז כ"ה כמו שביארנו,
                    ותוציא מקום גובה השמש לעת הזאת, יצא לך סימנו פ"ו מ"ה כ"ג,
                    ותגרע מקום הגובה מן האמצעי, יצא לך המסלול שמונה עשרה מעלות ושנים וחמשים חלקים ושתי שניות, סימנם י"ח נב"ב,
                    ואל תקפיד בכל מסלול על החלקים אלא אם יהיו פחות משלשים אל תפנה אליהם, ואם היו שלשים או יתר תחשוב אותם מעלה אחת ותוסיף אותה על מנין מעלות המסלול,
                    לפיכך יהיה מסלול זה תשע עשרה מעלות ותהיה מנתו על הדרך שביארנו שמונה ושלשים חלקים.
                </div>
                <div className="chapter-p" dir="rtl">
                    <AccodionSpan setState={() => setViewState(VIEW_C13_STATE_30)} className="span-5" collapseItem={state.useContent === true ? <DaysFromIkar2 params={params}><MekomShemeshAmiti days={params.days} /></DaysFromIkar2> : <></>}>מקום השמש האמתי</AccodionSpan>
                </div>
                <div className="chapter-p" dir="rtl">
                    <strong>י. </strong>
                    ולפי שהמסלול הזה היה פחות ממאה ושמונים, תגרע המנה שהיא שמונה ושלשים חלקים מאמצע השמש ישאר מאה וארבע מעלות ותשעה וחמשים חלקים וחמש ועשרים שניות, סימנם ק"ד נ"ט כ"ה,
                    ונמצא מקום השמש האמתי בתחלת ליל זה במזל סרטן בחמש עשרה מעלות בו פחות (ל"ה) שניות,
                    ואל תפנה אל השניות כלל לא במקום השמש ולא במקום הירח ולא בשאר חשבונות הראייה, אלא חקור על החלקים בלבד, ואם יהיו השניות קרוב לשלשים עשה אותם חלק והוסיפו על החלקים.
                </div>
                <p></p>
                <div className="chapter-p" dir="rtl">
                    <strong>יא. </strong>
                    ומאחר שתדע מקום השמש בכל עת שתרצה, תדע יום התקופה האמתי כל תקופה שתרצה, בין תקופות הבאות אחר עיקר זה שממנו התחלנו, בין תקופות שעברו משנים קדמוניות.
                </div>
            </Row>
        </Container>
    );
};

export default Chapter13content;