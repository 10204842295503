import React from "react";
import { Container, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import { VIEW_C12_STATE_OPEN, VIEW_C12_STATE_1, VIEW_C12_STATE_2, VIEW_C12_STATE_3, VIEW_C12_STATE_4, VIEW_C12_STATE_5, VIEW_C12_STATE_6, VIEW_C12_STATE_7, VIEW_C12_STATE_8 } from "../../screens/chapters/Chapter12";
import AccodionSpan from "../AccodionSpan";
import DaysFromIkar from "../DaysFromIkar";
import "./chaptersStyle.css"


const Chapter12content = ({ setViewState, useContent, params }) => {
    const toolTip = (
        <Tooltip>מהלך גובה השמש קטן מכדי שיוכל להראות במצגת</Tooltip>
    );
    return (
        <Container className="chapter-container">
            <Row>
                <h4 className="chpter-header">פרק יב</h4>
               
                <h6 className="paragrph">מהלך השמש האמצעי</h6>
                <p className="chapter-p" dir="rtl">
                    <strong className="span-6" onClick={() => setViewState(VIEW_C12_STATE_OPEN)}>א.</strong><strong> </strong>
                    מהלך השמש האמצעי ביום אחד שהוא ארבע ועשרים שעות תשעה וחמשים חלקים ושמונה שניות, סימנם נט"ח,
                    נמצא <span className="span-1" onClick={() => setViewState(VIEW_C12_STATE_1)}>מהלכה בעשרה ימים</span> תשע מעלות ואחד וחמשים חלקים ושלש ועשרים שניות, סימנם טנ"א כ"ג,
                    ונמצא <span className="span-2" onClick={() => setViewState(VIEW_C12_STATE_2)}>מהלכה במאה יום</span> שמנה ותשעים מעלות ושלשה ושלשים חלקים ושלש וחמשים שניות, סימנם צ"ח ל"ג נ"ג,
                    ונמצא שארית <span className="span-3" onClick={() => setViewState(VIEW_C12_STATE_3)}>מהלכה באלף יום</span> אחר שתשליך כל שלש מאות וששים מעלות כמו שביארנו, מאתים וחמש וששים מעלות ושמונה ושלשים חלקים וחמשים שניות, סימנם רס"ה לח"נ.
                    ונמצא שארית מהלכה <span className="span-4" onClick={() => setViewState(VIEW_C12_STATE_4)}>בעשרת אלפים יום</span>, מאה שש ושלשים מעלות ושמונה ועשרים חלקים ועשרים שניות, סימנם קל"ו כח"כ.
                    ועל דרך זאת תכפול ותוציא מהלכה לכל מנין שתרצה, וכן אם תרצה לעשות סימנין ידועים אצלך למהלכה לשני ימים ולשלשה ולארבעה עד עשרה תעשה.
                    וכן אם תרצה להיות לך סימנין ידועים מוכנין למהלכה לעשרים יום ולשלשים ולארבעים עד מאה תעשה,
                    ודבר גלוי הוא וידוע מאחר שידעת מהלך יום אחד.
                    וראוי הוא להיות מוכן וידוע אצלך מהלך אמצע השמש לתשעה ועשרים יום ולשלש מאות וארבעה וחמשים יום שהן ימי שנת הלבנה בזמן שחדשיה כסדרן,
                    והיא הנקראת שנה סדורה, שבזמן שיהיו לך אמצעיות אלו מוכנין יהיה החשבון קל עליך לראיית הירח,
                    לפי שתשעה ועשרים יום גמורים מליל הראייה עד ליל הראייה של חדש הבא וכן בכל חדש וחדש אין פחות מתשעה ועשרים יום ולא יתר,
                    שאין חפצינו בכל אלו החשבונות אלא לדעת הראייה בלבד,
                    וכן מליל הראייה של חדש זה עד ליל הראייה לאותו החדש לשנה הבאה שנה סדורה או שנה ויום אחד,
                    וכן בכל שנה ושנה, ומהלך השמש האמצעי <span className="span-5" onClick={() => setViewState(VIEW_C12_STATE_5)}>לתשעה ועשרים יום</span> שמונה ועשרים מעלות וחמשה ושלשים חלקים ושניה אחת,
                    סימנן כ"ח לה"א,
                    <span className="span-1" onClick={() => setViewState(VIEW_C12_STATE_6)}>ומהלכה לשנה סדורה</span> שלש מאות ושמונה וארבעים מעלות וחמשה וחמשים חלקים וחמש עשרה שניות סימנן שמ"ח נ"ה ט"ו.
                </p>

                <h6 className="paragrph">גובה השמש</h6>
                <div className="chapter-p" dir="rtl">
                    <strong>ב. </strong>
                    נקודה אחת יש בגלגל השמש וכן בשאר גלגלי השבעה כוכבים,
                    בעת שיהיה הכוכב בה יהיה גבוה מעל הארץ כל מאודו,
                    ואותה הנקודה של גלגל השמש ושאר הכוכבים חוץ מן הירח סובבת בשוה,
                    ומהלכה בכל שבעים שנה בקירוב מעלה אחת, ונקודה זו היא הנקראת גובה.
                    <span className="span-2" onClick={() => setViewState(VIEW_C12_STATE_7)}>גובה השמש</span> <OverlayTrigger overlay={toolTip}><span className="span-3">מהלכו בכל עשרה ימים</span></OverlayTrigger> שניה אחת וחצי שניה שהיא שלשים שלישיות,
                    נמצא <OverlayTrigger overlay={toolTip}><span className="span-4">מהלכו במאה יום</span></OverlayTrigger> חמש עשרה שניות,
                    <OverlayTrigger overlay={toolTip}><span className="span-5">ומהלכו באלף יום</span></OverlayTrigger> שני חלקים ושלשים שניות,
                    <OverlayTrigger overlay={toolTip}><span className="span-6">ומהלכו בעשרת אלפים</span></OverlayTrigger> יום חמשה ועשרים חלקים,
                    ונמצא מהלכו לתשעה ועשרים יום ארבע שניות ועוד,
                    ומהלכו בשנה סדורה שלש וחמשים שניות.
                    כבר אמרנו שהעיקר שממנו ההתחלה בחשבון זה הוא מתחלת ליל חמישי שיומו שלישי לחדש ניסן משנת שמונה ושלשים ותשע מאות וארבעת אלפים ליצירה,
                    ומקום השמש במהלכה האמצעי היה בעיקר הזה בשבע מעלות ושלשה חלקים ושנים ושלשים שניות ממזל טלה,
                    סימנן ז"ג ל"ב,
                    ומקום גובה השמש היה בעיקר זה בשש ועשרים מעלות וחמשה וארבעים חלקים ושמונה שניות ממזל תאומים,
                    סימנם כ"ו מה"ח,
                    כשתרצה לידע מקום השמש במהלכה האמצעי בכל זמן שתרצה,
                    תקח מנין הימים שמתחלת יום העיקר עד היום שתרצה,
                    ותוציא מהלכה האמצעי באותן הימים מן הסימנין שהודענו,
                    ותוסיף הכל על העיקר ותקבץ כל מין עם מינו,
                    והיוצא הוא מקום השמש במהלכה האמצעי לאותו היום.
                    כיצד הרי שרצינו לידע מקום השמש האמצעי בתחלת ליל השבת שיומו ארבעה עשר יום לחדש תמוז משנה זו שהיא שנת העיקר,
                    מצאנו מנין הימים מיום העיקר עד תחלת יום זה שאנו רוצים לידע מקום השמש בו מאה יום,
                    לקחנו אמצע מהלכה למאה יום שהוא צ"ח ל"ג נ"ג והוספנו על העיקר שהוא ז"ג ל"ב,
                    יצא מן החשבון מאה וחמש מעלות ושבעה ושלשים חלקים וחמש ועשרים שניות,
                    <AccodionSpan setState={() => setViewState(VIEW_C12_STATE_8)} className="span-1" collapseItem={useContent === true ? <DaysFromIkar params={params}/> : <></>}> סימנן ק"ה ל"ז כ"ה,</AccodionSpan>
                    ונמצא מקומה במהלך אמצעי בתחלת ליל זה במזל סרטן בחמש עשרה מעלות בו ושבעה ושלשים חלקים ממעלת שש עשרה,
                    והאמצע שיצא בחשבון זה פעמים יהיה בתחלת הלילה בשוה,
                    או קודם שקיעת החמה בשעה, או אחר שקיעת החמה בשעה,
                    ודבר זה לא תחוש לו בשמש בחשבון הראייה,
                    שהרי אנו משלימים קירוב זה כשנחשוב לאמצע הירח,
                    ועל הדרך הזאת תעשה תמיד לכל עת שתרצה ואפילו אחר אלף שנים,
                    שתקבץ כל השארית ותוסיף על העיקר יצא לך המקום האמצעי,
                    וכן תעשה באמצע הירח ובאמצע כל כוכב וכוכב,
                    מאחר שתדע מהלכו ביום אחד כמה הוא ותדע העיקר שממנו תתחיל,
                    ותקבץ מהלכו לכל השנים והימים שתרצה ותוסיף על העיקר ויצא לך מקומו במהלך אמצעי,
                    וכן תעשה בגובה השמש תוסיף מהלכו באותם הימים או השנים על העיקר יצא לך מקום גובה השמש לאותו היום שתרצה.
                    וכן אם תרצה לעשות לך עיקר אחר שתתחיל ממנו חוץ מעיקר זה שהתחלנו ממנו בשנה זו,
                    כדי שיהיה אותו עיקר בתחלת שנת מחזור ידוע,
                    או בתחלת מאה מן המאות, הרשות בידך,
                    ואם תרצה להיות העיקר שתתחיל ממנו משנים שעברו קודם עיקר זה או לאחר כמה שנים מעיקר זה הדרך ידועה,
                    כיצד היא הדרך, כבר ידעת מהלך השמש לשנה סדורה ומהלכה לתשעה ועשרים יום ומהלכה ליום אחד,
                    ודבר ידוע שהשנה שחדשיה שלמים היא יתרה על הסדורה יום אחד,
                    והשנה שחדשיה חסרין היא חסרה מן הסדורה יום אחד,
                    והשנה המעוברת אם היו חדשיה כסדרן תהיה יתרה על השנה הסדורה שלשים יום,
                    ואם היו חדשיה שלמים היא יתרה על הסדורה אחד ושלשים יום,
                    ואם היו חדשיה חסרין היא יתרה על הסדורה תשעה ועשרים יום.
                    ומאחר שכל הדברים האלו ידועים תוציא מהלך אמצע השמש לכל השנים והימים שתרצה ותוסיף על העיקר שעשינו,
                    יצא לך אמצעה ליום שתרצה משנים הבאות, ותעשה אותו היום עיקר,
                    או תגרע האמצע שהוצאת מן העיקר שעשינו ויצא לך העיקר ליום שתרצה משנים שעברו,
                    ותעשה אותו אמצע עיקר,
                    וכזה תעשה באמצע הירח ושאר הכוכבים אם יהיו ידועים לך,
                    וכבר נתבאר לך מכלל דברינו שכשם שתדע אמצע השמש לכל יום שתרצה מימים הבאים כך תדע אמצעה לכל יום שתרצה מימים שעברו.
                </div>
            </Row>

        </Container>
    );
};


export default Chapter12content;