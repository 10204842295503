import React, { forwardRef, useContext, useImperativeHandle, useRef } from "react";
import { useState } from "react";
import { Row, Button, Col, Form, Modal, Nav } from "react-bootstrap";
import validator from "validator";
import { Context as Authcontext } from "../context/Authcontext";

import CreateOrVerifyEmail from "./CreateOrVerifyEmail";

import firebase from "firebase";
import "firebase/functions"


const ContactAndOrder = forwardRef(({ history, hidden = false, nessage }, ref) => {
    const { state } = useContext(Authcontext);
    const [show, setShow] = useState(false);

    const [isRambam, setRambam] = useState(false);
    const [isRefael, setRefael] = useState(false);
    const [comments, setComments] = useState('');
    const [isValidMsg, setValidMsg] = useState(true);
    const [errorSending, setErrorSending] = useState('');

    const validateMsg = () => {
        const v = isRambam || isRefael || comments.length > 1;
        setValidMsg(v);
        return v;
    };

    const signinOrVerifyRef = useRef(null);

    const handleClose = () => setShow(false);
    const handleShow =  () => {
        // return true if action nedded else false
        if (state.useContent !== true && state.email !== '' && state.verified) {
            setShow(true);
            return true;
        }

        return signinOrVerifyRef.current.showIfNeed();

    };

    useImperativeHandle(ref, () =>({
        showIfNeed() {
            // return true if action nedded else false
            return handleShow();
        }
    }));


    const [tel, setTel] = useState('');
    const [isTel, setIsTel] = useState(true);

    const validateMobile = () => {
        const v = validator.isMobilePhone(tel);
        setIsTel(v);
        return v;
    };

    const baseMsg = nessage? nessage : 'כדי להזמין או ליצור קשר';
    const orderRambam = 'תוכנת קידוש החודש לרמב"ם 300 ש"ח';
    const orderRefael = 'תוכנת הספר תכונת השמים 600 ש"ח';

    const buildHtml = () => {
        let html = '';
        html += `<p>${'שם'} ${state.username}</p>`;
        html += `<p>${'אימייל'} ${state.email}</p>`;
        html += `<p>${'טלפון'} ${tel}</p>`;
        if (isRambam) {
            html += `<p>${orderRambam}</p>`;
        }
        if (isRefael) {
            html += `<p>${orderRefael}</p>`;
        }
        if (comments.length > 0) {
            html += `<p>${comments}</p>`;
        }

        return html;
    };

    const buildText = () => {
        let text = '';
        text += `${'שם'} ${state.username}\r\n`;
        text += `${'אימייל'} ${state.email}\r\n`;
        text += `${'טלפון'} ${tel}\r\n`;
        if (isRambam) {
            text += `${orderRambam}\r\n`;
        }
        if (isRefael) {
            text += `${orderRefael}\r\n`;
        }
        if (comments.length > 0) {
            text += `${comments}\r\n`;
        }

        return text;

    }



    return (
        <div style={{ textAlign: 'center' }}>
            <Button variant="primary"
                hidden={hidden}
                onClick={handleShow}
                style={{ marginBottom: 20 }}
            >
                ליצירת קשר להזמנה
            </Button>

            <CreateOrVerifyEmail history={history} baseMsg={baseMsg} state={state} ref={signinOrVerifyRef} />


            <Modal style={{ margin: 5 }} show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title >ליצירת קשר להזמנה</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form style={{ margin: 5, padding: 3 }}>
                        {nessage? <Form.Group className="mb-3" controlId="formMaessage">
                            <Form.Label dir="rtl">{nessage}</Form.Label>
                        </Form.Group> : null}
                        <Form.Group as={Row} className="mb-3" controlId="formBasicTel">
                            <Form.Label column sm="2">טלפון</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    type="tel"
                                    value={tel}
                                    onChange={e => setTel(e.target.value)}
                                    isInvalid={!isTel}
                                    onBlur={() => validateMobile()}
                                />
                                {!isTel ? <Form.Text style={{ color: 'red' }}>
                                    יש למלט טלפון חוקי
                                </Form.Text> : null}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formBaseName">
                            <Form.Label column sm="2">שם</Form.Label>
                            <Col sm="10">
                                <Form.Control readOnly value={state.username} sm="10" />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formBaseEmail">
                            <Form.Label column sm="2">אימייל</Form.Label>
                            <Col sm="10">
                                <Form.Control readOnly value={state.email} sm="10" />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm="2">הזמנות</Form.Label>
                            <Col sm="10">
                                <Form.Check
                                    checked={isRambam}
                                    onChange={e => setRambam(e.target.checked)}
                                    onBlur={validateMsg}
                                    type="checkbox" label={orderRambam} />
                                <Form.Check
                                    checked={isRefael}
                                    onChange={e => setRefael(e.target.checked)}
                                    onBlur={validateMsg}
                                    type="checkbox" label={orderRefael} />
                                {!isValidMsg ? <Form.Text style={{ color: 'red' }}>
                                    יש לכתוב הערות או להזמין לפחות אחת מן התוכנות
                                </Form.Text> : null}

                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formBasicComment">
                            <Form.Label column sm="2">הערות</Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    as="textarea" rows={3}
                                    type="text"
                                    value={comments}
                                    onChange={e => setComments(e.target.value)}
                                    isInvalid={!isValidMsg}
                                    onBlur={validateMsg}

                                />
                                {!isValidMsg ? <Form.Text style={{ color: 'red' }}>
                                    יש לכתוב הערות או להזמין לפחות אחת מן התוכנות
                                </Form.Text> : null}
                            </Col>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        סגור
                    </Button>
                    <Button variant="primary" onClick={async () => {
                        if (validateMsg() && isTel) {
                            setErrorSending('');
                            const sendMailEx = firebase.functions().httpsCallable("sendMailEx");
                            const data = {
                                to: "hillelskolnik@gmail.com",
                                from: state.email,
                                subject: `${'יצירת קשר מ'} ${state.username}`,
                                text: buildText(),
                                html: buildHtml()
                            };

                            try {
                                const res = await sendMailEx(data);
                                history.push('/connectionsent');

                            } catch (error) {
                                setErrorSending('תקלה בשליחת ההודעה');
                            }
                        }
                    }}>
                        שלח הודעה
                    </Button>
                    {errorSending !== '' ? <Form.Text style={{ color: 'red' }}>
                        {errorSending}
                    </Form.Text> : null}

                </Modal.Footer>
            </Modal>

        </div>
    );

});

export default ContactAndOrder;