
import React from "react";
import { Container, Row, Button, Col } from "react-bootstrap";

const EmailSendedScreen = ({ match, history, location }) => {
    // console.log(location);
    const reason = location.search === '' ? "לאימות הכתובת" :
        decodeURIComponent(location.search.split('=')[1]);
    return (
        <>
            <Container>
                <Row>
                    <h3 className="text-center" dir="rtl">אימייל נשלח ל {match.params.email} {reason} </h3>
                </Row>
                <Row className="justify-content-center">
                    <Col sm={2} className="text-center">
                        <Button className="outline-danger" onClick={
                            () => history.push('/')
                        }>סגור</Button>
                    </Col>
                </Row>

            </Container>
        </>
    );
};

export default EmailSendedScreen;