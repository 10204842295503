import React, { useEffect, useRef, useContext, useState } from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";

import { AnalyticsContext } from "../../context/AnalyticsContext";
import DualPaneManager from "../DualPaneManager";
import { MetaTags } from "react-meta-tags";
import CreateOrVerifyEmail from "../../components/CreateOrVerifyEmail";
import ContactAndOrder from "../../components/ContactAndOrder";
import { Context as Authcontext } from "../../context/Authcontext";
import { ORDER_MESSAGE } from "../../apis/commonConsts";
import Chapter16Content from "../../components/chaptersContents/Chapter16Content";
import RochavHaYareach, { AUTO_OPEN_ROCHAV_YAREACH, AUTO_RUN_ROCHAV_YAREACH, C16_P10, C16_p11, MAHALACH_ROSH_ROCHAV_YAREACH, MEKOM_EMZA_ROSH_ROCHAV_YAREACH, ROSH_TEXT_ROCHAV_YAREACH, TWO_POINTS_ROCHAV_YAREACH, YAREACH_ROCHAV_YAREACH, ZANAV_TEXT_ROCHAV_YAREACH } from "../../components/3d/RochavHaYareach";
import { emzaRoshSpeed, getMahalchRosh, getYareachAmiti } from "../../apis/orbits/orbitsGeneral";
import { SpeedUnits } from "../../utiles/hebrew-calculator";

export const VIEW_C16_STATE_OPEN = 0;

export const VIEW_C16_STATE_1 = 1;
export const VIEW_C16_STATE_2 = 2;
export const VIEW_C16_STATE_3 = 3;
export const VIEW_C16_STATE_4 = 4;
export const VIEW_C16_STATE_5 = 5;
export const VIEW_C16_STATE_6 = 6;

export const VIEW_C16_STATE_7 = 7;
export const VIEW_C16_STATE_8 = 8;
export const VIEW_C16_STATE_9 = 9;
export const VIEW_C16_STATE_10 = 10;
export const VIEW_C16_STATE_11 = 11;

export const VIEW_C16_STATE_17 = 17;
export const VIEW_C16_STATE_18 = 18;

export const VIEW_C16_STATE_12 = 12;
export const VIEW_C16_STATE_13 = 13;
export const VIEW_C16_STATE_14 = 14;
export const VIEW_C16_STATE_15 = 15;

export const VIEW_C16_STATE_16 = 16;

export const VIEW_C16_STATE_19 = 19;
export const VIEW_C16_STATE_20 = 20;
export const VIEW_C16_STATE_21 = 21;
export const VIEW_C16_STATE_22 = 22;

export const VIEW_C16_STATE_23 = 23;
export const VIEW_C16_STATE_24 = 24;
export const VIEW_C16_STATE_25 = 25;
export const VIEW_C16_STATE_26 = 26;
export const VIEW_C16_STATE_27 = 27;
export const VIEW_C16_STATE_28 = 28;
export const VIEW_C16_STATE_29 = 29;
export const VIEW_C16_STATE_30 = 30;
export const VIEW_C16_STATE_31 = 31;

export const VIEW_C16_STATE_32 = 32;
export const VIEW_C16_STATE_33 = 33;
export const VIEW_C16_STATE_34 = 34;
export const VIEW_C16_STATE_35 = 35;

export const VIEW_C16_STATE_36 = 36;


const Chapter16 = ({ history }) => {

    const [viewState, setViewState] = useState(VIEW_C16_STATE_OPEN);
    const { state } = useContext(Authcontext);
    const { height, width, graphicWidth, paneHeight } = useWindowDimensions();
    const { analytics } = useContext(AnalyticsContext);

    const [days, setDays] = useState(29);

    const signinOrVerifyRef = useRef(null);
    const orderRef = useRef(null);

    const rochavYareachRef = useRef();

    useEffect(() => {
        analytics();
    }, []);

    useEffect(() => {
        if (viewState !== VIEW_C16_STATE_36) {
            return;
        }
        calculateRochav();
    }, [days]);

    const calculateRochav = () => {
        const y = getYareachAmiti(days).amiti;
        const mr = getMahalchRosh(days);
        rochavYareachRef.current.setMoonRosh(y, mr);
    };

    const startRosh = 45;
    const setState = () => {
        switch (viewState) {
            case VIEW_C16_STATE_OPEN:
                rochavYareachRef.current.setRunState(AUTO_OPEN_ROCHAV_YAREACH);
                break;
            case VIEW_C16_STATE_1:
                rochavYareachRef.current.setRunState(AUTO_RUN_ROCHAV_YAREACH);
                break;
            case VIEW_C16_STATE_2:
                rochavYareachRef.current.setRunState(YAREACH_ROCHAV_YAREACH);
                rochavYareachRef.current.setMoonRosh(-startRosh, startRosh);
                break;
            case VIEW_C16_STATE_3:
                rochavYareachRef.current.setRunState(YAREACH_ROCHAV_YAREACH);
                rochavYareachRef.current.setMoonRosh(-startRosh + 180, startRosh);
                break;
            case VIEW_C16_STATE_4:
                if (signinOrVerifyRef.current.showIfNeed()) {
                    break;
                }
                rochavYareachRef.current.setRunState(YAREACH_ROCHAV_YAREACH | ROSH_TEXT_ROCHAV_YAREACH);
                rochavYareachRef.current.setMoonRosh(-startRosh + 90, startRosh);
                break;
            case VIEW_C16_STATE_5:
                if (signinOrVerifyRef.current.showIfNeed()) {
                    break;
                }
                rochavYareachRef.current.setRunState(YAREACH_ROCHAV_YAREACH | ROSH_TEXT_ROCHAV_YAREACH | ZANAV_TEXT_ROCHAV_YAREACH);
                rochavYareachRef.current.setMoonRosh(-startRosh - 90, startRosh);
                break;
            case VIEW_C16_STATE_6:
                if (signinOrVerifyRef.current.showIfNeed()) {
                    break;
                }
                rochavYareachRef.current.setRunState(AUTO_RUN_ROCHAV_YAREACH | ROSH_TEXT_ROCHAV_YAREACH | ZANAV_TEXT_ROCHAV_YAREACH | TWO_POINTS_ROCHAV_YAREACH);
                break;
            case VIEW_C16_STATE_7:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                rochavYareachRef.current.setRunState(MAHALACH_ROSH_ROCHAV_YAREACH | ROSH_TEXT_ROCHAV_YAREACH | ZANAV_TEXT_ROCHAV_YAREACH | TWO_POINTS_ROCHAV_YAREACH);
                rochavYareachRef.current.setMoonRosh(0, emzaRoshSpeed.degreesCount * 100);
                break;
            case VIEW_C16_STATE_8:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                rochavYareachRef.current.setRunState(MAHALACH_ROSH_ROCHAV_YAREACH | ROSH_TEXT_ROCHAV_YAREACH | ZANAV_TEXT_ROCHAV_YAREACH | TWO_POINTS_ROCHAV_YAREACH);
                rochavYareachRef.current.setMoonRosh(0, emzaRoshSpeed.degreesCount * 1000);
                break;
            case VIEW_C16_STATE_9:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                rochavYareachRef.current.setRunState(MAHALACH_ROSH_ROCHAV_YAREACH | ROSH_TEXT_ROCHAV_YAREACH | ZANAV_TEXT_ROCHAV_YAREACH | TWO_POINTS_ROCHAV_YAREACH);
                rochavYareachRef.current.setMoonRosh(0, emzaRoshSpeed.degreesCount * 10000);
                break;
            case VIEW_C16_STATE_10:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                rochavYareachRef.current.setRunState(MAHALACH_ROSH_ROCHAV_YAREACH | ROSH_TEXT_ROCHAV_YAREACH | ZANAV_TEXT_ROCHAV_YAREACH | TWO_POINTS_ROCHAV_YAREACH);
                rochavYareachRef.current.setMoonRosh(0, emzaRoshSpeed.degreesCount * 354);
                break;
            case VIEW_C16_STATE_11:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                rochavYareachRef.current.setRunState(MAHALACH_ROSH_ROCHAV_YAREACH | ROSH_TEXT_ROCHAV_YAREACH | ZANAV_TEXT_ROCHAV_YAREACH | TWO_POINTS_ROCHAV_YAREACH);
                rochavYareachRef.current.setMoonRosh(0, new SpeedUnits(0, 180, 57, 28).degreesCount);
                break;
            case VIEW_C16_STATE_12:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                rochavYareachRef.current.setRunState(YAREACH_ROCHAV_YAREACH
                    | ROSH_TEXT_ROCHAV_YAREACH
                    | ZANAV_TEXT_ROCHAV_YAREACH
                    | TWO_POINTS_ROCHAV_YAREACH);
                rochavYareachRef.current.setMoonRosh(-startRosh, startRosh);
                break;
            case VIEW_C16_STATE_13:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                rochavYareachRef.current.setRunState(YAREACH_ROCHAV_YAREACH
                    | ROSH_TEXT_ROCHAV_YAREACH
                    | ZANAV_TEXT_ROCHAV_YAREACH
                    | TWO_POINTS_ROCHAV_YAREACH);
                rochavYareachRef.current.setMoonRosh(-startRosh + 180, startRosh);
                break;
            case VIEW_C16_STATE_14:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                rochavYareachRef.current.setRunState(YAREACH_ROCHAV_YAREACH
                    | ROSH_TEXT_ROCHAV_YAREACH
                    | ZANAV_TEXT_ROCHAV_YAREACH
                    | TWO_POINTS_ROCHAV_YAREACH);
                rochavYareachRef.current.setMoonRosh(-startRosh + 90, startRosh);
                break;
            case VIEW_C16_STATE_15:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                rochavYareachRef.current.setRunState(YAREACH_ROCHAV_YAREACH
                    | ROSH_TEXT_ROCHAV_YAREACH
                    | ZANAV_TEXT_ROCHAV_YAREACH
                    | TWO_POINTS_ROCHAV_YAREACH);
                rochavYareachRef.current.setMoonRosh(-startRosh - 90, startRosh);
                break;
            case VIEW_C16_STATE_16:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                rochavYareachRef.current.setRunState(AUTO_OPEN_ROCHAV_YAREACH);
                break;
            case VIEW_C16_STATE_17:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                rochavYareachRef.current.setRunState(MEKOM_EMZA_ROSH_ROCHAV_YAREACH |
                    ROSH_TEXT_ROCHAV_YAREACH | ZANAV_TEXT_ROCHAV_YAREACH | TWO_POINTS_ROCHAV_YAREACH);
                rochavYareachRef.current.setMoonRosh(0, new SpeedUnits(0, 182, 29, 37).degreesCount);
                break;
            case VIEW_C16_STATE_18:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                rochavYareachRef.current.setRunState(MEKOM_EMZA_ROSH_ROCHAV_YAREACH | MAHALACH_ROSH_ROCHAV_YAREACH |
                    ROSH_TEXT_ROCHAV_YAREACH | ZANAV_TEXT_ROCHAV_YAREACH | TWO_POINTS_ROCHAV_YAREACH);
                rochavYareachRef.current.setMoonRosh(0, new SpeedUnits(0, 182, 29, 37).degreesCount);
                break;
            case VIEW_C16_STATE_19:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                rochavYareachRef.current.setRunState(C16_P10);
                rochavYareachRef.current.setMoonRosh(-startRosh + 90, startRosh);
                break;
            case VIEW_C16_STATE_20:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                rochavYareachRef.current.setRunState(C16_P10);
                rochavYareachRef.current.setMoonRosh(-startRosh - 90, startRosh);
                break;
            case VIEW_C16_STATE_21:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                rochavYareachRef.current.setRunState(C16_P10);
                rochavYareachRef.current.setMoonRosh(-startRosh + 180, startRosh);
                break;
            case VIEW_C16_STATE_22:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                rochavYareachRef.current.setRunState(C16_P10);
                rochavYareachRef.current.setMoonRosh(-startRosh, startRosh);
                break;
            case VIEW_C16_STATE_23:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                rochavYareachRef.current.setRunState(C16_p11);
                rochavYareachRef.current.setMoonRosh(-startRosh + 10, startRosh);
                break;
            case VIEW_C16_STATE_24:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                rochavYareachRef.current.setRunState(C16_p11);
                rochavYareachRef.current.setMoonRosh(-startRosh + 20, startRosh);
                break;
            case VIEW_C16_STATE_25:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                rochavYareachRef.current.setRunState(C16_p11);
                rochavYareachRef.current.setMoonRosh(-startRosh + 30, startRosh);
                break;
            case VIEW_C16_STATE_26:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                rochavYareachRef.current.setRunState(C16_p11);
                rochavYareachRef.current.setMoonRosh(-startRosh + 40, startRosh);
                break;
            case VIEW_C16_STATE_27:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                rochavYareachRef.current.setRunState(C16_p11);
                rochavYareachRef.current.setMoonRosh(-startRosh + 50, startRosh);
                break;
            case VIEW_C16_STATE_28:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                rochavYareachRef.current.setRunState(C16_p11);
                rochavYareachRef.current.setMoonRosh(-startRosh + 60, startRosh);
                break;
            case VIEW_C16_STATE_29:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                rochavYareachRef.current.setRunState(C16_p11);
                rochavYareachRef.current.setMoonRosh(-startRosh + 70, startRosh);
                break;
            case VIEW_C16_STATE_30:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                rochavYareachRef.current.setRunState(C16_p11);
                rochavYareachRef.current.setMoonRosh(-startRosh + 80, startRosh);
                break;
            case VIEW_C16_STATE_31:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                rochavYareachRef.current.setRunState(C16_p11);
                rochavYareachRef.current.setMoonRosh(-startRosh + 90, startRosh);
                break;
            case VIEW_C16_STATE_32:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                rochavYareachRef.current.setRunState(C16_p11);
                rochavYareachRef.current.setMoonRosh(-startRosh + 53, startRosh);
                break;
            case VIEW_C16_STATE_33:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                rochavYareachRef.current.setRunState(C16_p11);
                rochavYareachRef.current.setMoonRosh(-startRosh + 150, startRosh);
                break;
            case VIEW_C16_STATE_34:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                rochavYareachRef.current.setRunState(C16_p11);
                rochavYareachRef.current.setMoonRosh(-startRosh + 200, startRosh);
                break;
            case VIEW_C16_STATE_35:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                rochavYareachRef.current.setRunState(C16_p11);
                rochavYareachRef.current.setMoonRosh(-startRosh + 300, startRosh);
                break;
            case VIEW_C16_STATE_36:
                if (orderRef.current.showIfNeed()) {
                    break;
                }
                rochavYareachRef.current.setRunState(C16_p11);
                calculateRochav();
                break;

            default:
                break;
        }
    };

    useEffect(() => {
        setState();
        rochavYareachRef.current?.setSise(paneHeight, graphicWidth);

    }, [height, width, viewState]);


    return (
        <div>
            <MetaTags>
                <title>הלכות קידוש החודש לרמב"ם פרק טז</title>
                <meta name="description" content='בפרק טז הלכות קידוש החודש לרמב"ם יבואר חישוב מקום הראש ומקום הזנב, ונטית הירח בעגולתו לצפון או לדרום והוא נקרא רוחב הירח.' />
            </MetaTags>
            <CreateOrVerifyEmail state={state} history={history} ref={signinOrVerifyRef} />
            <ContactAndOrder history={history} ref={orderRef} hidden={true} nessage={ORDER_MESSAGE} />
            <DualPaneManager
                graphicPane={<RochavHaYareach ref={rochavYareachRef} />}

                dataPane={< Chapter16Content
                    setViewState={setViewState}
                    params={{ days, setDays }} />}
                height={height}
                width={width}
            />

        </div>
    );
};

export default Chapter16;