import { useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import * as THREE from "three";
import { GRAPHIC_PADDING } from "../../apis/commonConsts";
import { loadBall, loadEarth, loadGradientRing, loadMoon, loadSun, loadTorus, planeText, plateTransperantText, RingText, RingTransperantText, transperantSphere } from "../../apis/loaders";
import { getAliyaYesharaAngle, keepIn360Range, turnYByZAndAlfa } from "../../apis/orbits/orbitsGeneral";
import { textAlfaMap, textTexture } from "../../apis/textures";
import { fixedTo15, Matrix, Point3D } from "../../utiles/hebrew-calculator";
import { getGraphicWidthHeight } from "../../utiles/helperFunctions";
import { setGroupPosition } from "../../utiles/threeDHelpers";

export const KESHET_ORECH_RISHON_AUTO_RUN = 1;
export const ORECH_RISHON = 2;
export const KESHET_SKIAA = 4;
export const KESHET_SKIAA_PATH = 8;

export const AUTO_RUN_KESHET_ORECH_RISHON_OPEN =
    KESHET_ORECH_RISHON_AUTO_RUN
    | ORECH_RISHON
    | KESHET_SKIAA;

export const C_17_P_1 = KESHET_ORECH_RISHON_AUTO_RUN | ORECH_RISHON;

export const C_17_P_3_1 = KESHET_ORECH_RISHON_AUTO_RUN
    | KESHET_SKIAA_PATH
    | ORECH_RISHON
    | KESHET_SKIAA;
;


const KeshetSkiaaOrechRishon = forwardRef((props, ref) => {

    const mountRef = useRef(null);

    const [cameraRef, setCameraRef] = useState(null);
    const [rendererRef, setRendererRef] = useState(null);
    const [width, setWidth] = useState(500);
    const [height, setHeight] = useState(500);

    const vars = useRef({
        stateToRun: AUTO_RUN_KESHET_ORECH_RISHON_OPEN,
        updateAllObject: null, // calback function
        moonPos: 0,
        orechRishon: 15,
        cycles: 0,
        yomiTurn: 0,
        counter: 0
    });

    const isStopRun = useRef(false);

    useEffect(() => {
        if (!cameraRef || !rendererRef) {
            return;
        }

        const { w, h } = getGraphicWidthHeight();

        cameraRef.aspect = w / h;
        cameraRef.updateProjectionMatrix();
        rendererRef.setSize(w, h);
    }, [height, width]);


    useImperativeHandle(ref, () => ({

        setRunState(runState) {
            vars.current.stateToRun = runState;
            vars.current.moonPos = 0;
            vars.current.orechRishon = 15;
            vars.current.cycles = 0;
            vars.current.yomiTurn = 0;
            vars.current.counter = 0;

            vars.current.updateAllObject();
        },
        setSise(h, w) {
            setWidth(w);
            setHeight(h);
            vars.current.updateAllObject();
        },
        setOrechRishon(or) {
            vars.current.orechRishon = or;
            vars.current.updateAllObject();
        }
    }));

    const isVisible = (state) => {
        return (state & vars.current.stateToRun) === state;
    };

    useEffect(() => {
        const { w, h } = getGraphicWidthHeight();


        var scene = new THREE.Scene();
        var camera = new THREE.PerspectiveCamera(60, w / h, 0.1, 20000);
        var renderer = new THREE.WebGLRenderer();


        renderer.setSize(w, h);


        mountRef.current.appendChild(renderer.domElement);

        const earthPoint = new Point3D(0, 0, -1000);

        const mazalotRadius = 320;

        const visualNetiya = 12.5;

        // const yareachPoint = new Point3D(-mazalotRadius);
        // const yareach = loadMoon(scene);
        // yareach.rotation.y = Math.PI;


        const { mesh: background } = loadGradientRing(scene, 2000, 2000);
        background.position.z = -3000;

        const earthRadius = 150;

        const earth = loadEarth(scene, earthRadius);
        earth.rotation.y = Math.PI / 180 * (234.5 + 90);

        const bottom_1_x = new THREE.Group();

        bottom_1_x.rotation.x = -Math.PI / 180 * 23.5;
        const mazalotStart_2_y = new THREE.Group();
        mazalotStart_2_y.add(bottom_1_x);
        mazalotStart_2_y.rotation.y = Math.PI / 2;
        const mazalotGlobalY_3_y = new THREE.Group();
        mazalotGlobalY_3_y.add(mazalotStart_2_y);


        const topTurnAll_y = new THREE.Group();
        topTurnAll_y.add(mazalotGlobalY_3_y);
        scene.add(topTurnAll_y);

        const mazalotWidth = 5;
        const mazalotB = transperantSphere(bottom_1_x, true, mazalotRadius, 0xff0000, mazalotWidth, true);
        const mazalotF = transperantSphere(bottom_1_x, false, mazalotRadius, 0xff0000, mazalotWidth, true);
        mazalotB.material.transparent = true;
        mazalotB.material.opacity = 0.5;

        mazalotF.renderOrder = 1;

        const tube = 2;
        const top_1_ofek_z = new THREE.Group();
        scene.add(top_1_ofek_z);
        const ofek_y = new THREE.Group();
        top_1_ofek_z.add(ofek_y);
        const ofek = loadTorus(ofek_y, mazalotRadius - 1 - tube, tube, 0x00aaff, Math.PI);
        ofek.rotation.z = Math.PI / 2;
        ofek_y.rotation.y = Math.PI / 2;
        top_1_ofek_z.rotation.z = Math.PI / 180 * 32;
        const jerusalem = loadBall(top_1_ofek_z, 10, 0xff0000);
        jerusalem.position.x = earthRadius;

        const moon = loadMoon(topTurnAll_y);
        const moonPoint = new Point3D(-mazalotRadius);

        const sunMesh = loadSun(topTurnAll_y, 15, (m, m2) => {
            m2.transparent = true;
            m2.opacity = 0.9;
            m.opacity = 0.7;
        });

        // loadTorus(scene, mazalotRadius - 1 - tube , tube);
        const moonOfekPoint = new Point3D(0, 0, mazalotRadius);
        const moonOfekMesh = loadBall(topTurnAll_y, 6, 0x00ffaa);

        const kshatotRadius = mazalotRadius - 1 - tube;

        const keshetOfekMoon_1_y = new THREE.Group();
        topTurnAll_y.add(keshetOfekMoon_1_y);
        const keshetOfekMoonMesh = loadTorus(keshetOfekMoon_1_y, kshatotRadius, 1, 0x00ffaa, Math.PI);
        keshetOfekMoonMesh.rotation.z = Math.PI / 2;

        const keshetMoon_1_y = new THREE.Group();
        topTurnAll_y.add(keshetMoon_1_y);
        const keshetMoonMesh = loadTorus(keshetMoon_1_y, kshatotRadius, 1, 0xffff00, Math.PI);
        keshetMoonMesh.rotation.z = Math.PI / 2;

        const arcInfo = Math.PI / 180 * 72;

        const radiusInfo = 440;
        const widthInfo = 55;
        const keshetSkiaaText = RingText(scene, () => {
            return textTexture("");
        }, radiusInfo, widthInfo, arcInfo);
        keshetSkiaaText.rotation.z = - Math.PI / 2 - arcInfo / 2;

        // const textY = 360;
        // const keshetSkiaaText = planeText();
        // keshetSkiaaText.position.y = -textY;
        // scene.add(keshetSkiaaText);

        const mazalotOlimYordimTextMesh = RingText(scene, () => {
            return textTexture("");
        }, radiusInfo, widthInfo, arcInfo);
        mazalotOlimYordimTextMesh.rotation.z = Math.PI / 2 - arcInfo / 2;
        // const mazalotOlimYordimTextMesh = planeText();
        // mazalotOlimYordimTextMesh.position.y = textY;
        // scene.add(mazalotOlimYordimTextMesh);

        const offekText = "אופק מערבי ירושלים";
        const ofekMaaravText = plateTransperantText(scene, offekText, 0x00aaff, 0.9);
        ofekMaaravText.rotation.z = -Math.PI / 180 * 58;
        ofekMaaravText.position.z = 160;
        ofekMaaravText.renderOrder = 0;

        const olimYordimArc = Math.PI / 180 * 130;
        const olimyordimRadius = 490;
        const olimYordimWidth = 100;
        const ringUpText = "מזלות עולים קשת שקיעה ארוכה";
        const mazalotOlotMesh = RingTransperantText(scene, () => { return textAlfaMap(ringUpText, 1, 896, 64, -1) },
            olimyordimRadius, olimYordimWidth, 0xffff88, olimYordimArc);
        mazalotOlotMesh.rotation.z = -olimYordimArc / 2;

        const ringDown = "מזלות יורדים קשת שקיעה קצרה";
        const mzalotYordotMesh = RingTransperantText(scene, () => { return textAlfaMap(ringDown, 1, 896, 64, -1) },
            olimyordimRadius, olimYordimWidth, 0xffccff, olimYordimArc);
        mzalotYordotMesh.rotation.z = Math.PI - olimYordimArc / 2;



        setGroupPosition(scene, earthPoint);
        setCameraRef(camera);
        setRendererRef(renderer);

        const mat = new Matrix();
        vars.current.updateAllObject = () => {
            const sun = keepIn360Range(vars.current.moonPos - vars.current.orechRishon);

            const turnYForMoon = getAliyaYesharaAngle(sun).deg;
            // const turnYForMoon = getAliyaYesharaAngle(vars.current.moonPos).deg;
            const gova23Sun = Math.asin(Math.sin(sun / 180 * Math.PI) * Math.sin(Math.PI / 180 * 23.5)) / Math.PI * 180;
            const ofekFixSun = turnYByZAndAlfa(32, gova23Sun).deg;

            mazalotGlobalY_3_y.rotation.y = (-ofekFixSun - turnYForMoon) / 180 * Math.PI;


            mat.init();
            mat.rotMulY(-vars.current.moonPos);
            mat.rotMulX(23.5);
            mat.rotMulY(turnYForMoon - 90 + ofekFixSun);
            const ptMoon = mat.transform(moonPoint);
            setGroupPosition(moon, ptMoon);

            mat.init();
            mat.rotMulY(-sun);
            mat.rotMulX(23.5);
            mat.rotMulY(turnYForMoon - 90 + ofekFixSun);
            const ptSun = mat.transform(moonPoint);
            setGroupPosition(sunMesh, ptSun);

            const gova23moon = Math.asin(Math.sin((vars.current.moonPos % 360) / 180 * Math.PI) * Math.sin(Math.PI / 180 * 23.5)) / Math.PI * 180;
            const ofekFixMoon = turnYByZAndAlfa(32, gova23moon).deg;

            mat.init();
            mat.rotMulX(gova23moon);
            mat.rotMulY(ofekFixMoon);
            const ptOfekMoon = mat.transform(moonOfekPoint);
            setGroupPosition(moonOfekMesh, ptOfekMoon);

            moonOfekMesh.visible = isVisible(KESHET_SKIAA);

            const keshetOfekMonAngle = (-ofekFixMoon + 90) / 180 * Math.PI;
            keshetOfekMoon_1_y.rotation.y = keshetOfekMonAngle;
            const keshetMoonAngle = -Math.atan2(ptMoon.z, ptMoon.x) + Math.PI;
            keshetMoon_1_y.rotation.y = keshetMoonAngle;

            keshetOfekMoon_1_y.visible = isVisible(KESHET_SKIAA);
            keshetMoon_1_y.visible = isVisible(KESHET_SKIAA);

            let keshetAngle = `${fixedTo15(keepIn360Range((keshetMoonAngle - keshetOfekMonAngle) * 180 / Math.PI), 1)}`;
            if (keshetAngle.length === 2) {
                keshetAngle += ".0"
            }
            keshetSkiaaText.material.map.dispose();
            keshetSkiaaText.material.map =
                textTexture(`${"אורך ראשון"} ${Math.round(vars.current.orechRishon)} ${isVisible(KESHET_SKIAA) ? `${"קשת השקיעה"} ${keshetAngle}` : ""}`,
                    "#ffff88", "900 66px Arial",
                    '#0000ff', '#0000ff', '#000088', '#ffffff',
                    1024, 128, 4, -1);

            const isYordim = ((vars.current.moonPos % 360) >= 90 && (vars.current.moonPos % 360) < 270);
            const textOlim = "מזלות עולים קשת שקיעה ארוכה";
            const textYordim = "מזלות יורדים קשת שקיעה קצרה"
            const mazalotOlimYordimText = isYordim ? textYordim : textOlim;

            mazalotOlimYordimTextMesh.material.map.dispose();
            mazalotOlimYordimTextMesh.material.map = textTexture(isVisible(KESHET_SKIAA) ? mazalotOlimYordimText : `${"אורך ראשון"} ${Math.round(vars.current.orechRishon)}`,
                isYordim ? "#ffccff" : "#ffff88", "900 66px Arial",
                '#ff0000', '#ff0000', '#880000', '#ffffff',
                1024, 128, 4, 1, -1);


            const shkiatKesktSkiaStrps = 3;
            const countingSum = 40;
            if (vars.current.stateToRun === C_17_P_3_1) {

                let step = vars.current.cycles % shkiatKesktSkiaStrps;
                if (step === 0) {
                    if (vars.current.counter < countingSum) {
                        vars.current.counter++;
                        vars.current.moonPos += 0.5
                    }
                    else {
                        vars.current.cycles++;
                        vars.current.counter = 0;
                    }

                }
                else if (step === 1) {
                    const keshetAng = keepIn360Range((keshetMoonAngle - keshetOfekMonAngle) * 180 / Math.PI);
                    if (keshetAng > vars.current.yomiTurn) {
                        vars.current.yomiTurn += 0.05;
                    } else {
                        vars.current.yomiTurn = keshetAng;
                        vars.current.cycles++;
                    }

                }
                else if (step === 2) {
                    if (vars.current.counter < countingSum) {
                        vars.current.counter++;
                    }
                    else {
                        vars.current.cycles++;
                        vars.current.counter = 0;
                        vars.current.yomiTurn = 0;

                    }

                }                
            }

            topTurnAll_y.rotation.y = -vars.current.yomiTurn / 180 * Math.PI;


        };

        const utoRunSteps = 4;
        const autoRun = () => {
            if (vars.current.stateToRun === AUTO_RUN_KESHET_ORECH_RISHON_OPEN) {
                let step = vars.current.cycles % utoRunSteps;
                const isMoonPosUpdate = step === 0 || step === 2;
                if (isMoonPosUpdate) {
                    vars.current.moonPos += 0.25;
                }

                if (vars.current.moonPos > 720 && isMoonPosUpdate) {
                    vars.current.moonPos -= 720;
                    vars.current.cycles++;
                    // vars.current.orechRishon = vars.current.cycles % 2 === 0 ? 15 : 24;
                }

                step = vars.current.cycles % utoRunSteps;
                const isOrechUpdate = step === 1 || step === 3;

                if (isOrechUpdate) {
                    vars.current.orechRishon = step === 1 ? vars.current.orechRishon + 0.1 : vars.current.orechRishon - 0.1;
                    if (step === 1 && vars.current.orechRishon >= 24) {
                        vars.current.orechRishon = 24;
                        vars.current.cycles++;
                    } else if (step === 3 && vars.current.orechRishon <= 15) {
                        vars.current.orechRishon = 15;
                        vars.current.cycles++;

                    }
                }

                vars.current.updateAllObject();
            }
            else if (vars.current.stateToRun === C_17_P_1) {
                vars.current.moonPos += 0.25;
                vars.current.orechRishon = Math.sin((vars.current.moonPos / 60) % (Math.PI * 2)) * 12 + 18;
                vars.current.updateAllObject();
            }
            else if (vars.current.stateToRun === C_17_P_3_1) {

                vars.current.updateAllObject();
            }
        };

        var animate = function () {
            if (isStopRun.current) {
                return;
            }
            requestAnimationFrame(animate);

            autoRun();

            renderer.render(scene, camera);
        };

        const copymountRef = mountRef;
        animate();

        return () =>  {
            mountRef.current?.removeChild(renderer.domElement);            
            copymountRef.current?.removeChild(renderer.domElement);
            isStopRun.current = true;
        } ;

    }, []);

    return (
        <div ref={mountRef} style={{ padding: GRAPHIC_PADDING }} />

    );

});

export default KeshetSkiaaOrechRishon;