import { useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import * as THREE from "three";
import { GRAPHIC_PADDING } from "../../apis/commonConsts";
import { colorSphere, cylinderLine, cylinderWithTexture, loadEarth, loadGradientRing, loadLinedRing, loadMazalotEx, loadSun, textueSphere } from "../../apis/loaders";
import { aliyaYesharaPoint, getAliyaYesharaAngle, turnYByZAndAlfa } from "../../apis/orbits/orbitsGeneral";
import { verticalGradientTexure } from "../../apis/textures";
import { Matrix, Point3D } from "../../utiles/hebrew-calculator";
import { getGraphicWidthHeight } from "../../utiles/helperFunctions";
import { setGroupPosition, updateGeometryMyMatrix } from "../../utiles/threeDHelpers";

export const AUTO_RUN_EMZAYAREACH_REIYA = 1;
export const RUN_FROM_OUT_EMZAYAREACH_REIYA = 2;

const EmzaYareachShaatReiya = forwardRef((props, ref) => {
    const mountRef = useRef(null);

    const [cameraRef, setCameraRef] = useState(null);
    const [rendererRef, setRendererRef] = useState(null);
    const [width, setWidth] = useState(500);
    const [height, setHeight] = useState(500);

    const vars = useRef({
        stateToRun: AUTO_RUN_EMZAYAREACH_REIYA,
        updateAllObject: null, // calback function
        mazal: 0

    });

    const isStopRun = useRef(false);

    useEffect(() => {
        if (!cameraRef || !rendererRef) {
            return;
        }

        const { w, h } = getGraphicWidthHeight();

        cameraRef.aspect = w / h;
        cameraRef.updateProjectionMatrix();
        rendererRef.setSize(w, h);
    }, [height, width]);

    useImperativeHandle(ref, () => ({

        setRunState(runState) {
            vars.current.stateToRun = runState;
            vars.current.mazal = 0;
            vars.current.updateAllObject();
        },
        setSise(h, w) {
            setWidth(w);
            setHeight(h);
            vars.current.updateAllObject();
        },
        setMazal(m) {
            vars.current.mazal = m;
            vars.current.updateAllObject();
        }
    }));


    const getJerusalemPoint = (earthRadius) => {
        const jDeg = 32;
        const z = Math.cos(jDeg / 180 * Math.PI) * earthRadius;
        const y = Math.sin(jDeg / 180 * Math.PI) * earthRadius;

        return new Point3D(0, y, z);
    };


    const getZturn = (turnY) => {
        return Math.asin(-aliyaYesharaPoint(turnY).y);
    };


    useEffect(() => {
        const { w, h } = getGraphicWidthHeight();


        var scene = new THREE.Scene();
        var camera = new THREE.PerspectiveCamera(60, w / h, 0.1, 20000);
        var renderer = new THREE.WebGLRenderer();

        renderer.setSize(w, h);


        mountRef.current.appendChild(renderer.domElement);

        const earthRadius = 300;
        const rayRadius = 400;
        const rayWidth = 100;

        const earthPoint = new Point3D(0, 0, -1000);

        const earth = loadEarth(scene, earthRadius, null, 40, 40);
        const jerusalem = colorSphere(scene, 5);
        const jerusalemPoin = getJerusalemPoint(earthRadius);
        jerusalem.position.z = jerusalemPoin.z;
        jerusalem.position.y = jerusalemPoin.y;

        const raysRing = loadLinedRing(scene, rayRadius, rayWidth);
        raysRing.material.transparent = true;
        raysRing.material.opacity = 0.8;

        const dayNight = textueSphere(scene, earthRadius + 2);
        dayNight.material.transparent = true;
        dayNight.material.opacity = 0.8;

        const sun = loadSun(scene, 35);
        const sunPoint = new Point3D(-(rayRadius - 10), 0, 0);



        const { geometry: mazalotGeometry, mazalot } = loadMazalotEx(scene, 470, true);

        const mat = new Matrix();
        mat.init();
        mat.rotMulX(-23.5);
        updateGeometryMyMatrix(mazalotGeometry, mat);

        const { mesh: background } = loadGradientRing(scene, 1900, 1900,
            verticalGradientTexure(354,
                [{ offset: 1, color: "#003388" }, { offset: 0.9, color: "#0055c7" }, { offset: 0, color: "#03045e" }]
                // [{ offset: 1, color: "#003388" }, { offset: 0.9, color: "#0055c7" }, { offset: 0.65, color: "#FF0000" }, { offset: 0, color: "#03045e" }]
            ));
        background.position.z = -2500;

        const lineLength = 100;
        const plusMinusLine = cylinderLine(scene, Math.cos(32) * (earthRadius + 10), lineLength);
        plusMinusLine.position.y = Math.sin(32) * earthRadius + lineLength / 2 - 4;

        const plusMinusText = cylinderWithTexture(scene, Math.cos(32) * (earthRadius + 5), lineLength);

        plusMinusText.position.y = Math.sin(32) * earthRadius + lineLength * 1.25;


        earth.rotation.y = 234.5 / 180 * Math.PI;

        camera.position.z = 5;
        setGroupPosition(scene, earthPoint);

        setCameraRef(camera);
        setRendererRef(renderer);


        vars.current.updateAllObject = () => {
            const rotZ = getZturn(vars.current.mazal);
            raysRing.rotation.z = rotZ;
            dayNight.rotation.z = rotZ + Math.PI;

            mat.init();
            mat.rotMulZ(rotZ * 180 / Math.PI);
            let pt = mat.transform(sunPoint);
            setGroupPosition(sun, pt);

            mazalot.rotation.y = getAliyaYesharaAngle(-vars.current.mazal + 180).rad//vars.current.mazal / 180 * Math.PI + Math.PI;
            plusMinusLine.rotation.y = -turnYByZAndAlfa(rotZ * 180 / Math.PI).rad
        };

        const autoRun = () => {
            const s = vars.current.stateToRun;
            if (s & AUTO_RUN_EMZAYAREACH_REIYA) {
                vars.current.mazal += 0.5;
                if (vars.current.mazal > 360) {
                    vars.current.mazal -= 360;
                }
                vars.current.updateAllObject();
            }
        };

        var animate = function () {
            if (isStopRun.current) {
                return;
            }
            requestAnimationFrame(animate);

            autoRun();

            renderer.render(scene, camera);
        };

        const copymountRef = mountRef;
        animate();

        return () => {
            mountRef.current?.removeChild(renderer.domElement);
            copymountRef.current?.removeChild(renderer.domElement);
            isStopRun.current = true;
        };
    }, []);


    return (
        <div ref={mountRef} style={{ padding: GRAPHIC_PADDING }} />

    );
});


export default EmzaYareachShaatReiya;